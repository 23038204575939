import PropTypes from 'prop-types';
import UserRoute from './UserRoute';

const ManagerRoute = ({component, ...rest}) => <UserRoute {...rest} ManagerComponent={component} />;

ManagerRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ManagerRoute;
