import lookup from 'country-code-lookup';

export const sortBroadcasters = (broadcasterStats, selectedDateRange, selectedSort) => (a, b) => {
  const dateRange = selectedDateRange;
  const aPlatform = `${a.platforms[0]}_viewership`;
  const bPlatform = `${b.platforms[0]}_viewership`;
  const aStats = broadcasterStats[dateRange] ? broadcasterStats[dateRange][a.username] : {};
  const bStats = broadcasterStats[dateRange] ? broadcasterStats[dateRange][b.username] : {};

  const totalVisits = (stats) =>
    stats && stats.clicks_by_type
      ? stats.clicks_by_type.banner_graphic_clicks +
        stats.clicks_by_type.twitch_chatbot_clicks +
        stats.clicks_by_type.twitter_clicks +
        stats.clicks_by_type.live_graphic_clicks +
        stats.clicks_by_type.twitch_command_clicks +
        stats.clicks_by_type.custom_link_clicks
      : 0;

  const getCVM = (stats, platform) => {
    const cvm = totalVisits(stats) / (stats[platform].viewable_minutes / 1000000) || 0;
    return Number.isFinite(cvm) ? cvm : -cvm;
  };

  switch (selectedSort) {
    case 'viewable-minutes':
      return aStats && bStats
        ? bStats[bPlatform].viewable_minutes - aStats[aPlatform].viewable_minutes
        : 0;
    case 'average-viewers':
      return aStats && bStats
        ? bStats[bPlatform].average_viewers - aStats[aPlatform].average_viewers
        : 0;
    case 'recorded-broadcasts':
      return aStats && bStats ? bStats[bPlatform].broadcasts - aStats[aPlatform].broadcasts : 0;
    case 'cvm':
      return aStats && bStats ? getCVM(bStats, bPlatform) - getCVM(aStats, aPlatform) : 0;
    case 'total-conversions':
      return aStats && bStats ? bStats.conversions - aStats.conversions : 0;
    default:
      return aStats ? totalVisits(bStats) - totalVisits(aStats) : 0;
  }
};

export const hasValidStatus = (broadcaster, showInactives, campaignSlug) =>
  showInactives
    ? ['Active', 'Campaign Ended', 'Pending', 'Flagged', 'Deactivated'].includes(
        broadcaster.campaign_statuses[campaignSlug].status,
      )
    : ['Active', 'Campaign Ended'].includes(broadcaster.campaign_statuses[campaignSlug].status);

export const broadcasterGamesAndCountries = (broadcaster, broadcasterStats) => {
  return (
    // concatenate all game names together
    (
      broadcasterStats && broadcasterStats[`${broadcaster.platforms[0]}_stream_time_by_game`]
        ? broadcasterStats[`${broadcaster.platforms[0]}_stream_time_by_game`].map(
            (g) => g.game || '',
          )
        : []
    )
      .map((entry) => entry.toLowerCase())
      .join('-')
      // concatenate all country names together
      .concat(
        (broadcasterStats && broadcasterStats.clicks_by_country
          ? broadcasterStats.clicks_by_country.map((c) => (c && c.country) || '')
          : []
        )
          .map((entry) => (lookup.byIso(entry) ? lookup.byIso(entry).country.toLowerCase() : ''))
          .join('-'),
      )
  );
};
