import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const CampaignTerms = ({campaign, field}) => (
  <div className="campaignTerms">
    {field === 'payment_terms' && (
      <div className="disclaimer">
        <p>
          {`Campaign Fees and payment terms for ${campaign.name} are found `}
          <a href={`https://adv.gg/docs/${campaign.name}`}>here</a>. At a high level:
        </p>
      </div>
    )}
    <div className="terms">
      <ReactMarkdown source={campaign[field]} />
    </div>
  </div>
);

CampaignTerms.propTypes = {
  campaign: PropTypes.object,
  field: PropTypes.string,
};

export default CampaignTerms;
