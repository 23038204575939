import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {isPending, hasFailed} from 'redux-saga-thunk';
import {useCallback} from 'react';

import InlineSelect from 'components/molecules/InlineSelect';
import {
  managementSetApprovedChatbotRequest,
  managementSetTeamApprovedChatbotRequest,
} from 'store/actions';
import {useCampaign, useTeam} from 'hooks';

const ChatbotSelect = ({errorMessage, chatbotScope, team}) => {
  const dispatch = useDispatch();
  const campaign = useCampaign();
  const campaignTeam = useTeam();
  const currentTeam = team || campaignTeam;
  const handleChange = useCallback(
    (chatbot) =>
      dispatch(
        chatbotScope === 'campaign'
          ? managementSetApprovedChatbotRequest(chatbot, campaign.slug)
          : managementSetTeamApprovedChatbotRequest(chatbot, currentTeam.id),
      ),
    [campaign.slug, chatbotScope, dispatch, currentTeam.id],
  );
  const formatFunction = useCallback(
    (chatbot) => ({
      value: chatbot,
      name: chatbot,
    }),
    [],
  );

  const options = currentTeam.approved_chatbots;
  const currentValue =
    chatbotScope === 'campaign' ? campaign?.twitch_chatbot_account : currentTeam?.default_chatbot;
  const loading = useSelector((state) => isPending(state, 'setApprovedChatbot'));
  const failed = useSelector((state) => hasFailed(state, 'setApprovedChatbot'));
  return (
    <InlineSelect
      handleChange={handleChange}
      formatFunction={formatFunction}
      currentValue={currentValue}
      errorMessage={errorMessage}
      options={options}
      loading={loading}
      failed={failed}
    />
  );
};

ChatbotSelect.propTypes = {
  errorMessage: PropTypes.string,
  chatbotScope: PropTypes.oneOf(['team', 'campaign']),
  team: PropTypes.object,
};

export default ChatbotSelect;
