import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {fromAutosuggest} from 'store/selectors';
import {autosuggestSuggestionsRequest, autosuggestSuggestionsClear} from 'store/actions';
import AutoSuggestComponent from './components/AutoSuggest';
import {isPending, hasFailed} from 'redux-saga-thunk';

const AutoSuggest = ({searchUrl, searchParam, extraParams = {}, ...rest}) => {
  const dispatch = useDispatch();
  const suggestions = useSelector((state) => fromAutosuggest.all(state));
  const loading = useSelector((state) => isPending(state, 'autosuggestRequest'));
  const failed = useSelector((state) => hasFailed(state, 'autosuggestRequest'));
  const updateSuggestions = useCallback(
    (value) => dispatch(autosuggestSuggestionsRequest(searchUrl, searchParam, value, extraParams)),
    [dispatch, extraParams, searchParam, searchUrl],
  );
  const clearSuggestions = useCallback(() => dispatch(autosuggestSuggestionsClear()), [dispatch]);
  return (
    <AutoSuggestComponent
      suggestions={suggestions}
      loading={loading}
      failed={failed}
      clearSuggestions={clearSuggestions}
      updateSuggestions={updateSuggestions}
      extraParams={extraParams}
      {...rest}
    />
  );
};

AutoSuggest.propTypes = {
  searchUrl: PropTypes.string,
  searchParam: PropTypes.string,
  extraParams: PropTypes.object,
};

export default AutoSuggest;
