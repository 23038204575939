import {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';

import Checkbox from 'components/atoms/Checkbox';
import MultiSelect from 'components/molecules/MultiSelect';

const optionalSections = [
  {
    id: 'viewable_minutes',
    name: 'VMs',
  },
  {
    id: 'watched_hours',
    name: 'watched hours',
  },
  {
    id: 'hours_broadcast',
    name: 'hours broadcast',
  },
  {
    id: 'minutes_broadcast',
    name: 'stream time (min)',
  },
  {
    id: 'average_viewers',
    name: 'average viewers',
  },
  {
    id: 'peak_viewers',
    name: 'peak viewers',
  },
  {
    id: 'clicks',
    name: 'clicks',
  },
  {
    id: 'cvm',
    name: 'cvm',
  },
  {
    id: 'num_broadcasts',
    name: 'broadcast count',
  },
  {
    id: 'impressions',
    name: 'impressions',
  },
  {
    id: 'display_count',
    name: 'display count',
  },
  {
    id: 'video_impressions',
    name: 'video impressions',
  },
  {
    id: 'video_views',
    name: 'video views',
  },
];

const broadcasterOptionalSections = [
  {
    id: 'total_followers',
    name: 'followers',
  },
  {
    id: 'net_followers',
    name: 'followers gained',
  },
];

const reportSectionLabels = {
  clicks_by_type_table: 'Total Clicks By Component Type',
  clicks_by_target_table: 'Total Clicks By Target Link',
  clicks_by_country_table: 'Total Clicks And Percentages By Country',
  clicks_by_component_table: 'Clicks By Component Name',
  broadcaster_table: 'Data By Broadcaster',
  weekly_data_table: 'Data Broken Down By Week',
  monthly_data_table: 'Data Broken Down By Month',
  daily_data_table: 'Data Broken Down By Day',
  impressions_by_broadcaster: 'Daily Impressions By Broadcaster',
};

const ReportCustomization = ({reportSections}) => (
  <div className="reportCustomFields">
    <label htmlFor="Report Sections">Report Sections</label>
    <div className="content">
      {reportSections &&
        Object.keys(reportSections).map((section) => (
          <Fragment key={section}>
            <div className="autoActiveCheckbox">
              <p>{reportSectionLabels[section]}</p>
              <Field
                name="sections"
                component={Checkbox}
                checkboxclass="muiCheckbox"
                format={(value) => (value ? reportSections[section].include : undefined)}
                parse={(value) => {
                  const options = reportSections[section].optionalSections;
                  return {
                    ...reportSections,
                    [section]: {
                      include: value,
                      ...(options && {
                        optionalSections: reportSections[section].optionalSections,
                      }),
                    },
                  };
                }}
              />
            </div>
            {reportSections[section].optionalSections && reportSections[section].include && (
              <Field
                component={MultiSelect}
                title="Select report metrics"
                name={`sections.${section}.optionalSections`}
                items={
                  section === 'broadcaster_table'
                    ? [...optionalSections, ...broadcasterOptionalSections]
                    : optionalSections
                }
                selectedItems={reportSections[section].optionalSections}
                getsDerivedState
              />
            )}
          </Fragment>
        ))}
    </div>
  </div>
);

ReportCustomization.propTypes = {
  reportSections: PropTypes.object,
};

export default ReportCustomization;
