import PropTypes from 'prop-types';
import {Discord} from 'mdi-material-ui';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {setCookie} from 'redux-cookie';

import Button from 'components/molecules/Button';
import AdvModal from 'containers/AdvModal';
import useModal from 'hooks/useModal';

const DISCORD_MODAL_NAME = 'discord-modal-confirm';
const DISCORD_SCOPES = ['guilds', 'guilds.join', 'identify', 'email'];
const DISCORD_BASE_URL = 'https://discordapp.com/api/oauth2/authorize';
const DISCORD_FULL_URL = `${DISCORD_BASE_URL}?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${encodeURIComponent(
  DISCORD_REDIRECT_URL,
)}&response_type=code&scope=${DISCORD_SCOPES.join('%20')}`;

const DiscordDisplay = ({username}) => {
  const [modalShow] = useModal();
  const dispatch = useDispatch();
  const discordRedirect = useCallback(() => {
    // set the cookie for proper redirect back to the dashboard from `advocate-callbacks`
    dispatch(
      setCookie('advSourceDomain', window.location.host, {
        path: '/',
        domain: window.location.host.includes('localhost') ? 'localhost' : '.adv.gg',
      }),
    );

    // Redirect to the Discord OAuth page
    window.location.href = DISCORD_FULL_URL;
  }, [dispatch]);

  return (
    <div className="socialDisplay discord">
      <Discord />
      {!!username && (
        <div className="userDisplay">
          <h4>{`${username}`}</h4>
        </div>
      )}
      <Button handleClick={() => modalShow(DISCORD_MODAL_NAME)}>
        {username ? 'Change' : 'Connect your Discord account'}
      </Button>

      <AdvModal name={DISCORD_MODAL_NAME} contentLabel="Connect Your Discord Account">
        <label className="label" htmlFor="connect discord">
          Connect your Discord account
        </label>
        <p className="extraText">
          You will be added to the Advocate Discord server. Please remain in the server as long as
          you are participating in an Advocate campaign so we can reach you with any annoucements as
          needed.
        </p>
        <Button handleClick={() => discordRedirect()}>Connect</Button>
      </AdvModal>
    </div>
  );
};

DiscordDisplay.propTypes = {
  username: PropTypes.string,
};

export default DiscordDisplay;
