import PropTypes from 'prop-types';

import {Help} from 'mdi-material-ui';

const AdvTooltip = ({tooltip, className}) => (
  <span className={`advTooltip ${className || ''}`}>
    <span className="advTooltiptext">{tooltip}</span>
    <Help />
  </span>
);

AdvTooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default AdvTooltip;
