import PropTypes from 'prop-types';

import IconButton from 'components/components/IconButton';
import {Download} from 'mdi-material-ui';

const DownloadIcon = ({src, clickHandler}) => (
  <a href={src} download>
    <IconButton label="download" handleClick={clickHandler} download={src}>
      <Download />
    </IconButton>
  </a>
);

DownloadIcon.propTypes = {
  src: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
};

export default DownloadIcon;
