import {useState} from 'react';
import PropTypes from 'prop-types';
import Button from 'components/molecules/Button';
import {AdvModal} from 'containers';

const ComponentTypeSelectionModal = ({modalShow, modalClose, campaign, componentTypes}) => {
  const [selectedComponentTypeSlug, setSelectedComponentTypeSlug] = useState('live-graphics');

  const selectedType = componentTypes.find((type) => type.slug === selectedComponentTypeSlug) || {};

  const getNextModal =
    ['twitch-chat-bot', 'twitch-chat-bot-command'].includes(selectedComponentTypeSlug) &&
    (!campaign.twitch_chatbot_account || campaign.twitch_chatbot_account === 'advogg')
      ? 'no-chatbot-warning'
      : `${selectedComponentTypeSlug}-add`;

  return (
    <AdvModal name="add-new-component" contentLabel="Add New Component">
      <label htmlFor="labels">Select Component Type</label>
      <div className="adv-select">
        <select
          value={selectedComponentTypeSlug}
          onChange={(e) => setSelectedComponentTypeSlug(e.target.value)}
        >
          {componentTypes
            .filter(
              (type) =>
                campaign &&
                campaign.available_components &&
                campaign.available_components.includes(type.slug),
            )
            .map((type) => (
              <option value={type.slug} key={type.slug}>
                {type.name}
              </option>
            ))}
        </select>
      </div>
      <div className="extraText" />
      <div className="extraText">{selectedType.description}</div>
      <Button
        handleClick={() => {
          modalShow(getNextModal);
          modalClose('add-new-component');
        }}
      >
        Create
      </Button>
    </AdvModal>
  );
};

ComponentTypeSelectionModal.propTypes = {
  modalShow: PropTypes.func,
  modalClose: PropTypes.func,
  campaign: PropTypes.object,
  componentTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ComponentTypeSelectionModal;
