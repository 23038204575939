import {take, call, put, fork} from 'redux-saga/effects';
import {
  AUTOSUGGEST_SUGGESTIONS_REQUEST,
  autosuggestSuggestionsSuccess,
  autosuggestSuggestionsFailure,
} from './actions';

export function* suggestionsRequest(api, {searchUrl, params}, {thunk}) {
  try {
    const suggestions = yield call([api, api.get], searchUrl, {params});
    yield put(autosuggestSuggestionsSuccess(suggestions, thunk));
  } catch (error) {
    yield put(autosuggestSuggestionsFailure(error, thunk));
  }
}

// Watchers
export function* watchSuggestionsRequest(api) {
  while (true) {
    const {payload, meta} = yield take(AUTOSUGGEST_SUGGESTIONS_REQUEST);
    yield call(suggestionsRequest, api, payload, meta);
  }
}

export default function* sagas({api}) {
  yield fork(watchSuggestionsRequest, api);
}
