import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import moment from 'moment';

import {fromResources} from 'store/selectors';
import {useCampaignLabels} from 'hooks';
import useModal from 'hooks/useModal';

const useChannelPreview = (broadcaster) => {
  const {campaignSlug} = useParams();

  const labels = useCampaignLabels();
  const [modalShow] = useModal();

  const allFeaturedCampaigns = useSelector((state) =>
    fromResources.getResourceListByCampaign(state, 'featuredCampaigns', campaignSlug),
  );

  const featuredCampaigns = useMemo(() => {
    return allFeaturedCampaigns.filter((feature) => feature.broadcaster === broadcaster.username);
  }, [allFeaturedCampaigns, broadcaster.username]);

  const isFeatured = useMemo(() => {
    const currentlyFeatured = featuredCampaigns.filter((feature) => {
      const now = moment().format('YYYY-MM-DDTHH:mm:ssZ');
      const start = feature.start_time;
      const end = feature.end_time;
      return start <= now && end > now;
    });

    return currentlyFeatured.length > 0;
  }, [featuredCampaigns]);

  return {
    modalShow,
    labels,
    featuredCampaigns,
    isFeatured,
  };
};

export default useChannelPreview;
