import PropTypes from 'prop-types';
import {isEqual, isEmpty} from 'lodash';
import {useParams} from 'react-router-dom';
import {ViewSequential, ViewGrid, CheckDecagram} from 'mdi-material-ui';

import ToggleButton from 'components/components/ToggleButton';
import InlineSelect from 'components/molecules/InlineSelect';
import Button from 'components/molecules/Button';

const CampaignChannelsOptions = ({
  labels,
  labelsLoading,
  submitUpdatedLabel,
  modalShow,
  campaignIsEditable,
  options,
}) => {
  const {campaignSlug} = useParams();
  const {multiselectMode, selectedChannelsView, editingLabel} = options;

  return (
    <div className="preset_campaign-page-options">
      <span>
        {multiselectMode.val && selectedChannelsView.val && (
          <InlineSelect
            type="large"
            handleChange={(value) =>
              editingLabel.set(
                labels.find((l) => l.name === value) || {
                  ...editingLabel.val,
                  campaign: campaignSlug,
                  name: value,
                },
              )
            }
            currentValue={editingLabel.val.name}
            options={labels}
            formatFunction={(label) => ({...label, value: label.name})}
          />
        )}
        {multiselectMode.val && selectedChannelsView.val && (
          <Button handleClick={() => modalShow('campaign-channels-label-add')}>
            Add New Group
          </Button>
        )}
        {multiselectMode.val && selectedChannelsView.val && (
          <Button
            loading={labelsLoading}
            handleClick={() => {
              !isEmpty(editingLabel.val)
                ? submitUpdatedLabel(editingLabel.val).then(
                    multiselectMode.set(!multiselectMode.val),
                  )
                : multiselectMode.set(!multiselectMode.val);
            }}
            disabled={isEqual(
              editingLabel.val,
              labels.find((l) => l.name === editingLabel.val.name),
            )}
          >
            Save Group Changes
          </Button>
        )}
        {campaignIsEditable && labels.length > 0 && selectedChannelsView.val && (
          <Button
            type={multiselectMode.val ? 'cancel' : ''}
            handleClick={() => {
              multiselectMode.set(!multiselectMode.val);
              editingLabel.set(labels[0]);
            }}
          >
            {!multiselectMode.val ? 'Manage Groups' : 'Exit Group selection'}
          </Button>
        )}
        {!multiselectMode.val && (
          <Button type="hasIcon" handleClick={() => modalShow('add-campaign-feature')}>
            <CheckDecagram />
            Feature This Campaign
          </Button>
        )}
        {!multiselectMode.val && (
          <ToggleButton
            selectedIndex={selectedChannelsView.val}
            handleClick={() => selectedChannelsView.set(!selectedChannelsView.val)}
            keys={['ViewGrid', 'ViewSequential']}
          >
            <span>
              <ViewGrid />
              <p>Grid</p>
            </span>
            <span>
              <ViewSequential />
              <p>Detailed</p>
            </span>
          </ToggleButton>
        )}
      </span>
    </div>
  );
};

CampaignChannelsOptions.propTypes = {
  options: PropTypes.object,
  labels: PropTypes.arrayOf(PropTypes.object),
  labelsLoading: PropTypes.bool,
  submitUpdatedLabel: PropTypes.func,
  modalShow: PropTypes.func,
  campaignIsEditable: PropTypes.bool,
};

export default CampaignChannelsOptions;
