import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {PencilOutline, EyeOutline, Close} from 'mdi-material-ui';

import SelectWithIcon from 'components/molecules/SelectWithIcon';

const permissions = {
  readWrite: {
    label: 'Read/Write',
    value: 0,
    icon: (
      <span className="readWrite">
        <PencilOutline />
      </span>
    ),
  },
  readOnly: {
    label: 'Read Only',
    value: 1,
    icon: (
      <span className="readOnly">
        <EyeOutline />
      </span>
    ),
  },
  noAccess: {
    label: 'No Access',
    value: 2,
    icon: (
      <span className="noAccess">
        <Close />
      </span>
    ),
  },
};

const PermissionsField = ({permissionsList, ...rest}) => {
  const items = useMemo(() => {
    return permissionsList.map((permission) => permissions[permission]);
  }, [permissionsList]);

  return <SelectWithIcon items={items} {...rest} />;
};

PermissionsField.propTypes = {
  permissionsList: PropTypes.arrayOf(PropTypes.string),
};

export default PermissionsField;
