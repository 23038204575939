const IconDashboard = () => (
  <svg version="1.1" viewBox="0 0 612 547">
    <circle display="none" fill="#D1D3D4" cx="304" cy="284.272" r="161" />
    <g>
      <path
        d="M558.638,80.917c-0.008-25.911-20.781-46.992-46.309-46.992h-0.005l-403.394,0.149
      c-25.536,0.01-46.302,21.106-46.292,47.027l0.145,388.316c0.009,25.913,20.785,46.994,46.311,46.994l403.397-0.15
      c12.583-0.004,24.784-5.303,33.473-14.536c8.272-8.792,12.824-20.331,12.819-32.494L558.638,80.917z M512.478,481.262
      l-403.381,0.15c0,0-0.002,0-0.002,0c-6.246,0-11.309-5.375-11.312-12.007L97.638,81.089c-0.002-6.633,5.06-12.012,11.306-12.014
      l403.38-0.149c0.001,0,0.003,0,0.003,0h0.001c6.244,0,11.308,5.374,11.31,12.005l0.145,388.316
      C523.785,475.88,518.723,481.26,512.478,481.262z"
      />
      <path
        d="M438.037,124.669H310.71H183.383c-9.665,0-17.5,7.835-17.5,17.5v266c0,9.665,7.835,17.5,17.5,17.5H310.71h127.327
      c9.665,0,17.5-7.835,17.5-17.5v-133c0-0.273-0.028-0.539-0.041-0.809c0.013-0.27,0.041-0.536,0.041-0.809V142.169
      C455.537,132.504,447.702,124.669,438.037,124.669z M420.537,256.052H328.21v-96.383h92.327V256.052z M200.883,159.669h92.327
      v113.883v1.617v115.5h-92.327V159.669z M420.537,390.669H328.21v-98h92.327V390.669z"
      />
    </g>
  </svg>
);

export default IconDashboard;
