import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {
  resourceCreateRequest,
  resourceUpdateRequest,
  managementGetStatusesRequest,
} from 'store/actions';
import {required, createValidator, isDateBeforeDate} from 'services/validation';

const useCampaignCreationForm = ({isUpdating, campaign, team}) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (data) => {
      // If creating the campaign will also automatically add broadcasters, we
      // need to fetch the broadcaster statuses before completing the campaign
      // creation step
      const chainedCalls = isUpdating
        ? dispatch(
            resourceUpdateRequest('campaigns', campaign?.slug, {
              ...data,
            }),
          )
        : new Promise((resolve, reject) => {
            dispatch(
              resourceCreateRequest('campaigns', {
                ...data,
                auto_active_invites: !data.auto_active_invites,
                is_finite: !!data.end_date,
                budget: 1,
                target: 1,
                set_live_graphic_times: !!team,
                broadcasters_to_add:
                  !data.team_auto_add && data.broadcaster_participation
                    ? data.broadcaster_participation.broadcasters_to_add
                    : [],
                squads:
                  !data.team_auto_add && data.broadcaster_participation
                    ? data.broadcaster_participation.squads
                    : [],
                team: team?.id,
              }),
            ).then(
              (response) => {
                const campaignSlug = Object.keys(response.campaigns)[0];
                // If campaign creation was successful, get broadcaster statuses
                dispatch(managementGetStatusesRequest('campaigns', campaignSlug)).then(
                  () => resolve(response),
                  (error) => reject(error),
                );
              },
              (error) => reject(error),
            );
          });

      return chainedCalls;
    },
    [campaign?.slug, dispatch, isUpdating, team],
  );

  const validate = (values, props) =>
    createValidator({
      name: [required],
      start_date: [required, isDateBeforeDate(values.end_date)],
    })(values, props);

  return {
    validate,
    onSubmit,
  };
};

export default useCampaignCreationForm;
