import PropTypes from 'prop-types';
import {Plus} from 'mdi-material-ui';

import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Button from 'components/molecules/Button';
import DeleteButton from 'components/components/DeleteButton';
import DeleteModal from 'components/organisms/DeleteModal';
import CampaignLabelForm from '../../../../forms/CampaignLabelForm';
import {fromResources} from 'store/selectors';
import AdvModal from 'containers/AdvModal';
import useModal from 'hooks/useModal';

const CampaignLabelList = ({readOnly}) => {
  const {campaignSlug} = useParams();
  const [modalShow] = useModal();
  const campaignLabels = useSelector((state) =>
    fromResources.getResourceListByCampaign(state, 'labels', campaignSlug),
  );

  return (
    <div className="campaign-label-list">
      {!readOnly && (
        <Button
          type="campaign-label-add-button"
          className="add-button"
          handleClick={() => modalShow('campaign-label-add')}
        >
          <Plus />
        </Button>
      )}
      <div className="content">
        {!campaignLabels.length && (
          <h3 className="emptyText">Add a group to selectively distribute components.</h3>
        )}
        {campaignLabels.map((label) => (
          <div className="campaign-label" key={label.name}>
            {!readOnly && (
              <DeleteButton handleClick={() => modalShow(`campaign-label-${label.id}-delete`)} />
            )}
            <span className="campaign-label-target">{label.name}</span>
          </div>
        ))}
      </div>
      <div className="title">Campaign Groups</div>

      {/* Modals */}
      {!readOnly && (
        <AdvModal name="campaign-label-add" contentLabel="Add New Campaign Label">
          <CampaignLabelForm campaign={campaignSlug} modalName="campaign-label-add" />
        </AdvModal>
      )}

      {!readOnly &&
        campaignLabels.map((label) => (
          <DeleteModal
            modalName={`campaign-label-${label.id}-delete`}
            resource="labels"
            objectType="label"
            objectName={label.name}
            objectNeedle={label.id}
            key={`campaign-label-${label.id}-delete`}
          />
        ))}
    </div>
  );
};

CampaignLabelList.propTypes = {
  readOnly: PropTypes.bool,
};

export default CampaignLabelList;
