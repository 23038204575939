import PropTypes from 'prop-types';

const TermsOfService = ({isManager, setHasReadTos}) => {
  // slight offset so that checking that the user reached the bottom
  // doesn't need to be pixel perfect
  const checkOffset = 300;

  const updateRead = (event) => {
    if (
      event.target.scrollHeight - event.target.scrollTop <
      event.target.clientHeight + checkOffset
    ) {
      setHasReadTos(true);
    }
  };
  if (isManager) {
    return (
      <div className="termsOfService" id="termsOfService" onScroll={updateRead}>
        <p>
          THESE TERMS OF SERVICE (THESE &ldquo;
          <strong>TERMS</strong>
          &rdquo;) ESTABLISH THE TERMS AND CONDITIONS THAT APPLY TO YOU WHEN YOU USE THE ADVOCATE
          PLATFORM (AS DEFINED BELOW). BY USING THE ADVOCATE PLATFORM, YOU INDICATE YOUR ACCEPTANCE
          OF THESE TERMS AND YOUR AGREEMENT TO BE BOUND BY THESE TERMS, AS WELL AS ALL APPLICABLE
          LAWS AND REGULATIONS. YOU ARE NOT PERMITTED TO USE THE ADVOCATE PLATFORM IF YOU DO NOT
          AGREE TO THESE TERMS. THESE TERMS CAN BE CHANGED, MODIFIED, SUPPLEMENTED, AND/OR UPDATED
          BY ADVOCATES INC. (&ldquo;
          <strong>ADVOCATE</strong>
          ,&rdquo; &ldquo;
          <strong>WE</strong>, &ldquo;
          <strong>OUR</strong>
          ,&rdquo; OR &ldquo;
          <strong>US</strong>
          &rdquo;) AT ANY TIME; PROVIDED THAT WE WILL ENDEAVOR TO PROVIDE YOU WITH PRIOR NOTICE OF
          ANY MATERIAL CHANGES. YOUR CONTINUED USE OF THE ADVOCATE PLATFORM AFTER THE MODIFICATION
          OF THESE TERMS MEANS THAT YOU ACCEPT ALL SUCH CHANGES. ACCORDINGLY, YOU ARE ADVISED TO
          CONSULT THESE TERMS EACH TIME YOU ACCESS THE ADVOCATE PLATFORM IN ORDER TO VIEW ANY
          CHANGES TO THESE TERMS. THESE TERMS WERE LAST MODIFIED AS OF THE DATE INDICATED ABOVE.
        </p>
        <p>&nbsp;</p>
        <p>
          AS FURTHER DESCRIBED BELOW, THESE TERMS PROVIDE FOR THE USE OF ARBITRATION ON AN
          INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO
          LIMITS THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.&nbsp; YOU CAN OPT-OUT OF
          THIS AGREEMENT TO ARBITRATE BY CONTACTING
          <a href="mailto:ADMIN@ADV.GG">
            <strong>ADMIN@ADV.GG</strong>
          </a>{' '}
          WITHIN 30 DAYS&nbsp;OF FIRST ACCEPTING THESE TERMS AND STATING THAT YOU (INCLUDE YOUR
          FIRST AND LAST NAME) DECLINE THIS ARBITRATION AGREEMENT.
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>1. Use of Web Site, Mobile Applications, and our Service</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            a. The &ldquo;
            <strong>Advocate Platform</strong>
            &rdquo; is Advocate&rsquo;s website located at&nbsp;adv.gg and any related mobile
            applications, as the same may be updated, relocated, or otherwise modified from time to
            time, including through networks, embeddable widgets, downloadable software, and tablet
            computer applications, and all intellectual property contained therein. The Advocate
            Platform provides a services and/or self-service platform to enable companies, brand
            representatives, ad agencies, non-profits, etc. (collectively &ldquo;
            <strong>Brands</strong>
            &rdquo;) and esports teams, multi-channel networks, YouTube content creators, Discord
            content creators, Twitch content creators, social media Content Creators, or other
            content creators/Content Creators (collectively, &ldquo;
            <strong>Content Creators</strong>
            &rdquo; or &ldquo;
            <strong>Members</strong>
            &rdquo;) to connect, collaborate, execute, and manage advertising and promotional
            campaigns (&ldquo;
            <strong>Campaigns</strong>
            &rdquo;).&nbsp; Any person who accesses and/or uses the Advocate Platform, whether on
            his or her own behalf or on behalf of any third party, will be referred to herein as an
            &ldquo;
            <strong>Advocate User</strong>
            &rdquo;.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            b. Subject to the terms and conditions of these Terms, Advocate hereby grants you a
            limited, revocable, non-exclusive, non-transferable right to access and use the Advocate
            Platform during the term of these Terms. Unless otherwise specified in writing, the
            Advocate Platform is solely for your internal use and not for resale. Advocate reserves
            the right at all times and without notice to: (i) restrict and/or terminate your access
            to the Advocate Platform (or any portion thereof); and (ii) modify or discontinue
            providing the Advocate Platform (or any portion thereof).
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            c. Advocate&rsquo;s policy with respect to the collection and use of your personally
            identifiable information is set in our
            <a target="_blank" rel="noopener noreferrer" href="https://adv.gg/docs/privacy">
              Privacy Policy
            </a>
            . By accepting these Terms, you acknowledge your agreement with Advocate&rsquo;s
            <a target="_blank" rel="noopener noreferrer" href="https://adv.gg/docs/privacy">
              Privacy Policy
            </a>
            .
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            d. Our services or self-services evolve constantly. We reserve the right to change our
            services or self-services, such as by changing, adding or removing features at any time,
            for any reason. We may or may not provide notice of those changes to you. We will not be
            liable to you or any third part for the modification, price change, suspension or
            discontinuance of any of our services or self-services.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            e. Our services and self-services sometimes interact with social networks, or other
            online platforms such as, but not limited to, broadcasting platforms or video-on-demand
            platforms (‘Third-Party Services”) and the features and functionality they make
            available to us, which we do not control and may change without notice. If at any time
            these Third-Party Services stops making some or all of its features or functionality
            available to us, or available to us on reasonable terms as determined by Advocate in its
            sole discretion, we may stop providing access to those features or functionality and we
            will not be liable to you or any third party for any such change.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>2. Registration, Accounts, Passwords and Security</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>a. Accounts and Registration</u>. To access most features of the Advocate Platform,
            you must register for an account. When you register for an account, you may be required
            to provide us with some information about yourself, such as your email address or other
            contact information. You agree that the information you provide to us is accurate and
            that you will keep it accurate and up-to-date at all times. You are solely responsible
            for maintaining the confidentiality of your account and password, and you accept
            responsibility for all activities that occur under your account. If you have reason to
            believe that your account is no longer secure, then you must immediately notify us at{' '}
            <a href="mailto:admin@adv.gg">admin@adv.gg</a>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>b. Single Sign-On</u>. You may be permitted to register for and log on to the
            Advocate Platform via certain third party services (such as a social network).&nbsp;
            Advocate may use the profile information connected to the account you use to log into
            the Advocate Platform, including your name, to provide the Advocate Platform as
            described in these Terms and otherwise support your account. Such third party&rsquo;s
            use of your information will be subject to that service&rsquo;s{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://adv.gg/docs/privacy">
              Privacy Policy
            </a>
            . Further information about linking your account and use of third party services to log
            on to the Advocate Platform can be found in our{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://adv.gg/docs/privacy">
              Privacy Policy
            </a>
            .
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>c. Eligibility</u>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            i. You represent and warrant that you are at least 18 years of age, that you have not
            been previously suspended or removed from the Advocate Platform, and that you possess
            the legal right and ability to enter into these Terms. THE ADVOCATE PLATFORM IS NOT FOR
            PERSONS UNDER THE AGE OF 18 OR ANY USERS PREVIOUSLY SUSPENDED OR REMOVED FROM THE
            ADVOCATE PLATFORM BY ADVOCATE.&nbsp; IF YOU ARE UNDER 18 YEARS OF AGE, PLEASE DO NOT USE
            OR ACCESS THE ADVOCATE PLATFORM AT ANY TIME OR IN ANY MANNER.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            ii. If you are using the Advocate Platform on behalf of an individual, company, entity,
            or organization (each, an &ldquo;
            <strong>Organization</strong>
            &rdquo;), you represent and warrant that (A) you are an authorized representative of
            such Organization with the authority to bind such Organization to these Terms, (B) agree
            to be bound by these Terms on behalf of such Organization, and (C) your Organization
            meets the eligibility requirements for the Advocate Platform, as set forth in these
            Terms. Further, you will be solely responsible for ensuring your Organization complies
            with these Terms.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>3. Your Responsibilities</strong>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            You may use the Advocate Platform solely for lawful purposes, as intended through the
            provided functionality of the Advocate Platform. You may not use the Advocate Platform
            in any manner that could damage, disable, overburden, or impair our servers or networks,
            or interfere with any other party&rsquo;s use and enjoyment of the Advocate Platform.
            You may not attempt to gain unauthorized access to the Advocate Platform, user accounts,
            or computer systems or networks, through hacking, password mining or any other means.
            Without limiting any of the foregoing, you expressly agree that you will not (and you
            agree not to allow or assist any third party to):
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            a. use, copy, install, transfer or distribute the Advocate Platform, except as
            specifically permitted by these Terms;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            b. modify, adapt, translate, reverse engineer, decompile, or disassemble any portion of
            the Advocate Platform;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            c. remove or alter any copyright, trademark or other proprietary rights notices
            contained in or on the Advocate Platform or in or on any content or other material
            obtained through the Advocate Platform or the use of the Advocate Platform;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            d. rent, lease, or otherwise permit third parties to use the Advocates Platform or use
            the Advocates Platform to provide services to third parties (e.g., as a service bureau);
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>e. create user accounts under false or fraudulent pretenses;</li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            f. use any robot, spider, screen or database scraper, site search or retrieval
            application, or other automated device, process or means to access, retrieve or index
            any portion of the Advocate Platform;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            g. probe, scan or test the vulnerability of any system or network or breach any security
            or authentication measures;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            h. reformat, mirror, or frame any portion of the web pages that are part of the Advocate
            Platform;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            i. express or imply that any statements you make are endorsed by us, without our prior
            written consent in each instance;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            j. transmit (i) any content or information that is unlawful, fraudulent, threatening,
            abusive, libelous, defamatory, obscene or otherwise objectionable, or infringes on our
            or any third party&rsquo;s intellectual property or other rights, (ii) any material,
            non-public information about individuals or companies without the authorization to do
            so, (iii) any trade secret of any third party, and/or (iv) any advertisements,
            solicitations, chain letters, pyramid schemes, investment opportunities, or other
            unsolicited commercial communication (except as otherwise expressly permitted by us), or
            engage in spamming or flooding;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            k. transmit any software or other materials that contain any virus, worm, time bomb,
            Trojan horse, or other harmful or disruptive component; use any robot, spider, site
            search/retrieval application, or other manual or automatic device or process to
            retrieve, index, &ldquo;data mine&rdquo;, or in any way reproduce or circumvent the
            navigational structure or presentation of the Advocate Platform or its contents;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            l. harvest or collect information about other users without their prior written consent;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            m. undertake, cause, permit or authorize the translation, reverse engineering,
            disassembling or hacking of any aspect of the Advocate Platform or attempt to do any of
            the foregoing, except and solely to the extent permitted by these Terms, the Advocate
            Platform&rsquo;s authorized features, or by law, or otherwise attempt to use or access
            any portion of the Advocate Platform other than as intended by Advocate;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            n. access, tamper with, or use non-public areas of the Advocate Platform,
            Advocate&rsquo;s (and its hosting company&rsquo;s) computer systems and infrastructure,
            or the technical delivery systems of Advocate&rsquo;s providers;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            o. harass, abuse, harm, or advocate or incite harassment, abuse, or harm of another
            person or group, including Advocate employees and other users;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            p. create a new account with Advocate, without Advocate&rsquo;s express written consent,
            if Advocate previously disabled an account of yours;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            q. solicit, or attempt to solicit, personal information from other users, except as
            permitted through the Advocate Platform&rsquo;s functionality;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            r. restrict, discourage or inhibit any person from using the Advocate Platform, disclose
            personal information about a third person on the Advocate Platform or obtained from the
            Advocate Platform without the consent of such person, or collect information about
            users;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            s. gain unauthorized access to the Advocate Platform, to other users&rsquo; accounts,
            names or personally identifiable information, or to other computers or websites
            connected or linked to the Advocate Platform;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            t. violate any applicable federal, state or local laws, regulations, or these Terms;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            u. use the Advocate Platform for any illegal, inappropriate, and/or unauthorized
            conduct, including without limitation, using the Advocate Platform to contact other
            Advocate Users for sexual or other inappropriate purposes, or using the Advocate
            Platform in violation of Advocate&rsquo;s or any third party&#39;s intellectual property
            or other proprietary or legal rights; or
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>v. use or access the Advocate Platform to build a competing service.</li>
        </ol>
        <p>&nbsp;</p>
        <p>Additionally and without limiting any of the foregoing, you expressly confirm that:</p>
        <p>&nbsp;</p>
        <ol>
          <li>
            a. You will only use our services or self-services for business and professional
            reasons;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            b. You are at least 18 years old or otherwise able to enter into a legally binding
            agreement;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>c. You accept and will comply with this Agreement;</li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            d. If you are using our services or self-services as a representative of an
            organization, you have the power to enter into legally binding agreements for the
            organization; and
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            e. You are responsible for anyone that uses our services or self-services through your
            account, such as your employees, consultants, contractors, or other third parties.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          We may take any legal action and implement any technical remedies to prevent the violation
          of these provisions and to enforce these Terms.
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>4. Campaigns</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>a. Acceptance of Campaigns</u>. Advocate may offer Campaigns to Brands and Content
            Creators from time to time via the Advocate Platform. Content Creators and Brands who
            wish to participate in a Campaign must accept the proposed Campaign(s) in the manner and
            timeframe specified by Advocate.&nbsp; You will perform agreed-upon Campaigns in
            accordance with their terms,&nbsp; which may involve the placing of graphics, messages,
            material and/or other content on the live broadcasts of Content Creators. Agreement by
            Content Creators to participate in a Campaign shall constitute consent to the placing of
            such graphics, messages, material and/or other content related to such Campaign on such
            live broadcasts.&nbsp; Content Creators acknowledge that Campaigns may be performed by
            multiple Content Creators at the same or different times, and that the identity of other
            Content Creators who have accepted or are performing a Campaign is not material to
            Content Creator&rsquo;s acceptance of such Campaign and is not a permitted reason to
            terminate participation in an agreed-upon Campaign.&nbsp;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>b. Campaign Fees</u>. Brands will pay to Advocates the fees set out in each
            agreed-upon Campaign in accordance with the payment terms provided therein. Advocate
            will pay Content Creators in accordance with the fee schedule and payment terms set
            forth on the Content Creator’s Broadcaster Dashboard for participation in agreed-upon
            Campaigns. You are responsible for any withholdings, taxes, agency, and/or other fees
            due on and/or in connection with the total fees paid or payable to you under Campaigns.
            Advocate will not reimburse for any costs or expenses incurred in performing a Campaign
            unless expressly provided in the applicable Campaign.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>c. FTC Rules</u>. Each Advocate User is solely responsible for complying with all
            applicable laws and regulations relating to Campaigns, including without limitation, the
            Federal Trade Commission regulations on endorsements and truth in advertising.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>5. Consent to Electronic Communications</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>a. Consent to Electronic Communications</u>. By using the Advocate Platform or
            providing Personal Information (as defined in the{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://adv.gg/docs/privacy">
              Privacy Policy
            </a>
            ) to us, you agree that we may communicate with you electronically regarding security,
            privacy, and administrative issues relating to your use of the Advocate Platform. If we
            learn of a security system&rsquo;s breach, we may attempt to notify you electronically
            by posting a notice on the Advocate Platform or sending an email to you, if we have your
            email address. You may have a legal right to receive this notice in writing. To receive
            free written notice of a security breach (or to withdraw your consent from receiving
            electronic notice), please write to us at <a href="mailto:admin@adv.gg">admin@adv.gg</a>
            .
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            b. If you wish to remove yourself from any list (other than as set forth in Section
            5.a), please email us with an &quot;OPT-OUT&quot;, &quot;UNSUBSCRIBE&quot;,
            &quot;STOP&quot; or &quot;REMOVE&quot; in the subject line.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            c. Depending on your current carrier plan, you may incur charges for these Messages and
            Notifications and agree to not hold Advocate liable for any charges incurred. You
            acknowledge that any terms between you and any third-party provider (such as, for
            example, Apple&reg;, Android&trade;, or Verizon) create no obligation or responsibility
            on the part of Advocate, and that Advocate is not responsible for any failure of
            warranty by any such third party.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            d. Advocate cannot control certain factors relating to message delivery. You acknowledge
            that, depending on your mobile carrier&rsquo;s service, it may not be possible to
            transmit a text message to you successfully. We have no liability for transmission
            delays or message failures.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>6. Content </strong>
            <strong>Rights and Licenses</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>a. User Content</u>. Advocate Users may be able to upload, post, or otherwise submit
            content, such as profile information, photo, video, etc. (
            <strong>“Profile Content”</strong>) to the Advocate Platform. If you are a Content
            Creator and you participate in a Campaign via the Advocate Platform, you may be required
            to upload, post, or otherwise provide certain content to Advocate or to Brands directly
            to execute the Campaign such as Content Creator’s name, nickname, initials, autograph,
            signature, social media handle, blog name, photograph, likeness, caricature, image,
            voice, statements, biographic information, copy, photographs, videos, and other
            information (<strong>“Content Creator Property”</strong>
            ). If you are a Brand and you participate in a Campaign via the Advocate Platform, you
            may be required to upload, post, or otherwise provide certain content to Advocate or to
            Content Creators directly to execute the Campaign such as the Brand’s trademarks, logos,
            company name, brand information, copy, statements, photographs, videos, and other
            information (<strong>“Brand Content”</strong>
            ). Profile Content, Content Creator Property, and Brand Content are collectively
            referred to as <strong>“User Content.”</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>b. License to Advocate</u>. You hereby grant Advocate a world-wide, non-exclusive,
            sublicensable, royalty-free, transferable, limited license to use, modify, host, store,
            remove, publish, perform, reproduce, transmit, or display User Content that you submit
            to the Advocate Platform in order to facilitate the display and use of such User Content
            in accordance with the applicable settings specified in your account and the features of
            the Advocate Platform you elect to utilize. Further, if you enter into a Campaign, you
            hereby grant Advocate a nonexclusive, royalty-free, fully paid, irrevocable,
            transferable, worldwide license (with the right to grant and authorize sublicenses to
            Brands and Content Creators where applicable) to make, have made, use, import, offer for
            sale, sell, reproduce, distribute, modify, adapt, prepare derivative works of (&ldquo;
            <strong>Derivative Works</strong>
            &rdquo;), display, publicly perform (including on a through-to-the-audience basis), and
            otherwise use any User Content solely to execute any agreed-upon Campaign(s) in
            accordance with its terms. If provided for in a Campaign, Content Creator authorizes
            Advocate (or the Brand specified in the Campaign) to photograph, record, tape, film,
            video and otherwise visually and audiovisually record Content Creator’s image, likeness,
            and voice (“
            <strong>Recorded Content</strong>
            ”) to create Derivative Works in connection with Campaigns.{' '}
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>c. Limited License Grant to Other Users</u>. By posting or sharing User Content with
            other Advocate Users in connection with a Campaign, you grant those Advocate Users a
            non-exclusive license to use that User Content in order to perform any agreed-upon
            Campaigns in accordance with their terms, and in accordance with the applicable settings
            specified in your account and the features of the Advocate Platform you elect to
            utilize.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>d. Survival of Certain Rights</u>. If, during the term of a Campaign, Advocate (or
            the Brand specified in the Campaign) creates any Derivative Works or Recorded Content,
            using your Content Creator Property, you hereby grant to Advocate a royalty-free, fully
            paid, non-exclusive, irrevocable, transferable, worldwide license (with the right to
            grant and authorize sublicenses to Brands if specified in the applicable Campaign) to
            use any such Content Creator Property solely to the extent embodied in such Derivative
            Works or Recorded Content perpetually notwithstanding the expiration or termination of
            this Agreement, for any purpose and in any medium now known or hereinafter
            devised.&nbsp;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>d. Survival of Certain Rights</u>. If, during the term of a Campaign, Advocate (or
            the Brand specified in the Campaign) creates any Derivative Works or Recorded Content,
            using your Content Creator Property, you hereby grant to Advocate a royalty-free, fully
            paid, non-exclusive, irrevocable, transferable, worldwide license (with the right to
            grant and authorize sublicenses to Brands if specified in the applicable Campaign) to
            use any such Content Creator Property solely to the extent embodied in such Derivative
            Works or Recorded Content perpetually notwithstanding the expiration or termination of
            this Agreement, for any purpose and in any medium now known or hereinafter
            devised.&nbsp;
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>e. Waiver of Certain Rights</u>. Advocate is under no obligation to use any User
            Content, and Advocate may cease any permitted use of User Content at any time, with or
            without notice, and without any liability to you. You waive any rights to privacy,
            publicity, or any other rights of a similar nature in connection with the permitted use
            of your User Content. Except to the extent expressly set forth in the applicable
            Campaign, Content Creator will make no monetary or other claim against Advocate for use
            of your User Content as permitted by this Agreement.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>f. Data Retention; Data Analytics</u>. Advocate is not responsible for performing,
            and is not liable for any failure to perform, any back-up of any data provided,
            processed, or stored by an Advocate User in or through the Advocate Platform. Advocate
            may collect and use technical data and related information, including, but not limited
            to, UDID, and other technical information about Advocate Users&rsquo; devices, systems,
            application software, and peripherals, that is gathered periodically to facilitate the
            provision of software updates, product support, and other services to Advocate Users
            related to the Advocate Platform, and to track and report Advocate Users&rsquo; activity
            inside of the Advocate Platform. Advocates may use this information for analytics
            purposes to specifically identify and compare how various Advocate Users execute
            Campaigns, to identify successful techniques and pitfalls in executing Campaigns, and to
            create reports of the same to share with Brands.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>g. Representations and Warranties</u>. You represent and warrant to Advocate
            that:&nbsp; (i) you have the full right, power, and authority to grant the rights set
            forth in this Agreement and that doing so will not cause you to breach any other
            agreement to which you are bound or a party; (ii) you will perform your obligations
            under each agreed Campaign in compliance with all applicable laws and regulations,
            including without limitation, the Federal Trade Commission regulations on endorsements
            and truth in advertising; (iii) your User Content will not (A) infringe, violate, or
            misappropriate any third-party right, including any copyright, trademark, patent, trade
            secret, moral right, privacy right, right of publicity, or any other intellectual
            property or proprietary right or (B) slander, defame, or libel any other person; (iv)
            you have not misrepresented or concealed anything with respect to your background that
            may have a prejudicial effect on the value of any Campaign; (v) that you have not
            engaged and will not engage during any Campaign in any activity (criminal or otherwise)
            that could potentially have a negative impact on Advocate or other Advocate Users with
            whom you are engaged via a Campaign.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>h. Further Assurances</u>
            .You will, upon Advocate&rsquo;s reasonable request, furnish affidavits and other
            appropriate documentation of endorsement that may be required, in Advocate&rsquo;s
            reasonable judgment, to comply with any applicable governmental or other regulations,
            broadcast clearance procedures, or industry guidelines relating to each Campaign.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>7. Term and Termination</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>a. Term</u>. The term of these Terms (the &ldquo;
            <strong>Term</strong>
            &rdquo;) will commence on the date on which you first access or utilize the Advocate
            Platform in any way (the &ldquo;
            <strong>Effective Date</strong>
            &rdquo;) and will continue until terminated as provided herein.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>b. Termination</u>. Advocate may immediately terminate these Terms or any Campaign at
            any time for any reason upon written notice to you. An Advocate User may terminate these
            Terms at any time for any reason upon written notice to Advocate if no active Campaign
            is in effect with such Advocate User.&nbsp; Any Advocate User may terminate any Campaign
            or these Terms:
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>i. If Advocate is adjudicated insolvent or declares bankruptcy; or</li>
          <li>
            ii. If Advocate materially breaches this Agreement and, after receiving written notice
            of the breach, Advocates fails to cure the breach within 30 days.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>c. Effect of Termination</u>. Upon termination of these Terms or any Campaign,
            Advocate will pay to Content Creator any compensation due to Content Creator for
            services performed prior to the effective date of termination. The following Sections of
            these Terms will survive termination or expiration of these Terms: Introductory
            paragraphs, 1.a, 1.c, 4.c, 5, 6.d - 6.h, 7 - 25.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>8. Ownership</strong>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            The Advocate Platform is owned and operated by Advocate. The visual interfaces,
            graphics, design, compilation, information, data, computer code (including source code
            or object code), products, software, services, and all other elements of the Advocate
            Platform (&ldquo;
            <strong>Materials</strong>
            &rdquo;) provided by Advocate are protected by intellectual property and other laws. All
            Materials included in the Advocate Platform are the property of Advocate or our
            third-party licensors. Except as expressly authorized by Advocate, you may not make use
            of the Materials. Advocate reserves all rights to the Materials not granted expressly in
            these Terms.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>9. Non-Circumvention</strong>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            You acknowledge and agree that a substantial portion of the compensation Advocate
            receives for making the Advocate Platform available to you is collected through
            Campaigns managed via the Advocate Platform. Advocate only receives this compensation
            when a Brand and a Content Creator transact through the Advocate Platform. Therefore,
            for 24 months from the time you identify or are identified by any party through the
            Advocate Platform (the “<strong>Non-Circumvention Period</strong>
            ”), you must use the Advocate Platform as your exclusive method to request, make, and
            receive all payments for work directly or indirectly with that party or arising out of
            your relationship with that party (the “<strong>Advocate Relationship</strong>
            ”). You may opt-out of this obligation with respect to each Brand-Content Creator
            relationship only if the Brand or Content Creator pays Advocate for each such
            relationship:
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>a.</u> an “<strong>Opt-Out Fee</strong>” computed to be the greater of the following
            amounts:
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>i. $2,500; or</li>
          <li>
            ii. 15% of the cost to the Brand of the services to be performed in the Advocate
            Relationship during the Non-Circumvention Period, as estimated in good faith; or
          </li>
          <li>
            iii. all fees that would be earned by Advocate from the Advocate Relationship during the
            Non-Circumvention Period, computed based on the annualized amount earned by Content
            Creator from Brand during the most recent normalized 8-week period, or during such
            shorter period as data is available to Advocate; and
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>b.</u> interest at the rate of 18% per annum or the maximum rate permitted by
            applicable law, whichever is less, calculated from the date Brand first makes payment to
            the subject Content Creator until the date the Opt-Out Fee is paid.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>c.</u> To pay the Opt-Out Fee, you must request instructions by sending an email
            message to <a href="mailto:admin@adv.gg">admin@adv.gg</a>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>d.</u> Except if you pay the Opt-Out Fee, you agree not to circumvent the service
            offered by the Advocate Platform. By way of illustration and not in limitation of the
            foregoing, you must not:
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            i. Submit proposals or solicit parties identified through the Advocate Platform to
            contact, hire, work with, or pay outside the Advocate Platform.
          </li>
          <li>
            ii. Accept proposals or solicit parties identified through the Advocate Platform to
            contact, deliver services, invoice, or receive payment outside the Advocate Platform
          </li>
          <li>
            iii. Invoice or report on the Advocate Platform or in an Opt-Out Fee request an invoice
            or payment amount lower than that actually agreed between the Advocate Users.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>e.</u> You agree to notify Advocate immediately if another person improperly contacts
            you or suggests making or receiving payments outside of the Advocate Platform. If you
            are aware of a breach or potential breach of this non-circumvention agreement, please
            submit a confidential report to Advocate by sending an email message to{' '}
            <a href="mailto:admin@adv.gg">admin@adv.gg</a>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <u>f.</u> If you refuse to accept any new version of the Terms of Service or elect not
            to comply with certain conditions of using the Advocate Platform, such as minimum rates
            supported on the Advocate Platform, and therefore choose to cease using the Advocate
            Platform, you may pay the Opt-Out Fee for each other Advocate User you wish to continue
            working with on whatever terms you agree after you cease using the Advocate Platform.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>10. Disclaimer of Warranty</strong>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            a. TO THE FULLEST EXTENT PERMITTED BY LAW, THE ADVOCATE PLATFORM IS PROVIDED &ldquo;AS
            IS&rdquo; WITH ALL FAULTS, AND THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE,
            ACCURACY AND EFFORT IS WITH YOU. YOU ACKNOWLEDGE THAT, BECAUSE OF THE NATURE OF THE
            INTERNET, MOBILE NETWORKS, AND THE DEVICES WHICH ACCESS THE INTERNET AND/OR MOBILE
            NETWORKS, THE ADVOCATE PLATFORM MAY NOT BE ACCESSIBLE WHEN NEEDED, AND THAT INFORMATION,
            DATA, AUDIO AND VIDEO TRANSMITTED OVER THE INTERNET AND/OR MOBILE NETWORKS MAY BE
            SUBJECT TO INTERRUPTION OR THIRD PARTY INTERCEPTION AND MODIFICATION. TO THE FULLEST
            EXTENT PERMITTED BY LAW, ADVOCATE DISCLAIMS ANY AND ALL WARRANTIES OR CONDITIONS,
            EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OR CONDITIONS
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, ACCURACY OF
            INFORMATIONAL CONTENT, AND ABSENCE OF VIRUSES AND DAMAGING OR DISABLING CODE. TO THE
            FULLEST EXTENT PERMITTED BY LAW, NONE OF ADVOCATE, ITS AFFILIATES OR SERVICE PROVIDERS,
            ADVOCATE&rsquo;S CONTENT PROVIDERS AND/OR THEIR RESPECTIVE AFFILIATES, SUBSIDIARIES NOR
            THE EMPLOYEES, AGENTS AND/OR CONTRACTORS (COLLECTIVELY, THE &ldquo;
            <strong>ADVOCATE PARTIES</strong>
            &rdquo;) WARRANT THE ACCURACY, COMPLETENESS, OR TIMELINESS OF THE ADVOCATE PLATFORM.
            ADVOCATE CANNOT AND DOES NOT ASSUME ANY RESPONSIBILITY FOR ANY LOSS, DAMAGES OR
            LIABILITIES ARISING FROM THE FAILURE OF ANY TELECOMMUNICATIONS INFRASTRUCTURE, OR THE
            INTERNET OR FOR YOUR MISUSE OF ANY OF CONTENT AND INFORMATION ACCESSED THROUGH THE
            ADVOCATE PLATFORM. YOU EXPRESSLY AGREE THAT YOUR USE OF THE ADVOCATE PLATFORM AND YOUR
            RELIANCE UPON ANY OF THE RESPECTIVE CONTENT IS AT YOUR SOLE RISK.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            b. You acknowledge that any information you obtain from another Advocate User comes from
            those individuals, and not from Advocate, and that Advocate, to the fullest extent
            permitted by law, is not in any way responsible for any of the information these third
            parties may supply or for any statements, claims, or representations they may make. To
            the fullest extent permitted by law, Advocate disclaims any such statements, claims, or
            representations and the same do not expand or otherwise modify the terms of these Terms.
            If you are dissatisfied with the Advocate Platform, your sole and exclusive remedy is to
            discontinue accessing and using the Advocate Platform.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>11. Limitation of Liability</strong>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            a. TO THE FULLEST EXTENT PERMITTED BY LAW, EACH ADVOCATE USER IS SOLELY RESPONSIBLE FOR
            (I) HIS OR HER USE OF THE ADVOCATE PLATFORM, AND (II) ANY DAMAGES INCURRED BY HIM OR HER
            OR ANY THIRD PARTY THAT ARISE FROM OR ARE RELATED TO THE ADVOCATE PLATFORM. THE
            AGGREGATE LIABILITY OF THE ADVOCATE PARTIES FOR ANY DAMAGES, WHETHER ARISING IN
            CONTRACT, TORT OR OTHERWISE, WILL BE LIMITED TO ACTUAL DAMAGES PROVED, AND NOT TO EXCEED
            THE AMOUNT PAID BY YOU TO ADVOCATE FOR THE ADVOCATE PLATFORM. TO THE FULLEST EXTENT
            PERMITTED BY LAW, AND NOTWITHSTANDING ANYTHING TO THE CONTRARY SET FORTH HEREIN, NONE OF
            THE ADVOCATE PARTIES WILL BE LIABLE FOR ANY PUNITIVE, SPECIAL, INDIRECT OR CONSEQUENTIAL
            DAMAGES, EVEN IF ADVOCATE OR ITS CONTENT PROVIDERS HAVE BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            b. TO THE FULLEST EXTENT PERMITTED BY LAW, NONE OF THE ADVOCATE PARTIES WILL HAVE ANY
            LIABILITY WHATSOEVER FOR ANY LOSSES; COSTS OR DAMAGES OF ANY KIND INCURRED OR SUFFERED
            BY YOU AS A RESULT OF SERVICES PROVIDED BY ANY OF OTHER ADVOCATE USER PURSUANT TO THE
            ADVOCATE PLATFORM.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            c. YOU REPRESENT THAT YOU HAVE INDEPENDENTLY INVESTIGATED THE ADVISABILITY OF USING THE
            ADVOCATE PLATFORM AND ENGAGING IN ANY CAMPAIGNS YOU ENGAGE IN AND THE POSSIBLE RISKS
            INVOLVED IN USING THE ADVOCATE PLATFORM OR ENGAGING IN ANY CAMPAIGNS. YOU AGREE TO
            MAINTAIN YOUR OWN INSURANCE COVERING SUCH RISKS AND WILL LOOK SOLELY TO SUCH INSURANCE
            FOR REIMBURSEMENT OF ANY RESULTING DAMAGES.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>12. Third Party Disputes</strong>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            ADVOCATE IS NOT AFFILIATED WITH ANY BRAND, CONTENT CREATOR, CARRIER, SERVICE PROVIDER,
            OR THIRD PARTY SERVICE, AND ANY DISPUTE YOU HAVE WITH ANY BRAND, CONTENT CREATOR,
            CARRIER, SERVICE PROVIDER, THIRD PARTY SERVICE OR OTHER THIRD PARTY ARISING FROM YOUR
            USE OF THE ADVOCATE PLATFORM OR ANY CAMPAIGN, INCLUDING, WITHOUT LIMITATION, ANY OTHER
            ADVOCATE USER OR YOUR EMPLOYER, IS DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU
            IRREVOCABLY RELEASE ADVOCATE (AND OUR OFFICERS, DIRECTORS, AGENTS, SUBSIDIARIES, JOINT
            VENTURES, AND EMPLOYEES) FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND
            CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY
            CONNECTED WITH SUCH DISPUTES.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>13. Force Majeure</strong>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            Advocate will not be liable for any delay or failure to perform any obligation herein if
            the delay or failure is due to unforeseen events that are beyond Advocate&rsquo;s
            reasonable control, such as strikes, blockade, war, terrorism, riots, natural disasters,
            epidemic or governmental action, in so far as such an event prevents or delays Advocate
            in fulfilling its obligations hereunder.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>14. Indemnification and Release</strong>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            a. To the fullest extent permitted by law, you will defend, indemnify and hold Advocate,
            its licensors and each such party&#39;s parent organizations, subsidiaries, affiliates,
            officers, directors, members, employees, attorneys and agents, harmless against any loss
            or damage of any kind (including, without limitation, attorneys&rsquo; fees and lost
            revenues) arising from: (i) any and all breaches by you of these Terms or any
            representation, warranty or covenant contained herein; (ii) any and all use of the
            Advocate Platform not specifically authorized hereunder and on the Advocate Platform;
            and (iii) any and all claims and actions against Advocate by other parties to whom you
            allow access to the Advocate Platform.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            b. To the fullest extent permitted by law, you further waive, release and forever
            discharge the Advocate Parties from any and all responsibility or liability for injuries
            or damages resulting from your Massages or any other service obtained through the use of
            the Advocate Platform, including injuries or damages caused by the negligent act or
            omission of the Released Parties or in any way arising out of or connected with the
            Advocate Platform.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            c. We reserve the right, at our own expense, to assume the exclusive defense and control
            of any matter otherwise subject to indemnification by you, and in such case, you agree
            to cooperate with our defense of such claim. You will not, in any event, settle any
            claim or matter without our written consent.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>15. Additional Service Features</strong>.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            The Advocate Platform may contain information on products and services provided by third
            parties, and links (including advertisements) to third party web sites. This information
            and these products, services, and links are provided only as a convenience to Advocate
            Users. Advocate does not review or control this information or these products, services,
            or other web sites, and Advocate does not make any representations or warranties,
            express or implied, regarding this information or these products, services, or web
            sites. Inclusion of any of the foregoing in the Advocate Platform does not constitute or
            imply an endorsement, authorization, sponsorship, or affiliation by or with Advocate
            with respect to any third party or its web site or content, or any information,
            products, or services provided by that third party. Advocate is under no obligation to
            maintain any link on the Advocate Platform and may remove a link at any time in its sole
            discretion for any reason whatsoever.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            <strong>16. Dispute Resolution</strong>.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            <strong>a. Generally</strong>. In the interest of resolving disputes between you and
            Advocate in the most expedient and cost effective manner, you and Advocate agree that
            any dispute arising out of or in any way related to these Terms or your use of the
            Advocate Platform will be resolved by binding arbitration. Arbitration uses a neutral
            arbitrator instead of a judge or jury and can be subject to very limited review by
            courts. Arbitrators can award the same damages and relief that a court can award. This
            agreement to arbitrate disputes includes all claims arising out of or in any way related
            to these Terms or your use of the Advocate Platform, whether based in contract, tort,
            statute, fraud, misrepresentation, or any other legal theory, and regardless of whether
            a claim arises during or after the termination of these Terms. YOU UNDERSTAND AND AGREE
            THAT, BY ENTERING INTO THESE TERMS, YOU AND ADVOCATE ARE EACH WAIVING THE RIGHT TO A
            TRIAL BY JURY OR TO PARTICIPATE IN A CLASS OR COLLECTIVE ACTION. YOU FURTHER UNDERSTAND
            THAT THESE DISPUTE RESOLUTION TERMS WILL APPLY TO YOU AND ADVOCATE UNLESS YOU CHOOSE TO
            OPT OUT PURSUANT TO SECTION i BELOW.
          </li>
        </ol>
        <p>
          Whether to agree to arbitration is an important business decision.&nbsp; It is your
          decision to make, and you should not rely solely on the information provided in these
          Terms as it is not intended to contain a complete explanation of the consequences of
          arbitration.&nbsp; You should take further steps to conduct research and to consult with
          others, including, but not limited to, an attorney, regarding the consequences of your
          decision, just as you would when making any other important business or life decision.
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>b. Exceptions</strong>. Despite the provisions of the Section entitled
            &ldquo;Generally&rdquo; directly above, nothing in these Terms will be deemed to waive,
            preclude, or otherwise limit the right of either party to: (i) bring an individual
            action in small claims court; (ii) bring an action in a court pursuant to the California
            Private Attorneys General Act of 2004, California Labor Code &sect; 2698 et seq.; (iii)
            seek injunctive relief in a court of law; or (iv) to file suit in a court of law to
            address an intellectual property infringement claim.
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>c. Arbitrator</strong>. Any arbitration between you and Advocate will be
            governed by the Federal Arbitration Act, and governed by the Commercial Dispute
            Resolution Procedures and the Supplementary Procedures for Consumer Related Disputes
            (collectively, &ldquo;
            <strong>AAA Rules</strong>
            &rdquo;) of the American Arbitration Association (&ldquo;
            <strong>AAA</strong>
            &rdquo;), as modified by these Terms, and will be administered by the AAA. The AAA Rules
            and filing forms are available online at www.adr.org, by calling the AAA at
            1-800-778-7879, or by contacting Advocate. The arbitrator has exclusive authority to
            resolve any dispute relating to the interpretation, applicability, or enforceability of
            this binding arbitration agreement.
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>d. Notice; Process</strong>. A party who intends to seek arbitration must first
            send a written notice of the dispute to the other party by U.S. Mail or electronic mail
            within the applicable statute of limitations period (&ldquo;
            <strong>Notice</strong>
            &rdquo;). Advocate&rsquo;s address for Notice is: 2420 14
            <sup>th</sup>, Apt 822, Washington, D.C. 20009. The Notice must: (a) describe the nature
            and basis of the claim or dispute; and (b) set forth the specific relief sought (&ldquo;
            <strong>Demand</strong>
            &rdquo;). The parties will make good faith efforts to resolve the claim directly, but if
            the parties do not reach an agreement to do so within 30 days after the Notice is
            received, you or Advocate may commence an arbitration proceeding. During the
            arbitration, the amount of any settlement offer made by you or Advocate must not be
            disclosed to the arbitrator until after the arbitrator makes a final decision and award,
            if any. If the dispute is finally resolved through arbitration in your favor, Advocate
            will pay you the highest of the following: (i) the amount awarded by the arbitrator, if
            any; (ii) the last written settlement amount offered by Advocate in settlement of the
            dispute prior to the arbitrator&rsquo;s award; or (iii) $1,000.
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>e. Fees</strong>. If you commence arbitration in accordance with these Terms,
            Advocate will reimburse you for your payment of the filing fee, unless your claim is for
            more than $15,000 or as set forth below, in which case the payment of any fees will be
            decided by the AAA Rules. Any arbitration hearing will take place at a location to be
            agreed upon in Washington, DC, but if the claim is for $15,000 or less, you may choose
            whether the arbitration will be conducted: (i) solely on the basis of documents
            submitted to the arbitrator; (ii) through a non-appearance based telephone hearing; or
            (iii) by an in-person hearing as established by the AAA Rules in the county (or parish)
            of your billing address. If the arbitrator finds that either the substance of your claim
            or the relief sought in the Demand is frivolous or brought for an improper purpose (as
            measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the
            payment of all fees will be governed by the AAA Rules. In that case, you agree to
            reimburse Advocate for all monies previously disbursed by it that are otherwise your
            obligation to pay under the AAA Rules. Regardless of the manner in which the arbitration
            is conducted, the arbitrator must issue a reasoned written decision sufficient to
            explain the essential findings and conclusions on which the decision and award, if any,
            are based. The arbitrator may make rulings and resolve disputes as to the payment and
            reimbursement of fees or expenses at any time during the proceeding and upon request
            from either party made within 14 days of the arbitrator&rsquo;s ruling on the merits.
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>f. No Class Actions</strong>. YOU AND ADVOCATE AGREE THAT EACH MAY BRING CLAIMS
            AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR
            CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING (OTHER THAN ACTIONS
            UNDER THE CALIFORNIA PRIVATE ATTORNEYS GENERAL ACT OF 2004, California Labor Code &sect;
            2698 et seq. WHICH ARE NOT COVERED BY THIS SECTION 15). Further, unless both you and
            Advocate agree otherwise, the arbitrator may not consolidate more than one
            person&rsquo;s claims, and may not otherwise preside over any form of a representative
            or class proceeding.
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>g. Modifications to this Arbitration Provision</strong>. If Advocate makes any
            future change to this arbitration provision, other than a change to Advocate&rsquo;s
            address for Notice, you may reject the change by sending us written notice within 30
            days of the change to Advocate&rsquo;s address for Notice, in which case this
            arbitration provision, as in effect immediately prior to the changes you rejected, will
            continue to govern any disputes between you and Advocate. If you do not send such
            written notice, your continued use of the Advocate Platform following any such change
            means that you have consented to such change.
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>h. Enforceability</strong>. If the Section entitled &ldquo;No Class
            Actions&rdquo; is found to be unenforceable or if the entirety of this Section 15 is
            found to be unenforceable, then the entirety of this Section 15 will be null and void
            and, in that case, the parties agree that the exclusive jurisdiction and venue described
            directly below will govern any action arising out of or related to these Terms or your
            use of the Advocate Platform.
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>i. Opt Out.</strong> Arbitration is not a mandatory condition of these Terms. If
            you do not want to be subject to this Dispute Resolution provision, you may opt out of
            this Dispute Resolution provision by notifying Advocate in writing of your decision by
            sending, within 30 days of the date you receive these Terms, an electronic message to{' '}
            <a href="mailto:admin@adv.gg">admin@adv.gg</a>, stating clearly your full name and
            intent to opt out of the Dispute Resolution provision. Should you choose not to opt out
            of this Dispute Resolution provision within the 30-day period, you and Advocate will be
            bound by the terms of this Dispute Resolution provision. You have the right to consult
            with counsel of your choice concerning this Dispute Resolution provision. You understand
            that you will not be subject to retaliation if you exercise your right to opt out of
            coverage under this Dispute Resolution provision.
          </li>
        </ol>
        <ol>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ol>
            <li>
              <strong>17. Protected Activity Not Prohibited.</strong>
            </li>
          </ol>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <li>
            You should understand that, insofar as permitted by applicable law, nothing in these
            Terms will in any way limit or prohibit you from filing a charge or complaint with, or
            otherwise communicating or cooperating with or participating in any investigation or
            proceeding (&ldquo;
            <strong>Protected Activity</strong>
            &rdquo;) that may be conducted by any federal, state or local government agency or
            commission (&ldquo;
            <strong>Government Agencies</strong>
            &rdquo;).&nbsp; You understand that in connection with such Protected Activity, you are
            permitted to disclose documents or other information as permitted by law, and without
            giving notice to, or receiving authorization from, Advocate. Notwithstanding, in making
            any such disclosures or communications, you agree to take all reasonable precautions to
            prevent any unauthorized use or disclosure of any information that may constitute
            Advocate confidential information to any parties other than the Government Agencies.
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <li>
            You may receive confidential information from time to time in connection with your
            performance of Campaigns, including, without limitation, information regarding the
            release of new products, goods or services that has not yet been publicly disclosed
            <strong>(“Campaign Confidential Information”)</strong>. Any such Campaign Confidential
            Information will be disclosed to you pursuant to a written notice from Advocate
            indicating that such information constitutes Campaign Confidential Information pursuant
            to these Terms. You shall maintain the confidentiality of, and not directly or
            indirectly use or divulge to any Person, any Campaign Confidential Information unless
            and until you receive written authorization from Advocate to do so, or as may be
            required by applicable law. If you shall be required by applicable law to disclose any
            Campaign Confidential Information, you shall provide Advocate with prompt written notice
            of such requirement so that Advocate may seek an appropriate protective order or other
            appropriate remedy, and you shall cooperate with Advocate to obtain such an order or
            other remedy; provided that in the event such an order or remedy is not obtained, you
            shall disclose only that portion of such Campaign Confidential Information which you are
            legally compelled to disclose and shall exercise your best efforts to obtain reliable
            assurance that confidential treatment will be accorded to any such Campaign Confidential
            Information so disclosed.
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ol>
            <li>
              <strong>18. Governing Law; Choice of Forum</strong>.
            </li>
          </ol>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <li>
            The laws of the State of Delaware, excluding its conflicts of law rules, govern these
            Terms and your use of the Advocate Platform. Your use of the Advocate Platform may also
            be subject to other local, state, national, or international laws; provided, however,
            that the United Nations Convention on Contracts for the International Sale of Goods will
            not apply to any provision of these Terms. To the extent that any action relating to any
            dispute hereunder is permitted to be brought in a court of law, such action will be
            subject to the exclusive jurisdiction of the state and federal courts located in
            Washington, DC and you hereby irrevocably submit to personal jurisdiction in such
            courts, and waive any defense of inconvenient forum.
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ol>
            <li>
              <strong>19. Feedback</strong>.
            </li>
          </ol>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <li>
            If You provide any feedback to Advocate concerning the functionality and performance of
            the Advocate Platform (including identifying potential errors and improvements), You
            hereby assign to Advocate all right, title, and interest in and to such feedback, and
            Advocate is free to use such feedback without payment or restriction.
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ol>
            <li>
              <strong>20. Entire Agreement; Variation</strong>.
            </li>
          </ol>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <li>
            These Terms set forth the entire agreement between Advocate and you with respect to the
            Advocate Platform. &nbsp;These Terms supersede and govern all previous oral and written
            communications regarding these matters, all of which are merged into these Terms. No
            usage of trade or other regular practice or method of dealing between the parties will
            be used to modify, interpret, supplement, or alter these Terms.&nbsp; These Terms may be
            changed only by a written agreement signed by an authorized agent of the party against
            whom enforcement is sought
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            <strong>21. Severability</strong>.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            If any provision of these Terms is held invalid, illegal or unenforceable, such
            provisions will be modified, or if not possible, severed, to reflect the fullest valid,
            legal and enforceable expression of the intent of the parties and the remainder of these
            Terms will not be affected thereby.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            <strong>22. Relationship of Parties</strong>.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            <strong>Relationship of Parties. </strong>
            Nothing herein will be deemed to create an employer-employee relationship between
            Advocate and you, nor any agency, joint venture or partnership relationship between the
            parties. Neither party will have the right to bind the other to any obligation, nor have
            the right to incur any liability on behalf of the other.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            <strong>23. Waiver</strong>.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            No delay, omission, or failure to exercise any right or remedy provided herein will be
            deemed to be a waiver thereof or an acquiescence in the event giving rise to such right
            or remedy, but every such right or remedy may be exercised, from time to time as may be
            deemed expedient by the party exercising such remedy or right.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            <strong>24. Assignment</strong>.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            Neither these Terms nor any of the rights granted to you herein may be assigned or
            transferred by you, whether voluntarily or by operation of law, without the express
            prior written permission of Advocate and any attempt to do so will be null and void.
            However, Advocate may assign or transfer these Terms at any time without your
            permission.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            <strong>25. Third-Party Beneficiaries</strong>.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            The provisions of these Terms relating to the rights of Advocate content providers are
            intended for the benefit of such content providers, and such content providers, as
            third-party beneficiaries, will be entitled to enforce such provisions in accordance
            with their terms, irrespective of the fact that they are not signatories to these Terms.
          </li>
        </ol>
        <p>&nbsp;</p>
      </div>
    );
  }
  return (
    <div className="termsOfService" id="termsOfService" onScroll={updateRead}>
      <p>
        THESE TERMS OF SERVICE (THESE &ldquo;
        <strong>TERMS</strong>
        &rdquo;) ESTABLISH THE TERMS AND CONDITIONS THAT APPLY TO YOU WHEN YOU USE THE ADVOCATE
        PLATFORM (AS DEFINED BELOW). BY USING THE ADVOCATE PLATFORM, YOU INDICATE YOUR ACCEPTANCE OF
        THESE TERMS AND YOUR AGREEMENT TO BE BOUND BY THESE TERMS, AS WELL AS ALL APPLICABLE LAWS
        AND REGULATIONS. YOU ARE NOT PERMITTED TO USE THE ADVOCATE PLATFORM IF YOU DO NOT AGREE TO
        THESE TERMS. THESE TERMS CAN BE CHANGED, MODIFIED, SUPPLEMENTED, AND/OR UPDATED BY ADVOCATES
        INC. (&ldquo;
        <strong>ADVOCATE</strong>
        ,&rdquo; &ldquo;
        <strong>WE</strong>, &ldquo;
        <strong>OUR</strong>
        ,&rdquo; OR &ldquo;
        <strong>US</strong>
        &rdquo;) AT ANY TIME; PROVIDED THAT WE WILL ENDEAVOR TO PROVIDE YOU WITH PRIOR NOTICE OF ANY
        MATERIAL CHANGES. YOUR CONTINUED USE OF THE ADVOCATE PLATFORM AFTER THE MODIFICATION OF
        THESE TERMS MEANS THAT YOU ACCEPT ALL SUCH CHANGES. ACCORDINGLY, YOU ARE ADVISED TO CONSULT
        THESE TERMS EACH TIME YOU ACCESS THE ADVOCATE PLATFORM IN ORDER TO VIEW ANY CHANGES TO THESE
        TERMS. THESE TERMS WERE LAST MODIFIED AS OF THE DATE INDICATED ABOVE.
      </p>
      <p>&nbsp;</p>
      <p>
        AS FURTHER DESCRIBED BELOW, THESE TERMS PROVIDE FOR THE USE OF ARBITRATION ON AN INDIVIDUAL
        BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMITS THE
        REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.&nbsp; YOU CAN OPT-OUT OF THIS AGREEMENT
        TO ARBITRATE BY CONTACTING
        <a href="mailto:ADMIN@ADV.GG">
          <strong>ADMIN@ADV.GG</strong>
        </a>{' '}
        WITHIN 30 DAYS&nbsp;OF FIRST ACCEPTING THESE TERMS AND STATING THAT YOU (INCLUDE YOUR FIRST
        AND LAST NAME) DECLINE THIS ARBITRATION AGREEMENT.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>1. Use of Web Site, Mobile Applications, and our Service</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          a. The &ldquo;
          <strong>Advocate Platform</strong>
          &rdquo; is Advocate&rsquo;s website located at&nbsp;adv.gg and any related mobile
          applications, as the same may be updated, relocated, or otherwise modified from time to
          time, including through networks, embeddable widgets, downloadable software, and tablet
          computer applications, and all intellectual property contained therein. The Advocate
          Platform provides a platform to enable companies, brand representatives, ad agencies,
          non-profits, etc. (collectively &ldquo;
          <strong>Brands</strong>
          &rdquo;) and YouTube content creators, Discord content creators, Twitch content creators,
          social media Content Creators, or other content creators/Content Creators (collectively,
          &ldquo;
          <strong>Content Creators</strong>
          &rdquo;) to connect, collaborate, execute, and manage advertising and promotional
          campaigns (&ldquo;
          <strong>Campaigns</strong>
          &rdquo;).&nbsp; Any person who accesses and/or uses the Advocate Platform, whether on his
          or her own behalf or on behalf of any third party, will be referred to herein as an
          &ldquo;
          <strong>Advocate User</strong>
          &rdquo;.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          b. Subject to the terms and conditions of these Terms, Advocate hereby grants you a
          limited, revocable, non-exclusive, non-transferable right to access and use the Advocate
          Platform. Unless otherwise specified in writing, the Advocate Platform is solely for your
          internal use and not for resale. Advocate reserves the right at all times and without
          notice to: (i) restrict and/or terminate your access to the Advocate Platform (or any
          portion thereof); and (ii) modify or discontinue providing the Advocate Platform (or any
          portion thereof).
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          c. Advocate&rsquo;s policy with respect to the collection and use of your personally
          identifiable information is set in our
          <a target="_blank" rel="noopener noreferrer" href="https://adv.gg/docs/privacy">
            Privacy Policy
          </a>
          . By accepting these Terms, you acknowledge your agreement with Advocate&rsquo;s
          <a target="_blank" rel="noopener noreferrer" href="https://adv.gg/docs/privacy">
            Privacy Policy
          </a>
          .
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>2. Registration, Accounts, Passwords and Security</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>a. Accounts and Registration</u>. To access most features of the Advocate Platform, you
          must register for an account. When you register for an account, you may be required to
          provide us with some information about yourself, such as your email address or other
          contact information. You agree that the information you provide to us is accurate and that
          you will keep it accurate and up-to-date at all times. You are solely responsible for
          maintaining the confidentiality of your account and password, and you accept
          responsibility for all activities that occur under your account. If you have reason to
          believe that your account is no longer secure, then you must immediately notify us at{' '}
          <a href="mailto:admin@adv.gg">admin@adv.gg</a>.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>b. Single Sign-On</u>. You may be permitted to register for and log on to the Advocate
          Platform via certain third party services (such as a social network).&nbsp; Advocate may
          use the profile information connected to the account you use to log into the Advocate
          Platform, including your name, to provide the Advocate Platform as described in these
          Terms and otherwise support your account. Such third party&rsquo;s use of your information
          will be subject to that service&rsquo;s{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://adv.gg/docs/privacy">
            Privacy Policy
          </a>
          . Further information about linking your account and use of third party services to log on
          to the Advocate Platform can be found in our{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://adv.gg/docs/privacy">
            Privacy Policy
          </a>
          .
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>c. Eligibility</u>.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          i. You represent and warrant that you are at least 18 years of age, that you have not been
          previously suspended or removed from the Advocate Platform, and that you possess the legal
          right and ability to enter into these Terms. THE ADVOCATE PLATFORM IS NOT FOR PERSONS
          UNDER THE AGE OF 18 OR ANY USERS PREVIOUSLY SUSPENDED OR REMOVED FROM THE ADVOCATE
          PLATFORM BY ADVOCATE.&nbsp; IF YOU ARE UNDER 18 YEARS OF AGE, PLEASE DO NOT USE OR ACCESS
          THE ADVOCATE PLATFORM AT ANY TIME OR IN ANY MANNER.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          ii. If you are using the Advocate Platform on behalf of an individual, company, entity, or
          organization (each, an &ldquo;
          <strong>Organization</strong>
          &rdquo;), you represent and warrant that (A) you are an authorized representative of such
          Organization with the authority to bind such Organization to these Terms, (B) agree to be
          bound by these Terms on behalf of such Organization, and (C) your Organization meets the
          eligibility requirements for the Advocate Platform, as set forth in these Terms. Further,
          you will be solely responsible for ensuring your Organization complies with these Terms.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>3. Your Responsibilities</strong>.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          You may use the Advocate Platform solely for lawful purposes, as intended through the
          provided functionality of the Advocate Platform. You may not use the Advocate Platform in
          any manner that could damage, disable, overburden, or impair our servers or networks, or
          interfere with any other party&rsquo;s use and enjoyment of the Advocate Platform. You may
          not attempt to gain unauthorized access to the Advocate Platform, user accounts, or
          computer systems or networks, through hacking, password mining or any other means. Without
          limiting any of the foregoing, you expressly agree that you will not (and you agree not to
          allow or assist any third party to):
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          a. use, copy, install, transfer or distribute the Advocate Platform, except as
          specifically permitted by these Terms;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          b. modify, adapt, translate, reverse engineer, decompile, or disassemble any portion of
          the Advocate Platform;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          c. remove or alter any copyright, trademark or other proprietary rights notices contained
          in or on the Advocate Platform or in or on any content or other material obtained through
          the Advocate Platform or the use of the Advocate Platform;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          d. rent, lease, or otherwise permit third parties to use the Advocates Platform or use the
          Advocates Platform to provide services to third parties (e.g., as a service bureau);
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>e. create user accounts under false or fraudulent pretenses;</li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          f. use any robot, spider, screen or database scraper, site search or retrieval
          application, or other automated device, process or means to access, retrieve or index any
          portion of the Advocate Platform;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          g. probe, scan or test the vulnerability of any system or network or breach any security
          or authentication measures;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          h. reformat, mirror, or frame any portion of the web pages that are part of the Advocate
          Platform;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          i. express or imply that any statements you make are endorsed by us, without our prior
          written consent in each instance;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          j. transmit (i) any content or information that is unlawful, fraudulent, threatening,
          abusive, libelous, defamatory, obscene or otherwise objectionable, or infringes on our or
          any third party&rsquo;s intellectual property or other rights, (ii) any material,
          non-public information about individuals or companies without the authorization to do so,
          (iii) any trade secret of any third party, and/or (iv) any advertisements, solicitations,
          chain letters, pyramid schemes, investment opportunities, or other unsolicited commercial
          communication (except as otherwise expressly permitted by us), or engage in spamming or
          flooding;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          k. transmit any software or other materials that contain any virus, worm, time bomb,
          Trojan horse, or other harmful or disruptive component; use any robot, spider, site
          search/retrieval application, or other manual or automatic device or process to retrieve,
          index, &ldquo;data mine&rdquo;, or in any way reproduce or circumvent the navigational
          structure or presentation of the Advocate Platform or its contents;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          l. harvest or collect information about other users without their prior written consent;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          m. undertake, cause, permit or authorize the translation, reverse engineering,
          disassembling or hacking of any aspect of the Advocate Platform or attempt to do any of
          the foregoing, except and solely to the extent permitted by these Terms, the Advocate
          Platform&rsquo;s authorized features, or by law, or otherwise attempt to use or access any
          portion of the Advocate Platform other than as intended by Advocate;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          n. access, tamper with, or use non-public areas of the Advocate Platform, Advocate&rsquo;s
          (and its hosting company&rsquo;s) computer systems and infrastructure, or the technical
          delivery systems of Advocate&rsquo;s providers;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          o. harass, abuse, harm, or advocate or incite harassment, abuse, or harm of another person
          or group, including Advocate employees and other users;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          p. create a new account with Advocate, without Advocate&rsquo;s express written consent,
          if Advocate previously disabled an account of yours;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          q. solicit, or attempt to solicit, personal information from other users, except as
          permitted through the Advocate Platform&rsquo;s functionality;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          r. restrict, discourage or inhibit any person from using the Advocate Platform, disclose
          personal information about a third person on the Advocate Platform or obtained from the
          Advocate Platform without the consent of such person, or collect information about users;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          s. gain unauthorized access to the Advocate Platform, to other users&rsquo; accounts,
          names or personally identifiable information, or to other computers or websites connected
          or linked to the Advocate Platform;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          t. violate any applicable federal, state or local laws, regulations, or these Terms;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          u. use the Advocate Platform for any illegal, inappropriate, and/or unauthorized conduct,
          including without limitation, using the Advocate Platform to contact other Advocate Users
          for sexual or other inappropriate purposes, or using the Advocate Platform in violation of
          Advocate&rsquo;s or any third party&#39;s intellectual property or other proprietary or
          legal rights; or
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>v. use or access the Advocate Platform to build a competing service.</li>
      </ol>
      <p>
        We may take any legal action and implement any technical remedies to prevent the violation
        of these provisions and to enforce these Terms.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>4. Campaigns</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>a. Acceptance of Campaigns</u>. Advocate may offer Campaigns to Content Creators from
          time to time via the Advocate Platform. Content Creators who wish to participate in a
          Campaign must accept the proposed Campaign(s) in the manner and timeframe specified by
          Advocate.&nbsp; Content Creators will perform agreed-upon Campaigns in accordance with
          their terms,&nbsp; which may involve the placing of graphics, messages, material and/or
          other content on the live broadcasts of Content Creators. Agreement by Content Creators to
          participate in a Campaign shall constitute consent to the placing of such graphics,
          messages, material and/or other content related to such Campaign on such live
          broadcasts.&nbsp; Content Creators acknowledge that Campaigns may be performed by multiple
          Content Creators at the same or different times, and that the identity of other Content
          Creators who have accepted or are performing a Campaign is not material to Content
          Creator&rsquo;s acceptance of such Campaign and is not a permitted reason to terminate
          participation in an agreed-upon Campaign.&nbsp;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>b. Compensation</u>. Advocate will pay Content Creators in accordance with the fee
          schedule and payment terms set forth at{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://my.adv.gg/profile">
            my.adv.gg/profile
          </a>{' '}
          for participation in agreed-upon Campaigns. Content Creator shall be responsible for any
          withholdings, taxes, agency, and/or other fees due on and/or in connection with the total
          compensation payable under Campaigns. Advocate will not reimburse Content Creator for any
          costs or expenses incurred by a Content Creator in performing a Campaign.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>c. FTC Rules</u>. Each Advocate User is solely responsible for complying with all
          applicable laws and regulations relating to Campaigns, including without limitation, the
          Federal Trade Commission regulations on endorsements and truth in advertising.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>5. Consent to Electronic Communications</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>a. Consent to Electronic Communications</u>. By using the Advocate Platform or
          providing Personal Information (as defined in the{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://adv.gg/docs/privacy">
            Privacy Policy
          </a>
          ) to us, you agree that we may communicate with you electronically regarding security,
          privacy, and administrative issues relating to your use of the Advocate Platform. If we
          learn of a security system&rsquo;s breach, we may attempt to notify you electronically by
          posting a notice on the Advocate Platform or sending an email to you, if we have your
          email address. You may have a legal right to receive this notice in writing. To receive
          free written notice of a security breach (or to withdraw your consent from receiving
          electronic notice), please write to us at <a href="mailto:admin@adv.gg">admin@adv.gg</a>.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          b. If you wish to remove yourself from any list (other than as set forth in Section b),
          please email us with an &quot;OPT-OUT&quot;, &quot;UNSUBSCRIBE&quot;, &quot;STOP&quot; or
          &quot;REMOVE&quot; in the subject line.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          c. Depending on your current carrier plan, you may incur charges for these Messages and
          Notifications and agree to not hold Advocate liable for any charges incurred. You
          acknowledge that any terms between you and any third-party provider (such as, for example,
          Apple&reg;, Android&trade;, or Verizon) create no obligation or responsibility on the part
          of Advocate, and that Advocate is not responsible for any failure of warranty by any such
          third party.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          d. Advocate cannot control certain factors relating to message delivery. You acknowledge
          that, depending on your mobile carrier&rsquo;s service, it may not be possible to transmit
          a text message to you successfully. We have no liability for transmission delays or
          message failures.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>6. Content </strong>
          <strong>Rights and Licenses</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>a. Brand Content</u>. During the term of the applicable agreed-upon Campaign, Content
          Creator has the right to access and use the materials (e.g., copy points, tracking links,
          promo codes, etc.) provided by Advocate to Content Creator solely to execute the
          applicable Campaign in accordance with its terms.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>b. License to Content Creator Property</u>. Content Creator hereby grants to Advocate a
          nonexclusive, royalty-free, fully paid, irrevocable, transferable, worldwide license (with
          the right to grant and authorize sublicenses to Brands) to make, have made, use, import,
          offer for sale, sell, reproduce, distribute, modify, adapt, prepare derivative works of
          (&ldquo;
          <strong>Derivative Works</strong>
          &rdquo;), display, publicly perform (including on a through-to-the-audience basis), and
          otherwise use any Content Creator Property solely to execute the applicable Campaign in
          accordance with its terms. For purposes of this Agreement, &ldquo;
          <strong>Content Creator Property</strong>
          &rdquo; means Content Creator&rsquo;s name, nickname, initials, autograph, signature,
          social media handle, blog name, photograph, likeness, caricature, image, voice,
          statements, biographic information, copy, logos, company name, brand information, blog
          posts, photographs, videos, and any other information, content, or materials provided by
          Content Creator to Advocate in any manner (e.g. oral, video, written, etc.) in connection
          with an agreed-upon Campaign.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>c. Right to Record Content Creator</u>. If provided for in a Campaign, Content Creator
          authorizes Advocate (or the Brand specified in the Campaign) to photograph, record, tape,
          film, video and otherwise visually and audiovisually record Content Creator&rsquo;s image,
          likeness, and voice (&ldquo;
          <strong>Recorded Content</strong>
          &rdquo;) to create Derivative Works in connection with Campaigns.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>d. Survival of Certain Rights</u>. If, during the term of a Campaign, Advocate (or the
          Brand specified in the Campaign) creates any Derivative Works or Recorded Content, Content
          Creator hereby grants to Advocate a royalty-free, fully paid, non-exclusive, irrevocable,
          transferable, worldwide license (with the right to grant and authorize sublicenses to
          Brands if specified in the applicable Campaign) to use any Content Creator Property solely
          to the extent embodied in such Derivative Works or Recorded Content perpetually
          notwithstanding the expiration or termination of this Agreement, for any purpose and in
          any medium now known or hereinafter devised.&nbsp;
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>e. Waiver of Certain Rights</u>. Advocate is under no obligation to use the Content
          Creator Property, and Advocate may cease any permitted use of the Content Creator Property
          at any time, with or without notice, and without any liability to Content Creator.
          Advocate waives any rights to privacy, publicity, or any other rights of a similar nature
          in connection with the permitted use of the Content Creator Property. Except to the extent
          expressly set forth in the applicable Campaign, Content Creator will make no monetary or
          other claim against Advocate for use of the Content Creator Property as permitted by this
          Agreement.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>f. Data Retention; Data Analytics</u>. Advocate is not responsible for performing, and
          is not liable for any failure to perform, any back-up of any data provided, processed, or
          stored by an Advocate User in or through the Advocate Platform. Advocate may collect and
          use technical data and related information, including, but not limited to, UDID, and other
          technical information about Advocate Users&rsquo; devices, systems, application software,
          and peripherals, that is gathered periodically to facilitate the provision of software
          updates, product support, and other services to Advocate Users related to the Advocate
          Platform, and to track and report Advocate Users&rsquo; activity inside of the Advocate
          Platform. Advocates may use this information for analytics purposes to specifically
          identify and compare how various Advocate Users execute Campaigns, to identify successful
          techniques and pitfalls in executing Campaigns, and to create reports of the same to share
          with Brands.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>g. Representations and Warranties</u>. Content Creator represents and warrants to
          Advocate that:&nbsp; (i) Content Creator has the full right, power, and authority to grant
          the rights set forth in this Agreement and that doing so will not cause Content Creator to
          breach any other agreement to which Content Creator is bound or a party; (ii) Content
          Creator will perform its obligations under each Campaign in compliance with all applicable
          laws and regulations, including without limitation, the Federal Trade Commission
          regulations on endorsements and truth in advertising; (iii) Content Creator Property will
          not (A) infringe, violate, or misappropriate any third-party right, including any
          copyright, trademark, patent, trade secret, moral right, privacy right, right of
          publicity, or any other intellectual property or proprietary right or (B) slander, defame,
          or libel any other person; (iv) Content Creator has not misrepresented or concealed
          anything with respect to Content Creator&rsquo;s background that may have a prejudicial
          effect on the value of any Campaign; (v) that Content Creator has not engaged nor will
          Content Creator engage during any Campaign in any activity (criminal or otherwise) that
          could potentially have a negative impact on Advocate or Brands who have engaged Content
          Creator via a Campaign.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>h. Further Assurances</u>. Content Creator will, upon Advocate&rsquo;s reasonable
          request, furnish affidavits and other appropriate documentation of endorsement that may be
          required, in Advocate&rsquo;s reasonable judgment, to comply with any applicable
          governmental or other regulations, broadcast clearance procedures, or industry guidelines
          relating to each Campaign.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>7. Term and Termination</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>a. Term</u>. The term of these Terms (the &ldquo;
          <strong>Term</strong>
          &rdquo;) will commence on the date on which you first access or utilize the Advocate
          Platform in any way (the &ldquo;
          <strong>Effective Date</strong>
          &rdquo;) and will continue until terminated as provided herein.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>b. Termination</u>. Advocate may immediately terminate these Terms or any Campaign at
          any time for any reason upon written notice to you. An Advocate User may terminate these
          Terms at any time for any reason upon written notice to Advocate if no active Campaign is
          in effect with such Advocate User.&nbsp; Any Advocate User may terminate any Campaign or
          these Terms:
        </li>
      </ol>
      <ol>
        <li>If Advocate is adjudicated insolvent or declares bankruptcy; or</li>
        <li>
          If Advocate materially breaches this Agreement and, after receiving written notice of the
          breach, Advocates fails to cure the breach within 30 days.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <u>c. Effect of Termination</u>. Upon termination of these Terms or any Campaign, Advocate
          will pay to Content Creator any compensation due to Content Creator for services performed
          prior to the effective date of termination. The following Sections of these Terms will
          survive termination or expiration of these Terms: 3, c, 5, 6.b - 6.h, 7 - 24.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>8. Ownership</strong>.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          The Advocate Platform is owned and operated by Advocate. The visual interfaces, graphics,
          design, compilation, information, data, computer code (including source code or object
          code), products, software, services, and all other elements of the Advocate Platform
          (&ldquo;
          <strong>Materials</strong>
          &rdquo;) provided by Advocate are protected by intellectual property and other laws. All
          Materials included in the Advocate Platform are the property of Advocate or our
          third-party licensors. Except as expressly authorized by Advocate, you may not make use of
          the Materials. Advocate reserves all rights to the Materials not granted expressly in
          these Terms.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>9. Disclaimer of Warranty</strong>.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          a. TO THE FULLEST EXTENT PERMITTED BY LAW, THE ADVOCATE PLATFORM IS PROVIDED &ldquo;AS
          IS&rdquo; WITH ALL FAULTS, AND THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE,
          ACCURACY AND EFFORT IS WITH YOU. YOU ACKNOWLEDGE THAT, BECAUSE OF THE NATURE OF THE
          INTERNET, MOBILE NETWORKS, AND THE DEVICES WHICH ACCESS THE INTERNET AND/OR MOBILE
          NETWORKS, THE ADVOCATE PLATFORM MAY NOT BE ACCESSIBLE WHEN NEEDED, AND THAT INFORMATION,
          DATA, AUDIO AND VIDEO TRANSMITTED OVER THE INTERNET AND/OR MOBILE NETWORKS MAY BE SUBJECT
          TO INTERRUPTION OR THIRD PARTY INTERCEPTION AND MODIFICATION. TO THE FULLEST EXTENT
          PERMITTED BY LAW, ADVOCATE DISCLAIMS ANY AND ALL WARRANTIES OR CONDITIONS, EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OR CONDITIONS OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, ACCURACY OF
          INFORMATIONAL CONTENT, AND ABSENCE OF VIRUSES AND DAMAGING OR DISABLING CODE. TO THE
          FULLEST EXTENT PERMITTED BY LAW, NONE OF ADVOCATE, ITS AFFILIATES OR SERVICE PROVIDERS,
          ADVOCATE&rsquo;S CONTENT PROVIDERS AND/OR THEIR RESPECTIVE AFFILIATES, SUBSIDIARIES NOR
          THE EMPLOYEES, AGENTS AND/OR CONTRACTORS (COLLECTIVELY, THE &ldquo;
          <strong>ADVOCATE PARTIES</strong>
          &rdquo;) WARRANT THE ACCURACY, COMPLETENESS, OR TIMELINESS OF THE ADVOCATE PLATFORM.
          ADVOCATE CANNOT AND DOES NOT ASSUME ANY RESPONSIBILITY FOR ANY LOSS, DAMAGES OR
          LIABILITIES ARISING FROM THE FAILURE OF ANY TELECOMMUNICATIONS INFRASTRUCTURE, OR THE
          INTERNET OR FOR YOUR MISUSE OF ANY OF CONTENT AND INFORMATION ACCESSED THROUGH THE
          ADVOCATE PLATFORM. YOU EXPRESSLY AGREE THAT YOUR USE OF THE ADVOCATE PLATFORM AND YOUR
          RELIANCE UPON ANY OF THE RESPECTIVE CONTENT IS AT YOUR SOLE RISK.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          b. You acknowledge that any information you obtain from another Advocate User comes from
          those individuals, and not from Advocate, and that Advocate, to the fullest extent
          permitted by law, is not in any way responsible for any of the information these third
          parties may supply or for any statements, claims, or representations they may make. To the
          fullest extent permitted by law, Advocate disclaims any such statements, claims, or
          representations and the same do not expand or otherwise modify the terms of these Terms.
          If you are dissatisfied with the Advocate Platform, your sole and exclusive remedy is to
          discontinue accessing and using the Advocate Platform.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>10. Limitation of Liability</strong>.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          a. TO THE FULLEST EXTENT PERMITTED BY LAW, EACH ADVOCATE USER IS SOLELY RESPONSIBLE FOR
          (I) HIS OR HER USE OF THE ADVOCATE PLATFORM, AND (II) ANY DAMAGES INCURRED BY HIM OR HER
          OR ANY THIRD PARTY THAT ARISE FROM OR ARE RELATED TO THE ADVOCATE PLATFORM. THE AGGREGATE
          LIABILITY OF THE ADVOCATE PARTIES FOR ANY DAMAGES, WHETHER ARISING IN CONTRACT, TORT OR
          OTHERWISE, WILL BE LIMITED TO ACTUAL DAMAGES PROVED, AND NOT TO EXCEED THE AMOUNT PAID BY
          YOU TO ADVOCATE FOR THE ADVOCATE PLATFORM. TO THE FULLEST EXTENT PERMITTED BY LAW, AND
          NOTWITHSTANDING ANYTHING TO THE CONTRARY SET FORTH HEREIN, NONE OF THE ADVOCATE PARTIES
          WILL BE LIABLE FOR ANY PUNITIVE, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, EVEN IF
          ADVOCATE OR ITS CONTENT PROVIDERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          b. TO THE FULLEST EXTENT PERMITTED BY LAW, NONE OF THE ADVOCATE PARTIES WILL HAVE ANY
          LIABILITY WHATSOEVER FOR ANY LOSSES; COSTS OR DAMAGES OF ANY KIND INCURRED OR SUFFERED BY
          YOU AS A RESULT OF SERVICES PROVIDED BY ANY OF OTHER ADVOCATE USER PURSUANT TO THE
          ADVOCATE PLATFORM.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          c. YOU REPRESENT THAT YOU HAVE INDEPENDENTLY INVESTIGATED THE ADVISABILITY OF USING THE
          ADVOCATE PLATFORM AND ENGAGING IN ANY CAMPAIGNS YOU ENGAGE IN AND THE POSSIBLE RISKS
          INVOLVED IN USING THE ADVOCATE PLATFORM OR ENGAGING IN ANY CAMPAIGNS. YOU AGREE TO
          MAINTAIN YOUR OWN INSURANCE COVERING SUCH RISKS AND WILL LOOK SOLELY TO SUCH INSURANCE FOR
          REIMBURSEMENT OF ANY RESULTING DAMAGES.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>11. Third Party Disputes</strong>.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          ADVOCATE IS NOT AFFILIATED WITH ANY BRAND, CONTENT CREATOR, CARRIER, SERVICE PROVIDER, OR
          THIRD PARTY SERVICE, AND ANY DISPUTE YOU HAVE WITH ANY BRAND, CONTENT CREATOR, CARRIER,
          SERVICE PROVIDER, THIRD PARTY SERVICE OR OTHER THIRD PARTY ARISING FROM YOUR USE OF THE
          ADVOCATE PLATFORM OR ANY CAMPAIGN, INCLUDING, WITHOUT LIMITATION, ANY OTHER ADVOCATE USER
          OR YOUR EMPLOYER, IS DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY
          RELEASE ADVOCATE (AND OUR OFFICERS, DIRECTORS, AGENTS, SUBSIDIARIES, JOINT VENTURES, AND
          EMPLOYEES) FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF
          EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH
          DISPUTES.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>12. Force Majeure</strong>.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          Advocate will not be liable for any delay or failure to perform any obligation herein if
          the delay or failure is due to unforeseen events that are beyond Advocate&rsquo;s
          reasonable control, such as strikes, blockade, war, terrorism, riots, natural disasters,
          epidemic or governmental action, in so far as such an event prevents or delays Advocate in
          fulfilling its obligations hereunder.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>13. Indemnification and Release</strong>.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          a. To the fullest extent permitted by law, you will defend, indemnify and hold Advocate,
          its licensors and each such party&#39;s parent organizations, subsidiaries, affiliates,
          officers, directors, members, employees, attorneys and agents, harmless against any loss
          or damage of any kind (including, without limitation, attorneys&rsquo; fees and lost
          revenues) arising from: (i) any and all breaches by you of these Terms or any
          representation, warranty or covenant contained herein; (ii) any and all use of the
          Advocate Platform not specifically authorized hereunder and on the Advocate Platform; and
          (iii) any and all claims and actions against Advocate by other parties to whom you allow
          access to the Advocate Platform.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          b. To the fullest extent permitted by law, you further waive, release and forever
          discharge the Advocate Parties from any and all responsibility or liability for injuries
          or damages resulting from your Massages or any other service obtained through the use of
          the Advocate Platform, including injuries or damages caused by the negligent act or
          omission of the Released Parties or in any way arising out of or connected with the
          Advocate Platform.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          c. We reserve the right, at our own expense, to assume the exclusive defense and control
          of any matter otherwise subject to indemnification by you, and in such case, you agree to
          cooperate with our defense of such claim. You will not, in any event, settle any claim or
          matter without our written consent.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>14. Additional Service Features</strong>.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          The Advocate Platform may contain information on products and services provided by third
          parties, and links (including advertisements) to third party web sites. This information
          and these products, services, and links are provided only as a convenience to Advocate
          Users. Advocate does not review or control this information or these products, services,
          or other web sites, and Advocate does not make any representations or warranties, express
          or implied, regarding this information or these products, services, or web sites.
          Inclusion of any of the foregoing in the Advocate Platform does not constitute or imply an
          endorsement, authorization, sponsorship, or affiliation by or with Advocate with respect
          to any third party or its web site or content, or any information, products, or services
          provided by that third party. Advocate is under no obligation to maintain any link on the
          Advocate Platform and may remove a link at any time in its sole discretion for any reason
          whatsoever.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol>
        <li>
          <strong>15. Dispute Resolution</strong>.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol>
        <li>
          <strong>a. Generally</strong>. In the interest of resolving disputes between you and
          Advocate in the most expedient and cost effective manner, you and Advocate agree that any
          dispute arising out of or in any way related to these Terms or your use of the Advocate
          Platform will be resolved by binding arbitration. Arbitration uses a neutral arbitrator
          instead of a judge or jury and can be subject to very limited review by courts.
          Arbitrators can award the same damages and relief that a court can award. This agreement
          to arbitrate disputes includes all claims arising out of or in any way related to these
          Terms or your use of the Advocate Platform, whether based in contract, tort, statute,
          fraud, misrepresentation, or any other legal theory, and regardless of whether a claim
          arises during or after the termination of these Terms. YOU UNDERSTAND AND AGREE THAT, BY
          ENTERING INTO THESE TERMS, YOU AND ADVOCATE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY
          OR TO PARTICIPATE IN A CLASS OR COLLECTIVE ACTION. YOU FURTHER UNDERSTAND THAT THESE
          DISPUTE RESOLUTION TERMS WILL APPLY TO YOU AND ADVOCATE UNLESS YOU CHOOSE TO OPT OUT
          PURSUANT TO SECTION i BELOW.
        </li>
      </ol>
      <p>
        Whether to agree to arbitration is an important business decision.&nbsp; It is your decision
        to make, and you should not rely solely on the information provided in these Terms as it is
        not intended to contain a complete explanation of the consequences of arbitration.&nbsp; You
        should take further steps to conduct research and to consult with others, including, but not
        limited to, an attorney, regarding the consequences of your decision, just as you would when
        making any other important business or life decision.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>b. Exceptions</strong>. Despite the provisions of the Section entitled
          &ldquo;Generally&rdquo; directly above, nothing in these Terms will be deemed to waive,
          preclude, or otherwise limit the right of either party to: (i) bring an individual action
          in small claims court; (ii) bring an action in a court pursuant to the California Private
          Attorneys General Act of 2004, California Labor Code &sect; 2698 et seq.; (iii) seek
          injunctive relief in a court of law; or (iv) to file suit in a court of law to address an
          intellectual property infringement claim.
        </li>
        <p>&nbsp;</p>
        <li>
          <strong>c. Arbitrator</strong>. Any arbitration between you and Advocate will be governed
          by the Federal Arbitration Act, and governed by the Commercial Dispute Resolution
          Procedures and the Supplementary Procedures for Consumer Related Disputes (collectively,
          &ldquo;
          <strong>AAA Rules</strong>
          &rdquo;) of the American Arbitration Association (&ldquo;
          <strong>AAA</strong>
          &rdquo;), as modified by these Terms, and will be administered by the AAA. The AAA Rules
          and filing forms are available online at www.adr.org, by calling the AAA at
          1-800-778-7879, or by contacting Advocate. The arbitrator has exclusive authority to
          resolve any dispute relating to the interpretation, applicability, or enforceability of
          this binding arbitration agreement.
        </li>
        <p>&nbsp;</p>
        <li>
          <strong>d. Notice; Process</strong>. A party who intends to seek arbitration must first
          send a written notice of the dispute to the other party by U.S. Mail or electronic mail
          within the applicable statute of limitations period (&ldquo;
          <strong>Notice</strong>
          &rdquo;). Advocate&rsquo;s address for Notice is: 2420 14
          <sup>th</sup>, Apt 822, Washington, D.C. 20009. The Notice must: (a) describe the nature
          and basis of the claim or dispute; and (b) set forth the specific relief sought (&ldquo;
          <strong>Demand</strong>
          &rdquo;). The parties will make good faith efforts to resolve the claim directly, but if
          the parties do not reach an agreement to do so within 30 days after the Notice is
          received, you or Advocate may commence an arbitration proceeding. During the arbitration,
          the amount of any settlement offer made by you or Advocate must not be disclosed to the
          arbitrator until after the arbitrator makes a final decision and award, if any. If the
          dispute is finally resolved through arbitration in your favor, Advocate will pay you the
          highest of the following: (i) the amount awarded by the arbitrator, if any; (ii) the last
          written settlement amount offered by Advocate in settlement of the dispute prior to the
          arbitrator&rsquo;s award; or (iii) $1,000.
        </li>
        <p>&nbsp;</p>
        <li>
          <strong>e. Fees</strong>. If you commence arbitration in accordance with these Terms,
          Advocate will reimburse you for your payment of the filing fee, unless your claim is for
          more than $15,000 or as set forth below, in which case the payment of any fees will be
          decided by the AAA Rules. Any arbitration hearing will take place at a location to be
          agreed upon in Washington, DC, but if the claim is for $15,000 or less, you may choose
          whether the arbitration will be conducted: (i) solely on the basis of documents submitted
          to the arbitrator; (ii) through a non-appearance based telephone hearing; or (iii) by an
          in-person hearing as established by the AAA Rules in the county (or parish) of your
          billing address. If the arbitrator finds that either the substance of your claim or the
          relief sought in the Demand is frivolous or brought for an improper purpose (as measured
          by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of
          all fees will be governed by the AAA Rules. In that case, you agree to reimburse Advocate
          for all monies previously disbursed by it that are otherwise your obligation to pay under
          the AAA Rules. Regardless of the manner in which the arbitration is conducted, the
          arbitrator must issue a reasoned written decision sufficient to explain the essential
          findings and conclusions on which the decision and award, if any, are based. The
          arbitrator may make rulings and resolve disputes as to the payment and reimbursement of
          fees or expenses at any time during the proceeding and upon request from either party made
          within 14 days of the arbitrator&rsquo;s ruling on the merits.
        </li>
        <p>&nbsp;</p>
        <li>
          <strong>f. No Class Actions</strong>. YOU AND ADVOCATE AGREE THAT EACH MAY BRING CLAIMS
          AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS
          MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING (OTHER THAN ACTIONS UNDER THE
          CALIFORNIA PRIVATE ATTORNEYS GENERAL ACT OF 2004, California Labor Code &sect; 2698 et
          seq. WHICH ARE NOT COVERED BY THIS SECTION 15). Further, unless both you and Advocate
          agree otherwise, the arbitrator may not consolidate more than one person&rsquo;s claims,
          and may not otherwise preside over any form of a representative or class proceeding.
        </li>
        <p>&nbsp;</p>
        <li>
          <strong>g. Modifications to this Arbitration Provision</strong>. If Advocate makes any
          future change to this arbitration provision, other than a change to Advocate&rsquo;s
          address for Notice, you may reject the change by sending us written notice within 30 days
          of the change to Advocate&rsquo;s address for Notice, in which case this arbitration
          provision, as in effect immediately prior to the changes you rejected, will continue to
          govern any disputes between you and Advocate. If you do not send such written notice, your
          continued use of the Advocate Platform following any such change means that you have
          consented to such change.
        </li>
        <p>&nbsp;</p>
        <li>
          <strong>h. Enforceability</strong>. If the Section entitled &ldquo;No Class Actions&rdquo;
          is found to be unenforceable or if the entirety of this Section 15 is found to be
          unenforceable, then the entirety of this Section 15 will be null and void and, in that
          case, the parties agree that the exclusive jurisdiction and venue described directly below
          will govern any action arising out of or related to these Terms or your use of the
          Advocate Platform.
        </li>
        <p>&nbsp;</p>
        <li>
          <strong>i. Opt Out.</strong> Arbitration is not a mandatory condition of these Terms. If
          you do not want to be subject to this Dispute Resolution provision, you may opt out of
          this Dispute Resolution provision by notifying Advocate in writing of your decision by
          sending, within 30 days of the date you receive these Terms, an electronic message to{' '}
          <a href="mailto:admin@adv.gg">admin@adv.gg</a>, stating clearly your full name and intent
          to opt out of the Dispute Resolution provision. Should you choose not to opt out of this
          Dispute Resolution provision within the 30-day period, you and Advocate will be bound by
          the terms of this Dispute Resolution provision. You have the right to consult with counsel
          of your choice concerning this Dispute Resolution provision. You understand that you will
          not be subject to retaliation if you exercise your right to opt out of coverage under this
          Dispute Resolution provision.
        </li>
      </ol>
      <ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            <strong>16. Protected Activity Not Prohibited.</strong>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <li>
          You should understand that, insofar as permitted by applicable law, nothing in these Terms
          will in any way limit or prohibit you from filing a charge or complaint with, or otherwise
          communicating or cooperating with or participating in any investigation or proceeding
          (&ldquo;
          <strong>Protected Activity</strong>
          &rdquo;) that may be conducted by any federal, state or local government agency or
          commission (&ldquo;
          <strong>Government Agencies</strong>
          &rdquo;).&nbsp; You understand that in connection with such Protected Activity, you are
          permitted to disclose documents or other information as permitted by law, and without
          giving notice to, or receiving authorization from, Advocate. Notwithstanding, in making
          any such disclosures or communications, you agree to take all reasonable precautions to
          prevent any unauthorized use or disclosure of any information that may constitute Advocate
          confidential information to any parties other than the Government Agencies.
        </li>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <li>
          You may receive confidential information from time to time in connection with your
          performance of Campaigns, including, without limitation, information regarding the release
          of new products, goods or services that has not yet been publicly disclosed
          <strong>(“Campaign Confidential Information”)</strong>. Any such Campaign Confidential
          Information will be disclosed to you pursuant to a written notice from Advocate indicating
          that such information constitutes Campaign Confidential Information pursuant to these
          Terms. You shall maintain the confidentiality of, and not directly or indirectly use or
          divulge to any Person, any Campaign Confidential Information unless and until you receive
          written authorization from Advocate to do so, or as may be required by applicable law. If
          you shall be required by applicable law to disclose any Campaign Confidential Information,
          you shall provide Advocate with prompt written notice of such requirement so that Advocate
          may seek an appropriate protective order or other appropriate remedy, and you shall
          cooperate with Advocate to obtain such an order or other remedy; provided that in the
          event such an order or remedy is not obtained, you shall disclose only that portion of
          such Campaign Confidential Information which you are legally compelled to disclose and
          shall exercise your best efforts to obtain reliable assurance that confidential treatment
          will be accorded to any such Campaign Confidential Information so disclosed.
        </li>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            <strong>17. Governing Law; Choice of Forum</strong>.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <li>
          The laws of the State of Delaware, excluding its conflicts of law rules, govern these
          Terms and your use of the Advocate Platform. Your use of the Advocate Platform may also be
          subject to other local, state, national, or international laws; provided, however, that
          the United Nations Convention on Contracts for the International Sale of Goods will not
          apply to any provision of these Terms. To the extent that any action relating to any
          dispute hereunder is permitted to be brought in a court of law, such action will be
          subject to the exclusive jurisdiction of the state and federal courts located in
          Washington, DC and you hereby irrevocably submit to personal jurisdiction in such courts,
          and waive any defense of inconvenient forum.
        </li>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            <strong>18. Feedback</strong>.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <li>
          If You provide any feedback to Advocate concerning the functionality and performance of
          the Advocate Platform (including identifying potential errors and improvements), You
          hereby assign to Advocate all right, title, and interest in and to such feedback, and
          Advocate is free to use such feedback without payment or restriction.
        </li>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            <strong>19. Entire Agreement; Variation</strong>.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <li>
          These Terms set forth the entire agreement between Advocate and you with respect to the
          Advocate Platform. &nbsp;These Terms supersede and govern all previous oral and written
          communications regarding these matters, all of which are merged into these Terms. No usage
          of trade or other regular practice or method of dealing between the parties will be used
          to modify, interpret, supplement, or alter these Terms.&nbsp; These Terms may be changed
          only by a written agreement signed by an authorized agent of the party against whom
          enforcement is sought
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol>
        <li>
          <strong>20. Severability</strong>.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol>
        <li>
          If any provision of these Terms is held invalid, illegal or unenforceable, such provisions
          will be modified, or if not possible, severed, to reflect the fullest valid, legal and
          enforceable expression of the intent of the parties and the remainder of these Terms will
          not be affected thereby.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol>
        <li>
          <strong>21. Relationship of Parties</strong>.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol>
        <li>
          <strong>Relationship of Parties. </strong>
          Nothing herein will be deemed to create an employer-employee relationship between Advocate
          and you, nor any agency, joint venture or partnership relationship between the parties.
          Neither party will have the right to bind the other to any obligation, nor have the right
          to incur any liability on behalf of the other.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol>
        <li>
          <strong>22. Waiver</strong>.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol>
        <li>
          No delay, omission, or failure to exercise any right or remedy provided herein will be
          deemed to be a waiver thereof or an acquiescence in the event giving rise to such right or
          remedy, but every such right or remedy may be exercised, from time to time as may be
          deemed expedient by the party exercising such remedy or right.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol>
        <li>
          <strong>23. Assignment</strong>.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol>
        <li>
          Neither these Terms nor any of the rights granted to you herein may be assigned or
          transferred by you, whether voluntarily or by operation of law, without the express prior
          written permission of Advocate and any attempt to do so will be null and void. However,
          Advocate may assign or transfer these Terms at any time without your permission.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol>
        <li>
          <strong>24. Third-Party Beneficiaries</strong>.
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol>
        <li>
          The provisions of these Terms relating to the rights of Advocate content providers are
          intended for the benefit of such content providers, and such content providers, as
          third-party beneficiaries, will be entitled to enforce such provisions in accordance with
          their terms, irrespective of the fact that they are not signatories to these Terms.
        </li>
      </ol>
      <p>&nbsp;</p>
    </div>
  );
};

TermsOfService.propTypes = {
  isManager: PropTypes.bool,
  setHasReadTos: PropTypes.func,
};

export default TermsOfService;
