import {useState} from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxMarked,
  MinusBox,
  Twitch,
  Twitter,
  AccountCheck,
  AccountRemove,
} from 'mdi-material-ui';

import Button from 'components/molecules/Button';
import TextFilter from 'components/components/TextFilter';
import IconButton from 'components/components/IconButton';
import BroadcasterComplianceOverrideModal from '../BroadcasterComplianceOverrideModal';
import {formatDate} from 'utils/numberFormats';

const ComponentCompliance = ({
  openTimeline,
  compliantActions,
  noncompliantActions,
  selectedComponent,
  version,
  getIconOrStatus,
  showTweets,
  modalShow,
  showButtons,
}) => {
  const [filter, setFilter] = useState('');

  return (
    <div className="componentCompliance">
      <div className={`topBar ${selectedComponent.type}`}>
        <img className="componentImage" src={selectedComponent && version.image} alt="" />
        {selectedComponent.type === 'banner-graphics' ? <Twitch /> : <Twitter />}
        <h3>{selectedComponent && selectedComponent.name}</h3>
        <TextFilter placeholder="Filter" handleChange={(e) => setFilter(e.target.value)} />
        {showTweets && (
          <Button handleClick={showTweets}>
            {openTimeline ? 'Hide Timeline' : 'Show Timeline'}
          </Button>
        )}
      </div>
      {noncompliantActions.length > 0 && (
        <div className="complianceSection nonCompliantSection">
          {noncompliantActions
            .filter((a) => a.broadcaster?.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
            .map(
              (action) =>
                action.broadcaster && (
                  <div
                    className={`broadcasterItem ${
                      noncompliantActions.length > 4 ? 'small' : 'large'
                    } ${
                      selectedComponent.type === 'banner-graphics'
                        ? getIconOrStatus(action.status)
                        : 'incompliant'
                    }`}
                    key={action.broadcaster}
                  >
                    <img src={action.profile_image} alt="" />
                    <h3>{action.broadcaster}</h3>
                    {action.last_updated && <h4>{formatDate(action.last_updated)}</h4>}
                    {selectedComponent.type === 'banner-graphics' ? (
                      getIconOrStatus(action.status, true)
                    ) : (
                      <MinusBox />
                    )}
                    {showButtons && selectedComponent.type === 'banner-graphics' && (
                      <IconButton
                        handleClick={() =>
                          modalShow(
                            `override-compliance-${selectedComponent.id}-${action.broadcaster}`,
                          )
                        }
                        tooltip="Mark As Compliant"
                        label="twitch"
                      >
                        <AccountCheck />
                      </IconButton>
                    )}
                  </div>
                ),
            )}
        </div>
      )}
      {compliantActions.length > 0 && (
        <div className="complianceSection compliantSection">
          {compliantActions
            .filter((a) => a.broadcaster?.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
            .map(
              (action) =>
                action.broadcaster && (
                  <div
                    className={`broadcasterItem ${
                      compliantActions.length > 4 ? 'small' : 'large'
                    } ${
                      selectedComponent.type === 'banner-graphics'
                        ? getIconOrStatus(action.status)
                        : 'compliant'
                    }`}
                    key={action.broadcaster}
                  >
                    <img src={action.profile_image} alt="" />
                    <h3>{action.broadcaster}</h3>
                    {action.last_updated && <h4>{formatDate(action.last_updated)}</h4>}
                    {!showTweets ? getIconOrStatus(action.status, true) : <CheckboxMarked />}
                    {showButtons && selectedComponent.type === 'banner-graphics' && (
                      <IconButton
                        handleClick={() =>
                          modalShow(
                            `override-compliance-${selectedComponent.id}-${action.broadcaster}`,
                          )
                        }
                        tooltip="Mark As Non-Compliant"
                        label="twitch"
                      >
                        <AccountRemove />
                      </IconButton>
                    )}
                  </div>
                ),
            )}
        </div>
      )}

      {/* Override compliance score modal */}
      {showButtons &&
        noncompliantActions.map((action) => (
          <BroadcasterComplianceOverrideModal
            broadcaster={action.broadcaster}
            complianceAction={action}
            key={action.broadcaster}
          />
        ))}

      {/* Override compliance score modal */}
      {showButtons &&
        compliantActions.map((action) => (
          <BroadcasterComplianceOverrideModal
            broadcaster={action.broadcaster}
            complianceAction={action}
            key={action.broadcaster}
          />
        ))}
    </div>
  );
};

ComponentCompliance.propTypes = {
  openTimeline: PropTypes.bool,
  compliantActions: PropTypes.arrayOf(PropTypes.object),
  noncompliantActions: PropTypes.arrayOf(PropTypes.object),
  selectedComponent: PropTypes.object,
  version: PropTypes.object,
  getIconOrStatus: PropTypes.func,
  modalShow: PropTypes.func,
  showTweets: PropTypes.func,
  showButtons: PropTypes.bool,
};

export default ComponentCompliance;
