import {useState} from 'react';
import PropTypes from 'prop-types';
import {Send, SquareEditOutline} from 'mdi-material-ui';
import {getTweetIdFromUrl, getLinkText} from 'utils/campaignTools';
import {formatDate} from 'utils/numberFormats';
import useModal from 'hooks/useModal';

import TweetPreview from 'components/molecules/TweetPreview';
import IconButton from 'components/components/IconButton';
import EditTweetForm from '../../../../forms/EditTweetForm';
import ManualTweetModal from './components/ManualTweetModal';

const BroadcasterTwitterAction = ({action, index, isBlocked}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [modalShow] = useModal();
  const linkToShare = action.version ? getLinkText(action.version) : undefined;

  return (
    <div className="broadcasterActionTwitterWrapper">
      <div className="broadcasterAction">
        <div className="leftContent">
          <div className="leftBar">
            <h3 className="index">{index}</h3>
            <h2>{action.type.name}</h2>
            {action.version && action.version.config && action.version.config.is_editable && (
              <IconButton label="edit" handleClick={() => setIsEditing(!isEditing)}>
                <SquareEditOutline />
              </IconButton>
            )}
          </div>
          <div className="mask">
            {!isBlocked && (
              <div className="bottomBar">
                <h4>
                  {action.type.slug === 'twitter-send-tweet'
                    ? 'Send Tweet Automatically'
                    : 'Send Retweet'}
                </h4>
                <IconButton
                  label="help"
                  handleClick={() => modalShow(`action-${action.id}-complete`)}
                >
                  <Send />
                </IconButton>
              </div>
            )}
            {action.type.slug === 'twitter-send-tweet' && (
              <div className="bottomBar">
                <h4>Send Tweet Manually</h4>
                <IconButton label="help" handleClick={() => modalShow(`manual-tweet`)}>
                  <Send />
                </IconButton>
              </div>
            )}
          </div>
          {action.component && action.component.campaign && (
            <div className="campaignDetails">
              <p>{`${action.component.name} - ${action.component.campaign.name}`}</p>
              <p>{`Created ${formatDate(action.component.activeVersion.created)}`}</p>
            </div>
          )}
        </div>
        {action.type.slug === 'twitter-send-tweet' && (
          <div className="tweet tweetRow">
            {isEditing ? (
              <EditTweetForm
                action={action}
                cancel={() => setIsEditing(false)}
                linkToShare={linkToShare}
                initialValues={{
                  tweet_text: action.completion_data.tweet_text || action.version.message,
                }}
              />
            ) : (
              <p className="content">
                {action.completion_data.tweet_text || action.version.message}
              </p>
            )}
            {action.version && action.version.image && (
              <div>
                <img className="twitter-send-tweet" src={action.version.image} alt="" />
              </div>
            )}
          </div>
        )}
        {action.type.slug === 'twitter-retweet' && (
          <div className="tweet-preview-wrapper">
            <TweetPreview tweetId={getTweetIdFromUrl(action.version.message)} />
          </div>
        )}

        <ManualTweetModal action={action} />
      </div>
    </div>
  );
};

BroadcasterTwitterAction.propTypes = {
  action: PropTypes.object,
  index: PropTypes.number.isRequired,
  isBlocked: PropTypes.bool,
};

export default BroadcasterTwitterAction;
