// Takes raw profile data from the server and normalizes
export const PROFILE_COMPLETE_SET_USER_DATA = 'PROFILE_SET_USER_DATA';
export const PROFILE_LOAD_USER_DATA = 'PROFILE_LOAD_USER_DATA';

export const profileCompleteSetUserData = (userData, saveData) => ({
  type: PROFILE_COMPLETE_SET_USER_DATA,
  payload: {
    userData,
    saveData,
  },
});

export const profileLoadUserData = () => ({
  type: PROFILE_LOAD_USER_DATA,
});

// Empties User Data from store.  Used on logout to prevent information leakage
export const PROFILE_COMPLETE_CLEAR_USER_DATA = 'PROFILE_COMPLETE_CLEAR_USER_DATA';
export const PROFILE_RESET_USER_DATA = 'PROFILE_RESET_USER_DATA';

export const profileCompleteClearUserData = () => ({
  type: PROFILE_COMPLETE_CLEAR_USER_DATA,
});

export const profileResetUserData = () => ({
  type: PROFILE_RESET_USER_DATA,
});

// Request User Data on server.  Currently unused, since user data is returned from the server
// with a successful login request
export const PROFILE_READ_REQUEST = 'PROFILE_READ_REQUEST';
export const PROFILE_READ_SUCCESS = 'PROFILE_READ_SUCCESS';
export const PROFILE_READ_FAILED = 'PROFILE_READ_FAILED';

export const profileReadRequest = () => ({
  type: PROFILE_READ_REQUEST,
  meta: {thunk: 'profileRequest'},
});

export const profileReadSuccess = (thunk) => ({
  type: PROFILE_READ_SUCCESS,
  meta: {thunk},
});

export const profileReadFailed = (error, thunk) => ({
  type: PROFILE_READ_FAILED,
  error: true,
  payload: error,
  meta: {thunk},
});

// Update User Data on server
export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILED = 'PROFILE_UPDATE_FAILED';

export const profileUpdateRequest = (data) => ({
  type: PROFILE_UPDATE_REQUEST,
  payload: data,
  meta: {thunk: 'profileUpdate'},
});

export const profileUpdateSuccess = (thunk) => ({
  type: PROFILE_UPDATE_SUCCESS,
  meta: {thunk},
});

export const profileUpdateFailed = (error, thunk) => ({
  type: PROFILE_UPDATE_FAILED,
  error: true,
  payload: error,
  meta: {thunk},
});

// API Key Handling
export const API_KEY_GENERATE_REQUEST = 'API_KEY_GENERATE_REQUEST';
export const API_KEY_GENERATE_SUCCESS = 'API_KEY_GENERATE_SUCCESS';
export const API_KEY_GENERATE_FAILED = 'API_KEY_GENERATE_FAILED';

export const apiKeyGenerateRequest = () => ({
  type: API_KEY_GENERATE_REQUEST,
  meta: {thunk: 'apiKeyGenerate'},
});

export const apiKeyGenerateSuccess = (thunk) => ({
  type: API_KEY_GENERATE_SUCCESS,
  meta: {thunk},
});

export const apiKeyGenerateFailed = (error, thunk) => ({
  type: API_KEY_GENERATE_FAILED,
  error: true,
  payload: error,
  meta: {thunk},
});
