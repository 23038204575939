import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {isPending, hasFailed} from 'redux-saga-thunk';

import {shopifyURLRequest} from 'store/actions';
import BaseTemplate from 'components/components/BaseTemplate';
import LoadingBar from 'components/molecules/LoadingBar';

const ShopifyInstallPage = () => {
  const thunk = 'shopifyConfirm';
  const loading = useSelector((state) => isPending(state, thunk));
  const failed = useSelector((state) => hasFailed(state, thunk));

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(shopifyURLRequest(location.search));
  }, [location, dispatch]);

  return (
    <BaseTemplate>
      {loading && <LoadingBar message="Getting Shopify Credentials..." />}
      {failed && (
        <h2>
          Couldn&apos;t install Shopify app. Please <a href="mailto:admin@adv.gg">contact</a>
          The advocate admins for help
        </h2>
      )}
    </BaseTemplate>
  );
};

export default ShopifyInstallPage;
