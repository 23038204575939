import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {isPending} from 'redux-saga-thunk';

import LoadingBar from 'components/molecules/LoadingBar';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {sassVariables} from 'adv-theme';

// Takes raw view data and converts it to chart-ready data with the correct
// filters, dates and time key format applied
// viewershipData: {chunk_start [datetime], broadcaster [string], component_type [string], id [int], country [string]}
// output: { key [string], value [int], time [datetime]}
const formatViewData = (rawData) => rawData.map((view) => [view.day * 1000, view.avg_concurrents]);

const ViewershipChart = ({options, title, views}) => {
  const chartData = formatViewData(views);
  const loading = useSelector((state) => isPending(state, 'viewsRawData'));
  const defaultOptions = {
    chart: {
      type: 'areaspline',
      height: 250,
    },
    xAxis: {
      type: 'datetime',
      showInLegend: false,
      labels: {
        enabled: chartData.length !== 0,
      },
    },
    colors: [sassVariables.primary],
    title: {
      text: undefined,
    },
    yAxis: {
      title: {
        text: undefined,
      },
      minRange: 50,
      min: 0,
      gridLineColor: '000',
      alternateGridColor: '#f7f7ff',
      offset: 0,
    },
    tooltip: {
      shared: true,
      pointFormat: '{point.y} Viewers',
      valueDecimals: 0,
      enabled: chartData.length !== 0,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, sassVariables.primary],
            [1, '#ffffff'],
          ],
        },
        marker: {
          radius: 0,
          states: {
            hover: {
              enabled: chartData.length !== 0,
            },
          },
        },
        lineWidth: 2,
      },
    },
    series: [
      {
        data: chartData.length === 0 ? [[0, 0]] : chartData,
        showInLegend: false,
      },
    ],
  };

  return (
    <div className={`campaignMetricGraph ${loading ? 'loading' : ''}`}>
      <div className="topBar">
        <h2 className="title">{title}</h2>
      </div>
      {loading && <LoadingBar />}
      <div className="mainChart">
        <HighchartsReact highcharts={Highcharts} options={{...options, ...defaultOptions}} />
      </div>
    </div>
  );
};

ViewershipChart.propTypes = {
  options: PropTypes.object,
  title: PropTypes.string,
  views: PropTypes.arrayOf(PropTypes.object),
};

export default ViewershipChart;
