// https://github.com/diegohaz/arc/wiki/Actions
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
export const RESOURCE_CREATE_REQUEST = 'RESOURCE_CREATE_REQUEST';
export const RESOURCE_CREATE_SUCCESS = 'RESOURCE_CREATE_SUCCESS';
export const RESOURCE_CREATE_FAILURE = 'RESOURCE_CREATE_FAILURE';

export const resourceCreateRequest = (resource, data) => ({
  type: RESOURCE_CREATE_REQUEST,
  payload: {data},
  meta: {
    resource,
    thunk: `${resource}Create`,
  },
});

export const resourceCreateSuccess = (resource, detail, thunk) => ({
  type: RESOURCE_CREATE_SUCCESS,
  payload: detail,
  meta: {
    thunk,
    resource,
  },
});

export const resourceCreateFailure = (resource, error, thunk) => ({
  type: RESOURCE_CREATE_FAILURE,
  error: true,
  payload: error,
  meta: {
    resource,
    thunk,
  },
});

export const RESOURCE_LIST_READ_REQUEST = 'RESOURCE_LIST_READ_REQUEST';
export const RESOURCE_LIST_READ_SUCCESS = 'RESOURCE_LIST_READ_SUCCESS';
export const RESOURCE_LIST_READ_FAILURE = 'RESOURCE_LIST_READ_FAILURE';

export const resourceListReadRequest = (resource, params, thunk, path) => ({
  type: RESOURCE_LIST_READ_REQUEST,
  payload: {params},
  meta: {
    resource,
    path,
    thunk: thunk || `${resource}ListRead`,
  },
});

export const resourceListReadSuccess = (resource, list, thunk) => ({
  type: RESOURCE_LIST_READ_SUCCESS,
  payload: list,
  meta: {
    thunk,
    resource,
  },
});

export const resourceListReadFailure = (resource, error, thunk) => ({
  type: RESOURCE_LIST_READ_FAILURE,
  error: true,
  payload: error,
  meta: {
    thunk,
    resource,
  },
});

export const RESOURCE_BATCH_UPDATE_REQUEST = 'RESOURCE_BATCH_UPDATE_REQUEST';
export const RESOURCE_BATCH_UPDATE_SUCCESS = 'RESOURCE_BATCH_UPDATE_SUCCESS';
export const RESOURCE_BATCH_UPDATE_FAILURE = 'RESOURCE_BATCH_UPDATE_FAILURE';

export const resourceBatchUpdateRequest = (resource, data, thunk) => ({
  type: RESOURCE_BATCH_UPDATE_REQUEST,
  payload: {data},
  meta: {
    resource,
    thunk: thunk || `${resource}BatchUpdate`,
  },
});

export const resourceBatchUpdateSuccess = (resource, list, thunk) => ({
  type: RESOURCE_BATCH_UPDATE_SUCCESS,
  payload: list,
  meta: {
    thunk,
    resource,
  },
});

export const resourceBatchUpdateFailure = (resource, error, thunk) => ({
  type: RESOURCE_BATCH_UPDATE_FAILURE,
  error: true,
  payload: error,
  meta: {
    thunk,
    resource,
  },
});

export const RESOURCE_DETAIL_READ_REQUEST = 'RESOURCE_DETAIL_READ_REQUEST';
export const RESOURCE_DETAIL_READ_SUCCESS = 'RESOURCE_DETAIL_READ_SUCCESS';
export const RESOURCE_DETAIL_READ_FAILURE = 'RESOURCE_DETAIL_READ_FAILURE';

export const resourceDetailReadRequest = (resource, needle, params) => ({
  type: RESOURCE_DETAIL_READ_REQUEST,
  payload: {needle, params},
  meta: {
    resource,
    thunk: `${resource}DetailRead`,
  },
});

export const resourceDetailReadSuccess = (resource, detail, thunk) => ({
  type: RESOURCE_DETAIL_READ_SUCCESS,
  payload: detail,
  meta: {
    thunk,
    resource,
  },
});

export const resourceDetailReadFailure = (resource, error, thunk) => ({
  type: RESOURCE_DETAIL_READ_FAILURE,
  error: true,
  payload: error,
  meta: {
    thunk,
    resource,
  },
});

export const RESOURCE_UPDATE_REQUEST = 'RESOURCE_UPDATE_REQUEST';
export const RESOURCE_UPDATE_SUCCESS = 'RESOURCE_UPDATE_SUCCESS';
export const RESOURCE_UPDATE_FAILURE = 'RESOURCE_UPDATE_FAILURE';

export const resourceUpdateRequest = (resource, needle, data, thunk, partial) => ({
  type: RESOURCE_UPDATE_REQUEST,
  payload: {needle, data},
  meta: {
    resource,
    partial,
    thunk: thunk || `${resource}Update`,
  },
});

export const resourceUpdateSuccess = (resource, detail, thunk) => ({
  type: RESOURCE_UPDATE_SUCCESS,
  payload: detail,
  meta: {
    thunk,
    resource,
  },
});

export const resourceUpdateFailure = (resource, error, thunk) => ({
  type: RESOURCE_UPDATE_FAILURE,
  error: true,
  payload: error,
  meta: {
    thunk,
    resource,
  },
});

export const RESOURCE_DELETE_REQUEST = 'RESOURCE_DELETE_REQUEST';
export const RESOURCE_DELETE_SUCCESS = 'RESOURCE_DELETE_SUCCESS';
export const RESOURCE_DELETE_FAILURE = 'RESOURCE_DELETE_FAILURE';

export const resourceDeleteRequest = (resource, needle) => ({
  type: RESOURCE_DELETE_REQUEST,
  payload: {needle},
  meta: {
    resource,
    thunk: `${resource}Delete`,
  },
});

export const resourceDeleteSuccess = (resource, needle, thunk) => ({
  type: RESOURCE_DELETE_SUCCESS,
  payload: {needle},
  meta: {
    thunk,
    resource,
  },
});

export const resourceDeleteFailure = (resource, error, thunk) => ({
  type: RESOURCE_DELETE_FAILURE,
  error: true,
  payload: error,
  meta: {
    thunk,
    resource,
  },
});

// Set resource(s) locally, rather than from server data
export const RESOURCE_ADD = 'RESOURCE_ADD';

export const resourceAdd = (resources) => ({
  type: RESOURCE_ADD,
  payload: resources,
});
