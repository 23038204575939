const IconProfile = () => (
  <svg version="1.1" viewBox="0 0 612 547">
    <circle display="none" fill="#D1D3D4" cx="304" cy="284.272" r="161" />
    <g>
      <path
        d="M558.638,80.917c-0.008-25.911-20.781-46.992-46.309-46.992h-0.005l-403.394,0.149
      c-25.536,0.01-46.302,21.106-46.292,47.027l0.145,388.316c0.009,25.913,20.785,46.994,46.311,46.994l403.397-0.15
      c12.583-0.004,24.784-5.303,33.473-14.536c8.272-8.792,12.824-20.331,12.819-32.494L558.638,80.917z M512.478,481.262
      l-403.381,0.15c0,0-0.002,0-0.003,0c-6.245,0-11.308-5.375-11.311-12.007L97.638,81.089c-0.002-6.633,5.06-12.012,11.306-12.014
      l403.38-0.149c0.001,0,0.003,0,0.004,0c6.244,0,11.308,5.374,11.31,12.005l0.145,388.316
      C523.785,475.88,518.723,481.26,512.478,481.262z"
      />
      <path
        d="M390.618,289.96c12.422-16.73,19.78-37.434,19.78-59.825c0-55.453-45.114-100.567-100.567-100.567
      c-55.453,0-100.567,45.114-100.567,100.567c0,23.991,8.449,46.043,22.521,63.347l-37.908,103.783
      c-3.316,9.078,1.355,19.126,10.433,22.441c1.981,0.724,4.009,1.067,6.003,1.067c7.141,0,13.847-4.404,16.439-11.501l33.486-91.676
      c14.644,8.336,31.571,13.105,49.593,13.105c19.396,0,37.523-5.524,52.901-15.075l34.207,93.646
      c2.592,7.098,9.298,11.501,16.439,11.501c1.993,0,4.021-0.344,6.002-1.067c9.079-3.315,13.75-13.363,10.434-22.441L390.618,289.96z
      M244.264,230.135c0-36.154,29.413-65.567,65.567-65.567c36.154,0,65.567,29.413,65.567,65.567s-29.413,65.567-65.567,65.567
      C273.677,295.703,244.264,266.289,244.264,230.135z"
      />
    </g>
  </svg>
);

export default IconProfile;
