import PropTypes from 'prop-types';

const HamburgerMenu = ({children}) => (
  <div className="hamburgerMenu">
    <div className="hamburger">
      <span />
      <span />
      <span />
    </div>
    <div className="menu">{children}</div>
  </div>
);

HamburgerMenu.propTypes = {
  children: PropTypes.any,
};

export default HamburgerMenu;
