import {useDispatch} from 'react-redux';
import {modalShow as modalShowAction, modalHide as modalHideAction} from 'store/actions';

const useModal = () => {
  const dispatch = useDispatch();
  const modalShow = (name) => dispatch(modalShowAction(name));
  const modalHide = (name) => dispatch(modalHideAction(name));

  return [modalShow, modalHide];
};

export default useModal;
