import {take, call, put, fork} from 'redux-saga/effects';
import {FORM_ERROR} from 'final-form';
import {getCookie, setCookie, removeCookie} from 'redux-cookie';
import history from 'localHistory';

import {getExpiryDate} from 'utils/expiry';
import {
  AUTH_TEST_REQUEST,
  AUTH_CLIENT_LOGIN_REQUEST,
  AUTH_LOGOUT_REQUEST,
  AUTH_CHECK_STATUS,
  AUTH_COMPLETE_LOGIN,
  AUTH_COMPLETE_LOGOUT,
  AUTH_OAUTH2_CODE_REQUEST,
  AUTH_RESET_REQUEST_REQUEST,
  authResetRequestSuccess,
  authResetRequestFailure,
  AUTH_RESET_PASSWORD_REQUEST,
  authResetPasswordSuccess,
  authResetPasswordFailure,
  authSetStatus,
  authClientLoginFailed,
  authTestComplete,
  authLogoutFailed,
  authCompleteLogin,
  authCompleteLogout,
  authOAuth2CodeFailed,
  AUTH_SIGN_UP_REQUEST,
  authSignUpFailure,
  AUTH_EMAIL_CONFIRMATION_REQUEST,
  authEmailConfirmationFailed,
  authEmailConfirmationSuccess,
} from './actions';
import {
  profileCompleteClearUserData,
  profileLoadUserData,
  profileCompleteSetUserData,
  profileReadRequest,
} from '../profile/actions';
import {commonResetState} from '../common/actions';

// Handles New User Signup
export function* signUpRequest(api, payload, {thunk}) {
  try {
    const response = yield call([api, api.post], 'rest-auth/registration/', payload);
    yield put(profileCompleteSetUserData(response.user, true));
    yield put(authCompleteLogin(response.key, false, thunk));
  } catch (error) {
    const formError = {
      [FORM_ERROR]: error.non_field_errors ? error.non_field_errors[0] : JSON.stringify(error),
    };
    yield put(authSignUpFailure(formError, thunk));
  }
}

// Handles full local login functionality, including:
// - Setting cookie
// - Updating isAuthenticated redux store
// - redirecting to / then to /dashboard
export function* completeLogin(api, token, stayLoggedIn, next) {
  yield call([api, api.setToken], token);
  if (stayLoggedIn) {
    yield put(setCookie('token', token, {expires: getExpiryDate(), path: '/'}));
  }

  yield put(authSetStatus(true));
  yield call(history.push, next || '/');
}

// Handles full local login functionality, including:
// - Removing cookie
// - Updating isAuthenticated redux store
// - redirecting to /login
export function* completeLogout(api) {
  yield call([api, api.unsetToken]);
  yield put(removeCookie('token', {path: '/'}));
  yield put(authSetStatus(false));
  yield put(profileCompleteClearUserData());
  yield put(commonResetState());
  yield call(history.push, '/login');
}

// Sends username/password login to server (for brands/clients and admins)
export function* clientLoginRequest(api, payload, meta) {
  try {
    const response = yield call([api, api.post], 'rest-auth/login/', {
      username: payload.username,
      password: payload.password,
    });
    yield put(profileCompleteSetUserData(response.user, true));
    yield put(authCompleteLogin(response.key, payload.stayLoggedIn, meta.thunk, meta.next));
  } catch (error) {
    const formError = {
      [FORM_ERROR]: error.non_field_errors ? error.non_field_errors[0] : JSON.stringify(error),
    };
    yield put(authClientLoginFailed(formError, meta.thunk));
  }
}

// Sends logout request to server
export function* logoutRequest(api, meta) {
  try {
    yield call([api, api.post], 'rest-auth/logout/');
    yield put(authCompleteLogout(meta.thunk));
  } catch (error) {
    yield put(authLogoutFailed(error, meta.thunk));
  }
}

// Sends authentication code from oauth provider to server; used for logging in Broadcasters
export function* oauth2CodeRequest(api, code, provider, params, meta) {
  try {
    const response = yield call([api, api.post], `users/${provider}/`, {code}, {params});
    yield put(profileCompleteSetUserData(response.user, true));
    yield put(authCompleteLogin(response.key, true, meta.thunk));
  } catch (error) {
    yield put(authOAuth2CodeFailed(error, meta.thunk));
  }
}

// Sends test request with authenticated credientials to login-protected server test endpoint
// Used for development purposes
export function* testRequest(api, meta) {
  try {
    const response = yield call([api, api.get], 'users/test/');
    yield put(authTestComplete(response, false, meta.thunk));
  } catch (error) {
    yield put(authTestComplete(error, true, meta.thunk));
  }
}

// Checks for cookie-stored login/profile information when user first loads the app
export function* checkStatus(api) {
  const token = yield put(getCookie('token'));
  const isLoggedIn = !!token;

  if (isLoggedIn) {
    yield call([api, api.setToken], token);
    yield put(profileLoadUserData());
    yield put(profileReadRequest());
  }

  yield put(authSetStatus(isLoggedIn));
}

// Sends email to server for requesting password reset.
export function* resetRequest(api, {email}, {thunk}) {
  try {
    yield call([api, api.post], 'rest-auth/password/reset/', {
      email,
    });
    yield put(authResetRequestSuccess(thunk));
  } catch (error) {
    const formError = {
      [FORM_ERROR]: error.non_field_errors ? error.non_field_errors[0] : JSON.stringify(error),
    };
    yield put(authResetRequestFailure(formError, thunk));
  }
}

// Sends new password and confirmation tokens
export function* resetPassword(api, payload, {thunk}) {
  try {
    yield call([api, api.post], 'rest-auth/password/reset/confirm/', payload);
    yield put(authResetPasswordSuccess(thunk));
    yield call(history.push, '/login/reset-success');
  } catch (error) {
    if (error.token) {
      error._error = 'This reset link is invalid';
    }
    const formError = {
      [FORM_ERROR]: error.non_field_errors ? error.non_field_errors[0] : JSON.stringify(error),
    };
    yield put(authResetPasswordFailure(formError, thunk));
  }
}

// Confirm email
export function* confirmEmail(api, payload, {thunk}) {
  try {
    yield call([api, api.post], 'rest-auth/registration/verify-email/', payload);
    yield put(authEmailConfirmationSuccess(thunk));
  } catch (error) {
    yield put(authEmailConfirmationFailed(error, thunk));
  }
}

// Watchers
export function* watchSignUpRequest(api) {
  while (true) {
    const {payload, meta} = yield take(AUTH_SIGN_UP_REQUEST);
    yield call(signUpRequest, api, payload, meta);
  }
}

export function* watchAuthClientLoginRequest(api) {
  while (true) {
    const {payload, meta} = yield take(AUTH_CLIENT_LOGIN_REQUEST);
    yield call(clientLoginRequest, api, payload, meta);
  }
}

export function* watchAuthTestRequest(api) {
  while (true) {
    const {meta} = yield take(AUTH_TEST_REQUEST);
    yield call(testRequest, api, meta);
  }
}

export function* watchAuthLogoutRequest(api) {
  while (true) {
    const {meta} = yield take(AUTH_LOGOUT_REQUEST);
    yield call(logoutRequest, api, meta);
  }
}

export function* watchAuthCheckStatus(api) {
  while (true) {
    yield take(AUTH_CHECK_STATUS);
    yield call(checkStatus, api);
  }
}

export function* watchAuthCompleteLogin(api) {
  while (true) {
    const {meta} = yield take(AUTH_COMPLETE_LOGIN);
    yield call(completeLogin, api, meta.token, meta.stayLoggedIn, meta.next);
  }
}

export function* watchAuthCompleteLogout(api) {
  while (true) {
    yield take(AUTH_COMPLETE_LOGOUT);
    yield call(completeLogout, api);
  }
}

export function* watchAuthOAuth2CodeRequest(api) {
  while (true) {
    const {payload, meta} = yield take(AUTH_OAUTH2_CODE_REQUEST);
    yield call(oauth2CodeRequest, api, payload.code, payload.provider, payload.params, meta);
  }
}

export function* watchResetRequestRequest(api) {
  while (true) {
    const {payload, meta} = yield take(AUTH_RESET_REQUEST_REQUEST);
    yield call(resetRequest, api, payload, meta);
  }
}

export function* watchResetPasswordRequest(api) {
  while (true) {
    const {payload, meta} = yield take(AUTH_RESET_PASSWORD_REQUEST);
    yield call(resetPassword, api, payload, meta);
  }
}

export function* watchEmailConfirmationRequest(api) {
  while (true) {
    const {payload, meta} = yield take(AUTH_EMAIL_CONFIRMATION_REQUEST);
    yield call(confirmEmail, api, payload, meta);
  }
}

export default function* sagas({api}) {
  yield fork(watchAuthClientLoginRequest, api);
  yield fork(watchAuthOAuth2CodeRequest, api);
  yield fork(watchAuthLogoutRequest, api);
  yield fork(watchAuthTestRequest, api);
  yield fork(watchAuthCheckStatus, api);
  yield fork(watchAuthCompleteLogin, api);
  yield fork(watchAuthCompleteLogout, api);
  yield fork(watchResetRequestRequest, api);
  yield fork(watchResetPasswordRequest, api);
  yield fork(watchSignUpRequest, api);
  yield fork(watchEmailConfirmationRequest, api);
}
