import {useState} from 'react';
import PropTypes from 'prop-types';

import AdvWarning from 'components/components/AdvWarning';
import {CSSTransitionGroup} from 'react-transition-group';

const BoxSelect = ({items, input, handleOnChange, meta}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const toggleValue = (value) => {
    const newSelectedItems = selectedItems.includes(value)
      ? selectedItems.filter((i) => i !== value)
      : [...selectedItems, value];

    setSelectedItems(newSelectedItems);
    input && input.onChange(newSelectedItems);
    handleOnChange && handleOnChange(newSelectedItems);
  };

  return (
    <div className="boxSelect">
      <CSSTransitionGroup
        transitionName="slowFadeWarning"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {meta.touched && meta.error && meta.submitFailed && (
          <AdvWarning offsetError>{meta.error}</AdvWarning>
        )}
      </CSSTransitionGroup>
      {items &&
        items.map((option) => (
          <div
            key={option.text}
            role="button"
            tabIndex={0}
            className={`boxItem ${selectedItems.includes(option.value) ? 'selected' : ''}`}
            onClick={() => toggleValue(option.value)}
          >
            {option.icon}
            <p>{option.text}</p>
          </div>
        ))}
    </div>
  );
};

BoxSelect.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  input: PropTypes.object,
  handleOnChange: PropTypes.func,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
};

export default BoxSelect;
