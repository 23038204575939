import {images} from 'adv-theme';

const AdvocateLoginLogo = () => (
  <span className="advocateLoginLogo">
    <img className="advocateLogo" src={images.loginLogo} alt="Advocate" />
    {images.poweredByAdvocate && (
      <img className="poweredByAdvocate" src={images.poweredByAdvocate} alt="Powered By Advocate" />
    )}
  </span>
);

export default AdvocateLoginLogo;
