import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {resourceListReadRequest} from 'store/actions';
import BaseTemplate from 'components/components/BaseTemplate';
import BroadcasterList from 'components/organisms/BroadcasterList';
import BroadcasterListRow from 'components/organisms/BroadcasterListRow';
import {formatExactTime} from 'utils/numberFormats';
import {useNextChatbotPosts} from 'hooks';

const OverallAdmin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resourceListReadRequest('nextChatbotPosts'));
  }, [dispatch]);

  const nextPosts = useNextChatbotPosts();

  return (
    <BaseTemplate>
      <div className="navbar-bg" />
      <div className="overall-admin-page">
        <p>Next Chatbot Posts:</p>
        <BroadcasterList type="invite-list">
          {nextPosts
            .sort((a, b) => (b.next_post > a.next_post ? -1 : 1))
            .map((post) => (
              <BroadcasterListRow
                key={post.username}
                broadcaster={post}
                hourTimeStamp={post.next_post}
                formatTime={formatExactTime}
              />
            ))}
        </BroadcasterList>
      </div>
    </BaseTemplate>
  );
};

export default OverallAdmin;
