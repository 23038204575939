import PropTypes from 'prop-types';

import BroadcasterActionListItem from 'components/molecules/BroadcasterActionListItem';
import AdvModal from 'containers/AdvModal';

const BroadcasterActionModal = ({actions, broadcaster, components}) => {
  return (
    <AdvModal
      key={`broadcaster-actions-${broadcaster}-modal`}
      name={`broadcaster-actions-${broadcaster}`}
      contentLabel="Actions for this broadcaster"
    >
      <label htmlFor="broadcaster actions">{`Broadcaster actions for ${broadcaster}`}</label>
      {actions
        .sort((a, b) => (b.creation_time > a.creation_time ? 1 : -1))
        .filter((action) => !!components.find((a) => a.name === action.component))
        .map((action) => {
          const component = components.find((a) => a.name === action.component);

          const isLatest = actions.findIndex((a) => a.id === action.id) > -1;
          return (
            <BroadcasterActionListItem
              broadcasterAction={action}
              key={action.component_id}
              version={component.activeVersion}
              isLatest={isLatest}
            />
          );
        })}
    </AdvModal>
  );
};

BroadcasterActionModal.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  components: PropTypes.arrayOf(PropTypes.object),
  broadcaster: PropTypes.string,
};

export default BroadcasterActionModal;
