import PropTypes from 'prop-types';
import {Field} from 'react-final-form';

import TextArea from 'components/molecules/TextArea';

const PageTwo = ({isUpdating, team}) => {
  return (
    <div className="page page2">
      <label htmlFor="Campaign Creation Form">
        {isUpdating ? 'Update' : 'Add New'} {team ? 'Sponsor' : 'Campaign'}
        {isUpdating ? ' Requirements' : ' - Requirements'}
      </label>
      <Field
        name="requirements"
        placeholder="Broadcaster Requirements (optional)"
        component={TextArea}
      />
      <Field
        name="general_guidelines"
        placeholder="Broadcaster Guidelines (optional)"
        component={TextArea}
      />
    </div>
  );
};

PageTwo.propTypes = {
  team: PropTypes.object,
  isUpdating: PropTypes.bool,
};

export default PageTwo;
