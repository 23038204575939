module.exports = {
  sassVariables: {
    primary: '#0392c9',
    secondary: '#0d2240',
    'navbar-back-rotation': '230deg',
    'navbar-link-rotation': '-100deg',
    'login-background': "url('/images/TeamLiquid-Login.png')",
    'secondary-background': "''",
  },
  images: {
    loginLogo: '/images/logo-back-blue.png',
    iconLogo: '/images/logo-white.png',
    fullLogo: '/images/logo-blue.png',
    overviewLogo: '/images/logo-small.png',
    faviconLogo: '/images/logo-small.png',
    poweredByAdvocate: '/images/powered-by-advocate.png',
  },
}
