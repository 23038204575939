import {useState, useCallback} from 'react';
import {DateRangePicker} from 'react-dates';
import PropTypes from 'prop-types';
import moment from 'moment';
// Based off the airbnb react datepicker https://github.com/airbnb/react-dates
import 'react-dates/initialize';

import MonthRenderer from 'components/molecules/MonthRenderer';

const DatePicker = ({absoluteStart, absoluteEnd, onDatesChange, color}) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [startDate, setStartDate] = useState(moment(absoluteStart));
  const [endDate, setEndDate] = useState(moment(absoluteEnd));

  const handleODatesChange = useCallback(
    ({startDate, endDate}) => {
      setStartDate(startDate);
      setEndDate(endDate);
      onDatesChange && onDatesChange(startDate.toISOString(), endDate.toISOString());
    },
    [onDatesChange],
  );

  return (
    <div className={`datePicker ${color}`}>
      <DateRangePicker
        renderMonthElement={({month, onMonthSelect, onYearSelect}) => (
          <MonthRenderer
            start={absoluteStart}
            end={absoluteEnd}
            month={month}
            onMonthSelect={onMonthSelect}
            onYearSelect={onYearSelect}
          />
        )}
        startDateId="startDate"
        endDateId="endDate"
        startDate={startDate}
        endDate={endDate}
        showDefaultInputIcon
        displayFormat="MMM D"
        numberOfMonths={1}
        onDatesChange={handleODatesChange}
        isOutsideRange={(d) => d.isAfter(absoluteEnd) || d.isBefore(absoluteStart)}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => {
          setFocusedInput(focusedInput);
        }}
      />
    </div>
  );
};

DatePicker.propTypes = {
  absoluteStart: PropTypes.string,
  absoluteEnd: PropTypes.string,
  onDatesChange: PropTypes.func,
  color: PropTypes.string.isRequired,
};

export default DatePicker;
