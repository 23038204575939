import {useCallback, useRef} from 'react';
import PropTypes from 'prop-types';

import Button from 'components/molecules/Button';
import {sortCaseInsensitive} from 'utils/general';

const BrowsersourcePreviewLink = ({broadcasters, browsersourcePreviewUrl}) => {
  const selectRef = useRef();
  const goToPreview = useCallback(() => {
    const previewUrl = `${browsersourcePreviewUrl}/${selectRef.current.value}/`;
    window.open(previewUrl, '_blank');
  }, [browsersourcePreviewUrl]);

  return (
    <>
      <label htmlFor="preview live graphics">Preview Live Graphics as</label>
      <div className="adv-select">
        <select ref={selectRef}>
          {sortCaseInsensitive(broadcasters).map((broadcaster) => (
            <option value={broadcaster} key={broadcaster}>
              {broadcaster}
            </option>
          ))}
        </select>
      </div>
      <div className="extraText" />
      <div className="extraText">
        The preview will only show components from this campaign. Broadcasters with any other active
        campaign will not have those components shown.
      </div>
      <Button handleClick={goToPreview}>Open Preview</Button>
    </>
  );
};

BrowsersourcePreviewLink.propTypes = {
  broadcasters: PropTypes.arrayOf(PropTypes.string),
  browsersourcePreviewUrl: PropTypes.string,
};

export default BrowsersourcePreviewLink;
