import {useMemo} from 'react';

// sort compliance actions by type and name
const sortActions = (a, b) => {
  if (b.type === a.type) {
    return b.component_name > a.component_name ? -1 : 1;
  }
  return b.type > a.type ? -1 : 1;
};

const useBroadcasterCompliance = ({
  componentsCompliance,
  complianceActions,
  broadcasterActions,
  broadcaster,
}) => {
  const filteredBroadcasterActions = broadcasterActions.filter(
    (action) => action.broadcaster === broadcaster,
  );

  const sortedComplianceEntries = useMemo(
    () =>
      Object.values(componentsCompliance)
        .concat(complianceActions)
        .filter((a) => a.required)
        .sort(sortActions),
    [complianceActions, componentsCompliance],
  );

  return {
    sortedComplianceEntries,
    filteredBroadcasterActions,
  };
};

export default useBroadcasterCompliance;
