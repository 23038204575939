import {useState} from 'react';
import PropTypes from 'prop-types';
import {CSSTransitionGroup} from 'react-transition-group';
import {Twitter, Twitch} from 'mdi-material-ui';

import DeleteModal from 'components/organisms/DeleteModal';
import Button from 'components/molecules/Button';
import AdvCheckbox from 'components/atoms/AdvCheckbox';
import ComponentPreview from 'components/organisms/ComponentPreview';
import ComponentFormModal from '../ComponentFormModal';
import VersionPreview from './components/VersionPreview';
import AdvModal from 'containers/AdvModal';
import AttachLabelForm from '../../../../forms/AttachLabelForm';
import DCTAFormModal from './components/DCTAFormModal';
import {getActiveVersion, getDisplayVersions, inHistory, hasHistory} from 'utils/campaignTools';

import {useComponentVersions, useCampaignLabels} from 'hooks';

const ComponentTypeSection = ({
  hasButtons,
  componentType,
  components,
  campaign,
  multiselectMode,
  handleLabelUpdate,
  editingLabel,
}) => {
  const [activeVersionComparison, setActiveVersionComparison] = useState(0);
  const [componentTypeHistory, setComponentTypeHistory] = useState(0);
  const versions = useComponentVersions();
  const labels = useCampaignLabels();
  const hasVersionSubsection = hasHistory(components, versions);

  const toggleActiveVersionComparison = (componentId) => {
    if (activeVersionComparison === componentId) {
      setActiveVersionComparison(0);
    } else {
      setActiveVersionComparison(componentId);
    }
  };

  const toggleHistory = (componentType) => {
    if (componentTypeHistory === componentType) {
      setComponentTypeHistory(0);
    } else {
      setComponentTypeHistory(componentType);
    }
  };

  const getIconAndType = (componentType) => {
    switch (componentType.slug) {
      case 'twitch-chat-bot':
      case 'twitch-chat-bot-command':
        return (
          <span className="twitch">
            <Twitch />
            {componentType.name}
          </span>
        );
      case 'suggested-tweet':
      case 'suggested-retweet':
        return (
          <span className="twitter">
            <Twitter />
            {componentType.name}
          </span>
        );
      default:
        return <span>{componentType.name}</span>;
    }
  };

  return (
    <div className="componentTypeSection">
      <h2>{getIconAndType(componentType)}</h2>
      <div className="extraContents">
        {!componentType.is_versioned && versions.length > 0 && hasVersionSubsection && (
          <Button handleClick={() => toggleHistory(componentType.name)}>
            {componentTypeHistory === componentType.name ? 'Show Active Only' : 'Show All'}
          </Button>
        )}
        {componentType.instructions && <h4>{componentType.instructions}</h4>}
      </div>
      <div>
        {components.map((component) => {
          const version = getActiveVersion(component, versions);
          const updatedComponents = editingLabel.components.includes(component.id)
            ? editingLabel.components.filter((c) => c !== component.id)
            : editingLabel.components.concat(component.id);
          const commonProps = {
            key: `component-${component.id}`,
            component,
            componentType,
            version,
            campaignLink: component.activeVersion.link,
          };

          return (
            <span className="multiselectWrapper" key={`component-${component.id}`}>
              <CSSTransitionGroup
                transitionName="fade"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}
              >
                {multiselectMode && editingLabel.components && (
                  <AdvCheckbox
                    isChecked={editingLabel.components.includes(component.id)}
                    onAnyClick
                    onCheck={() =>
                      handleLabelUpdate({
                        ...editingLabel,
                        components: updatedComponents,
                      })
                    }
                  />
                )}
              </CSSTransitionGroup>
              {multiselectMode ? (
                <ComponentPreview
                  {...commonProps}
                  handleImgOnClick={() =>
                    handleLabelUpdate({
                      ...editingLabel,
                      components: updatedComponents,
                    })
                  }
                  showButtons={false}
                />
              ) : (
                <ComponentPreview
                  {...commonProps}
                  isSelected={activeVersionComparison === component.id}
                  handleImgOnClick={() =>
                    componentType.is_versioned ? toggleActiveVersionComparison(component.id) : null
                  }
                  showButtons={hasButtons}
                />
              )}
            </span>
          );
        })}
      </div>

      {/* Version Subsection */}
      {componentType.is_versioned && (
        <CSSTransitionGroup
          transitionName="slideAB"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {activeVersionComparison && components.find((c) => c.id === activeVersionComparison) ? (
            <div className="versions">
              <CSSTransitionGroup
                transitionName="fade"
                transitionEnterTimeout={250}
                transitionLeaveTimeout={1}
              >
                {components.map((component) =>
                  activeVersionComparison === component.id
                    ? getDisplayVersions(
                        versions.filter((version) => component.versions.includes(version.id)),
                      ).map((version, index) => (
                        <VersionPreview
                          version={version}
                          component={component}
                          canReactivate={index !== 0 && hasButtons && component.is_ready}
                          key={`version-${version.id}`}
                          isVersioned
                        />
                      ))
                    : '',
                )}
              </CSSTransitionGroup>
            </div>
          ) : (
            ''
          )}
        </CSSTransitionGroup>
      )}

      {/* History Subsection */}
      {!componentType.is_versioned && (
        <CSSTransitionGroup
          transitionName="slideAB"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {componentTypeHistory ? (
            <div className="versions">
              {components.map(
                (component) =>
                  inHistory(component, versions) && (
                    <VersionPreview
                      version={getActiveVersion(component, versions)}
                      component={component}
                      retweet={component.name === 'Suggested Retweet'}
                      canReactivate={false}
                      key={`component-${component.id}`}
                    />
                  ),
              )}
            </div>
          ) : (
            ''
          )}
        </CSSTransitionGroup>
      )}

      {/* Update modals */}
      {hasButtons &&
        componentType.slug !== 'dynamic-cta' &&
        components.map((component) => (
          <ComponentFormModal
            modalName={`component-${component.id}-update`}
            component={component}
            componentType={componentType}
            activeVersion={getActiveVersion(component, versions)}
            activeLink={component.activeVersion.link}
            campaign={campaign}
            key={`component-update-${component.id}`}
          />
        ))}

      {/* Update modals */}
      {hasButtons &&
        componentType.slug === 'dynamic-cta' &&
        components.map((component) => (
          <DCTAFormModal
            component={component}
            modalName={`component-${component.id}-update`}
            key={component.id}
          />
        ))}

      {/* Delete modals */}
      {hasButtons &&
        components.map((component) => (
          <DeleteModal
            modalName={`component-${component.id}-delete`}
            resource="components"
            objectType="component"
            objectName={component.name}
            objectNeedle={component.id}
            key={`component-delete-${component.id}`}
            hasArchiveOption={component}
          />
        ))}

      {/* Label modals */}
      {hasButtons &&
        components.map((component) => (
          <AdvModal
            name={`labels-component-${component.id}`}
            key={`component-label-modal-${component.id}`}
            contentLabel="Component Groups"
          >
            <AttachLabelForm
              component={component}
              title={component.name}
              campaignSlug={campaign.slug}
              labels={labels.filter((label) => label.components.includes(component.id))}
            />
          </AdvModal>
        ))}
    </div>
  );
};

ComponentTypeSection.propTypes = {
  requiresLink: PropTypes.bool,
  hasButtons: PropTypes.bool,
  componentType: PropTypes.object.isRequired,
  components: PropTypes.arrayOf(PropTypes.object),
  channelCount: PropTypes.number,
  campaign: PropTypes.object,
  multiselectMode: PropTypes.bool,
  handleLabelUpdate: PropTypes.func,
  editingLabel: PropTypes.object,
};

export default ComponentTypeSection;
