import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {fromAutosuggest} from 'store/selectors';
import {autosuggestLocalSuggestions, autosuggestSuggestionsClear} from 'store/actions';
import AutoSuggest from './components/AutoSuggest';

const AutoSuggestLocal = ({suggestionSet, filterField, ...rest}) => {
  const dispatch = useDispatch();
  const suggestions = useSelector((state) => fromAutosuggest.all(state));
  const updateSuggestions = useCallback(
    (value) =>
      dispatch(
        autosuggestLocalSuggestions(
          value
            ? suggestionSet.filter((suggestion) => suggestion[filterField].startsWith(value))
            : [],
        ),
      ),
    [dispatch, filterField, suggestionSet],
  );
  const clearSuggestions = useCallback(() => dispatch(autosuggestSuggestionsClear()), [dispatch]);
  return (
    <AutoSuggest
      suggestions={suggestions}
      clearSuggestions={clearSuggestions}
      updateSuggestions={updateSuggestions}
      {...rest}
    />
  );
};

AutoSuggestLocal.propTypes = {
  suggestionSet: PropTypes.arrayOf(PropTypes.object),
  filterField: PropTypes.string,
};

export default AutoSuggestLocal;
