import PropTypes from 'prop-types';
import {useState} from 'react';
import {Settings} from 'mdi-material-ui';
import {Redirect} from 'react-router-dom';
import {isEmpty} from 'lodash';

import Button from 'components/molecules/Button';
import BroadcasterList from 'components/organisms/BroadcasterList';
import BroadcasterListRow from 'components/organisms/BroadcasterListRow';
import InviteListModal from 'components/organisms/InviteListModal';

import CampaignDeleteModal from 'components/components/CampaignDeleteModal';
import HamburgerMenu from 'components/components/HamburgerMenu';

import useModal from 'hooks/useModal';

import {AdvModal} from 'containers';
import CampaignSquadsForm from '../../forms/CampaignSquadsForm';
import ChatbotPostForm from '../../forms/ChatbotPostForm';
import SponsorCampaignCreationForm from 'components/components/CampaignCreationForm/SponsorCampaignCreationForm';
import InviteLinkDisplay from 'components/components/InviteLinkDisplay';
import ChatbotSelect from 'components/components/ChatbotSelect';
import CampaignAddBroadcasterForm from 'components/components/BroadcasterParticipationForm/CampaignAddBroadcasterForm';
import CampaignAddBroadcasterFromTeamForm from 'components/components/BroadcasterParticipationForm/CampaignAddBroadcasterFromTeamForm';
import useCampaignManagement from './hooks/useCampaignManagement';
import CampaignTemplate from '../../components/CampaignTemplate';
import CampaignNotFoundPage from '../../components/CampaignNotFoundPage';
import {isCampaignEnded} from 'utils/numberFormats';
import CampaignSettingToggle from './components/CampaignSettingToggle';

const isInactive = (broadcaster, campaign) =>
  broadcaster &&
  ['Flagged', 'Deactivated', 'Pending', 'Campaign Ended'].includes(
    broadcaster.campaign_statuses[campaign.slug] &&
      broadcaster.campaign_statuses[campaign.slug].status,
  );

const isActive = (broadcaster, campaign) =>
  broadcaster &&
  broadcaster.campaign_statuses[campaign.slug] &&
  broadcaster.campaign_statuses[campaign.slug].status === 'Active';

const getTimeStamp = (campaign) => (broadcaster) =>
  broadcaster &&
  broadcaster.campaign_statuses[campaign.slug] &&
  broadcaster.campaign_statuses[campaign.slug].responded_at;

const getChatbotDisplay = (campaign) =>
  campaign && campaign.twitch_chatbot_account !== 'advogg'
    ? campaign.twitch_chatbot_account
    : 'advogg (default)';

const MenuItem = ({onClick, text}) => (
  <div className="menuItem" tabIndex={-1} onClick={onClick} role="button">
    <h4>{text}</h4>
  </div>
);

MenuItem.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

const CampaignManagement = () => {
  const [toDashboard, setToDashboard] = useState(false);
  const [modalShow] = useModal();

  const {
    campaign,
    campaignBroadcasters,
    teamBroadcasters,
    userLoading,
    activateBroadcaster,
    deactivateBroadcaster,
    setChatbot,
    pending,
    isAdmin,
    team,
    squads,
  } = useCampaignManagement();

  if (toDashboard) return <Redirect to="/dashboard" />;
  if (!userLoading && isEmpty(campaign)) return <CampaignNotFoundPage />;

  return (
    <CampaignTemplate>
      <div className="campaign-management-page">
        {!isCampaignEnded(campaign) && (
          <div className="preset_campaign-page-options">
            <div>
              <Button
                type="hasIcon hamburgerNeighbor"
                handleClick={() => modalShow('campaign-settings')}
              >
                <Settings />
                Campaign Settings
              </Button>
              <HamburgerMenu>
                <MenuItem
                  onClick={() => modalShow('add-broadcaster-to-campaign')}
                  text="Invite a Broadcaster"
                />
                <MenuItem
                  onClick={() => modalShow(`sent-invites-${campaign?.slug}`)}
                  text="See Sent Invites"
                />
                <MenuItem
                  onClick={() => modalShow('add-broadcaster-from-team')}
                  text="Add a Broadcaster"
                />
                {squads.length > 0 && (
                  <MenuItem
                    onClick={() => modalShow(`manage-squads-${campaign?.slug}`)}
                    text="Manage Squads"
                  />
                )}
                <MenuItem
                  onClick={() => modalShow(`campaign-${campaign.slug}-update`)}
                  text="Update Campaign"
                />
                <MenuItem
                  onClick={() => modalShow(`campaign-${campaign?.slug}-delete`)}
                  text="End Campaign"
                />
              </HamburgerMenu>
            </div>
          </div>
        )}
        <div className="broadcasterTables">
          {!isCampaignEnded(campaign) && (
            <span className="broadcasterTableWrapper">
              <BroadcasterList
                loading={userLoading}
                label="Inactive Broadcasters"
                type="with-button"
              >
                {campaignBroadcasters.length > 0 &&
                campaignBroadcasters.filter((broadcaster) => isInactive(broadcaster, campaign))
                  .length > 0 ? (
                  campaignBroadcasters
                    .filter((broadcaster) => isInactive(broadcaster, campaign))
                    .map((broadcaster) => (
                      <BroadcasterListRow
                        key={broadcaster.username}
                        broadcaster={broadcaster}
                        hourTimeStamp={getTimeStamp(campaign)(broadcaster)}
                      >
                        <Button
                          handleClick={() => activateBroadcaster(broadcaster.username)}
                          loading={!!pending[`statusUpdate${broadcaster.username}`]}
                        >
                          Activate
                        </Button>
                      </BroadcasterListRow>
                    ))
                ) : (
                  <tr className="noBroadcasters">
                    <td>{userLoading ? '' : 'No Inactive Broadcasters Found'}</td>
                  </tr>
                )}
              </BroadcasterList>
              <p>
                Inactive broadcasters receive broadcaster actions for Banner Graphics and Suggested
                Tweets, but will not have Live Graphics or the Twitch Chatbot present in their
                streams.
              </p>
            </span>
          )}
          {!isCampaignEnded(campaign) && (
            <BroadcasterList loading={userLoading} label="Active Broadcasters" type="with-button">
              {campaignBroadcasters.length > 0 &&
              campaignBroadcasters.filter((broadcaster) => isActive(broadcaster, campaign)).length >
                0 ? (
                campaignBroadcasters
                  .filter((broadcaster) => isActive(broadcaster, campaign))
                  .map((broadcaster) => (
                    <BroadcasterListRow
                      key={broadcaster.username}
                      broadcaster={broadcaster}
                      hourTimeStamp={getTimeStamp(campaign)(broadcaster)}
                    >
                      <Button
                        handleClick={() => deactivateBroadcaster(broadcaster.username)}
                        loading={!!pending[`statusUpdate${broadcaster.username}`]}
                      >
                        Deactivate
                      </Button>
                    </BroadcasterListRow>
                  ))
              ) : (
                <tr className="noBroadcasters">
                  <td>{userLoading ? '' : 'No Active Broadcasters Found'}</td>
                </tr>
              )}
            </BroadcasterList>
          )}
        </div>
      </div>

      <AdvModal name="add-broadcaster-to-campaign" contentLabel="Add Broadcaster To Campaign">
        <CampaignAddBroadcasterForm
          campaign={campaign}
          broadcasters={teamBroadcasters}
          destination="Campaign"
          endpoint="invite"
          extraAutoSuggestParams={{
            exclude_on_campaign: campaign?.slug || undefined,
          }}
        />
        <InviteLinkDisplay inviteType="campaigns" needle={campaign?.slug} />
      </AdvModal>

      {campaign && (
        <InviteListModal
          broadcasters={campaignBroadcasters}
          identifier="campaign"
          needle={campaign?.slug}
        />
      )}

      <AdvModal name="add-broadcaster-from-team" contentLabel="Add Broadcaster To Campaign">
        <CampaignAddBroadcasterFromTeamForm
          campaign={campaign}
          teamBroadcasters={teamBroadcasters}
          campaignBroadcasters={campaignBroadcasters}
          title="Add a Team Broadcaster to This Campaign "
          buttonText="Add"
          endpoint="add_from_team"
          extraAutoSuggestParams={{
            team: campaign?.team || undefined,
            exclude_on_campaign: campaign?.slug || undefined,
          }}
          successMsg="Broadcaster Added!"
        />
      </AdvModal>

      <AdvModal
        name={`manage-squads-${campaign?.slug}`}
        contentLabel={`manage-squads-${campaign?.slug}`}
      >
        <CampaignSquadsForm campaign={campaign} squads={squads} />
      </AdvModal>

      <AdvModal name="campaign-settings" contentLabel="Campaign Settings">
        <label htmlFor="Chatbot Account">Campaign Settings</label>
        <div className="loginButton">
          <Button handleClick={setChatbot}>Authorize New Twitch Account</Button>
          <h4 className="chatbotName">
            Chatbot will post as
            {campaign?.team ? (
              <ChatbotSelect chatbotScope="campaign" errorMessage="Could not update chatbot" />
            ) : (
              <b> {getChatbotDisplay(campaign)}</b>
            )}
          </h4>
        </div>
        {isAdmin && (
          <div>
            <hr />
            <ChatbotPostForm campaign={campaign} broadcasters={campaignBroadcasters} />
          </div>
        )}
        <div className="campaignSettings">
          {campaign?.team && (
            <CampaignSettingToggle
              campaign={campaign}
              onLabel="Automatically add new broadcasters to this campaign"
              offLabel="Manually add new broadcasters to this campaign"
              valueName="team_auto_add"
              value={campaign?.team_auto_add || false}
            />
          )}
          <CampaignSettingToggle
            campaign={campaign}
            onLabel="Broadcasters are added as active to this campaign"
            offLabel="Broadcasters are added as inactive to this campaign"
            valueName="auto_active_invites"
            value={campaign?.auto_active_invites || false}
          />
          <CampaignSettingToggle
            campaign={campaign}
            onLabel="Live graphics are independent from other campaigns"
            offLabel="Live graphics will rotate with other campaigns"
            valueName="independent_live_graphics"
            value={campaign?.independent_live_graphics || false}
          />
          <CampaignSettingToggle
            campaign={campaign}
            onLabel="Broadcasters are notified when they have pending actions"
            offLabel="Broadcasters are not notified when they have pending actions"
            valueName="action_notifications"
            value={campaign?.action_notifications || false}
          />
        </div>
      </AdvModal>

      <CampaignDeleteModal
        modalName={`campaign-${campaign?.slug}-delete`}
        campaign={campaign}
        key={`campaign-${campaign?.slug}-delete`}
        redirectFunc={setToDashboard}
        warning
        extraText="This cannot be undone. Ended campaigns will still be visible, but deleted campaigns will no longer appear. If you have any concerns, contact an Advocate admin."
      />
      <SponsorCampaignCreationForm
        modalName={`campaign-${campaign?.slug}-update`}
        initialValues={{
          ...campaign,
          start_hour: new Date(campaign?.start_date).getHours(),
          end_hour: new Date(campaign?.end_date).getHours(),
        }}
        campaign={campaign}
        team={team}
        isUpdating
      />
    </CampaignTemplate>
  );
};

export default CampaignManagement;
