import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {Field, Form} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {useCampaigns} from 'hooks';

import {
  managementSetTeamMemberSquadRequest,
  managementRemoveTeamMemberSquadRequest,
  modalHide,
  resourceAdd,
} from 'store/actions';

import Button from 'components/molecules/Button';
import Select from 'components/molecules/Select';

const BroadcasterSquadForm = ({squads, broadcaster, broadcasterSquad}) => {
  const dispatch = useDispatch();
  const campaigns = useCampaigns();

  const onSubmit = useCallback(
    async (data) => {
      try {
        if (broadcasterSquad && !data.squad) {
          await dispatch(
            managementRemoveTeamMemberSquadRequest(broadcasterSquad, broadcaster.username),
          );
        } else if (
          (broadcasterSquad && data.squad !== broadcasterSquad) ||
          (!broadcasterSquad && data.squad)
        ) {
          await dispatch(managementSetTeamMemberSquadRequest(data.squad, broadcaster.username));
        }
      } catch (error) {
        return error;
      }
      // This form does not have validation, since an empty submission might mean
      // that we want to remove the old squad. However, if there was no prior squad
      // and no squad selected, or the same squad, we shouldn't send a network request
      return null;
    },
    [broadcaster.username, broadcasterSquad, dispatch],
  );

  const onSubmitSuccess = useCallback(
    (result) => {
      // Remove the broadcaster from the previous squad's roster in store
      const newSquad = result ? Object.values(result.squads)[0] : {};
      const squadToRemove = squads.find((s) => s.roster.includes(broadcaster.username));
      if (
        result &&
        newSquad.roster.includes(broadcaster.username) &&
        squadToRemove &&
        newSquad.id !== squadToRemove.id
      ) {
        dispatch(
          resourceAdd({
            squads: {
              [squadToRemove.id]: {
                ...squadToRemove,
                roster: squadToRemove.roster.filter((b) => b !== broadcaster.username),
              },
            },
          }),
        );
      }
      // Updates campaign broadcaster fields with the new broadcaster if they have
      // the new squad
      if (
        result &&
        (!squadToRemove || newSquad.id !== squadToRemove.id) &&
        newSquad.roster.includes(broadcaster.username)
      ) {
        // Mimics logic on the backend to set a new broadcaster participation if the
        // broadcaster did not have previous participation for that campaign, and the
        // campaign has the new squad the broadcaster was added to
        dispatch(
          resourceAdd({
            broadcasters: {
              [broadcaster.username]: {
                ...broadcaster,
                campaign_statuses: campaigns.reduce(
                  (acc, campaign) =>
                    campaign.squads.includes(newSquad.id) &&
                    !broadcaster.campaign_statuses[campaign.slug]
                      ? {
                          ...acc,
                          [campaign.slug]: {
                            status: campaign.auto_active_invites ? 'Active' : 'Inactive',
                          },
                        }
                      : {
                          ...acc,
                          [campaign.slug]: broadcaster.campaign_statuses[campaign.slug],
                        },
                  {},
                ),
              },
            },
          }),
        );
      }
      dispatch(modalHide(`broadcaster-assign-squad-${broadcaster.username}`));
    },
    [broadcaster, campaigns, dispatch, squads],
  );

  return (
    <Form onSubmit={onSubmit} initialValues={{squad: broadcasterSquad}}>
      {({handleSubmit, submitting, error, submitError, form}) => (
        <form
          onSubmit={(data) =>
            handleSubmit(data)?.then((data) => {
              const {submitSucceeded} = form.getState();
              submitSucceeded && onSubmitSuccess(data);
            })
          }
          className="component-label-form"
        >
          <label htmlFor="labels">
            {`Assign ${broadcasterSquad ? 'another' : 'a'} squad for `}
            {broadcaster.username}
          </label>
          <Field component={Select} name="squad">
            <option value="">Select a Squad</option>
            {squads.map((squad) => (
              <option key={squad.id} value={squad.id}>
                {squad.name}
              </option>
            ))}
          </Field>
          {<Button loading={!!submitting}>Set</Button>}
          {(error || submitError) && (
            <div className="help is-danger centered">{error || submitError}</div>
          )}
        </form>
      )}
    </Form>
  );
};

BroadcasterSquadForm.propTypes = {
  squads: PropTypes.arrayOf(PropTypes.object),
  broadcasterSquad: PropTypes.number,
  broadcaster: PropTypes.object,
};

export default BroadcasterSquadForm;
