import PropTypes from 'prop-types';
import {images} from 'adv-theme';
import IconButton from 'components/components/IconButton';
import {Twitch, Twitter, Mixer} from 'mdi-material-ui';

const ChannelPreviewTopBar = ({children, mainPlatform, broadcaster}) => (
  <div className="topBar">
    <img
      src={broadcaster.profile_image ? broadcaster.profile_image : images.squareLogo}
      className="avatar"
      alt=""
    />
    <h2>{broadcaster.username}</h2>
    {children}
    {mainPlatform === 'twitch' && (
      <a
        href={`https://www.twitch.tv/${broadcaster.username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton tooltip="Twitch Channel" extraClass="twitch" label="twitch">
          <Twitch />
        </IconButton>
      </a>
    )}
    {mainPlatform === 'mixer' && (
      <a
        href={`https://www.mixer.com/${broadcaster.username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton tooltip="Mixer Channel" extraClass="mixer" label="mixer">
          <Mixer />
        </IconButton>
      </a>
    )}
    {broadcaster.twitter && (
      <a
        href={`https://twitter.com/${broadcaster.twitter}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton tooltip="Twitter Account" label="twitter">
          <Twitter />
        </IconButton>
      </a>
    )}
  </div>
);

ChannelPreviewTopBar.propTypes = {
  children: PropTypes.any,
  mainPlatform: PropTypes.string,
  broadcaster: PropTypes.object,
};

export default ChannelPreviewTopBar;
