import PropTypes from 'prop-types';

import AdvWarning from 'components/components/AdvWarning';
import {CSSTransitionGroup} from 'react-transition-group';

const TextArea = ({meta, rows, placeholder, input, disabled, maxLength, wrap}) => (
  <div className="adv-textarea-wrapper">
    <CSSTransitionGroup
      transitionName="slowFadeWarning"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={500}
    >
      {meta.touched && meta.error && meta.submitFailed && <AdvWarning>{meta.error}</AdvWarning>}
    </CSSTransitionGroup>
    <div className="adv-textarea">
      <textarea
        rows={rows}
        spellCheck={false}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        wrap={wrap}
        {...input}
      />
    </div>
  </div>
);

TextArea.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  rows: PropTypes.number,
  handleRef: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  wrap: PropTypes.string,
};

TextArea.defaultProps = {
  rows: 4,
};

export default TextArea;
