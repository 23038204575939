const IconLogout = () => (
  <svg version="1.1" viewBox="0 0 612 547">
    <circle display="none" fill="#D1D3D4" cx="304" cy="284.272" r="161" />
    <path
      fill="none"
      stroke="#000000"
      strokeWidth="40"
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M343.539,135.021
    c-28.219-19.799-62.587-31.428-99.677-31.428c-95.961,0-173.753,77.792-173.753,173.752c0,95.961,77.792,173.752,173.753,173.752
    c37.089,0,71.458-11.629,99.677-31.43"
    />
    <polyline
      fill="none"
      stroke="#000000"
      strokeWidth="40"
      strokeLinecap="round"
      strokeMiterlimit="10"
      points="454.361,170.783
      561.912,278.333 456.34,383.906"
    />
    <line
      fill="#FFFFFF"
      stroke="#000000"
      strokeWidth="40"
      strokeLinecap="round"
      strokeMiterlimit="10"
      x1="561.912"
      y1="278.333"
      x2="243.862"
      y2="278.333"
    />
  </svg>
);

export default IconLogout;
