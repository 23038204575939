import {useState} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';

import AdvInput from 'components/molecules/AdvInput';
import TextArea from 'components/molecules/TextArea';
import DateRangeField from 'components/molecules/DateRangeField';

import {isCampaignStarted, isCampaignEnded} from 'utils/numberFormats';

const PageOne = ({form, isUpdating, team, campaign}) => {
  const campaignInfo = ['guidelines', 'requirements', 'description'];
  const [selectedCampaignInfo, setSelectedCampaignInfo] = useState(campaignInfo[0]);

  return (
    <div className="page page1">
      <label htmlFor="Campaign Creation Form">
        {isUpdating ? 'Update' : 'Add New'} {team ? 'Sponsor' : 'Campaign'}
        {isUpdating ? ' Details' : ' - Details'}
      </label>
      <Field name="name" placeholder="Name" type="text" component={AdvInput} />
      <DateRangeField
        startFieldName="start_date"
        endFieldName="end_date"
        specifyTime
        campaign={campaign}
        formStartTime={form.getState().values['start_date']}
        formEndTime={form.getState().values['end_date']}
        startDisabled={isCampaignStarted(campaign)}
        endDisabled={isCampaignEnded(campaign)}
        updatingCampaign={isUpdating}
        startAbsoluteEnd={campaign ? campaign.end_date : null}
        endAbsoluteStart={campaign ? campaign.start_date : null}
      />

      <div className="inputWithLabelWrapper">
        <label htmlFor={`impressions-cutoff-${campaign ? campaign.slug : ''}`}>
          Impressions Cutoff (optional)
        </label>
        <Field
          name="impression_cutoff"
          type="number"
          component={AdvInput}
          id={`impressions-cutoff-${campaign ? campaign.slug : ''}`}
        />
      </div>

      <div className="inputWithLabelWrapper">
        <label htmlFor={`daily-cap-${campaign ? campaign.slug : ''}`}>
          Daily Impression Cap (optional)
        </label>
        <Field
          name="daily_impression_cap"
          type="number"
          component={AdvInput}
          id={`daily-cap-${campaign ? campaign.slug : ''}`}
        />
      </div>

      <div className="inputWithLabelWrapper">
        <label htmlFor={`campign-impressions-${campaign ? campaign.slug : ''}`}>
          Seconds Per Impression
        </label>
        <Field
          name="seconds_per_impression"
          type="number"
          component={AdvInput}
          id={`campaign-impressions-${campaign ? campaign.slug : ''}`}
        />
      </div>

      {isUpdating && (
        <span>
          {campaignInfo.map((info) => (
            <span
              key={info}
              onClick={() => setSelectedCampaignInfo(info)}
              className={`fieldSelection ${selectedCampaignInfo === info ? 'selected' : ''}`}
              role="button"
              tabIndex={0}
            >
              {info}
            </span>
          ))}
        </span>
      )}
      {!team && <Field name="client" placeholder="Client" type="text" component={AdvInput} />}
      <div
        style={{
          display: !isUpdating || selectedCampaignInfo === 'description' ? 'block' : 'none',
        }}
      >
        <Field name="description" placeholder="Campaign Description" component={TextArea} />
      </div>
      {isUpdating && team && (
        <>
          <div
            style={{
              display: selectedCampaignInfo === 'requirements' ? 'block' : 'none',
            }}
          >
            <Field
              name="requirements"
              placeholder="Broadcaster Requirements"
              component={TextArea}
            />
          </div>
          <div
            style={{
              display: selectedCampaignInfo === 'guidelines' ? 'block' : 'none',
            }}
          >
            <Field
              name="general_guidelines"
              placeholder="Broadcaster Guidelines"
              component={TextArea}
            />
          </div>
        </>
      )}
    </div>
  );
};

PageOne.propTypes = {
  form: PropTypes.any,
  campaign: PropTypes.object,
  team: PropTypes.object,
  isUpdating: PropTypes.bool,
};

export default PageOne;
