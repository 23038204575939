import {useState} from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import AdvWarning from 'components/components/AdvWarning';

const AutoSuggest = ({
  placeholder,
  suggestions,
  meta,
  displayParam,
  SuggestionComponent,
  longerThan = 1,
  allowRaw,
  input,
  clearSuggestions,
  updateSuggestions,
  onSelect,
}) => {
  const [value, setValue] = useState('');

  const onSuggestionsFetchRequested = ({value}) => {
    updateSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    clearSuggestions();
  };

  const handleChange = (event, {newValue}) => {
    if (input) {
      input.onChange(allowRaw ? newValue : '');
    }
    setValue(newValue);
  };

  const onSuggestionSelected = (event, {suggestion}) => {
    if (input) {
      input.onChange(suggestion);
    } else {
      onSelect(suggestion);
      setValue('');
    }
  };

  return (
    <div className="autosuggest-wrapper">
      {meta && meta.touched && meta.error && meta.submitFailed && (
        <AdvWarning>{meta.error}</AdvWarning>
      )}
      <Autosuggest
        suggestions={Array.isArray(suggestions) ? suggestions.slice(0, 10) : []}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        shouldRenderSuggestions={(value) => value?.trim().length > longerThan}
        getSuggestionValue={(suggestion) => `${suggestion[displayParam]}`}
        renderSuggestion={(suggestion) => <SuggestionComponent suggestion={suggestion} />}
        inputProps={{placeholder, value, onChange: handleChange}}
      />
    </div>
  );
};

AutoSuggest.propTypes = {
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateSuggestions: PropTypes.func.isRequired,
  clearSuggestions: PropTypes.func.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  displayParam: PropTypes.string.isRequired,
  SuggestionComponent: PropTypes.func,
  placeholder: PropTypes.string,
  allowRaw: PropTypes.bool,
  longerThan: PropTypes.number,
  onSelect: PropTypes.func,
};

export default AutoSuggest;
