import {useEffect} from 'react';
import PropTypes from 'prop-types';

import {useDispatch} from 'react-redux';
import {setCookie} from 'redux-cookie';
import {Twitch} from 'mdi-material-ui';

import Button from 'components/components/Button';

const BroadcasterLogin = ({
  linkText = 'Log In With Twitch',
  hasPermissionDetails = false,
  onClick = () => null,
  baseUrl = 'https://id.twitch.tv/oauth2/authorize',
  scopes = [
    'user:read:email',
    'chat:read',
    'chat:edit',
    'channel:moderate',
    'moderation:read',
    'channel:manage:moderators',
  ],
  loginCallbackUrl = TWITCH_CALLBACK_URL,
  loginClientId = TWITCH_CLIENT_ID,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setCookie('advSourceDomain', window.location.host, {
        path: '/',
        domain: IS_DEV ? 'localhost' : '.adv.gg',
      }),
    );
  });

  const handleClick = (e) => {
    e.preventDefault();
    const url = `${baseUrl}?response_type=code&client_id=${loginClientId}&redirect_uri=${encodeURIComponent(
      loginCallbackUrl,
    )}&scope=${scopes.join('+')}`;

    onClick();
    window.location.href = url;
  };

  const permissionDetails = `Your Twitch account is used as your broadcaster 
  account on Advocate. Advocate will not send live Stream Chat and Rooms messages 
  through your account unless you choose to enable it as a chatbot. Advocate will 
  grant moderator privileges to its chatbots to enable them to post in your chat 
  unconditionally, and will not perform any other moderation actions.`;

  return (
    <>
      <Button handleClick={handleClick} color="twitch" icon={<Twitch />}>
        {linkText}
      </Button>
      {hasPermissionDetails && <h3 className="permissionDetails">{permissionDetails}</h3>}
    </>
  );
};

BroadcasterLogin.propTypes = {
  setSourceDomain: PropTypes.func,
  linkText: PropTypes.node,
  linkClass: PropTypes.string,
  extraText: PropTypes.string,
  onClick: PropTypes.func,
  baseUrl: PropTypes.string,
  scopes: PropTypes.arrayOf(PropTypes.string),
  loginCallbackUrl: PropTypes.string,
  loginClientId: PropTypes.string,
  hasPermissionDetails: PropTypes.bool,
};

export default BroadcasterLogin;
