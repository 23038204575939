import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {isObject} from 'lodash';

import BroadcasterParticipationForm from './components/BroadcasterParticipationForm';
import AutoSuggestLocal from 'components/components/AutoSuggest/AutoSuggestLocal';
import {managementSetStatusRequest} from 'store/actions';
import {required, useValidator} from 'services/validation';

const CampaignAddBroadcasterFromTeamForm = (props) => {
  const {campaign, endpoint, teamBroadcasters, campaignBroadcasters} = props;
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (data) => {
      const broadcasters = teamBroadcasters.map((b) => b.username);
      // throw an error if the broadcaster is not on the team
      if (
        isObject(data.broadcaster)
          ? !broadcasters.includes(data.broadcaster.username)
          : !broadcasters.includes(data.broadcaster)
      ) {
        return {[FORM_ERROR]: 'Please select a valid team member!'};
      }
      // throw an error if the broadcaster is already on the campaign
      if (
        campaignBroadcasters
          .map((b) => b.username)
          .includes(isObject(data.broadcaster) ? data.broadcaster.username : data.broadcaster)
      ) {
        return {[FORM_ERROR]: 'This broadcaster is already on this campaign!'};
      }
      try {
        await dispatch(
          // data is a  broadcaster object if selected from the autosuggest dropdown
          // otherwise data is a broadcaster username from the text input
          managementSetStatusRequest(
            'campaigns',
            endpoint,
            isObject(data.broadcaster) ? data.broadcaster.username : data.broadcaster,
            campaign.slug,
          ),
        );
      } catch (error) {
        return error;
      }
    },
    [campaign.slug, campaignBroadcasters, dispatch, endpoint, teamBroadcasters],
  );

  const validate = useValidator({
    broadcaster: [required],
  });

  const componentProps = {
    suggestionSet: teamBroadcasters,
    filterField: 'username',
    longerThan: 0,
  };

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, form, error, submitting, submitError}) => (
        <BroadcasterParticipationForm
          handleSubmit={(data) =>
            handleSubmit(data)?.then(() => {
              const {submitSucceeded} = form.getState();
              submitSucceeded && form.reset();
            })
          }
          error={error || submitError}
          submitting={submitting}
          component={AutoSuggestLocal}
          componentProps={componentProps}
          {...props}
        />
      )}
    </Form>
  );
};

CampaignAddBroadcasterFromTeamForm.propTypes = {
  campaign: PropTypes.object,
  endpoint: PropTypes.string,
  teamBroadcasters: PropTypes.arrayOf(PropTypes.object),
  campaignBroadcasters: PropTypes.arrayOf(PropTypes.object),
};

export default CampaignAddBroadcasterFromTeamForm;
