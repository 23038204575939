import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {isPending, hasFailed} from 'redux-saga-thunk';

import {invitationResponseRequest} from 'store/actions';
import {fromMetrics} from 'store/selectors';
import PropTypes from 'prop-types';

import Button from 'components/molecules/Button';
import CampaignProposalModal from './components/CampaignProposalModal';
import useModal from 'hooks/useModal';

const Invitation = ({invite, onInvite, invitationType}) => {
  const dispatch = useDispatch();
  const [modalShow] = useModal();
  const to = invitationType === 'Campaign' ? 'campaigns' : 'teams';

  const id = invite.slug || invite.id;
  const acceptPending = useSelector((state) => isPending(state, `invitationaccept${id}`));
  const declinePending = useSelector((state) => isPending(state, `invitationdecline${id}`));
  const acceptFailed = useSelector((state) => hasFailed(state, `invitationaccept${id}`));
  const paymentData = useSelector((state) => fromMetrics.getPaymentData(state));

  const invitationResponse = useCallback(
    (response, onInvite) => dispatch(invitationResponseRequest(to, id, response)).then(onInvite),
    [dispatch, id, to],
  );

  return (
    <div className="campaignInvite">
      <h2>
        <b>{invite.name}</b>
        {acceptFailed ? 'Could not join team' : `${invitationType} Invitation`}
      </h2>
      {invitationType === 'Campaign' ? (
        <Button handleClick={() => modalShow(`accept-invite-${id}`)}>See Details</Button>
      ) : (
        <>
          <Button
            handleClick={() => invitationResponse('accept', onInvite())}
            loading={acceptPending}
            disabled={acceptFailed}
          >
            Accept
          </Button>
          <Button handleClick={() => invitationResponse('decline')} loading={declinePending}>
            Decline
          </Button>
        </>
      )}

      {invitationType === 'Campaign' && (
        <CampaignProposalModal
          invite={invite}
          paymentData={paymentData}
          acceptInvite={() => invitationResponse('accept', onInvite())}
          declineInvite={() => invitationResponse('decline')}
        />
      )}
    </div>
  );
};

Invitation.propTypes = {
  invite: PropTypes.object,
  onInvite: PropTypes.func,
  invitationType: PropTypes.oneOf(['Team', 'Campaign']),
};

export default Invitation;
