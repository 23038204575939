import PropTypes from 'prop-types';

import IconButton from 'components/components/IconButton';
import {Download} from 'mdi-material-ui';

const DownloadButton = ({href}) => (
  <a href={href} download>
    <IconButton label="download" download={href}>
      <Download />
    </IconButton>
  </a>
);

DownloadButton.propTypes = {
  href: PropTypes.string.isRequired,
};

export default DownloadButton;
