import {useParams} from 'react-router-dom';
import {fromResources} from 'store/selectors';
import {useSelector} from 'react-redux';

export const useCampaign = () => {
  const {campaignSlug} = useParams();
  return useSelector((state) => fromResources.getResourceDetail(state, 'campaigns', campaignSlug));
};

export const useCampaigns = () => {
  return useSelector((state) => fromResources.getResourceList(state, 'campaigns'));
};

export const useTeam = () => {
  const campaign = useCampaign();
  return useSelector((state) =>
    campaign?.team ? fromResources.getResourceDetail(state, 'teams', campaign.team) : null,
  );
};

export const useTeams = () => {
  return useSelector((state) => fromResources.getResourceList(state, 'teams'));
};

export const useSquads = (team) => {
  const campaign = useCampaign();
  return useSelector((state) =>
    team || campaign
      ? fromResources.getResourceListByTeam(state, 'squads', team || campaign.team)
      : [],
  );
};

export const useBroadcasters = () => {
  const {campaignSlug} = useParams();
  return useSelector((state) => {
    return fromResources.getBroadcastersByCampaign(state, campaignSlug);
  });
};

export const useCampaignLabels = () => {
  const {campaignSlug} = useParams();
  return useSelector((state) => {
    return fromResources.getResourceListByCampaign(state, 'labels', campaignSlug);
  });
};

export const useCampaignLinks = () => {
  const {campaignSlug} = useParams();
  return useSelector((state) => {
    return fromResources.getResourceListByCampaign(state, 'campaignLinks', campaignSlug);
  });
};

export const useCampaignResource = (name) => {
  const {campaignSlug} = useParams();
  return useSelector((state) => {
    return fromResources.getResourceListByCampaign(state, name, campaignSlug);
  });
};

export const useNextChatbotPosts = () => {
  const {campaignSlug} = useParams();
  return useSelector((state) => {
    return fromResources.getResourceListByCampaign(state, 'nextChatbotPosts', campaignSlug);
  });
};

export const useComponentTypes = () => {
  return useSelector((state) => {
    return fromResources.getResourceList(state, 'componentTypes');
  });
};

export const useComponents = () => {
  return useSelector((state) => {
    return fromResources.getResourceList(state, 'components');
  });
};

export const useComponentVersions = () => {
  return useSelector((state) => {
    return fromResources.getResourceList(state, 'componentVersions');
  });
};

export const useBroadcasterActions = () => {
  return useSelector((state) => {
    return fromResources.getResourceList(state, 'broadcasterActions');
  });
};

export const useCampaignInvitations = () => {
  return useSelector((state) => {
    return fromResources.getResourceList(state, 'campaignsInvitations');
  });
};

export const useTeamInvitations = () => {
  return useSelector((state) => {
    return fromResources.getResourceList(state, 'teamsInvitations');
  });
};

export const useChatbotModStatus = () => {
  const {campaignSlug} = useParams();
  return useSelector((state) => {
    return fromResources.getResourceListByCampaign(state, 'moderatorStatus', campaignSlug);
  });
};

export const useCompliance = () => {
  return useSelector((state) => {
    return fromResources.getResourceList(state, 'compliance');
  });
};

export const useAvailableTypes = () => {
  return useSelector((state) => {
    return fromResources.getResourceList(state, 'availableTypes');
  });
};
