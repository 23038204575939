import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {resourceUpdateRequest} from 'store/actions';
import {isPending} from 'redux-saga-thunk';

import ToggleButton from 'components/components/ToggleButton';

const CampaignSettingToggle = ({campaign, onLabel, offLabel, value, valueName}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => isPending(state, 'campaignsUpdate'));
  const setValue = useCallback(
    (valueName, value) =>
      dispatch(
        resourceUpdateRequest('campaigns', campaign.slug, {
          ...campaign,
          [valueName]: value,
        }),
      ),
    [campaign, dispatch],
  );
  return (
    <div className="toggleForm">
      <ToggleButton
        selectedIndex={campaign ? !value : false}
        handleClick={() => setValue(valueName, !value)}
        disabled={loading || !campaign}
      />
      <h4>{campaign && value ? onLabel : offLabel}</h4>
    </div>
  );
};

CampaignSettingToggle.propTypes = {
  campaign: PropTypes.object,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  value: PropTypes.bool,
  valueName: PropTypes.string,
};

export default CampaignSettingToggle;
