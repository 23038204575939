import {isEmpty} from 'lodash';

import LoadingBar from 'components/molecules/LoadingBar';
import EmptySplash from 'components/molecules/EmptySplash';
import AdvCheckbox from 'components/atoms/AdvCheckbox';
import {AdvModal} from 'containers';
import CampaignLabelForm from '../../forms/CampaignLabelForm';
import CampaignTemplate from '../../components/CampaignTemplate';
import CampaignNotFoundPage from '../../components/CampaignNotFoundPage';
import ChannelPreview from './components/ChannelPreview';
import DetailedChannelPreview from './components/DetailedChannelPreview';
import CampaignChannelsOptions from './components/CampaignChannelsOptions';
import CampaignChannelsFilters from './components/CampaignChannelsFilters';
import {isCampaignEnded} from 'utils/numberFormats';
import FeaturedCampaignForm from '../../forms/FeaturedCampaignForm';

import {CSSTransitionGroup} from 'react-transition-group';
import useCampaignChannels from './hooks/useCampaignChannels';
import useModal from 'hooks/useModal';

const CampaignChannels = () => {
  const [modalShow, modalHide] = useModal();
  const {
    campaign,
    labelsLoading,
    labels,
    broadcasterStats,
    userLoading,
    readOnly,
    submitUpdatedLabel,
    filters,
    options,
    broadcasters,
  } = useCampaignChannels();

  const {multiselectMode, selectedChannelsView, editingLabel} = options;
  const {broadcasters: labelBroadcasters} = editingLabel.val;

  if (!userLoading && isEmpty(campaign)) return <CampaignNotFoundPage />;

  const currentStats = broadcasterStats[filters.selectedDateRange.val] || {};
  const comparisonStats = broadcasterStats['60'] || {};

  const campaignIsEditable = !isCampaignEnded(campaign) && !readOnly;

  return (
    <CampaignTemplate>
      <CSSTransitionGroup
        transitionName="slowFadeLoadingBar"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {userLoading && <LoadingBar message="Loading Channels" />}
      </CSSTransitionGroup>
      <CampaignChannelsOptions
        options={options}
        labels={labels}
        labelsLoading={labelsLoading}
        submitUpdatedLabel={submitUpdatedLabel}
        modalShow={modalShow}
        campaignIsEditable={campaignIsEditable}
      />
      <CampaignChannelsFilters
        campaign={campaign}
        selectedChannelsView={selectedChannelsView.val}
        filters={filters}
      />
      <div className={userLoading ? 'campaignChannelsPage is-loading' : 'campaignChannelsPage'}>
        {broadcasters.length > 0 ? (
          broadcasters.map((broadcaster) =>
            selectedChannelsView.val ? (
              <div className="multiselectWrapper" key={broadcaster.username}>
                <CSSTransitionGroup
                  transitionName="fade"
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={500}
                >
                  {multiselectMode.val && labelBroadcasters && (
                    <AdvCheckbox
                      isChecked={labelBroadcasters.includes(broadcaster.username)}
                      onAnyClick
                      onCheck={() =>
                        editingLabel.set({
                          ...editingLabel.val,
                          broadcasters: labelBroadcasters.includes(broadcaster.username)
                            ? labelBroadcasters.filter((c) => c !== broadcaster.username)
                            : labelBroadcasters.concat(broadcaster.username),
                        })
                      }
                    />
                  )}
                </CSSTransitionGroup>
                <ChannelPreview
                  campaignSlug={campaign.slug}
                  broadcaster={broadcaster}
                  modalShow={modalShow}
                  stats={currentStats[broadcaster.username]}
                  comparisonStats={
                    filters.selectedDateRange.val === '30'
                      ? comparisonStats[broadcaster.username]
                      : null
                  }
                  showButtons={campaignIsEditable && !multiselectMode.val}
                  hasConversions={campaign ? campaign.has_conversions : false}
                  isActiveOnCampaign={['Active', 'Campaign Ended'].includes(
                    broadcaster.campaign_statuses[campaign.slug].status,
                  )}
                />
              </div>
            ) : (
              <DetailedChannelPreview
                broadcaster={broadcaster}
                stats={currentStats[broadcaster.username]}
                key={broadcaster.username}
                isActiveOnCampaign={['Active', 'Campaign Ended'].includes(
                  broadcaster.campaign_statuses[campaign.slug].status,
                )}
                hasConversions={campaign ? campaign.has_conversions : false}
              />
            ),
          )
        ) : (
          <EmptySplash>
            <h2>No channels found on this campaign!</h2>
          </EmptySplash>
        )}
      </div>

      {/* Create group modal */}
      {multiselectMode.val && campaignIsEditable && (
        <AdvModal name="campaign-channels-label-add" contentLabel="Create New Campaign Group">
          <CampaignLabelForm
            campaign={campaign.slug}
            setSelectedLabel={(val) => editingLabel.set({...editingLabel.val, name: val})}
          />
        </AdvModal>
      )}

      {/* Create Featured Campaign modal */}
      {!multiselectMode.val && campaignIsEditable && (
        <AdvModal name="add-campaign-feature" contentLabel="Feature Broadcaster">
          <FeaturedCampaignForm
            campaign={campaign}
            onSubmitSuccess={() => modalHide('add-campaign-feature')}
          />
        </AdvModal>
      )}
    </CampaignTemplate>
  );
};

export default CampaignChannels;
