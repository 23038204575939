import {required, createValidator} from 'services/validation';
import {useCallback} from 'react';
import {Form, Field} from 'react-final-form';

import {authResetPasswordRequest} from 'store/actions';
import {CSSTransitionGroup} from 'react-transition-group';
import {LockOutline} from 'mdi-material-ui';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import LoginInput from '../LoginInput';
import Button from 'components/components/Button';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const {uid, token} = useParams();
  const onSubmit = useCallback(
    (data) =>
      dispatch(
        authResetPasswordRequest({
          ...data,
          uid,
          token,
        }),
      ),
    [dispatch, token, uid],
  );

  const validate = createValidator({
    new_password1: [required],
    new_password2: [required],
  });

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, submitting, error}) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="new_password1"
            label="Enter New Password"
            placeholder="Enter New Password"
            component={LoginInput}
            icon={<LockOutline />}
            type="password"
          />

          <Field
            name="new_password2"
            label="Confirm Password"
            placeholder="Confirm Password"
            component={LoginInput}
            icon={<LockOutline />}
            type="password"
          />
          <Button loading={!!submitting}>Reset Password</Button>
          <CSSTransitionGroup
            transitionName="slowFade"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {error && <div className="help is-danger centered">{error}</div>}
          </CSSTransitionGroup>
        </form>
      )}
    </Form>
  );
};

export default ResetPassword;
