import {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {SingleDatePicker as ReactPicker} from 'react-dates';
import {CSSTransitionGroup} from 'react-transition-group';
import {CalendarMonth} from 'mdi-material-ui';
import moment from 'moment';

import AdvWarning from 'components/components/AdvWarning';
import MonthRenderer from 'components/molecules/MonthRenderer';

const SingleDatePicker = ({
  input,
  meta,
  placeholder,
  disabled,
  withIcon,
  extendsBeforeCurrentDay,
  absoluteStart,
  absoluteEnd,
  initialTime,
  appendToBody,
}) => {
  const [focused, setFocused] = useState(null);
  const [date, setDate] = useState(initialTime ? moment(initialTime) : null);

  const onDateChange = useCallback(
    (date) => {
      setDate(date);
      input.onChange && input.onChange(date);
    },
    [input],
  );

  return (
    <div
      className={`datePicker tertiary form ${withIcon ? 'withIcon' : ''} ${
        disabled ? 'disabled' : ''
      }`}
    >
      <CSSTransitionGroup
        transitionName="slowFadeWarning"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {meta.touched && meta.error && meta.submitFailed && <AdvWarning>{meta.error}</AdvWarning>}
      </CSSTransitionGroup>
      {withIcon && <CalendarMonth />}
      <ReactPicker
        renderMonthElement={({month, onMonthSelect, onYearSelect}) => (
          <MonthRenderer
            start={absoluteStart}
            end={absoluteEnd}
            month={month}
            onMonthSelect={onMonthSelect}
            onYearSelect={onYearSelect}
          />
        )}
        placeholder={placeholder}
        date={date}
        onDateChange={onDateChange}
        focused={focused}
        onFocusChange={({focused}) => setFocused(focused)}
        id="date"
        isOutsideRange={(day) =>
          (!extendsBeforeCurrentDay && day.isBefore(moment().startOf('day'))) ||
          (absoluteStart && day.isBefore(moment(absoluteStart).startOf('day'))) ||
          (absoluteEnd && day.isAfter(absoluteEnd))
        }
        disabled={disabled}
        displayFormat="MMMM DD"
        numberOfMonths={1}
        appendToBody={appendToBody}
        hideKeyboardShortcutsPanel
      />
    </div>
  );
};

SingleDatePicker.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  withIcon: PropTypes.bool,
  extendsBeforeCurrentDay: PropTypes.bool,
  absoluteStart: PropTypes.string,
  absoluteEnd: PropTypes.string,
  initialTime: PropTypes.string,
  appendToBody: PropTypes.bool,
};

export default SingleDatePicker;
