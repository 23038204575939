import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import Button from 'components/molecules/Button';
import AdvModal from 'containers/AdvModal';
import useModal from 'hooks/useModal';
import {resourceDeleteRequest, resourceUpdateRequest} from 'store/actions';
import {isPending, hasFailed} from 'redux-saga-thunk';

const DeleteModal = ({
  resource,
  objectNeedle,
  modalName,
  objectName,
  objectType,
  extraText,
  warning,
  hasArchiveOption,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => isPending(state, `${resource}Delete`));
  const updateLoading = useSelector((state) => isPending(state, 'componentsUpdate'));
  const failed = useSelector((state) => hasFailed(state, `${resource}Delete`));
  const [, modalHide] = useModal();
  const deleteRequest = useCallback(
    () => dispatch(resourceDeleteRequest(resource, objectNeedle)),
    [dispatch, objectNeedle, resource],
  );
  const archiveComponentRequest = useCallback(
    () =>
      dispatch(
        resourceUpdateRequest('components', objectNeedle, {
          ...hasArchiveOption,
          end_time: new Date().toISOString(),
        }),
      ).then(() => modalHide(modalName)),
    [dispatch, hasArchiveOption, modalHide, modalName, objectNeedle],
  );

  return (
    <AdvModal name={modalName} contentLabel={`${objectName} Delete Modal`}>
      <div className="deleteLabel">
        {`Delete ${hasArchiveOption ? 'or Archive ' : ''}`}
        {objectType}
        {` "${objectName}"?`}
      </div>
      {warning && <div className="warning">WARNING</div>}
      {extraText && <div className="extraText">{extraText}</div>}
      {failed && (
        <div className="help is-danger centered">
          {`Couldn't delete `}
          {objectType}. Please try again later
        </div>
      )}
      <Button type="cancel" handleClick={() => modalHide(modalName)}>
        Cancel
      </Button>
      <Button loading={!!loading} handleClick={onDelete ? onDelete : deleteRequest}>
        Delete
      </Button>
      {hasArchiveOption && (
        <Button loading={!!updateLoading} handleClick={archiveComponentRequest}>
          Archive
        </Button>
      )}
    </AdvModal>
  );
};

DeleteModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  objectName: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  warning: PropTypes.bool,
  hasArchiveOption: PropTypes.object,
  extraText: PropTypes.string,
  resource: PropTypes.string,
  objectNeedle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDelete: PropTypes.func,
};

export default DeleteModal;
