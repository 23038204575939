import PropTypes from 'prop-types';

import EmptySplash from 'components/molecules/EmptySplash';
import OverviewSection from 'components/molecules/OverviewSection';
import CampaignPreview from './components/CampaignPreview';
import usePermissions from 'hooks/usePermissions';

const sortCampaigns = (campaignA, campaignB) => {
  if ((campaignA.end_date && campaignB.end_date) || (!campaignA.end_date && !campaignB.end_date)) {
    return campaignA.name > campaignB.name ? 1 : -1;
  }
  if (campaignA.end_date) {
    return 0;
  }
  return -1;
};

const CampaignPreviewSection = ({campaigns, team}) => {
  const {canEditCampaigns} = usePermissions();

  return (
    <OverviewSection
      className="campaignPreviewSection"
      title={team ? 'Sponsors' : 'Your Campaigns'}
    >
      <div className="campaignPreviews">
        {campaigns.length !== 0 ? (
          campaigns
            .sort(sortCampaigns)
            .map((campaign) => (
              <CampaignPreview
                key={campaign.slug}
                campaign={campaign}
                isDeletable={canEditCampaigns}
                team={team}
              />
            ))
        ) : (
          <EmptySplash>
            <h2>
              You have no active
              {team ? ' sponsors!' : ' campaigns!'}
            </h2>
            <h2>Create one using the button above!</h2>
          </EmptySplash>
        )}
      </div>
    </OverviewSection>
  );
};

CampaignPreviewSection.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object),
  team: PropTypes.object,
};

export default CampaignPreviewSection;
