import PropTypes from 'prop-types';

import TextFilter from 'components/components/TextFilter';
import StaticDateRanges from 'components/molecules/StaticDateRanges';
import LabelSelect from 'components/components/LabelSelect';
import AdvCheckbox from 'components/atoms/AdvCheckbox';

const CampaignChannelsFilters = ({campaign, filters, selectedChannelsView}) => {
  const {channelsFilter, gameCountryFilter, selectedDateRange, showInactives, selectedSort} =
    filters;
  return (
    <div className="preset_campaign-page-options inner-bar">
      <span>
        {campaign && campaign.team && (
          <StaticDateRanges
            handleChange={(selected) => {
              selectedDateRange.set(selected);
            }}
            dateRanges={['07', '30', '90', 'all']}
            selectedRange={selectedDateRange.val.toString()}
          />
        )}
        <AdvCheckbox
          isChecked={showInactives.val}
          onCheck={() => showInactives.set(!showInactives.val)}
          label="Show Inactive Channels"
        />
        <TextFilter
          placeholder="Filter by Channels & Groups"
          handleChange={(e) => channelsFilter.set(e.target.value)}
        />
        <TextFilter
          placeholder="Filter by Games & Countries"
          handleChange={(e) => gameCountryFilter.set(e.target.value)}
        />
        {selectedChannelsView && (
          <LabelSelect label="Sort by" handleChange={selectedSort.set}>
            <option value="total-clicks">Total Clicks</option>
            {campaign?.has_conversions && (
              <option value="total-conversions">Total Conversions</option>
            )}
            <option value="viewable-minutes">Viewable Minutes</option>
            <option value="cvm">CVM</option>
          </LabelSelect>
        )}
        {!selectedChannelsView && (
          <LabelSelect label="Sort by" handleChange={selectedSort.set}>
            <option value="total-clicks">Total Clicks</option>
            {campaign?.has_conversions && (
              <option value="total-conversions">Total Conversions</option>
            )}
            <option value="viewable-minutes">Viewable Minutes</option>
            <option value="average-viewers">Average Viewers</option>
            <option value="recorded-broadcasts">Recorded Broadcasts</option>
            <option value="cvm">CVM</option>
          </LabelSelect>
        )}
      </span>
    </div>
  );
};

CampaignChannelsFilters.propTypes = {
  campaign: PropTypes.object,
  filters: PropTypes.object,
  selectedChannelsView: PropTypes.bool,
};

export default CampaignChannelsFilters;
