/* eslint-disable no-restricted-syntax */
import {useState} from 'react';
import PropTypes from 'prop-types';
import {isEqual, intersection, maxBy} from 'lodash';

import AdvCheckbox from 'components/atoms/AdvCheckbox';

const SubSelect = ({input, disabled, selectionInput, initialSelection}) => {
  const [teamOpened, setTeamOpened] = useState(null);
  const [squadOpened, setSquadOpened] = useState(null);
  const [selectedBroadcasters, setSelectedBroadcasters] = useState(
    initialSelection || {
      broadcasters_to_add: [],
      squads: [],
    },
  );

  const assignSelectedBroadcasters = (selectedBroadcasters, selectedSquads) => {
    setSelectedBroadcasters({
      ...selectedBroadcasters,
      broadcasters_to_add: selectedBroadcasters,
      squads: selectedSquads,
    });

    input.onChange &&
      input.onChange({
        ...selectedBroadcasters,
        broadcasters_to_add: selectedBroadcasters,
        squads: selectedSquads,
      });
  };

  const broadcasterCheckLogic = (broadcasterChecked, contentArray, broadcaster, squad) => {
    if (broadcasterChecked) {
      assignSelectedBroadcasters(
        selectedBroadcasters.broadcasters_to_add.filter((b) => b !== broadcaster.name),
        selectedBroadcasters.squads.filter((s) => s !== squad.key),
      );
    }
    if (!broadcasterChecked) {
      assignSelectedBroadcasters(
        [...selectedBroadcasters.broadcasters_to_add, broadcaster.name],
        isEqual(
          intersection(
            [...selectedBroadcasters.broadcasters_to_add, broadcaster.name],
            contentArray,
          ).sort(),
          contentArray.sort(),
        )
          ? [...selectedBroadcasters.squads, squad.key]
          : selectedBroadcasters.squads,
      );
    }
  };

  const squadCheckLogic = (squadChecked, contentArray, squad) => {
    if (squadChecked) {
      assignSelectedBroadcasters(
        selectedBroadcasters.broadcasters_to_add.filter((b) => !contentArray.includes(b)),
        selectedBroadcasters.squads.filter((s) => s !== squad.key),
      );
    }
    if (!squadChecked) {
      assignSelectedBroadcasters(
        [...new Set([...selectedBroadcasters.broadcasters_to_add, ...contentArray])],
        [...selectedBroadcasters.squads, squad.key],
      );
    }
  };

  const topLevelHeight = (team) =>
    (team.content ? team.content.length : 0) +
    (team.broadcasters ? team.broadcasters.length : 0) +
    (maxBy(selectionInput[0].content, (i) => i.content.length)
      ? maxBy(selectionInput[0].content, (i) => i.content.length).content.length
      : 0);

  return (
    <>
      <div className={`subSelect ${disabled ? 'disabled' : ''}`}>
        {Array.isArray(selectionInput) &&
          selectionInput.map((team, teamIndex) => (
            <div key={team.id} className="teamLevel">
              <div
                className="team"
                role="button"
                tabIndex={-1}
                onClick={() => {
                  if (!disabled) {
                    setTeamOpened(teamOpened !== team.id ? team.id : null);
                    setSquadOpened(null);
                  }
                }}
              >
                <div className="borderStyle" />
                <p>{team.name}</p>
                <div className={`arrow ${!disabled && teamOpened === team.id ? 'isOpen' : ''}`} />
              </div>
              <div
                className={`content ${squadOpened ? 'squadOpened' : ''} ${
                  !disabled && teamOpened === team.id ? `has-${topLevelHeight(team)}-items` : ''
                }`}
              >
                {team.content &&
                  team.content.map((squad, squadIndex) => {
                    const squadKey = `${squad.key}-${team.id}`;
                    const contentArray = squad.content.map((b) => b.name);
                    const squadChecked = isEqual(
                      intersection(selectedBroadcasters.broadcasters_to_add, contentArray).sort(),
                      contentArray.sort(),
                    );
                    return squad.content.length > 0 ? (
                      <div key={squadKey} className="squadLevel">
                        <div className="squad">
                          <AdvCheckbox
                            isChecked={squadChecked}
                            onCheck={() => squadCheckLogic(squadChecked, contentArray, squad)}
                          />
                          <div
                            className="clickable"
                            role="button"
                            tabIndex={-1}
                            onClick={() =>
                              setSquadOpened(squadOpened !== squadKey ? squadKey : null)
                            }
                          >
                            <p>{squad.name}</p>
                            <div className={`arrow ${squadOpened === squadKey ? 'isOpen' : ''}`} />
                          </div>
                        </div>
                        <div
                          className={`content ${
                            squadOpened === squadKey ? `has-${squad.content.length}-items` : ''
                          }`}
                        >
                          {squad.content
                            .sort((a, b) => (b.name > a.name ? -1 : 1))
                            .map((broadcaster) => {
                              const broadcasterChecked =
                                selectedBroadcasters.broadcasters_to_add.includes(broadcaster.name);
                              return (
                                <div key={broadcaster.name} className="broadcasterLevel">
                                  <AdvCheckbox
                                    isChecked={broadcasterChecked}
                                    onCheck={() =>
                                      broadcasterCheckLogic(
                                        broadcasterChecked,
                                        contentArray,
                                        broadcaster,
                                        squad,
                                      )
                                    }
                                  />
                                  <p>{broadcaster.name}</p>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      ''
                    );
                  })}
                <div className="divisionBar" />
                {team.broadcasters &&
                  team.broadcasters
                    .sort((a, b) => (b.username > a.username ? -1 : 1))
                    .map((broadcaster) => {
                      const broadcasterChecked = selectedBroadcasters.broadcasters_to_add.includes(
                        broadcaster.username,
                      );
                      const checkFunc = () =>
                        assignSelectedBroadcasters(
                          broadcasterChecked
                            ? selectedBroadcasters.broadcasters_to_add.filter(
                                (b) => b !== broadcaster.username,
                              )
                            : [...selectedBroadcasters.broadcasters_to_add, broadcaster.username],
                          selectedBroadcasters.squads,
                        );
                      return (
                        <div
                          key={broadcaster.username}
                          className="broadcasterLevel"
                          role="button"
                          tabIndex={-1}
                          onClick={checkFunc}
                        >
                          <AdvCheckbox isChecked={broadcasterChecked} onCheck={checkFunc} />
                          <p>{broadcaster.username}</p>
                        </div>
                      );
                    })}
              </div>
            </div>
          ))}
      </div>
      <div className="subSelectSpace" />
    </>
  );
};

SubSelect.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  selectionInput: PropTypes.arrayOf(PropTypes.object),
  initialSelection: PropTypes.arrayOf(PropTypes.string),
};

export default SubSelect;
