import PropTypes from 'prop-types';
import {ClockOutline} from 'mdi-material-ui';
import {formatTime} from 'utils/numberFormats';

const TimeTooltip = ({datetime}) => (
  <div className="timeTooltip">
    <ClockOutline />
    <h4 className="tooltip">{formatTime(datetime)}</h4>
  </div>
);

TimeTooltip.propTypes = {
  datetime: PropTypes.string,
};

export default TimeTooltip;
