/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {Camcorder, PlusBoxMultiple, Earth, Gamepad} from 'mdi-material-ui';

import useChannelPreview from '../ChannelPreview/hooks/useChannelPreview';
import {formatNumber} from 'utils/numberFormats';
import ChannelPreviewTopBar from 'components/molecules/ChannelPreviewTopBar';
import lookup from 'country-code-lookup';

const DetailedChannelPreview = ({broadcaster, stats, hasConversions, isActiveOnCampaign}) => {
  const {isFeatured} = useChannelPreview(broadcaster);

  const statsIn = stats || {
    clicks_by_type: {},
    viewership: {},
    clicks_by_country: [],
    stream_time_by_game: [],
    conversions: 0,
    fullscreen_video: {},
    impressions: {},
  };

  const mainPlatform = broadcaster.platforms[0];

  const {
    clicks_by_type,
    [`${mainPlatform}_viewership`]: viewership = {},
    clicks_by_country,
    [`${mainPlatform}_stream_time_by_game`]: stream_time_by_game = {},
    conversions,
    fullscreen_video,
    impressions,
  } = statsIn;

  const totalVisits =
    clicks_by_type.banner_graphic_clicks +
    clicks_by_type.twitch_chatbot_clicks +
    clicks_by_type.twitch_command_clicks +
    clicks_by_type.twitter_clicks +
    clicks_by_type.live_graphic_clicks +
    clicks_by_type.custom_link_clicks;

  const clicksPerVisits = totalVisits / (viewership.viewable_minutes / 1000000);

  return (
    <div className="detailed channelWrapper">
      <div className="DetailedChannelPreview">
        <div className="backgroundImage" />
        <ChannelPreviewTopBar broadcaster={broadcaster} mainPlatform={mainPlatform} />
        {!isActiveOnCampaign && <h4 className="liveText inactive">Inactive</h4>}
        <div className="bannerSection">
          {broadcaster.is_live && isActiveOnCampaign && (
            <h4 className="liveText greenPulse">LIVE</h4>
          )}
          {isFeatured && isActiveOnCampaign && <h4 className="featuredText pinkPulse">FEATURED</h4>}
        </div>
        <div className="contentSection">
          <div className="statsOne">
            <h3>
              <Camcorder /> Viewership{' '}
            </h3>
            <p>
              Viewable Minutes:
              <b>{formatNumber(viewership.viewable_minutes)}</b>
            </p>
            <p>
              Recorded Broadcasts:
              <b>{formatNumber(viewership.broadcasts)}</b>
            </p>
            <p>
              Average Viewers:
              <b>{formatNumber(viewership.average_viewers)}</b>
            </p>
            <p>
              Peak Viewers:
              <b>{formatNumber(viewership.peak_viewers)}</b>
            </p>
          </div>
          <div className="statsTwo">
            <h3>
              <PlusBoxMultiple /> Attribution{' '}
            </h3>
            <p>
              Total Visits:
              <b>{formatNumber(totalVisits)}</b>
            </p>
            {hasConversions && (
              <p>
                Total Conversions:
                <b>{conversions ? formatNumber(conversions) : 0}</b>
              </p>
            )}
            <p>
              CVM:
              <b>{viewership.viewable_minutes ? formatNumber(clicksPerVisits) : 0}</b>
            </p>
            {!!clicks_by_type.banner_graphic_clicks && (
              <p>
                Banner Graphic Clicks:
                <b>{formatNumber(clicks_by_type.banner_graphic_clicks)}</b>
              </p>
            )}
            {!!clicks_by_type.live_graphic_clicks && (
              <p>
                Live Graphic Visits:
                <b>{formatNumber(clicks_by_type.live_graphic_clicks)}</b>
              </p>
            )}
            {!!clicks_by_type.twitch_chatbot_clicks && (
              <p>
                Chatbot Clicks:
                <b>{formatNumber(clicks_by_type.twitch_chatbot_clicks)}</b>
              </p>
            )}
            {!!clicks_by_type.twitch_command_clicks && (
              <p>
                Chatbot Command Clicks:
                <b>{formatNumber(clicks_by_type.twitch_command_clicks)}</b>
              </p>
            )}
            {!!clicks_by_type.twitter_clicks && (
              <p>
                Twitter Clicks:
                <b>{formatNumber(clicks_by_type.twitter_clicks)}</b>
              </p>
            )}
            {!!clicks_by_type.custom_link_clicks && (
              <p>
                Custom Link Clicks:
                <b>{formatNumber(clicks_by_type.custom_link_clicks)}</b>
              </p>
            )}
            {fullscreen_video.plays > 0 && (
              <p>
                Streamed Video Impressions:
                <b>{formatNumber(fullscreen_video.impressions)}</b>
              </p>
            )}
            {fullscreen_video.plays > 0 && (
              <p>
                Streamed Video Plays:
                <b>{formatNumber(fullscreen_video.plays)}</b>
              </p>
            )}
          </div>
          {!isEmpty(clicks_by_country) && (
            <div className="statsThree">
              <h3>
                <Earth /> Clicks by Country{' '}
              </h3>
              {clicks_by_country.length > 0 &&
                clicks_by_country.map((country) => (
                  <p key={country.country}>
                    {lookup.byIso(country.country)
                      ? lookup.byIso(country.country).country
                      : 'Not Found'}
                    : <b>{formatNumber(country.clicks)}</b>{' '}
                    <span className="percent">
                      ({formatNumber((country.clicks / totalVisits) * 100)}
                      %)
                    </span>
                  </p>
                ))}
            </div>
          )}
          {!isEmpty(stream_time_by_game) && (
            <div className="statsFour">
              <h3>
                <Gamepad /> Top Games by Hours Streamed
              </h3>
              {stream_time_by_game.length > 0 &&
                stream_time_by_game.map((game) => (
                  <p key={game.game}>
                    {game.game}: <b>{formatNumber(game.hours)}</b>
                  </p>
                ))}
            </div>
          )}
          {!isEmpty(impressions) && (
            <div className="statsFive">
              <h3>
                <Camcorder /> Live Graphic Impressions
              </h3>
              {Object.keys(impressions).map((livegraphic) => (
                <p key={livegraphic}>
                  {livegraphic}: <b>{formatNumber(impressions[livegraphic])}</b>
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DetailedChannelPreview.propTypes = {
  avatarUrl: PropTypes.string,
  broadcaster: PropTypes.object.isRequired,
  hasConversions: PropTypes.bool,
  isActiveOnCampaign: PropTypes.bool,
  countryStats: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  stats: PropTypes.object,
};

DetailedChannelPreview.defaultProps = {
  avatarUrl: '/images/NewSquareIconSmall.png',
};

export default DetailedChannelPreview;
