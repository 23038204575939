import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {isPending, hasFailed} from 'redux-saga-thunk';

import {authEmailConfirmationRequest} from 'store/actions';
import Button from 'components/components/Button';

const EmailConfirmation = () => {
  const {key} = useParams();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  const loading = useSelector((state) => isPending(state, 'emailConfirmation'));
  const failed = useSelector((state) => hasFailed(state, 'emailConfirmation'));

  useEffect(() => {
    dispatch(authEmailConfirmationRequest(key)).then(
      () => setConfirmed(true),
      (error) => setError(error._error || error.detail || error[0] || error),
    );
  }, [key, dispatch]);

  return (
    <div>
      {!error && loading && <span>Loading...</span>}
      {failed && (
        <span>
          Unable to confirm email. Please reach out to Advocate at
          <a href="mailto:contact@adv.gg"> contact@adv.gg</a> or on our discord for support.
        </span>
      )}
      {confirmed && (
        <div>
          <h2>Email Successfully Confirmed!</h2>
          <br />
          <Button link="/">Back to Dashboard</Button>
        </div>
      )}
    </div>
  );
};

export default EmailConfirmation;
