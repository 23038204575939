import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {resourceUpdateRequest} from 'store/actions';
import {CheckCircle, CircleSlice8} from 'mdi-material-ui';
import Button from 'components/molecules/Button';

const formatCreationDate = (dateString) => {
  const date = new Date(dateString);

  return date.toLocaleDateString('en-us', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

const BroadcasterActionListItem = ({broadcasterAction, loading, version, isLatest}) => {
  const dispatch = useDispatch();
  const markIncomplete = useCallback(
    () =>
      dispatch(
        resourceUpdateRequest('broadcasterActions', broadcasterAction.id, {
          ...broadcasterAction,
          completion_action: 'mark_incomplete',
        }),
      ),
    [broadcasterAction, dispatch],
  );

  return (
    <div className="broadcasterActionListItem topToggle">
      <span className="topDate">
        <h4>
          {'Created '}
          {formatCreationDate(broadcasterAction.creation_time)}
        </h4>
      </span>
      <span className="content">
        {version && version.image && <img className="cornerPreview" alt="" src={version.image} />}
        {broadcasterAction.completion_time ? <CheckCircle /> : <CircleSlice8 />}
        <span>
          <h4>{broadcasterAction.type.name}</h4>
          <h4>{broadcasterAction.completion_time ? 'Action Complete' : 'Action Incomplete'}</h4>
        </span>
        {broadcasterAction.completion_time && isLatest && (
          <Button loading={loading} handleClick={markIncomplete}>
            Mark Incomplete
          </Button>
        )}
      </span>
      <h4 className="componentName">{broadcasterAction.component}</h4>
    </div>
  );
};

BroadcasterActionListItem.propTypes = {
  broadcasterAction: PropTypes.object.isRequired,
  version: PropTypes.object,
  loading: PropTypes.bool,
  isLatest: PropTypes.bool,
};

export default BroadcasterActionListItem;
