import {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fromProfile} from 'store/selectors';
import {browsersourcePositioningReadRequest} from 'store/actions';

const useStreamPreview = ({
  setScalingFactor,
  selectedOutputResolutionWidth,
  setSelectedImageIndexes,
  setSelectedOutputResolutionHeight,
  setSelectedOutputResolutionWidth,
}) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => fromProfile.getFullProfile(state));
  const fetchPositions = useCallback(
    () => dispatch(browsersourcePositioningReadRequest()),
    [dispatch],
  );

  useEffect(() => {
    fetchPositions();
  }, [dispatch, fetchPositions]);

  // handle window resize and recalculate scaling factor due to browser window resize
  useEffect(() => {
    const resizedWindow = () =>
      setScalingFactor(
        document.getElementById('browsersource-image').offsetWidth / selectedOutputResolutionWidth,
      );
    window.addEventListener('resize', resizedWindow);
    return () => window.removeEventListener('resize', resizedWindow);
  }, [setScalingFactor, selectedOutputResolutionWidth]);

  // Get initial values based on prop data and rendered DOM once
  useEffect(() => {
    const [selectedOutputResolutionWidth, selectedOutputResolutionHeight] =
      profile.profile.base_canvas_resolution.split('x');

    if (document.getElementById('browsersource-image')) {
      setScalingFactor(
        document.getElementById('browsersource-image').offsetWidth / selectedOutputResolutionWidth,
      );
    }
    setSelectedOutputResolutionWidth(selectedOutputResolutionWidth);
    setSelectedOutputResolutionHeight(selectedOutputResolutionHeight);
  }, [
    setScalingFactor,
    setSelectedImageIndexes,
    setSelectedOutputResolutionWidth,
    setSelectedOutputResolutionHeight,
    profile.profile.base_canvas_resolution,
  ]);

  return {};
};

export default useStreamPreview;
