import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {isPending, hasFailed} from 'redux-saga-thunk';
import {parse} from 'query-string';

import {twitterConnectRequest} from 'store/actions';
import SocialCallback from '../SocialCallback';

const TwitterCallbackPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {oauth_token: OAuthToken, oauth_verifier: OAuthVerifier} = parse(location.search);
  const loading = useSelector((state) => isPending(state, 'twitterConnect'));
  const failed = useSelector((state) => hasFailed(state, 'twitterConnect'));
  const sendCallbackData = useCallback(
    () => dispatch(twitterConnectRequest(OAuthToken, OAuthVerifier)),
    [dispatch, OAuthVerifier, OAuthToken],
  );

  const props = {
    loading,
    failed,
    sendCallbackData,
  };
  return <SocialCallback {...props} />;
};

export default TwitterCallbackPage;
