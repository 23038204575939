import {useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Twitch} from 'mdi-material-ui';
import {fromProfile} from 'store/selectors';

const PanelSetupSteps = () => {
  const [minimized, setMinimized] = useState(false);
  const username = useSelector((state) => fromProfile.getUsername(state));

  return (
    <>
      {!minimized && (
        <div className="panelSetupSteps">
          <div className="step">
            <h2>
              <b>1.</b> Copy the panel link to your clipboard
            </h2>
          </div>
          <div className="step">
            <div className="mainContent">
              <h2>
                <b>2.</b> Download the panel image
              </h2>
            </div>
          </div>
          <div className="step">
            <h2>
              <b>3.</b> Navigate to your Twitch page
              <a
                href={`https://www.twitch.tv/${username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitch />
              </a>
            </h2>
          </div>
          <div className="step">
            <h2>
              <b>4.</b> Toggle &quot;Edit Panels&quot;
            </h2>
          </div>
          <div className="step">
            <h2>
              <b>5.</b> Add a new panel and enter details
            </h2>
          </div>
          <div className="step">
            <h2>
              <b>6.</b> Click submit!
            </h2>
          </div>
        </div>
      )}
      <button className="minimizePanelSteps" onClick={() => setMinimized(!minimized)}>
        <h4>{minimized ? 'Show steps to add a Twitch panel' : 'Hide setup steps'}</h4>
      </button>
    </>
  );
};

PanelSetupSteps.propTypes = {
  action: PropTypes.object,
};

export default PanelSetupSteps;
