import {useState} from 'react';
import PropTypes from 'prop-types';
import {flattenDeep} from 'lodash';
import StatsTable from 'components/organisms/StatsTable';
import TweetPreview from 'components/molecules/TweetPreview';
import EmptySplash from 'components/molecules/EmptySplash';
import ComponentPreview from 'components/organisms/ComponentPreview';
import Button from 'components/molecules/Button';
import CampaignTerms from './components/CampaignTerms';
import CustomLinkComponentPreview from '../../../../components/CustomLinkComponentPreview';
import {isCampaignStarted, isCampaignEnded, formatDate} from 'utils/numberFormats';
import {getTweetIdFromUrl, mergeBroadcasterViewership} from 'utils/campaignTools';

const BroadcasterCampaignPreview = ({
  campaign,
  actions,
  statsTable,
  setBroadcasterStatus,
  loadingBroadcasterStatus,
}) => {
  const [selectedView, setSelectedView] = useState('Components');
  const [selectedType, setSelectedType] = useState(0);

  const componentTypes = campaign.components
    ? [...new Set(campaign.components.map((c) => c.type.name))]
        .filter((t) => t !== 'Suggested Tweet' && t !== 'Suggested Retweet')
        .sort()
    : [];

  const subTabs = [];

  if (campaign.payment_terms) subTabs.push('Payment Terms');
  if (campaign.general_guidelines) subTabs.push('Guidelines');
  if (campaign.description) subTabs.push('Description');
  if (campaign.requirements) subTabs.push('Requirements');

  const campaignFieldKeys = {
    'Payment Terms': 'payment_terms',
    Guidelines: 'general_guidelines',
    Description: 'description',
    Requirements: 'requirements',
  };

  const campaignStats = mergeBroadcasterViewership(campaign).concat({
    label: 'Clicks Generated',
    value: campaign.metrics.clicks,
  });

  // disabled until payment business logic is decided
  // if (campaign.viewership && campaign.payment_per_mvm > 0) {
  //   campaignStats.push({
  //     label: 'Earned',
  //     value: formatDollar(
  //       (parseInt(campaign.payment_per_mvm, 10)
  //       * ((campaign.viewership.average_viewers * campaign.viewership.minutes_streamed) / 1000000))
  //       + parseInt(campaign.payment_initial, 10)),
  //   })
  // }

  if (campaign.has_conversions) {
    campaignStats.push({
      label: 'Conversions',
      value: campaign.metrics.conversions,
    });
  }

  const activePeriods =
    campaign.active_periods &&
    campaign.active_periods.length > 0 &&
    campaign.active_periods.sort((a, b) => (new Date(b.start) < new Date(a.start) ? 1 : -1));

  const campaignVersionIds = campaign.components
    ? flattenDeep(campaign.components.map((c) => c.versions.map((v) => v.id)))
    : [];
  const tweets = actions
    ? actions
        .filter(
          (a) =>
            (a.type.slug === 'twitter-send-tweet' || a.type.slug === 'twitter-retweet') &&
            campaignVersionIds.includes(a.version.id),
        )
        .map((a) => a.completion_data.tweet_url || a.version.message)
    : [];

  if (tweets.length > 0) componentTypes.push('Sent Tweets');

  return (
    <div className="campaignWrapper" id={campaign.slug}>
      <div className={`subNavbar ${isCampaignEnded(campaign) ? 'ended' : ''}`}>
        {campaign.team && <img className="teamLogo" alt="" src={campaign.team.logo} />}
        <h3>{campaign.name}</h3>
        <h4>
          <b>{isCampaignEnded(campaign) ? 'Campaign Ended' : campaign.status}</b>
          {` - ${!isCampaignStarted(campaign) ? 'Starts ' : 'Started '}
          ${formatDate(campaign.start_date)}${
            campaign.end_date
              ? `,
          ${isCampaignEnded(campaign) ? 'ended ' : 'ends '}
          ${formatDate(campaign.end_date)}`
              : ''
          }`}
        </h4>
        {campaign.allow_self_deactivate && (
          <Button
            loading={loadingBroadcasterStatus}
            handleClick={() =>
              setBroadcasterStatus(campaign.status === 'Active' ? 'deactivate' : 'activate')
            }
          >
            {campaign.status === 'Active' ? 'Suspend Campaign' : 'Activate Campaign'}
          </Button>
        )}
        <div className="subNavbarItemWrapper">
          {['Components', ...subTabs].map((tab) => (
            <div
              className={`subNavbarItem ${tab === selectedView ? 'active' : ''}`}
              key={tab}
              role="button"
              tabIndex={0}
              onClick={() => setSelectedView(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
      <span className="content">
        {campaign.active_periods &&
          (campaign.active_periods.length !== 0 || !isCampaignEnded(campaign)) && (
            <div className="infoSection">
              {campaign.active_periods && campaign.active_periods.length === 0 && (
                <p>Awaiting approval for this campaign</p>
              )}
              {campaign.active_periods && activePeriods[0] && (
                <p>{`You joined on ${formatDate(activePeriods[0].start)}${
                  campaign.team ? ` through team ${campaign.team.name}` : ''
                }`}</p>
              )}
              {campaign.active_periods &&
                activePeriods[activePeriods.length - 1] &&
                activePeriods[activePeriods.length - 1].end && (
                  <p>{`You left this campaign on ${formatDate(
                    activePeriods[activePeriods.length - 1].end,
                  )}`}</p>
                )}
              {campaign.active_periods && campaign.active_periods.length > 0 && (
                <StatsTable metrics={campaignStats} loadingThunk="campaignsDetailRead" />
              )}
            </div>
          )}
        <div className="tabSection">
          {subTabs.map(
            (tab) =>
              selectedView === tab && (
                <CampaignTerms key={tab} campaign={campaign} field={campaignFieldKeys[tab]} />
              ),
          )}
          {selectedView === 'Components' && (
            <div>
              <div className="subNavbar">
                <div className="subNavbarItemWrapper">
                  {componentTypes.map((tab, index) => (
                    <div
                      className={`subNavbarItem ${index === selectedType ? 'active' : ''}`}
                      key={tab}
                      role="button"
                      tabIndex={0}
                      onClick={() => setSelectedType(index)}
                    >
                      {tab}
                    </div>
                  ))}
                </div>
              </div>
              <div className="componentSection">
                {campaign.components &&
                  campaign.components
                    .filter(
                      (component) =>
                        !component.is_deleted &&
                        component.type.name === componentTypes[selectedType],
                    )
                    .map((component) => {
                      const clicks = statsTable.components.find((c) => c[0] === component.id);
                      switch (component.type.slug) {
                        case 'custom-link':
                          return (
                            <CustomLinkComponentPreview
                              component={component}
                              componentType={component.type}
                              version={component.versions[0]}
                              key={`component-${component.id}`}
                            />
                          );
                        default:
                          return (
                            <span key={`component-${component.id}`}>
                              <ComponentPreview
                                component={component}
                                componentType={component.type}
                                version={component.versions[0]}
                                showButtons={false}
                                hasCheckMark
                                chatbotUsername={campaign.twitch_chatbot_account}
                              />
                              {clicks && <p>{`${clicks[1]} clicks`}</p>}
                            </span>
                          );
                      }
                    })}
                {campaign.components &&
                  campaign.components.filter(
                    (component) =>
                      !component.is_deleted && component.type.name === componentTypes[selectedType],
                  ).length === 0 && (
                    <EmptySplash>
                      <h3>No Components Available</h3>
                    </EmptySplash>
                  )}
                {componentTypes[selectedType] === 'Sent Tweets' &&
                  tweets.map((tweet) => (
                    <span key={`${getTweetIdFromUrl(tweet)}-${campaign.slug}`}>
                      <TweetPreview tweetId={getTweetIdFromUrl(tweet)} />
                    </span>
                  ))}
              </div>
            </div>
          )}
        </div>
      </span>
    </div>
  );
};

BroadcasterCampaignPreview.propTypes = {
  campaign: PropTypes.object.isRequired,
  actions: PropTypes.arrayOf(PropTypes.object),
  statsTable: PropTypes.object,
  setBroadcasterStatus: PropTypes.func,
  loadingBroadcasterStatus: PropTypes.bool,
};

export default BroadcasterCampaignPreview;
