/* eslint-disable no-underscore-dangle */
// https://github.com/diegohaz/arc/wiki/Example-app
import 'react-hot-loader/patch';
import 'babel-polyfill';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import Cookies from 'universal-cookie';

import configureStore from 'store/configure';
import api from 'services/api';
import App from 'components/App';
import './sass/style.sass';
import history from './localHistory';

const initialState = window.__INITIAL_STATE__;
const cookies = new Cookies();
const store = configureStore(initialState, {api: api.create()}, cookies);

const renderApp = () => (
  <Provider store={store}>
    <Router history={history}>
      <App userAgent={navigator.userAgent} />
      <div id="modalParent" />
    </Router>
  </Provider>
);

const root = document.getElementById('app');
render(renderApp(), root);

if (module.hot) {
  module.hot.accept('components/App', () => {
    require('components/App');
    render(renderApp(), root);
  });
}
