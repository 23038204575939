import {take, call, put, fork} from 'redux-saga/effects';
import {setCookie, removeCookie} from 'redux-cookie';
import {normalize} from 'normalizr';
import {FORM_ERROR} from 'final-form';
import {values} from 'lodash';

import {getExpiryDate} from 'utils/expiry';
import {
  PROFILE_READ_REQUEST,
  PROFILE_COMPLETE_SET_USER_DATA,
  PROFILE_UPDATE_REQUEST,
  PROFILE_LOAD_USER_DATA,
  PROFILE_COMPLETE_CLEAR_USER_DATA,
  profileCompleteSetUserData,
  profileReadSuccess,
  profileReadFailed,
  profileUpdateSuccess,
  profileUpdateFailed,
  API_KEY_GENERATE_REQUEST,
  apiKeyGenerateSuccess,
  apiKeyGenerateFailed,
} from './actions';
import {user as userSchema} from '../resources/schemas';
import {resourceAdd} from '../resources/actions';

// Handles storing raw (pre-normalization) user data in cookie.
// Splits data between auth-related data, such as userType and authentication status,
// which needs to be in a cookie for SSR, and campaign level data, which is larger
// and can live in localStorage
export function* saveUserData(userData) {
  const user = values(userData.user)[0];
  const userAuthData = {
    user: {
      [user.username]: {
        username: user.username,
        user_type: user.user_type,
        tos_agreed: user.tos_agreed,
      },
    },
  };
  yield put(
    setCookie('user', JSON.stringify(userAuthData), {
      expires: getExpiryDate(),
      path: '/',
    }),
  );

  localStorage.setItem('user', JSON.stringify(userData));
}

// Normalizes user data to separate out campaign data, and then passes to
export function* completeSetUserData(userData, saveData) {
  const normalizedData = normalize(userData, userSchema);
  if (saveData) {
    yield call(saveUserData, {user: normalizedData.entities.user});
  }
  yield put(resourceAdd(normalizedData.entities));
}

// Fetches cached/cookied user data
export function* loadUserData() {
  let user = localStorage.getItem('user');
  if (typeof user === 'string') user = JSON.parse(user);
  yield put(resourceAdd(user));
}

// Clears user data cookie and empties user store
export function* completeClearUserData() {
  yield put(removeCookie('user', {path: '/'}));
  localStorage.removeItem('user');
}

// Updates user data on server
export function* updateRequest(api, meta, data) {
  try {
    const response = yield call([api, api.put], 'rest-auth/user/', data);
    yield put(profileCompleteSetUserData(response, true));
    yield put(profileUpdateSuccess(meta.thunk));
  } catch (error) {
    const formError = {
      [FORM_ERROR]: error.non_field_errors ? error.non_field_errors[0] : JSON.stringify(error),
    };
    yield put(profileUpdateFailed(formError, meta.thunk));
  }
}

// Gets user data from server.  Currently unused, as userData is sent with successful login request
export function* readRequest(api, meta) {
  try {
    const response = yield call([api, api.get], 'rest-auth/user/');
    yield put(profileCompleteSetUserData(response, true));
    yield put(profileReadSuccess(meta.thunk));
  } catch (error) {
    yield put(profileReadFailed(error, meta.thunk));
  }
}

export function* generateApiKeyRequest(api, meta) {
  try {
    const response = yield call([api, api.post], 'generate-api-key/');
    yield put(apiKeyGenerateSuccess(meta.thunk));
    yield put(profileCompleteSetUserData(response, true));
  } catch (error) {
    yield put(apiKeyGenerateFailed(error, meta.thunk));
  }
}

// Watchers
export function* watchProfileUpdateRequest(api) {
  while (true) {
    const {meta, payload} = yield take(PROFILE_UPDATE_REQUEST);
    yield call(updateRequest, api, meta, payload);
  }
}

export function* watchProfileReadRequest(api) {
  while (true) {
    const {meta} = yield take(PROFILE_READ_REQUEST);
    yield call(readRequest, api, meta);
  }
}

export function* watchProfileCompleteSetUserData() {
  while (true) {
    const {payload} = yield take(PROFILE_COMPLETE_SET_USER_DATA);
    yield call(completeSetUserData, payload.userData, payload.saveData);
  }
}

export function* watchProfileLoadUserData() {
  while (true) {
    yield take(PROFILE_LOAD_USER_DATA);
    yield call(loadUserData);
  }
}

export function* watchProfileCompleteClearUserData() {
  while (true) {
    yield take(PROFILE_COMPLETE_CLEAR_USER_DATA);
    yield call(completeClearUserData);
  }
}

export function* watchGenerateApiKeyRequest(api) {
  while (true) {
    const {meta} = yield take(API_KEY_GENERATE_REQUEST);
    yield call(generateApiKeyRequest, api, meta);
  }
}

export default function* sagas({api}) {
  yield fork(watchProfileReadRequest, api);
  yield fork(watchProfileUpdateRequest, api);
  yield fork(watchProfileCompleteSetUserData);
  yield fork(watchProfileLoadUserData);
  yield fork(watchProfileCompleteClearUserData);
  yield fork(watchGenerateApiKeyRequest, api);
}
