import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {parse} from 'query-string';
import {isPending, hasFailed} from 'redux-saga-thunk';

import {discordConnectRequest} from 'store/actions';
import SocialCallback from '../SocialCallback';

const DiscordCallbackPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {code} = parse(location.search);
  const loading = useSelector((state) => isPending(state, 'discordConnect'));
  const failed = useSelector((state) => hasFailed(state, 'discordConnect'));

  const props = {
    sendCallbackData: () => dispatch(discordConnectRequest(code, DISCORD_REDIRECT_URL)),
    loading,
    failed,
  };
  return <SocialCallback {...props} />;
};

export default DiscordCallbackPage;
