import PropTypes from 'prop-types';

const ToggleButton = ({handleClick, children, selectedIndex, keys}) => {
  const getButton = (child, index, keys) => (
    <button
      onClick={handleClick}
      key={(keys && keys[index]) || (child.type && child.type.name)}
      className={selectedIndex - index ? 'selected' : ''}
    >
      {child}
    </button>
  );

  return (
    <div
      className={children ? 'toggleButton' : 'toggleButton binaryToggle'}
      onClick={!children ? handleClick : () => {}}
      role="button"
      tabIndex="-1"
    >
      {children && children.length > 1
        ? children.map((child, index) => getButton(child, index, keys))
        : getButton('', 1)}
    </div>
  );
};

ToggleButton.propTypes = {
  handleClick: PropTypes.func,
  children: PropTypes.any,
  selectedIndex: PropTypes.bool,
  keys: PropTypes.arrayOf(PropTypes.string),
};

export default ToggleButton;
