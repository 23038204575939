import {useState} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

import Button from 'components/molecules/Button';
import LabelSelect from 'components/components/LabelSelect';

const BroadcasterControlPanel = ({triggerVideo, videoComponents, versions, loading}) => {
  const versionsByKey = {};
  versions.forEach((v) => {
    versionsByKey[v.id] = v;
  });
  const [selectedVideo, setSelectedVideo] = useState(
    videoComponents.length
      ? videoComponents.sort((a, b) =>
          versionsByKey[b.versions[0]].created > versionsByKey[a.versions[0]].created ? 1 : -1,
        )[0].id
      : null,
  );

  return (
    <div className="broadcasterControlPanel">
      <h3>Browsersource Control Panel</h3>
      <div className="options">
        <div className={loading ? 'is-loading' : ''}>
          {videoComponents.length > 0 && (
            <>
              <LabelSelect handleChange={setSelectedVideo} label="available videos">
                {videoComponents
                  .sort((a, b) =>
                    versionsByKey[b.versions[0]].created > versionsByKey[a.versions[0]].created
                      ? 1
                      : -1,
                  )
                  .map((component) => (
                    <option key={component.id} value={component.id}>
                      {component.name}
                    </option>
                  ))}
              </LabelSelect>
              <Button disabled={!selectedVideo} handleClick={() => triggerVideo(selectedVideo)}>
                Play On-Stream Video
              </Button>
            </>
          )}
          <NavLink to="/stream-preview">
            <Button>Adjust live graphic positions</Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

BroadcasterControlPanel.propTypes = {
  triggerVideo: PropTypes.func,
  videoComponents: PropTypes.arrayOf(PropTypes.object),
  versions: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

export default BroadcasterControlPanel;
