import {Redirect} from 'react-router-dom';

import PropTypes from 'prop-types';
import UserRoute from './UserRoute';

import usePermissions from 'hooks/usePermissions';

const CampaignManageRoute = ({component, ...rest}) => {
  const {canEditCampaigns} = usePermissions();

  if (canEditCampaigns) return <UserRoute {...rest} ManagerComponent={component} />;

  return <Redirect to={{pathname: '/dashboard'}} />;
};

CampaignManageRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default CampaignManageRoute;
