import {useState} from 'react';
import PropTypes from 'prop-types';
import {Filter} from 'mdi-material-ui';

import {formatNumber} from 'utils/numberFormats';
import {CSSTransitionGroup} from 'react-transition-group';
import CampaignMetricCard from 'components/organisms/CampaignMetricCard';
import lookup from 'country-code-lookup';

const GameMetricsTable = ({loading, isFiltered, headers, metrics, expandableSectionData}) => {
  const [sortByIndex, setSortByIndex] = useState(1);
  const [openExpandableSections, setOpenExpandableSections] = useState([]);

  const toggleOpen = (index) => {
    if (openExpandableSections.includes(index)) {
      setOpenExpandableSections(openExpandableSections.filter((i) => i !== index));
    } else {
      setOpenExpandableSections([...openExpandableSections, index]);
    }
  };

  const hasExpandableSectionData = (game) =>
    expandableSectionData['Top Countries'][game] || expandableSectionData['Top Contributors'][game];

  const sortMetrics = (a, b) => ((b.data[sortByIndex] || 0) > (a.data[sortByIndex] || 0) ? 1 : -1);

  return (
    <div
      className={loading ? 'campaignMetric MetricsTable is-loading' : 'campaignMetric MetricsTable'}
    >
      <div className="topBar metricsHeaders">
        <CSSTransitionGroup
          transitionName="fade"
          transitionEnterTimeout={250}
          transitionLeaveTimeout={0}
        >
          {isFiltered && <Filter />}
        </CSSTransitionGroup>
        {headers.map((header, index) => (
          <div
            key={header}
            role="button"
            tabIndex={-1}
            onClick={() => index !== sortByIndex && setSortByIndex(index)}
            className={index === sortByIndex ? 'sortingBy' : ''}
          >
            <h2>{header}</h2>
          </div>
        ))}
      </div>
      <div className="content">
        {!loading &&
          metrics &&
          metrics.sort(sortMetrics).map((metricsRow, index) => (
            <div className="metricRow statRow" key={metricsRow.game}>
              <div
                className={`rowValues ${
                  hasExpandableSectionData(metricsRow.game) ? 'clickable' : ''
                }`}
                role="button"
                tabIndex={-1}
                onClick={() => hasExpandableSectionData(metricsRow.game) && toggleOpen(index)}
              >
                {metricsRow.data.map((metricColumn, index) => (
                  <p key={`${metricsRow.game}${headers[index]}`} className="metricsColumn">
                    {formatNumber(metricColumn)}
                  </p>
                ))}
              </div>
              <CSSTransitionGroup
                transitionName="slideMetrics"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}
              >
                {openExpandableSections.includes(index) && (
                  <div className="expandableSection">
                    {expandableSectionData['Top Countries'][metricsRow.game] && (
                      <CampaignMetricCard
                        metrics={Object.entries(
                          expandableSectionData['Top Countries'][metricsRow.game],
                        )
                          .sort((a, b) => b[1] - a[1])
                          .slice(0, 5)
                          .map((item) => ({
                            label:
                              item[0] && item[0] !== 'null' && lookup.byIso(item[0])
                                ? lookup.byIso(item[0]).country
                                : 'Country Not Found',
                            value: item[1],
                          }))}
                        title="Top Countries by Clicks"
                        withPercentage={metricsRow.data[3]}
                        hasTopBar
                      />
                    )}
                    {expandableSectionData['Top Contributors'][metricsRow.game] && (
                      <CampaignMetricCard
                        metrics={[...expandableSectionData['Top Contributors'][metricsRow.game]]
                          .map((item) => ({
                            label: item.name,
                            value: item.viewable_minutes,
                          }))
                          .sort((a, b) => b.value - a.value)}
                        title="Top Contributors by Viewable Minutes"
                        hasTopBar
                      />
                    )}
                  </div>
                )}
              </CSSTransitionGroup>
            </div>
          ))}
      </div>
    </div>
  );
};

GameMetricsTable.propTypes = {
  loading: PropTypes.bool,
  isFiltered: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.string),
  metrics: PropTypes.arrayOf(PropTypes.object),
  expandableSectionData: PropTypes.object,
};

export default GameMetricsTable;
