import PropTypes from 'prop-types';

const BroadcasterList = ({label, children, type, loading}) => (
  <table className={`table is-hoverable broadcaster-table ${type} ${loading ? 'is-loading' : ''}`}>
    <thead>
      <tr>
        <th colSpan="3">{label}</th>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </table>
);

BroadcasterList.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
};

export default BroadcasterList;
