import {useState} from 'react';
import PropTypes from 'prop-types';
import Button from 'components/molecules/Button';
import {formatNumber} from 'utils/numberFormats';
import {mergeBroadcasterViewership} from 'utils/campaignTools';

const BroadcasterCampaignTotals = ({campaigns, clicks, countryTable}) => {
  const [hasBreakdown, toggleBreakdown] = useState(false);

  const campaignTotals = campaigns.reduce(
    (a, b) => {
      const mergedViewership = mergeBroadcasterViewership(b);
      return {
        hours: a.hours + mergedViewership[0].value,
        avg: a.avg + mergedViewership[1].value,
        vm: a.vm + mergedViewership[2].value,
      };
    },
    {
      hours: 0,
      avg: 0,
      vm: 0,
    },
  );

  const campaignStats = [
    {
      label: 'Average Viewers: ',
      value: campaignTotals.avg / campaigns.length,
    },
    {
      label: 'Total Viewable Minutes: ',
      value: campaignTotals.vm,
    },
    {
      label: 'Total Clicks Generated: ',
      value: clicks ? clicks.length : 0,
    },
  ];

  return (
    <div className="broadcasterCampaignTotals">
      {campaignStats.map((stat) => (
        <div className="campaignTotal" key={stat.label}>
          <h4>{stat.label}</h4>
          <h4 className="highlight">{formatNumber(stat.value)}</h4>
        </div>
      ))}
      {clicks.length > 0 && countryTable[0].key && (
        <div className={`campaignTotal ${hasBreakdown ? 'expanded' : ''}`}>
          <h4>Most Clicks by Country: </h4>
          <h4 className="highlight">{countryTable[0].key}</h4>
          <Button
            type={hasBreakdown ? 'isNotPressed' : 'isPressed'}
            handleClick={() => toggleBreakdown(!hasBreakdown)}
          >
            {hasBreakdown ? 'Hide all' : 'See all'}
          </Button>
        </div>
      )}
      {clicks.length > 0 && countryTable[0].key && (
        <div className={`totalsTable ${hasBreakdown ? 'expanded' : ''}`}>
          {countryTable.map((country) => (
            <div key={country.label}>
              <span
                className={`percentageBar is-${formatNumber(
                  (country.value / clicks.length) * 100,
                )}`}
              />
              <h4>{country.label}</h4>
              <h4>{formatNumber(country.value)}</h4>
              <h4>{`${formatNumber((country.value / clicks.length) * 100)}%`}</h4>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

BroadcasterCampaignTotals.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object),
  clicks: PropTypes.arrayOf(PropTypes.object),
  countryTable: PropTypes.arrayOf(PropTypes.object),
};

export default BroadcasterCampaignTotals;
