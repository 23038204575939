import {useMemo} from 'react';

const useCampaignBroadcasterCompliance = ({
  broadcasterCompliance,
  actions,
  selectedComponent,
  selectedType,
}) => {
  const componentCompliance = useMemo(
    () =>
      selectedComponent
        ? broadcasterCompliance.reduce((acc, broadcasterInfo) => {
            if (broadcasterInfo.compliance[selectedComponent.id]) {
              acc.push({
                ...broadcasterInfo.componentsCompliance[selectedComponent.id],
                broadcaster: broadcasterInfo.name,
                profile_image: broadcasterInfo.profile_image,
              });
            }
            return acc;
          }, [])
        : [],
    [broadcasterCompliance, selectedComponent],
  );
  let noncompliantActions = componentCompliance.filter((a) => !a.compliant && a.required);
  let compliantActions = componentCompliance.filter((a) => a.compliant && a.required);

  // for tweet compliance, look at broadcaster actions
  if (selectedType === 'suggested-tweet' || selectedType === 'suggested-retweet') {
    noncompliantActions = selectedComponent
      ? actions.filter((a) => a.component === selectedComponent.name && !a.completion_time)
      : [];
    compliantActions = selectedComponent
      ? actions.filter((a) => a.component === selectedComponent.name && a.completion_time)
      : [];
  }

  return {
    noncompliantActions,
    compliantActions,
  };
};

export default useCampaignBroadcasterCompliance;
