import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {timeFormat} from 'd3';

import DownloadIcon from 'components/components/DownloadIcon';
import DeleteButton from 'components/components/DeleteButton';
import AdvWarning from 'components/components/AdvWarning';
import DeleteModal from 'components/organisms/DeleteModal';
import {fromResources} from 'store/selectors';
import {modalShow, resourceListReadRequest} from 'store/actions';
import {formatDate} from 'utils/numberFormats';

const fullFormat = timeFormat('%I:%M%p %x');

const ReportList = ({campaign}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resourceListReadRequest('reports'));
  }, [dispatch]);

  const reportList = useSelector((state) => fromResources.getResourceList(state, 'reports'));

  const reportListFormatted = reportList
    .filter((r) => r.campaigns.includes(campaign.slug))
    .sort((a, b) => ((b.created || 'd') > (a.created || 'd') ? 1 : -1))
    .map((report) => ({
      ...report,
      report_start: new Date(report.report_start),
      report_end: new Date(report.report_end),
      created: new Date(report.created),
    }));

  return (
    <div className="reportListCard">
      <div className="topBar">
        <h2>Reports</h2>
      </div>
      <ul className="reportList">
        {!reportListFormatted.length && (
          <h3 className="emptyText">You have not created any reports yet</h3>
        )}
        {reportListFormatted.map((report, index) => (
          <li
            className={index % 2 === 0 ? 'reportListItem even' : 'reportListItem'}
            key={report.id}
          >
            <div className="content">
              <h4>
                <strong>{report.name}</strong>
              </h4>
              <h4>
                {report.report
                  ? `(${report.type} - Created ${formatDate(report.created)})`
                  : '(Generating Report)'}
              </h4>
            </div>
            <span className="reportListItemButtons">
              {report.failed && <AdvWarning>failed to generate</AdvWarning>}
              {report.report && <DownloadIcon src={report.report} clickHandler={() => {}} />}
              <DeleteButton handleClick={() => dispatch(modalShow(`report-${report.id}-delete`))} />
            </span>
          </li>
        ))}
      </ul>

      {reportListFormatted.map((report) => (
        <DeleteModal
          modalName={`report-${report.id}-delete`}
          resource="reports"
          objectType="report"
          objectName={`generated ${fullFormat(report.created)}`}
          objectNeedle={report.id}
          key={report.id}
        />
      ))}
    </div>
  );
};

ReportList.propTypes = {
  campaign: PropTypes.object,
  reportList: PropTypes.arrayOf(PropTypes.object),
  showDeleteReportModal: PropTypes.func,
  fullFormat: PropTypes.func,
};

export default ReportList;
