export const MANAGEMENT_SET_STATUS_REQUEST = 'MANAGEMENT_SET_STATUS_REQUEST';
export const MANAGEMENT_SET_STATUS_FAILURE = 'MANAGEMENT_SET_STATUS_FAILURE';
export const MANAGEMENT_SET_STATUS_SUCCESS = 'MANAGEMENT_SET_STATUS_SUCCESS';

export const managementSetStatusRequest = (
  resource,
  status,
  broadcaster,
  needle,
  fromBroadcaster,
) => ({
  type: MANAGEMENT_SET_STATUS_REQUEST,
  payload: {
    data: {broadcaster},
    needle,
    resource,
    fromBroadcaster,
  },
  meta: {status, thunk: `statusUpdate${broadcaster}`},
});

export const managementSetStatusFailure = (error, thunk) => ({
  type: MANAGEMENT_SET_STATUS_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

export const managementSetStatusSuccess = (thunk) => ({
  type: MANAGEMENT_SET_STATUS_SUCCESS,
  meta: {thunk},
});

export const MANAGEMENT_GET_STATUSES_REQUEST = 'MANAGEMENT_GET_STATUSES_REQUEST';
export const MANAGEMENT_GET_STATUSES_FAILURE = 'MANAGEMENT_GET_STATUSES_FAILURE';

export const managementGetStatusesRequest = (resource, needle) => ({
  type: MANAGEMENT_GET_STATUSES_REQUEST,
  payload: {
    needle,
    resource,
  },
  meta: {thunk: `getStatuses${needle}`},
});

export const managementGetStatusesFailure = (error, thunk) => ({
  type: MANAGEMENT_GET_STATUSES_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

export const MANAGEMENT_INVITE_LINK_REQUEST = 'MANAGEMENT_INVITE_LINK_REQUEST';
export const MANAGEMENT_INVITE_LINK_FAILURE = 'MANAGEMENT_INVITE_LINK_FAILURE';

export const managementInviteLinkRequest = (resource, needle) => ({
  type: MANAGEMENT_INVITE_LINK_REQUEST,
  payload: {resource, needle},
  meta: {thunk: `${resource}InviteLink${needle}`},
});

export const managementInviteLinkFailure = (error, thunk) => ({
  type: MANAGEMENT_INVITE_LINK_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

export const MANAGEMENT_SET_CHATBOT_REQUEST = 'MANAGEMENT_SET_CHATBOT_REQUEST';
export const MANAGEMENT_SET_CHATBOT_FAILURE = 'MANAGEMENT_SET_CHATBOT_FAILURE';

export const managementSetChatbotRequest = (code, campaign) => ({
  type: MANAGEMENT_SET_CHATBOT_REQUEST,
  payload: {code, campaign},
  meta: {campaign, thunk: 'setCampaignChatbot'},
});

export const managementSetChatbotFailure = (error, thunk) => ({
  type: MANAGEMENT_SET_CHATBOT_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

export const MANAGEMENT_SET_TEAM_CHATBOT_REQUEST = 'MANAGEMENT_SET_TEAM_CHATBOT_REQUEST';
export const MANAGEMENT_SET_TEAM_CHATBOT_FAILURE = 'MANAGEMENT_SET_TEAM_CHATBOT_FAILURE';

export const managementSetTeamChatbotRequest = (code, team) => ({
  type: MANAGEMENT_SET_TEAM_CHATBOT_REQUEST,
  payload: {code, team},
  meta: {team, thunk: 'setTeamChatbot'},
});

export const managementSetTeamChatbotFailure = (error, thunk) => ({
  type: MANAGEMENT_SET_TEAM_CHATBOT_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

export const MANAGEMENT_SET_APPROVED_CHATBOT_REQUEST = 'MANAGEMENT_SET_APPROVED_CHATBOT_REQUEST';
export const MANAGEMENT_SET_APPROVED_CHATBOT_FAILURE = 'MANAGEMENT_SET_APPROVED_CHATBOT_FAILURE';

export const managementSetApprovedChatbotRequest = (chatbot, campaign) => ({
  type: MANAGEMENT_SET_APPROVED_CHATBOT_REQUEST,
  payload: {chatbot, campaign},
  meta: {campaign, thunk: 'setApprovedChatbot'},
});

export const managementSetApprovedChatbotFailure = (error, thunk) => ({
  type: MANAGEMENT_SET_APPROVED_CHATBOT_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

export const MANAGEMENT_SET_TEAM_APPROVED_CHATBOT_REQUEST =
  'MANAGEMENT_SET_TEAM_APPROVED_CHATBOT_REQUEST';
export const MANAGEMENT_SET_TEAM_APPROVED_CHATBOT_FAILURE =
  'MANAGEMENT_SET_TEAM_APPROVED_CHATBOT_FAILURE';

export const managementSetTeamApprovedChatbotRequest = (chatbot, team) => ({
  type: MANAGEMENT_SET_TEAM_APPROVED_CHATBOT_REQUEST,
  payload: {chatbot, team},
  meta: {team, thunk: 'setApprovedChatbot'},
});

export const managementSetTeamApprovedChatbotFailure = (error, thunk) => ({
  type: MANAGEMENT_SET_TEAM_APPROVED_CHATBOT_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

export const MANAGEMENT_SET_TEAM_MEMBER_SQUAD_REQUEST = 'MANAGEMENT_SET_TEAM_MEMBER_SQUAD_REQUEST';
export const MANAGEMENT_SET_TEAM_MEMBER_SQUAD_FAILURE = 'MANAGEMENT_SET_TEAM_MEMBER_SQUAD_FAILURE';

export const managementSetTeamMemberSquadRequest = (squad, broadcaster) => ({
  type: MANAGEMENT_SET_TEAM_MEMBER_SQUAD_REQUEST,
  payload: {squad, broadcaster},
  meta: {thunk: `squad${squad}Update${broadcaster}`},
});

export const managementSetTeamMemberSquadFailure = (error, thunk) => ({
  type: MANAGEMENT_SET_TEAM_MEMBER_SQUAD_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

export const MANAGEMENT_REMOVE_TEAM_MEMBER_SQUAD_REQUEST =
  'MANAGEMENT_REMOVE_TEAM_MEMBER_SQUAD_REQUEST';
export const MANAGEMENT_REMOVE_TEAM_MEMBER_SQUAD_FAILURE =
  'MANAGEMENT_REMOVE_TEAM_MEMBER_SQUAD_FAILURE';

export const managementRemoveTeamMemberSquadRequest = (squad, broadcaster) => ({
  type: MANAGEMENT_REMOVE_TEAM_MEMBER_SQUAD_REQUEST,
  payload: {squad, broadcaster},
  meta: {thunk: `squad${squad}Remove${broadcaster}`},
});

export const managementRemoveTeamMemberSquadFailure = (error, thunk) => ({
  type: MANAGEMENT_REMOVE_TEAM_MEMBER_SQUAD_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

export const MANAGEMENT_SET_CAMPAIGN_SQUAD_REQUEST = 'MANAGEMENT_SET_CAMPAIGN_SQUAD_REQUEST';
export const MANAGEMENT_SET_CAMPAIGN_SQUAD_FAILURE = 'MANAGEMENT_SET_CAMPAIGN_SQUAD_FAILURE';

export const managementSetCampaignSquadRequest = (squad, campaign) => ({
  type: MANAGEMENT_SET_CAMPAIGN_SQUAD_REQUEST,
  payload: {squad, campaign},
  meta: {
    thunkA: `squad${squad}BroadcasterUpdate${campaign}`,
    thunkB: `squad${squad}CampaignUpdate${campaign}`,
  },
});

export const managementSetCampaignSquadFailure = (error, thunk) => ({
  type: MANAGEMENT_SET_CAMPAIGN_SQUAD_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

export const MANAGEMENT_REMOVE_CAMPAIGN_SQUAD_REQUEST = 'MANAGEMENT_REMOVE_CAMPAIGN_SQUAD_REQUEST';
export const MANAGEMENT_REMOVE_CAMPAIGN_SQUAD_FAILURE = 'MANAGEMENT_REMOVE_CAMPAIGN_SQUAD_FAILURE';

export const managementRemoveCampaignSquadRequest = (squad, campaign) => ({
  type: MANAGEMENT_REMOVE_CAMPAIGN_SQUAD_REQUEST,
  payload: {squad, campaign},
  meta: {thunk: `squad${squad}Remove${campaign}`},
});

export const managementRemoveCampaignSquadFailure = (error, thunk) => ({
  type: MANAGEMENT_REMOVE_CAMPAIGN_SQUAD_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});
