import PropTypes from 'prop-types';
import {INVITED} from 'store/management/campaignStatuses';

import BroadcasterList from 'components/organisms/BroadcasterList';
import BroadcasterListRow from 'components/organisms/BroadcasterListRow';
import {AdvModal} from 'containers';

const InviteListModal = ({identifier, needle, broadcasters}) => {
  const statusIdentifier = `${identifier}_statuses`;

  const isInvited = (broadcaster) => broadcaster[statusIdentifier][needle]?.status === INVITED;

  // Sort invitations first by Open/Closed, and within that by username order
  const broadcasterSort = (a, b) => {
    if (isInvited(a) === isInvited(b)) {
      return a.username.localeCompare(b.username);
    }
    if (isInvited(a)) {
      return -1;
    }
    return 1;
  };

  const sortedBroadcasters = broadcasters.sort(broadcasterSort);

  return (
    <AdvModal name={`sent-invites-${needle}`} contentLabel="See Sent Invites">
      <label htmlFor="invitations">Active Invitations</label>
      <BroadcasterList type="invite-list">
        {sortedBroadcasters.map((broadcaster) => (
          <BroadcasterListRow
            key={broadcaster.username}
            broadcaster={broadcaster}
            hourTimeStamp={broadcaster[statusIdentifier][needle]?.invited_at}
          >
            {isInvited(broadcaster) ? (
              <span className="list-open">Open</span>
            ) : (
              <span className="list-closed">Closed</span>
            )}
          </BroadcasterListRow>
        ))}
      </BroadcasterList>
    </AdvModal>
  );
};

InviteListModal.propTypes = {
  broadcasters: PropTypes.arrayOf(PropTypes.object),
  needle: PropTypes.string,
  identifier: PropTypes.oneOf(['team', 'campaign']),
};

export default InviteListModal;
