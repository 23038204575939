export const COMPLIANCE_AUDIT_REQUEST = 'COMPLIANCE_AUDIT_REQUEST';
export const COMPLIANCE_AUDIT_SUCCESS = 'COMPLIANCE_AUDIT_SUCCESS';
export const COMPLIANCE_AUDIT_FAILURE = 'COMPLIANCE_AUDIT_FAILURE';

export const complianceAuditRequest = (payload) => ({
  type: COMPLIANCE_AUDIT_REQUEST,
  payload,
  meta: {thunk: 'complianceAuditTrigger'},
});

export const complianceAuditSuccess = (thunk) => ({
  type: COMPLIANCE_AUDIT_SUCCESS,
  meta: {thunk},
});

export const complianceAuditFailure = (error, thunk) => ({
  type: COMPLIANCE_AUDIT_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});

export const COMPLIANCE_OVERRIDE_REQUEST = 'COMPLIANCE_OVERRIDE_REQUEST';
export const COMPLIANCE_OVERRIDE_SUCCESS = 'COMPLIANCE_OVERRIDE_SUCCESS';
export const COMPLIANCE_OVERRIDE_FAILURE = 'COMPLIANCE_OVERRIDE_FAILURE';

export const complianceOverrideRequest = (broadcaster, action, versionId, existingCompliance) => ({
  type: COMPLIANCE_OVERRIDE_REQUEST,
  payload: {
    broadcaster,
    action,
    component_version_id: versionId,
  },
  meta: {thunk: 'complianceOverrideRequest', existingCompliance, broadcaster},
});

export const complianceOverrideSuccess = (thunk) => ({
  type: COMPLIANCE_OVERRIDE_SUCCESS,
  meta: {thunk},
});

export const complianceOverrideFailure = (error, thunk) => ({
  type: COMPLIANCE_OVERRIDE_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});
