const IconStream = () => (
  <svg version="1.1" viewBox="0 0 612 547">
    <circle display="none" fill="#D1D3D4" cx="304" cy="284.272" r="161" />
    <g>
      <path
        d="M512.328,68.925c6.244,0,11.308,5.374,11.31,12.005l0.145,388.316c0.003,6.633-5.06,12.013-11.305,12.015l-403.381,0.15
      c0,0-0.002,0-0.003,0c-6.245,0-11.308-5.375-11.311-12.007L97.638,81.089c-0.002-6.633,5.06-12.012,11.306-12.014l403.38-0.149
      C512.325,68.925,512.327,68.925,512.328,68.925 M512.329,33.925h-0.005l-403.394,0.149c-25.536,0.01-46.302,21.106-46.292,47.027
      l0.145,388.316c0.009,25.913,20.785,46.994,46.311,46.994l403.397-0.15c12.583-0.004,24.784-5.303,33.473-14.536
      c8.272-8.792,12.824-20.331,12.819-32.494l-0.145-388.314C558.63,55.007,537.856,33.925,512.329,33.925L512.329,33.925z"
      />
    </g>
    <g>
      <path
        d="M310.71,249.303c14.263,0,25.866,11.604,25.866,25.867c0,14.262-11.604,25.866-25.866,25.866s-25.866-11.604-25.866-25.866
      C284.844,260.907,296.447,249.303,310.71,249.303 M310.71,214.303c-33.615,0-60.866,27.25-60.866,60.867
      c0,33.616,27.251,60.866,60.866,60.866s60.866-27.25,60.866-60.866C371.576,241.553,344.325,214.303,310.71,214.303L310.71,214.303
      z"
      />
    </g>
    <g>
      <path
        d="M395.209,159.478c6.909,0,13.404,2.691,18.29,7.576c10.086,10.085,10.085,26.496,0,36.581
      c-1.331,1.331-2.837,2.534-4.479,3.575c-13.459,8.542-19.445,25.099-14.563,40.273c10.002,31.078,1.891,64.708-21.168,87.767
      c-16.339,16.338-38.062,25.336-61.166,25.336c-8.364,0-16.655-1.198-24.643-3.561c-3.293-0.975-6.633-1.442-9.928-1.442
      c-12.855,0-25.033,7.116-31.145,19.039c-1.229,2.396-2.821,4.582-4.735,6.495c-4.886,4.886-11.381,7.576-18.291,7.576
      s-13.405-2.69-18.291-7.576c-4.885-4.885-7.576-11.381-7.576-18.29c0-6.908,2.69-13.404,7.577-18.291
      c1.913-1.913,4.098-3.506,6.495-4.734c14.978-7.679,22.37-24.932,17.596-41.072c-9.015-30.482-0.671-63.362,21.775-85.809
      c16.335-16.335,38.053-25.331,61.154-25.33c7.132,0,14.242,0.876,21.136,2.602c2.832,0.709,5.686,1.052,8.506,1.052
      c12.584,0,24.492-6.815,30.727-18.253c1.193-2.189,2.687-4.188,4.437-5.938C381.805,162.168,388.3,159.478,395.209,159.478
      M395.209,124.478c-15.577,0-31.154,5.942-43.039,17.828c-4.23,4.231-7.693,8.936-10.419,13.936
      c-9.715-2.433-19.679-3.649-29.636-3.65c-31.093-0.001-62.181,11.857-85.904,35.581c-32.616,32.615-42.811,79.159-30.589,120.483
      c-5.502,2.821-10.67,6.525-15.278,11.133c-23.77,23.77-23.77,62.308,0,86.077c11.885,11.885,27.462,17.827,43.039,17.827
      c15.577,0,31.154-5.942,43.039-17.827c4.608-4.607,8.312-9.775,11.133-15.278c11.264,3.332,22.918,4.998,34.57,4.998
      c31.095,0,62.188-11.862,85.914-35.587c33.342-33.342,43.253-81.241,29.737-123.238c3.713-2.356,7.233-5.138,10.473-8.377
      c23.771-23.771,23.77-62.309,0-86.078C426.363,130.42,410.786,124.478,395.209,124.478L395.209,124.478z"
      />
    </g>
  </svg>
);

export default IconStream;
