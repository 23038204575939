import PropTypes from 'prop-types';

const AdvWarning = ({children, offsetError}) => (
  <div className={`formDanger ${offsetError ? 'offsetError' : ''}`}>
    <h3>{children}</h3>
  </div>
);

AdvWarning.propTypes = {
  children: PropTypes.any,
  offsetError: PropTypes.bool,
};

export default AdvWarning;
