import {useState} from 'react';
import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';
import LoadingBar from 'components/molecules/LoadingBar';
import AdvModal from 'containers/AdvModal';

import {Help} from 'mdi-material-ui';
import useModal from 'hooks/useModal';

const HelpModal = ({modalName, contentLabel, content, imgsrc, videosrc, type}) => {
  const [modalShow] = useModal();
  const [videoLoaded, setVideoLoaded] = useState(false);
  return (
    <span className="advTooltip helpModal">
      <Help onClick={() => modalShow(modalName)} />
      <AdvModal name={modalName} contentLabel={contentLabel}>
        <label htmlFor={contentLabel}>{contentLabel}</label>
        <div className={`instructions ${videoLoaded ? '' : 'videoLoading'} ${type}`}>
          <ReactMarkdown source={content} />
          <img src={imgsrc} alt="" />
          {videosrc && (
            <video
              onLoadStart={() => setVideoLoaded(false)}
              onCanPlayThrough={() => setVideoLoaded(true)}
              src={videosrc}
              alt=""
              muted
              autoPlay
              loop
            />
          )}
          {videosrc && !videoLoaded && <LoadingBar />}
        </div>
      </AdvModal>
    </span>
  );
};

HelpModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  contentLabel: PropTypes.string.isRequired,
  content: PropTypes.string,
  imgsrc: PropTypes.string,
  videosrc: PropTypes.string,
  type: PropTypes.string,
};

export default HelpModal;
