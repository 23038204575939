import {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {useDispatch} from 'react-redux';

import {required, email, useValidator} from 'services/validation';
import {useTeams} from 'hooks';
import {resourceUpdateRequest} from 'store/actions';
import AdvInput from 'components/molecules/AdvInput';
import Button from 'components/components/Button';
import MultiSelect from 'components/molecules/MultiSelect';
import PermissionsField from '../PermissionsField';

const ManagerForm = ({manager, onSubmitSuccess}) => {
  const dispatch = useDispatch();
  const teams = useTeams();
  const selectedTeams = teams.filter((team) => manager?.teams?.includes(team.id));

  const validate = useValidator({
    'user.email': [required, email],
  });

  const onSubmit = useCallback(
    async (data) => {
      const teamIds = data.teams.map((team) => team.id);
      const updatedData = {...data, teams: teamIds};

      try {
        await dispatch(resourceUpdateRequest('managers', manager.id, updatedData));
      } catch (error) {
        return error;
      }
    },
    [dispatch, manager],
  );

  return (
    <Form onSubmit={onSubmit} initialValues={manager} validate={validate}>
      {({handleSubmit, submitting, error, form}) => (
        <form
          onSubmit={(data) => {
            handleSubmit(data)?.then(() => {
              const {submitSucceeded} = form.getState();
              submitSucceeded && onSubmitSuccess();
            });
          }}
        >
          <Field name="user.email" placeholder="Email" component={AdvInput} />
          <Field
            name="teams"
            title="Teams"
            items={teams}
            component={MultiSelect}
            selectedItems={selectedTeams}
          />
          <Field
            name="user.permissions_campaigns"
            label="Campaigns"
            permissionsList={['readWrite', 'readOnly']}
            component={PermissionsField}
          />
          <Field
            name="user.permissions_teams"
            label="Teams"
            permissionsList={['readWrite', 'readOnly']}
            component={PermissionsField}
          />
          <Field
            name="user.permissions_users"
            label="Users"
            permissionsList={['readWrite', 'readOnly', 'noAccess']}
            component={PermissionsField}
          />
          <Button loading={!!submitting}>Update User</Button>
          {error && <div className="help is-danger centered">{error}</div>}
        </form>
      )}
    </Form>
  );
};

ManagerForm.propTypes = {
  manager: PropTypes.object.isRequired,
  onSubmitSuccess: PropTypes.func,
};

export default ManagerForm;
