import {useState, useCallback, useEffect} from 'react';
import {CSSTransitionGroup} from 'react-transition-group';
import {
  CheckboxMarked,
  PencilBox,
  MinusBox,
  ViewGrid,
  ViewSequential,
  HelpBox,
} from 'mdi-material-ui';

import Button from 'components/molecules/Button';
import EmptySplash from 'components/molecules/EmptySplash';
import LabelSelect from 'components/components/LabelSelect';
import TextFilter from 'components/components/TextFilter';
import LoadingBar from 'components/molecules/LoadingBar';
import ToggleButton from 'components/components/ToggleButton';
import AdvCheckbox from 'components/atoms/AdvCheckbox';
import {AdvModal} from 'containers';
import CampaignNotFoundPage from '../../components/CampaignNotFoundPage';
import BroadcasterCompliance from './components/BroadcasterCompliance';
import ComponentCompliance from './components/ComponentCompliance';
import useCampaignCompliance from './hooks/useCampaignCompliance';
import useCampaignBroadcasterCompliance from './hooks/useCampaignBroadcasterCompliance';
import useCampaignComponentCompliance from './hooks/useCampaignComponentCompliance';
import useModal from 'hooks/useModal';
import CampaignTemplate from '../../components/CampaignTemplate';
import {isCampaignEnded} from 'utils/numberFormats';
import {isEmpty} from 'lodash';

const getIconOrStatus = (status, icon) => {
  switch (status) {
    case 'Compliant':
    case 'Manually Marked As Compliant':
      return icon ? <CheckboxMarked /> : 'compliant';
    // case 'Unable to Find Link':
    // return icon ? <LinkBox /> : 'wrongLink'
    case 'Incorrect Image':
      return icon ? <PencilBox /> : 'wrongImage';
    case "Can't Connect":
      return icon ? <HelpBox /> : 'cantConnect';
    default:
      return icon ? <MinusBox /> : 'incompliant';
  }
};

// broadcasters are sorted by overall compliance first, and username second
const sortBroadcasters = (a, b) => {
  if (
    (b.overallCompliance === 'compliant' && a.overallCompliance === 'compliant') ||
    (b.overallCompliance === 'noncompliant' && a.overallCompliance === 'noncompliant')
  ) {
    return b.name > a.name ? -1 : 1;
  }
  return a.overallCompliance === 'compliant' ? 1 : -1;
};

const CampaignCompliance = () => {
  const {
    campaign,
    readOnly,
    broadcasters,
    components,
    compliance,
    actions,
    userLoading,
    pageLoading,
    validComponents,
    versions,
    complianceAuditComplete,
    complianceAuditLoading,
    triggerComplianceAudit,
    componentsWithCompliance,
  } = useCampaignCompliance();

  const [modalShow] = useModal();
  const [selectedView, setSelectedView] = useState(false);
  const [expandedDetails, setExpandedDetails] = useState(true);
  const [broadcasterFilter, setBroadcasterFilter] = useState('');
  const [showBroadcasters, setShowBroadcasters] = useState('all');

  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedType, setSelectedType] = useState(selectedComponent?.type);
  const switchView = useCallback(() => {
    setSelectedView(!selectedView);
    setExpandedDetails(true);
    setBroadcasterFilter('');
    setShowBroadcasters('all');
  }, [selectedView]);

  useEffect(() => {
    setSelectedComponent(componentsWithCompliance.sort((a, b) => (b.type > a.type ? -1 : 1))[0]);
  }, [componentsWithCompliance]);

  const campaignIsEditable = !isCampaignEnded(campaign) && !readOnly;

  const {
    broadcasterCompliance,
    compliantBroadcasterCount,
    noncompliantBroadcasterCount,
    hasChatbotModCompliance,
    broadcasterActions,
  } = useCampaignBroadcasterCompliance({
    actions,
    components,
    compliance,
  });

  const {noncompliantActions, compliantActions} = useCampaignComponentCompliance({
    actions,
    broadcasterCompliance,
    selectedComponent,
    selectedType,
  });

  const getHref = useCallback(
    (broadcaster, action) => {
      if (!broadcaster) return null;
      switch (selectedView ? selectedType : action.type) {
        case 'suggested-tweet':
          return (action && action.completion_data && action.completion_data.tweet_url) || null;
        case 'suggested-retweet':
          return broadcaster.twitter ? `https://www.twitter.com/${broadcaster.twitter}` : null;
        case 'banner-graphics':
          return `https://www.twitch.tv/${broadcaster.name}`;
        default:
          return null;
      }
    },
    [selectedType, selectedView],
  );

  if (!userLoading && isEmpty(campaign)) return <CampaignNotFoundPage />;

  return (
    <CampaignTemplate>
      <div className="campaign-compliance-page">
        {!pageLoading && (
          <div className="campaignPageOptions preset_campaign-page-options">
            <span>
              <ToggleButton
                selectedIndex={!selectedView}
                handleClick={switchView}
                keys={['ViewSequential', 'ViewGrid']}
              >
                <span>
                  <ViewSequential />
                  <p>Broadcasters</p>
                </span>
                <span>
                  <ViewGrid />
                  <p>Components</p>
                </span>
              </ToggleButton>
              {campaignIsEditable && selectedComponent && (
                <Button
                  handleClick={() => modalShow(`trigger-compliance-audit-${selectedComponent.id}`)}
                >
                  Trigger Audit
                </Button>
              )}
            </span>
          </div>
        )}
        {!pageLoading && (
          <div className="campaignComplianceContent">
            {broadcasters.length > 0 && (
              <div className="campaignPageOptions preset_campaign-page-options inner-bar">
                <span>
                  <span>
                    <div className="icons">
                      <h4>Compliant</h4>
                      <CheckboxMarked />
                      {selectedType === 'banner-graphics' && <h4>Incorrect Image</h4>}
                      {selectedType === 'banner-graphics' && <PencilBox />}
                      {/* selectedType === 'banner-graphics' && <h4>Unable to find link</h4> */}
                      {/* selectedType === 'banner-graphics' && <LinkBox /> */}
                      <h4>Non-Compliant</h4>
                      <MinusBox />
                      {isCampaignEnded(campaign) ? (
                        <h4 className="note">* Final compliance shown from end of campaign</h4>
                      ) : (
                        <h4 className="note">
                          <b>{`${compliantBroadcasterCount}/${broadcasters.length}`}</b>{' '}
                          Broadcasters Compliant
                        </h4>
                      )}
                    </div>
                  </span>
                  <span>
                    {!selectedView && (
                      <AdvCheckbox
                        isChecked={!expandedDetails}
                        onCheck={() => setExpandedDetails(!expandedDetails)}
                        label="Hide details"
                      />
                    )}
                    {selectedView && selectedType && (
                      <LabelSelect
                        label="Component Type"
                        defaultValue={selectedType}
                        handleChange={(value) => {
                          setSelectedType(value);
                          setSelectedComponent(
                            components.find(
                              (c) =>
                                c.type === value &&
                                !c.is_deleted &&
                                validComponents.includes(c.name),
                            ),
                          );
                        }}
                      >
                        {components.filter((c) => c.type === 'banner-graphics').length > 0 && (
                          <option value="banner-graphics">Banner Graphics</option>
                        )}
                        {components.filter((c) => c.type === 'suggested-tweet').length > 0 && (
                          <option value="suggested-tweet">Sponsored Tweets</option>
                        )}
                        {components.filter((c) => c.type === 'suggested-retweet').length > 0 && (
                          <option value="suggested-retweet">Sponsored Retweets</option>
                        )}
                      </LabelSelect>
                    )}
                    {selectedView && selectedComponent && (
                      <LabelSelect
                        label="Component"
                        defaultValue={selectedComponent.id}
                        handleChange={(value) =>
                          setSelectedComponent(components.find((c) => c.id.toString() === value))
                        }
                      >
                        {components
                          .filter((c) => !c.is_deleted && validComponents.includes(c.name))
                          .map(
                            (component) =>
                              component.type === selectedType && (
                                <option key={component.id} value={component.id}>
                                  {component.name}
                                </option>
                              ),
                          )}
                      </LabelSelect>
                    )}
                    {!selectedView && (
                      <TextFilter
                        placeholder="Filter broadcaster"
                        handleChange={(e) => setBroadcasterFilter(e.target.value)}
                      />
                    )}
                    {!selectedView && (
                      <LabelSelect
                        label="Show"
                        handleChange={(value) => setShowBroadcasters(value)}
                      >
                        <option value="all">All</option>
                        {compliantBroadcasterCount > 0 && noncompliantBroadcasterCount > 0 && (
                          <option value="compliant">Compliant</option>
                        )}
                        {compliantBroadcasterCount > 0 && noncompliantBroadcasterCount > 0 && (
                          <option value="noncompliant">Non-Compliant</option>
                        )}
                      </LabelSelect>
                    )}
                  </span>
                </span>
              </div>
            )}
            {broadcasters.length > 0 ? (
              <div className="complianceContent">
                <div className={`complianceContainer ${selectedView ? '' : 'grid'}`}>
                  {selectedView && selectedComponent && (
                    <ComponentCompliance
                      compliantActions={compliantActions}
                      noncompliantActions={noncompliantActions}
                      selectedComponent={selectedComponent}
                      version={
                        selectedComponent &&
                        versions.find((v) => v.id === selectedComponent.versions[0])
                      }
                      showButtons={campaignIsEditable}
                      getIconOrStatus={getIconOrStatus}
                      modalShow={modalShow}
                    />
                  )}
                  {!selectedView &&
                    broadcasterCompliance
                      .filter((b) => {
                        return (
                          b.name?.toLowerCase().indexOf(broadcasterFilter.toLowerCase()) !== -1 &&
                          (showBroadcasters === 'all' || b.overallCompliance === showBroadcasters)
                        );
                      })
                      .sort(sortBroadcasters)
                      .map((broadcaster) => (
                        <BroadcasterCompliance
                          broadcasterCompliance={broadcaster}
                          hasChatbotModCompliance={hasChatbotModCompliance}
                          campaignSlug={campaign.slug}
                          components={components}
                          broadcasterActions={broadcasterActions}
                          versions={versions}
                          getHref={getHref}
                          showButtons={campaignIsEditable}
                          getIconOrStatus={getIconOrStatus}
                          modalShow={modalShow}
                          expandedDetails={expandedDetails}
                          key={broadcaster.name}
                        />
                      ))}
                </div>
              </div>
            ) : (
              <EmptySplash>
                <h2>There are no broadcasters on this campaign yet!</h2>
              </EmptySplash>
            )}
          </div>
        )}
        {pageLoading && <LoadingBar message="Loading Compliance" />}
      </div>

      {/* Trigger compliance audit modal */}
      {campaignIsEditable && (compliantActions.length > 0 || noncompliantActions.length > 0) && (
        <AdvModal
          name={`trigger-compliance-audit-${selectedComponent.id}`}
          contentLabel="Trigger Compliance Audit"
        >
          <label htmlFor="trigger compliance audit">
            Are you sure you want to trigger a compliance audit?
          </label>
          <div className="extraText" />
          <div className="extraText">
            Broadcasters are audited once daily at 7AM ET - If you would like compliance to be
            updated again, you may trigger an additional compliance audit. Results may take up to an
            hour to appear.
          </div>
          <CSSTransitionGroup
            transitionName="fade"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {complianceAuditComplete && <div className="warning">Audit Triggered</div>}
          </CSSTransitionGroup>
          {!complianceAuditComplete && (
            <Button
              loading={complianceAuditLoading}
              handleClick={() => {
                triggerComplianceAudit(selectedComponent.id);
              }}
            >
              Trigger Audit
            </Button>
          )}
        </AdvModal>
      )}
    </CampaignTemplate>
  );
};

export default CampaignCompliance;
