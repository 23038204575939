import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import {isObject} from 'lodash';

import BroadcasterParticipationForm from './components/BroadcasterParticipationForm';
import AutoSuggest from 'components/components/AutoSuggest/AutoSuggest';
import {managementSetStatusRequest} from 'store/actions';
import {required, useValidator} from 'services/validation';

const CampaignAddBroadcasterForm = (props) => {
  const {campaign, endpoint, broadcasters, extraAutoSuggestParams} = props;
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (data) => {
      const broadcasterUsername = isObject(data.broadcaster)
        ? data.broadcaster.username
        : data.broadcaster;
      // throw an error if the broadcaster is already on the campaign
      if (broadcasters.map((b) => b.username).includes(broadcasterUsername)) {
        const {status} = broadcasters.find((b) => b.username === broadcasterUsername)
          .campaign_statuses[campaign.slug];
        if (status === 'Invited') {
          return {[FORM_ERROR]: 'This broadcaster already has a pending invitation!'};
        }
        // we don't throw an exception if the status is declined since the manager is allowed to reinvite
        if (status !== 'Declined') {
          return {[FORM_ERROR]: 'This broadcaster is already on this campaign!'};
        }
      }
      if (isObject(data.broadcaster) || data.broadcaster) {
        try {
          return dispatch(
            // data is a  broadcaster object if selected from the autosuggest dropdown
            // otherwise data is a broadcaster username from the text input
            managementSetStatusRequest(
              'campaigns',
              endpoint,
              isObject(data.broadcaster) ? data.broadcaster.username : data.broadcaster,
              campaign.slug,
            ),
          );
        } catch (error) {
          return error;
        }
      }
      return {[FORM_ERROR]: 'Please select a valid broadcaster!'};
    },
    [broadcasters, campaign.slug, dispatch, endpoint],
  );

  const validate = useValidator({
    broadcaster: [required],
  });

  const componentProps = {
    searchUrl: 'broadcasters/search/',
    searchParam: 'username',
    extraParams: extraAutoSuggestParams,
  };

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, form, error, submitError, submitting}) => (
        <BroadcasterParticipationForm
          handleSubmit={(data) =>
            handleSubmit(data)?.then(() => {
              const {submitSucceeded} = form.getState();
              submitSucceeded && form.reset();
            })
          }
          error={error || submitError}
          submitting={submitting}
          component={AutoSuggest}
          componentProps={componentProps}
          {...props}
        />
      )}
    </Form>
  );
};

CampaignAddBroadcasterForm.propTypes = {
  campaign: PropTypes.object,
  endpoint: PropTypes.string,
  broadcasters: PropTypes.arrayOf(PropTypes.object),
  extraAutoSuggestParams: PropTypes.object,
};

export default CampaignAddBroadcasterForm;
