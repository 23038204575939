import {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isPending} from 'redux-saga-thunk';

import BaseTemplate from 'components/components/BaseTemplate';
import LabelSelect from 'components/components/LabelSelect';
import EmptySplash from 'components/molecules/EmptySplash';
import LoadingBar from 'components/molecules/LoadingBar';
import BroadcasterCampaignTotals from './components/BroadcasterCampaignTotals';
import BroadcasterCampaignPreview from './components/BroadcasterCampaignPreview';
import {
  resourceListReadRequest,
  metricsRawDataRequest,
  managementSetStatusRequest,
} from 'store/actions';
import {fromProfile, fromMetrics} from 'store/selectors';
import {isCampaignStarted, isCampaignEnded} from 'utils/numberFormats';
import {getFrequencyTableFromClicks, getLabeledCountryClicksFromTable} from 'utils/campaignTools';
import {useCampaigns, useBroadcasterActions} from 'hooks';

const BroadcasterCampaigns = () => {
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState('All Campaigns');
  const profile = useSelector((state) => fromProfile.getFullProfile(state));
  const username = profile.username;

  useEffect(() => {
    dispatch(
      resourceListReadRequest('campaigns', {
        include_metrics: 'true',
        broadcaster: username,
      }),
    );
    dispatch(resourceListReadRequest('broadcasterActions'));
    dispatch(metricsRawDataRequest('clicks'));
  }, [dispatch, username]);

  const campaigns = useCampaigns();
  const actions = useBroadcasterActions();
  const clicks = useSelector((state) => fromMetrics.getRaw(state, 'clicks'));
  const loadingBroadcasterStatus = useSelector((state) =>
    isPending(state, `statusUpdate${username}`),
  );
  const loading = useSelector(
    (state) =>
      isPending(state, 'campaignsListRead') ||
      isPending(state, 'broadcasterActionsListRead') ||
      isPending(state, 'clicksRawData'),
  );

  const setBroadcasterStatus = useCallback(
    (broadcaster, status, campaign) =>
      dispatch(managementSetStatusRequest('campaigns', status, broadcaster, campaign, true)),
    [dispatch],
  );

  const filteredActions = actions.filter((action) => action.completion_time);

  const campaignStatsTables = getFrequencyTableFromClicks(clicks);
  const finalCountryTable = getLabeledCountryClicksFromTable(campaignStatsTables);

  const getCampaignStatus = (campaign) => {
    if (!isCampaignStarted(campaign)) {
      return 'Upcoming Campaigns';
    }
    if (isCampaignEnded(campaign)) {
      return 'Past Campaigns';
    }
    switch (campaign.status) {
      case 'Active':
        return 'Active Campaigns';
      case 'Pending':
        return 'Pending Campaigns';
      case 'Deactivated':
        return 'Suspended Campaigns';
      default:
        return 'Active Campaigns';
    }
  };

  const formattedCampaigns = campaigns.map((campaign) => ({
    ...campaign,
    campaignStatus: getCampaignStatus(campaign),
  }));

  const campaignStatuses = [
    'All Campaigns',
    ...new Set(formattedCampaigns.map((c) => c.campaignStatus)),
  ];

  return (
    <BaseTemplate>
      <span className="headerBackground" />
      <div className="broadcasterCampaignsPage">
        <div className="campaignsWrapper">
          {!loading && (
            <span className="topRow">
              <BroadcasterCampaignTotals
                campaigns={campaigns}
                clicks={clicks}
                countryTable={finalCountryTable}
              />
              {formattedCampaigns && formattedCampaigns.length > 3 && (
                <LabelSelect label="Show" handleChange={setSelectedFilter}>
                  {campaignStatuses.map((status) => (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  ))}
                </LabelSelect>
              )}
            </span>
          )}
          {!loading &&
            campaigns &&
            (campaigns.length > 0 ? (
              formattedCampaigns
                .filter((campaign) =>
                  selectedFilter === 'All Campaigns'
                    ? true
                    : campaign.campaignStatus === selectedFilter,
                )
                .sort((a, b) => (new Date(b.start_date) < new Date(a.start_date) ? -1 : 1))
                .map((campaign) => (
                  <BroadcasterCampaignPreview
                    key={campaign.slug}
                    campaign={campaign}
                    actions={filteredActions}
                    statsTable={campaignStatsTables}
                    setBroadcasterStatus={(status) =>
                      setBroadcasterStatus(username, status, campaign.slug)
                    }
                    loadingBroadcasterStatus={loadingBroadcasterStatus}
                  />
                ))
            ) : (
              <EmptySplash>
                <h3>No Campaigns Found!</h3>
              </EmptySplash>
            ))}
          {loading && <LoadingBar message="Loading Campaigns" />}
        </div>
      </div>
    </BaseTemplate>
  );
};

export default BroadcasterCampaigns;
