import {useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import SelectOrCreate from 'components/molecules/SelectOrCreate';
import Button from 'components/molecules/Button';
import {url, urlProtocol} from 'services/validation';

const LinkSelectWithAdd = ({handleAddButton, input, meta, disabled, links}) => {
  const [error, setError] = useState(null);

  const handleAdd = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (!input.value) return '';
      if (url(input.value) || urlProtocol(input.value)) {
        setError(url(input.value) || urlProtocol(input.value));
        return '';
      }
      setError(null);
      handleAddButton();
      return '';
    },
    [handleAddButton, input.value],
  );

  return (
    <div className="link-select">
      <SelectOrCreate
        items={links}
        handleOnChange={() => setError(null)}
        input={input}
        meta={{
          ...meta,
          error,
          submitFailed: !!error,
          touched: !!error,
        }}
        disabled={disabled}
      />
      <Button type="link-button" handleClick={handleAdd} disabled={disabled}>
        Insert Link
      </Button>
    </div>
  );
};

LinkSelectWithAdd.propTypes = {
  handleAddButton: PropTypes.func,
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.object),
};

export default LinkSelectWithAdd;
