import {put, call, all, takeEvery} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import {FORM_ERROR} from 'final-form';

import {multiWidgetArray} from 'store/resources/schemas';
import {resourceAdd} from 'store/resources/actions';
import {WIDGETS_UPDATE_REQUEST, widgetsUpdateSuccess, widgetsUpdateFailure} from './actions';

export function* updateWidget(api, widget) {
  return yield call([api, api.put], `widgets/${widget.type}/${widget.id}/`, widget);
}

export function* renderDCTA(api, dcta) {
  yield call([api, api.post], `dctas/${dcta.id}/render/`);
}

export function* updateMultipleWidgets(api, widgets, dcta, thunk) {
  try {
    const data = yield all(widgets.map((widget) => call(updateWidget, api, widget)));
    const normalizedWidgets = normalize(data, multiWidgetArray);
    yield put(resourceAdd(normalizedWidgets.entities));
    yield call(renderDCTA, api, dcta);
    yield put(widgetsUpdateSuccess(thunk));
  } catch (e) {
    const formError = {
      [FORM_ERROR]: e.non_field_errors ? e.non_field_errors[0] : JSON.stringify(e),
    };
    yield put(widgetsUpdateFailure(formError, thunk));
  }
}

export function* watchUpdateWidgets(api, {payload, meta}) {
  yield call(updateMultipleWidgets, api, payload.widgets, payload.dcta, meta.thunk);
}

export default function* sagas({api}) {
  yield takeEvery(WIDGETS_UPDATE_REQUEST, watchUpdateWidgets, api);
}
