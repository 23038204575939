import PropTypes from 'prop-types';
import {useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import BaseTemplate from 'components/components/BaseTemplate';
import {useParams} from 'react-router-dom';
import Navbar from './components/Navbar';
import {resourceDetailReadRequest, resourceListReadRequest} from 'store/actions';
import {fromResources} from 'store/selectors';
import isEmpty from 'lodash/isEmpty';

let dataLastFetched = '';

const CampaignTemplate = ({children}) => {
  const dispatch = useDispatch();
  const {campaignSlug} = useParams();

  const broadcastersExist = !isEmpty(
    useSelector((state) => fromResources.getResourceList(state, 'broadcasters')),
  );
  const componentTypesExist = !isEmpty(
    useSelector((state) => fromResources.getResourceList(state, 'componentTypes')),
  );
  const watchPartiesExist = !isEmpty(
    useSelector((state) => fromResources.getResourceList(state, 'watchParties')),
  );
  const campaignComponentsExist = !isEmpty(
    useSelector((state) =>
      fromResources.getResourceListByCampaign(state, 'components', campaignSlug),
    ),
  );
  // check if the campaign has been loaded with include_metrics
  const campaignExists = useSelector((state) =>
    fromResources.getResourceDetail(state, 'campaigns', campaignSlug),
  );
  const campaignMetricsExist = useMemo(
    () => !!campaignExists?.overview_metrics_set,
    [campaignExists],
  );

  useEffect(() => {
    if (dataLastFetched !== campaignSlug) {
      dataLastFetched = campaignSlug;
      !componentTypesExist && dispatch(resourceListReadRequest('componentTypes'));
      !watchPartiesExist && dispatch(resourceListReadRequest('watchParties'));
      !campaignComponentsExist &&
        dispatch(
          resourceListReadRequest('components', {
            campaign: campaignSlug,
          }),
        );
      !campaignMetricsExist &&
        dispatch(
          resourceDetailReadRequest('campaigns', campaignSlug, {
            include_metrics: 'true',
          }),
        );
      !broadcastersExist && dispatch(resourceListReadRequest('broadcasters'));
    }
  }, [
    broadcastersExist,
    campaignComponentsExist,
    campaignMetricsExist,
    campaignSlug,
    componentTypesExist,
    watchPartiesExist,
    dispatch,
  ]);

  return (
    <BaseTemplate>
      <Navbar />
      {children}
    </BaseTemplate>
  );
};

CampaignTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CampaignTemplate;
