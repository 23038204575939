import {AlertCircle, CheckCircleOutline} from 'mdi-material-ui';
import {useMemo} from 'react';
import {isEmpty} from 'lodash';

import {formatDate, isCampaignEnded, isCampaignStarted} from 'utils/numberFormats';
import {isDailyCapped} from 'utils/campaignTools';
import AttributionOverTimeChart from 'components/charts/AttributionOverTimeChart';
import StaticDateRanges from 'components/molecules/StaticDateRanges';
import ViewershipChart from 'components/charts/ViewershipChart';
import TimeTooltip from 'components/components/TimeTooltip';
import StatsTable from 'components/organisms/StatsTable';
import AdvTooltip from 'components/components/AdvTooltip';
import CampaignTemplate from '../../components/CampaignTemplate';
import CampaignNotFoundPage from '../../components/CampaignNotFoundPage';
import CampaignLiveQueue from './components/CampaignLiveQueue';
import CampaignLabelList from './components/CampaignLabelList';
import useCampaignOverview from './hooks/useCampaignOverview';
import formatEngagementStats from './metrics/formatEngagementStats';
import formatViewershipStats from './metrics/formatViewershipStats';

const CampaignOverview = () => {
  const {
    campaign,
    activeBroadcasters,
    teams,
    filteredClicks,
    filteredViewership,
    readOnly,
    userLoading,
    availableCharts,
    selectedDateRange,
    setSelectedChartView,
    selectedChartView,
    setSelectedDateRange,
    overviewMetrics,
    overviewComparisonMetrics,
    chatbotStatus,
  } = useCampaignOverview();
  const viewershipStats = useMemo(
    () => formatViewershipStats(activeBroadcasters, overviewMetrics, overviewComparisonMetrics),
    [activeBroadcasters, overviewComparisonMetrics, overviewMetrics],
  );
  const engagementStats = useMemo(
    () => formatEngagementStats(campaign, overviewMetrics, overviewComparisonMetrics),
    [campaign, overviewComparisonMetrics, overviewMetrics],
  );

  if (!userLoading && isEmpty(campaign)) return <CampaignNotFoundPage />;

  return (
    <CampaignTemplate>
      <div className="campaignOverviewPage">
        <div className="topSection">
          {!isCampaignStarted(campaign) && (
            <div className="campaignBanner campaignWarning">
              <p>
                <AlertCircle />
                This campaign has not started yet!
              </p>
            </div>
          )}
          {isDailyCapped(campaign) && (
            <div className="campaignBanner campaignMessage">
              <p>
                <CheckCircleOutline />
                This campaign has reached its daily impressions cap!
              </p>
            </div>
          )}
          <div className="campaignTitle">
            <div className="leftTitle">
              <h1>{campaign && campaign.name}</h1>
              {teams && teams.find((team) => team.id === campaign.team) && (
                <div className="campaignDates">
                  {campaign && campaign.end_date ? (
                    <span>
                      <p>{formatDate(campaign.start_date)}</p>
                      <TimeTooltip datetime={campaign.start_date} />
                      <p> -{formatDate(campaign.end_date)}</p>
                      <TimeTooltip datetime={campaign.end_date} />
                    </span>
                  ) : (
                    <span>
                      <p>{`${isCampaignStarted(campaign) ? 'Started' : 'Starts'} ${formatDate(
                        campaign.start_date,
                      )}`}</p>
                      <TimeTooltip datetime={campaign.start_date} />
                    </span>
                  )}
                </div>
              )}
            </div>
            {teams && teams.find((team) => team.id === campaign.team) ? (
              <img
                className="overview-logo"
                src={teams.find((team) => team.id === campaign.team).logo}
                alt=""
              />
            ) : (
              <div className="subtitle">
                <h3>
                  {isCampaignStarted(campaign) ? 'Started' : 'Starts'}{' '}
                  <b>
                    {campaign && campaign.start_date ? (
                      <b>
                        {formatDate(campaign.start_date)}
                        <TimeTooltip datetime={campaign.start_date} />
                      </b>
                    ) : (
                      <b>TBD</b>
                    )}
                  </b>
                </h3>
                {campaign && (
                  <h3>
                    {isCampaignEnded(campaign) ? 'Ended' : 'Ends'}{' '}
                    <b>
                      {campaign && campaign.end_date ? (
                        <b>
                          {formatDate(campaign.end_date)}
                          <TimeTooltip datetime={campaign.end_date} />
                        </b>
                      ) : (
                        <b>TBD</b>
                      )}
                    </b>
                  </h3>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="middleSection">
          <div className="middleOptions">
            <h4 label={chatbotStatus} className="chatbotStatus">
              {'chatbot is '}
              <b>{chatbotStatus}</b>
              {chatbotStatus && chatbotStatus !== 'up' && (
                <AdvTooltip tooltip="Contact an admin for more information" />
              )}
            </h4>
            {campaign && campaign.team && (
              <StaticDateRanges
                handleChange={(selected) => setSelectedDateRange(selected)}
                dateRanges={['07', '30', '90', 'all']}
                selectedRange={selectedDateRange.toString()}
              />
            )}
            {availableCharts.clicks && availableCharts.viewership && (
              <span
                className="toggleLabel noMobile"
                role="button"
                tabIndex={0}
                onClick={() => setSelectedChartView(!selectedChartView)}
              >
                <h4>{selectedChartView ? 'See Viewership Chart' : 'See Click Chart'}</h4>
              </span>
            )}
          </div>
          <StatsTable metrics={viewershipStats} loadingThunk="campaignsDetailRead" />
          <StatsTable metrics={engagementStats} loadingThunk="campaignsDetailRead" />
          {availableCharts.clicks && selectedChartView && (
            <AttributionOverTimeChart
              title="Clicks By Day"
              name="clicks-by-day"
              timeKeyFormat="%Y-%m-%d"
              data={filteredClicks}
              unit="clicks"
            />
          )}
          {availableCharts.viewership && (!selectedChartView || !availableCharts.clicks) && (
            <ViewershipChart
              title="Concurrent Viewers"
              name="viewers-by-day"
              views={filteredViewership}
            />
          )}
        </div>
        <div className="middleSection">
          <CampaignLabelList readOnly={isCampaignEnded(campaign) || readOnly} />
          <CampaignLiveQueue />
        </div>
      </div>
    </CampaignTemplate>
  );
};

export default CampaignOverview;
