import {useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Close} from 'mdi-material-ui';
import Modal from 'react-modal';
import {fromModal} from 'store/selectors';
import {modalHide} from 'store/actions';

const AdvModal = ({name, contentLabel, children, extraClass}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => isOpen || fromModal.isOpen(state, name));
  const onClose = useCallback(() => dispatch(modalHide(name)), [dispatch, name]);
  Modal.setAppElement(document.getElementById('root'));

  useEffect(() => {
    return () => isOpen && onClose();
  }, [isOpen, onClose]);

  return (
    <Modal
      className={{
        base: `AdvModal form ${extraClass || ''}`,
        beforeClose: 'remove',
        afterOpen: '',
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={{
        base: 'modalBackdrop',
        beforeClose: '',
        afterOpen: '',
      }}
      shouldCloseOnOverlayClick
      contentLabel={contentLabel}
      parentSelector={() => document.querySelector('#modalParent')}
    >
      <Close
        className="remove"
        onClick={onClose}
        onKeyPress={onClose}
        tabIndex={0}
        role="button"
        aria-label="close"
      />
      {children}
    </Modal>
  );
};

AdvModal.propTypes = {
  contentLabel: PropTypes.string.isRequired,
  children: PropTypes.any,
  extraClass: PropTypes.string,
  name: PropTypes.string,
};

export default AdvModal;
