import {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {isEqual} from 'lodash';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import {normalize} from 'normalizr';
import {labels} from 'store/resources/schemas';

import {
  required,
  isTweet,
  maxLength,
  isValidBannerImage,
  isValidGraphic,
  createValidator,
  isValidCommandName,
  isValidDuration,
  onlyOne,
  noURLs,
  isValidMovie,
  isVimeoOrYoutubeVideoUrl,
  excludeCharacter,
  url,
  urlProtocol,
  isDateBeforeDate,
  alphanumeric,
  noNullString,
} from 'services/validation';
import blacklist from 'validator/lib/blacklist';
import AdvInput from 'components/molecules/AdvInput';
import FileInput from 'components/molecules/FileInput';
import Button from 'components/molecules/Button';
import TextArea from 'components/molecules/TextArea';
import MultiSelect from 'components/molecules/MultiSelect';
import CheckboxField from 'components/components/CheckboxField';
import SelectOrCreate from 'components/molecules/SelectOrCreate';
import LinkSelectWithAdd from 'components/organisms/LinkSelectWithAdd';
import DateRangeField from 'components/molecules/DateRangeField';
import TertiaryOption from 'components/components/TertiaryOption';
import ChatbotComponentSelect from 'components/molecules/ChatbotComponentSelect';
import ColorPicker from 'components/components/ColorPicker';
import WatchPartySelect from 'components/organisms/WatchPartySelect';
import ScheduleField from 'components/molecules/ScheduleField';
import {formatDate} from 'utils/numberFormats';
import {getFileDisplayName} from 'utils/campaignTools';
import {resourceCreateRequest, resourceUpdateRequest, resourceAdd} from 'store/actions';
import {useCampaignLabels, useCampaignLinks, useCampaign, useBroadcasters} from 'hooks';
import useModal from 'hooks/useModal';

const emptyArray = [];

const getValidatorsForType = (componentType, values, campaign) => {
  const validators = {
    name: [required, noNullString],
    start_time: [isDateBeforeDate(values.end_time)],
  };

  switch (componentType) {
    case 'live-graphics':
      validators.image = [required, isValidGraphic];

      if (campaign.set_live_graphic_times) {
        validators.config = [required, isValidDuration];
      }
      return validators;
    case 'banner-graphics':
      return {
        ...validators,
        image: [required, isValidBannerImage],
        link: [required, url, urlProtocol],
      };
    case 'suggested-tweet':
      validators.message = [required, maxLength(280), noNullString];
      validators.image = [isValidBannerImage];

      if (campaign.keyword_links) {
        validators.keyword = [required, excludeCharacter('-')];
      }

      return validators;
    case 'suggested-retweet':
      return {
        ...validators,
        message: [required, isTweet, noNullString],
      };
    case 'twitch-chat-bot':
      validators.message = [required, noURLs, noNullString];

      if (campaign.hard_coded_links) {
        validators.message = [required, noNullString];
      }

      return validators;
    case 'twitch-chat-bot-command':
      validators.config = [required, isValidCommandName];
      validators.message = [required, noURLs, noNullString];

      if (campaign.hard_coded_links) {
        validators.message = [required, noNullString];
      }

      return validators;
    case 'fullscreen-video':
      return {
        ...validators,
        youtube_url: [onlyOne(['image']), isVimeoOrYoutubeVideoUrl],
        image: [isValidMovie],
      };
    case 'custom-link':
      return {
        ...validators,
        link: [required, url, urlProtocol],
        config: [alphanumeric],
      };
    case 'advocate-widget':
      return {
        ...validators,
        widget_upload: [required],
      };
    default:
      return {};
  }
};

const MediaField = ({componentType, placeholder, clearMediaField, name}) => (
  <Field
    name={name}
    clearField={clearMediaField}
    component={FileInput}
    allowedInputs={componentType}
    placeholder={placeholder}
  />
);

const fieldPropTypes = {
  placeholder: PropTypes.string,
  componentType: PropTypes.string,
  clearMediaField: PropTypes.func,
  name: PropTypes.string,
};

MediaField.propTypes = fieldPropTypes;

const ComponentForm = ({
  component,
  componentType,
  componentFields,
  additionalData,
  initialValues,
  modalName,
}) => {
  const dispatch = useDispatch();
  const [, modalHide] = useModal();

  const campaign = useCampaign();
  const broadcasters = useBroadcasters();
  const campaignLabels = useCampaignLabels();
  const campaignLinks = useCampaignLinks();

  const [visibleOptionalFields, setVisibleOptionalFields] = useState([]);

  useEffect(() => {
    const visibleOptionalFields = [];
    if (initialValues?.start_time || initialValues?.end_time) {
      visibleOptionalFields.push('date_range');
    }
    if (initialValues?.config?.source_keyword) {
      visibleOptionalFields.push('source_keyword');
    }
    setVisibleOptionalFields(visibleOptionalFields);
  }, [initialValues]);

  // Sets all fields to a predefined order
  const sortFields = () => {
    const allFields = [...visibleOptionalFields, ...componentFields];

    const sortedFields = [];
    // Array order is predefined presentation order
    const possibleFields = [
      'name',
      'date_range',
      'command',
      'duration',
      'delay',
      'link',
      'source_keyword',
      'chatbot-select',
      'tweet',
      'label',
      'color',
      'link-with-add',
      'message',
      'description',
      'impression_cutoff',
      'daily_impression_cap',
      'image',
      'message_interval',
      'video',
      'widget_upload',
      'youtube',
      'watch-party-select',
      'minute-select',
      'is_editable',
      'is_fulfillable',
      'link-preview',
      'keyword_option',
      'date_range_option',
    ];

    possibleFields.forEach((field) => {
      if (allFields.includes(field)) {
        sortedFields.push(field);
      }
    });
    return sortedFields;
  };

  // default field
  if (campaignLabels && campaignLabels.length && !initialValues) {
    componentFields.push('label');
  }

  const componentLabels = campaignLabels.filter((label) =>
    component ? label.components.includes(component.id) : false,
  );

  const allBroadcasters = broadcasters.map((b) => b.username);

  // list of broadcasters from labels on this component
  const currentValidBroadcasters =
    componentLabels.length > 0
      ? componentLabels.reduce((acc, val) => acc.concat(val.broadcasters), [])
      : allBroadcasters;

  // indicator that actions will get created based on image or label changes
  const willTriggerActions = (values) => {
    const bannerImageChanged =
      componentType.slug === 'banner-graphics' &&
      values.image &&
      values.image.name &&
      initialValues &&
      initialValues.image &&
      getFileDisplayName(values.image.name) !== getFileDisplayName(initialValues.image);

    // list of broadcasers after labels would be applied
    const newValidBroadcasters =
      values.labels && values.labels.length > 0
        ? campaignLabels.reduce((acc, val) => {
            const matchingLabel = values.labels.find((l) => l.name === val.name);
            return matchingLabel ? acc.concat(matchingLabel.broadcasters) : acc;
          }, [])
        : allBroadcasters;

    return (
      componentType.slug === 'banner-graphics' &&
      (bannerImageChanged ||
        (initialValues && !isEqual(newValidBroadcasters.sort(), currentValidBroadcasters.sort())))
    );
  };

  const getFieldComponent = (fieldName, values, form) => {
    const clearOptionalFields = (optionalFields) => {
      optionalFields.forEach((field) => {
        form.change(field, null);
        form.resetFieldState(field);
      });
    };

    const tertiaryOptionHandleClick = (fieldName, optionalFields, clearConfig) => {
      clearConfig
        ? clearOptionalFields(['config'])
        : visibleOptionalFields.includes(fieldName) && clearOptionalFields(optionalFields);
      setVisibleOptionalFields(
        visibleOptionalFields.includes(fieldName)
          ? visibleOptionalFields.filter((f) => f !== fieldName)
          : [...visibleOptionalFields, fieldName],
      );
    };

    const addLinkToMessage = () => {
      form.change(
        'message',
        form.getState().values.message ? `${form.getState().values.message} {{link}}` : '{{link}}',
      );
    };

    const clearMediaField = (name) => {
      form.change(name, null);
    };

    switch (fieldName) {
      case 'image':
        return (
          <MediaField
            name="image"
            key={fieldName}
            allowedInputs={componentType.slug}
            clearMediaField={() => clearMediaField('image')}
          />
        );
      case 'video':
        return (
          <MediaField
            name="image"
            key={fieldName}
            allowedInputs={componentType.slug}
            placeholder="Choose a video"
            clearMediaField={() => clearMediaField('image')}
          />
        );
      case 'name':
        return (
          <Field
            key={fieldName}
            name="name"
            type="text"
            component={AdvInput}
            label="Name"
            id="component-name"
          />
        );
      case 'link':
        return (
          <Field
            key={fieldName}
            name="link"
            items={campaignLinks.filter((l) => l.active)}
            component={SelectOrCreate}
          />
        );
      case 'message':
        return (
          <Field
            key={fieldName}
            name="message"
            placeholder="Enter your message"
            component={TextArea}
            disabled={!!initialValues && componentType.slug === 'suggested-tweet'}
          />
        );
      case 'description':
        return (
          <Field
            key={fieldName}
            name="description"
            placeholder="Enter a description of the component for intructional purposes"
            component={TextArea}
          />
        );
      case 'tweet':
        return (
          <Field
            key={fieldName}
            name="message"
            placeholder="Enter tweet URL"
            component={AdvInput}
          />
        );
      case 'link-with-add':
        return (
          <Field
            key={fieldName}
            name="link"
            handleAddButton={addLinkToMessage}
            component={LinkSelectWithAdd}
            links={campaignLinks.filter((l) => l.active)}
            disabled={
              (!!initialValues && componentType.slug === 'suggested-tweet') || values.message
                ? values.message.includes('{{link}}')
                : initialValues &&
                  initialValues.message &&
                  initialValues.message.includes('{{link}}')
            }
          />
        );
      case 'command':
        return (
          <Field
            key={fieldName}
            name="config"
            label="Command"
            tooltip="Command to trigger message (don't include `!`)"
            placeholder="don't include `!`"
            type="text"
            component={AdvInput}
            format={(value) => (value ? value.command : undefined)}
            parse={(value) => ({command: value})}
          />
        );
      case 'label':
        return (
          <Field
            key={fieldName}
            component={MultiSelect}
            name="labels"
            title="Groups"
            items={campaignLabels}
            selectedItems={componentLabels}
          />
        );
      case 'duration':
        return (
          <Field
            key={fieldName}
            name="config"
            placeholder="(in seconds)"
            tooltip="Duration (in seconds)"
            label="Duration"
            type="number"
            min={0}
            component={AdvInput}
            format={(value) => (value ? value.duration : undefined)}
            parse={(value) => ({...form.getState().values.config, duration: value})}
          />
        );
      case 'delay':
        return (
          <Field
            key={fieldName}
            name="config"
            label="Pause"
            placeholder="(in seconds) (optional)"
            tooltip="Pause after image (in seconds) (optional)"
            type="number"
            min={0}
            component={AdvInput}
            format={(value) => (value ? value.rotation_delay : undefined)}
            parse={(value) => ({...form.getState().values.config, rotation_delay: value})}
          />
        );
      case 'message_interval':
        return (
          <Field
            key={fieldName}
            name="config"
            label="Pause"
            tooltip="Time to next message (in seconds)"
            placeholder="in seconds"
            type="number"
            min={0}
            component={AdvInput}
            format={(value) => (value ? value.message_interval : undefined)}
            parse={(value) => ({...form.getState().values.config, message_interval: value})}
          />
        );
      case 'youtube':
        return (
          <Field
            key={fieldName}
            name="youtube_url"
            placeholder="or, enter a YouTube URL"
            type="url"
            component={AdvInput}
          />
        );
      case 'chatbot-select':
        return (
          <Field
            key={fieldName}
            name="config"
            component={ChatbotComponentSelect}
            format={(value) => (value ? value.chatbot_component : undefined)}
            parse={(value) => ({chatbot_component: value})}
          />
        );
      case 'is_editable':
        return (
          <CheckboxField fieldName="is_editable" description="Allow broadcasters to edit Tweet" />
        );
      case 'is_fulfillable':
        return (
          <CheckboxField
            fieldName="is_fulfillable"
            description="Allow broadcasters to reuse the link"
          />
        );
      case 'source_keyword':
        return (
          <Field
            key={fieldName}
            name="config"
            placeholder="Link Keyword"
            type="text"
            component={AdvInput}
            format={(value) => (value ? value.source_keyword : undefined)}
            parse={(value) => ({...form.getState().values.config, source_keyword: value})}
          />
        );
      case 'keyword_option':
        return (
          <TertiaryOption
            key={fieldName}
            disabled={initialValues}
            optionText={
              !visibleOptionalFields.includes('source_keyword')
                ? 'Use keyword link'
                : 'Use generated links instead'
            }
            onClick={() => tertiaryOptionHandleClick('source_keyword', ['source_keyword'], true)}
          />
        );
      case 'date_range_option':
        return (
          <TertiaryOption
            key={fieldName}
            disabled={
              initialValues && initialValues.end_time < moment().format('YYYY-MM-DDTHH:mm:SSZ')
            }
            optionText={
              visibleOptionalFields.includes('date_range')
                ? 'Set persistent component'
                : 'Select active date range'
            }
            onClick={() => tertiaryOptionHandleClick('date_range', ['start_time', 'end_time'])}
          />
        );
      case 'date_range':
        return (
          <DateRangeField
            key={fieldName}
            specifyTime
            startDisabled={
              initialValues && initialValues.end_time < moment().format('YYYY-MM-DDTHH:mm:SSZ')
            }
            endDisabled={
              initialValues && initialValues.end_time < moment().format('YYYY-MM-DDTHH:mm:SSZ')
            }
            campaign={campaign}
            formStartTime={values.start_time}
            formEndTime={values.end_time}
            initialStartTime={initialValues ? initialValues.start_time : null}
            initialEndTime={initialValues ? initialValues.end_time : null}
            startAbsoluteStart={campaign ? campaign.start_date : null}
            startAbsoluteEnd={campaign ? campaign.end_date : null}
            endAbsoluteStart={campaign ? campaign.start_date : null}
            endAbsoluteEnd={campaign ? campaign.end_date : null}
          />
        );
      case 'link-preview':
        return (
          <div key={fieldName} className="previewSection">
            <span className="labelItem">
              <p>
                {visibleOptionalFields.includes('source_keyword')
                  ? 'Links will be consistent, but will not be distinguishable between broadcasters'
                  : 'Links will be generated and tracked per broadcaster'}
              </p>
            </span>
          </div>
        );
      case 'widget_upload':
        return (
          <Field
            component={FileInput}
            allowedInputs={componentType.slug}
            key={fieldName}
            name={fieldName}
            placeholder="Select zip of your widget files"
            clearMediaField={() => clearMediaField(fieldName)}
          />
        );
      case 'color':
        return (
          <Field
            component={ColorPicker}
            key={fieldName}
            name="config"
            format={(value) => (value ? value.color : undefined)}
            parse={(value) => ({...form.getState().values.config, color: value})}
          />
        );
      case 'watch-party-select':
        return (
          <Field
            component={WatchPartySelect}
            key={fieldName}
            name="config"
            format={(value) => (value ? value.watch_party_id : undefined)}
            parse={(value) => ({
              ...form.getState().values.config,
              watch_party_id: value,
            })}
          />
        );
      case 'minute-select':
        return (
          <Field
            component={ScheduleField}
            key={fieldName}
            selectedItems={initialValues?.config?.schedule || emptyArray}
            name="config"
            format={(value) => (value ? value.schedule : undefined)}
            parse={(value) => ({
              ...form.getState().values.config,
              schedule: value,
            })}
          />
        );
      case 'impression_cutoff':
        return (
          <Field
            key={fieldName}
            min={0}
            label="Cutoff"
            component={AdvInput}
            name="impression_cutoff"
            placeholder="in impressions (optional)"
            type="number"
            tooltip="Component is ended after this many impressions (optional)"
          />
        );
      case 'daily_impression_cap':
        return (
          <Field
            key={fieldName}
            min={0}
            component={AdvInput}
            label="Daily Cap"
            name="daily_impression_cap"
            placeholder="in impressions (optional)"
            type="number"
            tooltip="Component is taken out of rotation for the day after this many impressions (optional)"
          />
        );
      default:
        return (
          <p key="notValid" className="is-danger">
            Not a valid component field
          </p>
        );
    }
  };

  const validate = (values) =>
    createValidator(getValidatorsForType(componentType.slug, values, campaign))(values);

  const onSubmit = useCallback(
    (data) => {
      // Don't include a link if a link is selected with the LinkSelectWithAdd,
      // but no {{link}} template is included in the message (chatbot, tweets)
      const shouldHaveLink = () => {
        switch (componentType.slug) {
          case 'banner-graphics':
          case 'custom-link':
            return true;
          case 'suggested-tweet':
          case 'twitch-chat-bot':
          case 'twitch-chat-bot-command':
            return data.link && !!(data.link && data.message.includes('{{link}}'));
          default:
            return false;
        }
      };

      const fullData = {
        ...data,
        ...(shouldHaveLink() && {link: blacklist(data.link, ' ')}),
        type: componentType.slug,
        campaign: campaign.slug,
        is_movable: true,
      };

      if (fullData.config && fullData.config.schedule) {
        fullData.config.schedule = {
          minutes: fullData.config.schedule.map((minute) => minute.id),
        };
      }

      // We need to dispatch our label updates AFTER we get the component back,
      // so we'll wrap successive dispatches in a single Promise
      const chainedCalls = new Promise((resolve, reject) => {
        // Action for component update/create
        const action = component
          ? resourceUpdateRequest('components', component.id, {
              ...fullData,
              id: component.id,
            })
          : resourceCreateRequest('components', fullData);

        // Action for update label
        const labelAction = (response) => {
          // If successful, update any new labels locally, since label data is usually
          // handled via the campaign endpoint, rather than the component endpoint
          if (!component) {
            const componentId = +Object.keys(response.components)[0];
            const updatedLabels = (data.labels || []).map((label) => ({
              ...label,
              components: [...label.components, componentId],
            }));
            const normalizedLabels = normalize(updatedLabels, [labels]);
            dispatch(resourceAdd(normalizedLabels.entities));
          }
          resolve(response);
        };

        dispatch(action).then(
          (response) => labelAction(response),
          (error) => reject(error),
        );
      });

      return chainedCalls.then(() => modalHide(modalName));
    },
    [campaign.slug, component, componentType.slug, dispatch, modalHide, modalName],
  );

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
      {({handleSubmit, error, submitting, form}) => (
        <form onSubmit={(data) => handleSubmit(data)}>
          <label htmlFor="Component Form">
            {component ? `Update "${component.name}"` : `Add New ${componentType.name} Component`}
          </label>

          {component && (
            <p className="extraTip">
              {'Last updated '}
              {formatDate(additionalData.created)}
            </p>
          )}

          {sortFields().map((field) => getFieldComponent(field, form.getState().values, form))}

          {campaign && campaign.keyword_links && componentType.slug === 'suggested-tweet' && (
            <Field
              name="keyword"
              type="text"
              component={AdvInput}
              placeholder="Enter link keyword"
            />
          )}

          {willTriggerActions(form.getState().values) && (
            <p className="extraTip centered">
              This update will trigger broadcaster actions to be completed by broadcasters on your
              campaign
            </p>
          )}

          <Button loading={!!submitting}>{component ? 'Update' : 'Submit'}</Button>
          {error && <div className="help is-danger centered">{error}</div>}
        </form>
      )}
    </Form>
  );
};

ComponentForm.propTypes = {
  initialValues: PropTypes.object,
  additionalData: PropTypes.object,
  component: PropTypes.object,
  componentType: PropTypes.object.isRequired,
  componentFields: PropTypes.array,
  optionalFields: PropTypes.array,
  modalName: PropTypes.string,
};

export default ComponentForm;
