export const BROWSERSOURCE_TRIGGER_REQUEST = 'BROWSERSOURCE_TRIGGER_REQUEST';
export const BROWSERSOURCE_TRIGGER_SUCCESS = 'BROWSERSOURCE_TRIGGER_SUCCESS';
export const BROWSERSOURCE_TRIGGER_FAILURE = 'BROWSERSOURCE_TRIGGER_FAILURE';

export const browsersourceTriggerRequest = (componentID) => ({
  type: BROWSERSOURCE_TRIGGER_REQUEST,
  payload: {component: componentID},
  meta: {thunk: 'browsersourceTrigger'},
});

export const browsersourceTriggerSuccess = (thunk) => ({
  type: BROWSERSOURCE_TRIGGER_SUCCESS,
  meta: {thunk},
});

export const browsersourceTriggerFailure = (error, thunk) => ({
  type: BROWSERSOURCE_TRIGGER_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});

export const BROWSERSOURCE_POSITIONING_SAVE_REQUEST = 'BROWSERSOURCE_POSITIONING_SAVE_REQUEST';
export const BROWSERSOURCE_POSITIONING_SAVE_SUCCESS = 'BROWSERSOURCE_POSITIONING_SAVE_SUCCESS';
export const BROWSERSOURCE_POSITIONING_SAVE_FAILURE = 'BROWSERSOURCE_POSITIONING_SAVE_FAILURE';

export const browsersourcePositioningSaveRequest = (data) => ({
  type: BROWSERSOURCE_POSITIONING_SAVE_REQUEST,
  payload: {data},
  meta: {thunk: 'browsersourcePositioningSave'},
});

export const browsersourcePositioningSaveSuccess = (newPositions, thunk) => ({
  type: BROWSERSOURCE_POSITIONING_SAVE_SUCCESS,
  payload: newPositions,
  meta: {thunk},
});

export const browsersourcePositioningSaveFailure = (error, thunk) => ({
  type: BROWSERSOURCE_POSITIONING_SAVE_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});

export const BROWSERSOURCE_POSITIONING_READ_REQUEST = 'BROWSERSOURCE_POSITIONING_READ_REQUEST';
export const BROWSERSOURCE_POSITIONING_READ_SUCCESS = 'BROWSERSOURCE_POSITIONING_READ_SUCCESS';
export const BROWSERSOURCE_POSITIONING_READ_FAILURE = 'BROWSERSOURCE_POSITIONING_READ_FAILURE';

export const browsersourcePositioningReadRequest = (data) => ({
  type: BROWSERSOURCE_POSITIONING_READ_REQUEST,
  payload: {data},
  meta: {thunk: 'browsersourcePositioningRead'},
});

export const browsersourcePositioningReadSuccess = (data, thunk) => ({
  type: BROWSERSOURCE_POSITIONING_READ_SUCCESS,
  payload: data,
  meta: {thunk},
});

export const browsersourcePositioningReadFailure = (error, thunk) => ({
  type: BROWSERSOURCE_POSITIONING_READ_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});

export const BROWSERSOURCE_TEST_PATTERN_REQUEST = 'BROWSERSOURCE_TEST_PATTERN_REQUEST';
export const BROWSERSOURCE_TEST_PATTERN_FAILURE = 'BROWSERSOURCE_TEST_PATTERN_FAILURE';

export const browsersourceTestPatternRequest = () => ({
  type: BROWSERSOURCE_TEST_PATTERN_REQUEST,
  meta: {thunk: 'browsersourceTestPattern'},
});

export const browsersourceTestPatternFailure = (error, thunk) => ({
  type: BROWSERSOURCE_TEST_PATTERN_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});
