import {useState} from 'react';
import {Field, Form} from 'react-final-form';
import useNewReportForm from './hooks/useNewReportForm';

import Button from 'components/molecules/Button';
import DateRangeField from 'components/molecules/DateRangeField';
import AdvInput from 'components/molecules/AdvInput';
import Select from 'components/molecules/Select';
import Checkbox from 'components/atoms/Checkbox';
import TertiaryOption from 'components/components/TertiaryOption';
import ReportCustomization from './components/ReportCustomization';

const NewReportForm = () => {
  const [customizeSections, setCustomizeSections] = useState(false);
  const [selectedSections, setSelectedSections] = useState([]);
  const {onSubmit, validate, initialValues, loading, campaign} = useNewReportForm();
  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
      {({handleSubmit, error, form, submitError}) => (
        <form onSubmit={handleSubmit}>
          <div className="window">
            <label htmlFor="choose date range">Generate New Report</label>
            <p className="help">Report generation can take a few minutes</p>

            <Field name="name" placeholder="Report Name" type="text" component={AdvInput} />

            <Field component={Select} name="type">
              <option value="">Select report Type</option>
              <option value="P">PDF</option>
              <option value="C">CSV</option>
              <option value="X">Excel</option>
            </Field>

            <DateRangeField
              color="tertiary"
              campaign={campaign}
              formStartTime={form.getState().values['start_time']}
              formEndTime={form.getState().values['end_time']}
              startAbsoluteStart={campaign ? campaign.start_date : null}
              startAbsoluteEnd={campaign ? campaign.end_date : null}
              endAbsoluteStart={campaign ? campaign.start_date : null}
              endAbsoluteEnd={campaign ? campaign.end_date : null}
              extendsBeforeCurrentDay
              specifyTime
            />

            <div className="autoActiveCheckbox">
              <Field name="empty_rows" component={Checkbox} checkboxclass="muiCheckbox" />
              <p>Only show broadcasters with metrics</p>
            </div>

            <TertiaryOption
              optionText={
                customizeSections ? 'Use default report sections' : 'Customize report sections'
              }
              onClick={() => setCustomizeSections(!customizeSections)}
            />

            {(error || submitError) && (
              <div className="help is-danger centered">{error || submitError}</div>
            )}
            <div className="flexSpacing" />
            <Button handleClick={() => setCustomizeSections(false)} loading={!!loading}>
              Generate
            </Button>
          </div>

          {customizeSections && (
            <ReportCustomization
              reportSections={form.getState().values['sections']}
              selectedReportSections={selectedSections}
              setSelectedSections={setSelectedSections}
            />
          )}
        </form>
      )}
    </Form>
  );
};

export default NewReportForm;
