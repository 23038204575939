import PropTypes from 'prop-types';

import {CSSTransitionGroup} from 'react-transition-group';

const LoginInput = ({input, meta, placeholder, type, icon}) => (
  <div>
    <CSSTransitionGroup
      transitionName="slowFade"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={500}
    >
      {meta.touched && meta.error && meta.submitFailed && (
        <p className="help is-danger is-pulled-right">{meta.error}</p>
      )}
    </CSSTransitionGroup>
    <div className="loginInput">
      {icon}
      <input type={type} placeholder={placeholder} {...input} />
    </div>
  </div>
);

LoginInput.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object,
  icon: PropTypes.element,
};

LoginInput.defaultProps = {
  type: 'text',
};

export default LoginInput;
