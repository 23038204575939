import {useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {resourceListReadRequest} from 'store/actions';

import {useComponents} from 'hooks';
import {fromResources, fromProfile} from 'store/selectors';
import EmptySplash from 'components/molecules/EmptySplash';
import BaseTemplate from 'components/components/BaseTemplate';

const BroadcasterWatchParty = () => {
  const dispatch = useDispatch();
  const components = useComponents();
  const iframe = useRef(null);

  const watchPartyComponents = components.filter(
    (component) => component.type === 'watch-party' && component.is_ready && !component.is_deleted,
  );

  const hasComponents = !isEmpty(components);
  const hasWatchParties = !isEmpty(watchPartyComponents);
  const userName = useSelector((state) => fromProfile.getUsername(state));

  useEffect(() => {
    !hasComponents && dispatch(resourceListReadRequest('components'));
    !hasWatchParties && dispatch(resourceListReadRequest('watchParties'));
  }, [dispatch, hasComponents, hasWatchParties]);

  const selectedComponent = watchPartyComponents.length > 0 ? watchPartyComponents[0] : undefined;

  const watchParty = useSelector((state) =>
    fromResources.getResourceDetail(
      state,
      'watchParties',
      selectedComponent?.activeVersion?.config.watch_party_id,
    ),
  );

  const getSettingsData = () => ({
    widgetId: selectedComponent.id,
    broadcaster: userName,
    token: watchParty?.api_key,
  });

  const handleMessage = (event) => {
    if (event.data.action === 'get-settings' && iframe.current) {
      iframe.current.contentWindow.postMessage(getSettingsData(), '*');
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, [selectedComponent]);

  return (
    <BaseTemplate>
      {isEmpty(watchParty) || !hasWatchParties ? (
        <EmptySplash>
          <h2>You don&apos;t have any active Watch Parties right now</h2>
        </EmptySplash>
      ) : (
        <iframe
          src={`${watchParty.current_version.source}broadcaster.html`} // eslint-disable-line camelcase
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          width="100%"
          height="100%"
          title={selectedComponent.name}
          ref={iframe}
        />
      )}
    </BaseTemplate>
  );
};

export default BroadcasterWatchParty;
