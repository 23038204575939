import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useDeepMemo} from 'hooks/utils';

import {fromResources} from 'store/selectors';
import Select from 'components/molecules/Select';

const WatchPartySelect = ({input, meta}) => {
  const watchParties = useSelector((state) => fromResources.getResourceList(state, 'watchParties'));

  const changedParties = useDeepMemo(() => watchParties);
  const {onChange} = input;

  useEffect(() => {
    if (changedParties.length > 0) {
      onChange(changedParties[0].id);
    }
  }, [changedParties, onChange]);

  return (
    <Select input={input} meta={meta}>
      {changedParties.map((party) => (
        <option key={party.id} value={party.id}>
          {party.game}
        </option>
      ))}
    </Select>
  );
};

WatchPartySelect.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default WatchPartySelect;
