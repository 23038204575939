import {useCallback} from 'react';

const useStreamHandlers = ({
  activeDrag,
  setHasCollisions,
  setActiveDrag,
  touchedRotations,
  setTouchedRotations,
  initialRotations,
}) => {
  // Update values in state when live-graphics have been moved
  const handleDrag = useCallback(
    (e, _ui) => {
      if (e.target.id !== 'browsersource-image' && e.target.id !== '') {
        const rotationId = parseInt(e.target.id.split('-')[0], 10);
        setHasCollisions(false);
        setActiveDrag(rotationId);
      }
    },
    [setHasCollisions, setActiveDrag],
  );

  // Update values in state when live-graphics have been scaled
  const handleScaleChange = useCallback(
    (val, rotation, selectedImageIndexes, scalingFactor) => {
      const currentIndex = selectedImageIndexes[rotation.id] || 0;
      const image = rotation.images[currentIndex];
      const elementId = `${rotation.id}-${image.version_id}`;
      const newPos = document
        .getElementById(elementId)
        .parentElement.style.transform.match(/-?\d+\.?\d*/g)
        .map(Number);

      setTouchedRotations({
        ...touchedRotations,
        [rotation.id]: {
          ...rotation,
          xPos: newPos[0] / scalingFactor,
          yPos: newPos[1] / scalingFactor,
          scale: val / 100,
        },
      });
    },
    [touchedRotations, setTouchedRotations],
  );

  // Record Positions when live-graphics have stopped moving
  const handleStop = useCallback(
    (e, rotation, rotationIndex, scalingFactor) => {
      const image = rotation.images[rotationIndex];
      const elementId = `${rotation.id}-${image.version_id}`;

      const recordPosition = () => {
        const newPos = document
          .getElementById(elementId)
          .parentElement.style.transform.match(/-?\d+\.?\d*/g)
          .map(Number);
        setTouchedRotations({
          ...touchedRotations,
          [rotation.id]: {
            ...rotation,
            xPos: (newPos[0] || 0) / scalingFactor,
            yPos: (newPos[1] || 0) / scalingFactor,
          },
        });
      };

      if (e.target.id !== 'browsersource-image' && e.target.id !== '') {
        const rotationId = parseInt(e.target.id.split('-')[0], 10);
        recordPosition(e.target.id, rotationId);
      } else {
        /* Handles the case of handleStop being called while the cursor is past
      the bounds of the stream preview by using the last active image */
        activeDrag && recordPosition(activeDrag);
      }
    },
    [setTouchedRotations, touchedRotations, activeDrag],
  );

  return {
    handleDrag,
    handleScaleChange,
    handleStop,
  };
};

export default useStreamHandlers;
