import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';

import ObjectSelect from 'components/molecules/ObjectSelect';
import {useComponents} from 'hooks';

const ChatbotComponentSelect = ({input}) => {
  const {campaignSlug} = useParams();
  const objects = useComponents();

  return (
    <ObjectSelect
      input={input}
      defaultText="Simultaneous Chatbot Post (optional)"
      keyField="id"
      valueField="id"
      textField="name"
      objects={objects.filter(
        (component) =>
          component.campaign === campaignSlug &&
          component.type === 'twitch-chat-bot-command' &&
          !component.is_deleted,
      )}
    />
  );
};

ChatbotComponentSelect.propTypes = {
  input: PropTypes.object,
};

export default ChatbotComponentSelect;
