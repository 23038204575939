import PropTypes from 'prop-types';

import {formatShortDate} from 'utils/numberFormats';
import AdvModal from 'containers/AdvModal';

const ComplianceHistoryModal = ({componentsCompliance, broadcaster}) => {
  return (
    <AdvModal
      key={`compliance-history-${broadcaster}`}
      name={`compliance-history-${broadcaster}`}
      contentLabel="Compliance history for this broadcaster"
    >
      <label htmlFor="compliance history">{`Compliance history for ${broadcaster}`}</label>
      {Object.values(componentsCompliance)
        .filter((a) => !!a.history)
        .map((action) => (
          <div className="complianceHistoryItem" key={action.component}>
            <span className="title">
              <h4>{action.name}</h4>
            </span>
            {action.history
              .sort((a, b) => (b[0] < a[0] ? -1 : 0))
              .map((status, index) => (
                <span key={`${status[0]}-${status[1]}`} className={status[1]}>
                  <p>{formatShortDate(status[0])}</p>
                  <p>{`${status[1]}${index === 0 ? ' (Current)' : ''}`}</p>
                </span>
              ))}
          </div>
        ))}
    </AdvModal>
  );
};

ComplianceHistoryModal.propTypes = {
  componentsCompliance: PropTypes.object,
  broadcaster: PropTypes.string,
};

export default ComplianceHistoryModal;
