import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ContentCopy} from 'mdi-material-ui';
import InlineSelect from 'components/molecules/InlineSelect';
import {useCampaignLabels} from 'hooks';
import {fromResources} from 'store/selectors';
import {isPending} from 'redux-saga-thunk';
import {managementInviteLinkRequest} from 'store/actions';

const formatTime = (timeString) => {
  const timeDifference = (Date.parse(timeString) - Date.now()) / 1000;

  if (timeDifference > 86400) {
    return `This link expires in ${Math.floor(timeDifference / 86400)} ${
      Math.floor(timeDifference / 86400) === 1 ? 'day' : 'days'
    }`;
  }
  if (timeDifference > 7200) {
    return `This link expires in ${Math.floor(timeDifference / 3600)} hours`;
  }
  if (timeDifference > 3600) {
    return `This link expires in ${Math.floor(timeDifference / 3600)} hour`;
  }
  return `This link expires in ${Math.floor(timeDifference / 60)} minutes `;
};

const InviteLinkDisplay = ({route = 'invite', inviteType, needle}) => {
  const dispatch = useDispatch();
  const [addLabel, setAddLabel] = useState(false);
  const [labelSelection, setLabelSelection] = useState(null);

  useEffect(() => {
    dispatch(managementInviteLinkRequest(inviteType, needle));
  }, [needle, dispatch, inviteType]);

  const labels = useCampaignLabels();
  const invite = useSelector((state) =>
    fromResources.getResourceDetail(state, 'inviteLinks', needle),
  );
  const loading = useSelector((state) => isPending(state, `${inviteType}InviteLink${needle}`));

  const host = window.location.hostname;
  const protocol = host.includes('localhost') ? 'http' : 'https';
  let inviteUrl = `${protocol}://${host}/${route}/${invite ? invite.key : ''}`;
  if (addLabel) {
    inviteUrl += `?label=${labelSelection || labels[0].name}`;
  }

  return (
    <div className="inviteLinkDisplay">
      <div>or, share an invite link</div>
      <div className={`linkBox ${loading ? 'loading' : ''}`}>
        {invite && !loading ? (
          <div>
            <p>{inviteUrl}</p>
            <CopyToClipboard text={inviteUrl}>
              <div className="copySection">
                <ContentCopy />
                <span className="tooltip">Copied!</span>
              </div>
            </CopyToClipboard>
          </div>
        ) : (
          <div />
        )}
      </div>
      {addLabel && (
        <InlineSelect
          type="large"
          handleChange={setLabelSelection}
          currentValue={labelSelection}
          options={labels}
          formatFunction={(label) => ({...label, value: label.name})}
        />
      )}
      <span className="inlineBottomExtras">
        {invite && invite.expires_at && <h4>{formatTime(invite.expires_at)}</h4>}
        {labels && labels.length > 0 && (
          <span role="button" tabIndex={-1} onClick={() => setAddLabel(!addLabel)}>
            <h4>{addLabel ? 'Remove group' : 'Invite to group'}</h4>
          </span>
        )}
      </span>
    </div>
  );
};

InviteLinkDisplay.propTypes = {
  route: PropTypes.string,
  inviteType: PropTypes.oneOf(['teams', 'campaigns']),
  needle: PropTypes.string,
};

export default InviteLinkDisplay;
