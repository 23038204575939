import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';

const AdvCheckbox = ({checkboxclass, input, ...props}) => (
  <Checkbox
    className={checkboxclass}
    checked={!!input.value}
    onChange={input.onChange}
    {...props}
  />
);

AdvCheckbox.propTypes = {
  labelStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  checkboxclass: PropTypes.string,
  input: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.node,
};

export default AdvCheckbox;
