/* eslint-disable jsx-a11y/no-static-element-interactions */
import {useState} from 'react';
import PropTypes from 'prop-types';
import {BlockPicker} from 'react-color';

import AdvWarning from 'components/components/AdvWarning';
import Button from 'components/molecules/Button';
import {CSSTransitionGroup} from 'react-transition-group';

const defaultColor = '#000';

const ColorPicker = ({input, meta}) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = (event) => {
    event.preventDefault();
    setShowPopup(!showPopup);
  };

  const closePopup = () => setShowPopup(false);

  const handleColorChange = (color) => {
    const {onChange} = input;
    onChange(color.hex);
  };

  return (
    <div className="colorPicker">
      <CSSTransitionGroup
        transitionName="slowFadeWarning"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {meta && meta.touched && meta.error && meta.submitFailed && (
          <AdvWarning>{meta.error}</AdvWarning>
        )}
      </CSSTransitionGroup>
      <div className="colorButtonGroup">
        <Button handleClick={togglePopup} type="colorButton">
          Choose Text Color
        </Button>
        <div className="colorPickerSwatch" style={{backgroundColor: input.value || defaultColor}} />
      </div>
      {showPopup && (
        <div className="colorPopover">
          <div className="colorCover" onClick={closePopup} />
          <BlockPicker color={input.value || defaultColor} onChangeComplete={handleColorChange} />
        </div>
      )}
    </div>
  );
};

ColorPicker.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default ColorPicker;
