import PropTypes from 'prop-types';

import DateTimeField from 'components/molecules/DateTimeField';

const DateRangeField = ({
  specifyTime,
  startAbsoluteStart,
  startAbsoluteEnd,
  formStartTime,
  formEndTime,
  startFieldName,
  endFieldName,
  endAbsoluteStart,
  endAbsoluteEnd,
  extendsBeforeCurrentDay,
  startDisabled,
  endDisabled,
  startPlaceholder,
  endPlaceholder,
  appendToBody,
}) => (
  <div className="datePickersWithHour">
    <DateTimeField
      specifyTime={specifyTime}
      initialValue={formStartTime}
      fieldName={startFieldName || 'start_time'}
      absoluteStart={startAbsoluteStart}
      absoluteEnd={startAbsoluteEnd}
      extendsBeforeCurrentDay={extendsBeforeCurrentDay}
      disabled={startDisabled}
      placeholder={startPlaceholder || 'Start Date'}
      appendToBody={appendToBody}
      title="Start"
    />

    <DateTimeField
      specifyTime={specifyTime}
      initialValue={formEndTime}
      fieldName={endFieldName || 'end_time'}
      absoluteStart={endAbsoluteStart}
      absoluteEnd={endAbsoluteEnd}
      extendsBeforeCurrentDay={extendsBeforeCurrentDay}
      disabled={endDisabled}
      placeholder={endPlaceholder || 'End Date'}
      appendToBody={appendToBody}
      title="End"
    />
  </div>
);

DateRangeField.propTypes = {
  specifyTime: PropTypes.bool,
  startAbsoluteStart: PropTypes.string,
  startAbsoluteEnd: PropTypes.string,
  endAbsoluteStart: PropTypes.string,
  endAbsoluteEnd: PropTypes.string,
  formStartTime: PropTypes.string,
  formEndTime: PropTypes.string,
  startFieldName: PropTypes.string,
  endFieldName: PropTypes.string,
  startDisabled: PropTypes.bool,
  endDisabled: PropTypes.bool,
  extendsBeforeCurrentDay: PropTypes.bool,
  startPlaceholder: PropTypes.string,
  endPlaceholder: PropTypes.string,
  appendToBody: PropTypes.bool,
};

export default DateRangeField;
