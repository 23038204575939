import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {profileUpdateRequest} from 'store/actions';
import {fromProfile} from 'store/selectors';

const useStreamPreviewSidebar = ({setHasCollisions, setTouchedRotations}) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => fromProfile.getFullProfile(state));

  const resetPositioning = useCallback(() => {
    setTouchedRotations([]);
    setHasCollisions(false);
  }, [setHasCollisions, setTouchedRotations]);

  const handleImageUpdate = useCallback(
    (e) => {
      e.persist();

      const file = e.target.files ? e.target.files[0] : {};
      dispatch(profileUpdateRequest({...profile, stream_preview_screenshot: file}));
    },
    [dispatch, profile],
  );

  const decrementImageIndex = useCallback((selectedImageIndexes, rotation) => {
    const currentIndex = selectedImageIndexes[rotation.id] || 0;
    if (currentIndex - 1 < 0) {
      return {
        ...selectedImageIndexes,
        [rotation.id]: rotation.images.length,
      };
    } else {
      return {
        ...selectedImageIndexes,
        [rotation.id]: currentIndex - 1,
      };
    }
  }, []);

  const incrementImageIndex = useCallback((selectedImageIndexes, rotation) => {
    const currentIndex = selectedImageIndexes[rotation.id] || 0;
    if (currentIndex + 1 < rotation.images.length) {
      return {
        ...selectedImageIndexes,
        [rotation.id]: currentIndex + 1,
      };
    } else {
      return {
        ...selectedImageIndexes,
        [rotation.id]: 0,
      };
    }
  }, []);

  return {
    resetPositioning,
    handleImageUpdate,
    decrementImageIndex,
    incrementImageIndex,
  };
};

export default useStreamPreviewSidebar;
