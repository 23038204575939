import PropTypes from 'prop-types';
import {Route, Redirect, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {fromAuth} from 'store/selectors';

const PrivateRoute = ({component: Component, ...rest}) => {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => fromAuth.isAuthenticated(state));

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: `${location.pathname}${location.search || ''}`},
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
