import PropTypes from 'prop-types';
import {Field} from 'react-final-form';

import Checkbox from 'components/atoms/Checkbox';

const PageFour = ({team}) => {
  return (
    <div className="page page4">
      <label htmlFor="Campaign Creation Form">
        Add New
        {team ? ' Sponsor' : ' Campaign'} - Settings
      </label>
      <div className="autoActiveCheckbox">
        <Field name="auto_active_invites" component={Checkbox} checkboxclass="muiCheckbox" />
        <p>Place broadcasters as inactive by default</p>
      </div>
      <div className="autoActiveCheckbox">
        <Field name="action_notifications" component={Checkbox} checkboxclass="muiCheckbox" />
        <p>Notify broadcasters of pending actions</p>
      </div>
    </div>
  );
};

PageFour.propTypes = {
  team: PropTypes.object,
};

export default PageFour;
