import {put, call, takeEvery} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import {FORM_ERROR} from 'final-form';

import history from 'localHistory';
import {
  resourceUpdateSuccess,
  resourceDetailReadSuccess,
  resourceDetailReadRequest,
} from 'store/resources/actions';
import * as schemas from 'store/resources/schemas';
import * as actions from './actions';

export function* setBroadcasterStatus(
  api,
  {data, resource, needle, fromBroadcaster},
  {status, thunk},
) {
  try {
    const newStatus = yield call([api, api.patch], `${resource}/${needle}/${status}/`, data);
    if (fromBroadcaster) {
      yield put(resourceUpdateSuccess('campaigns', {campaigns: newStatus}, thunk));
    } else {
      const normalizedDetail = normalize(newStatus, schemas.broadcasters);
      yield put(resourceUpdateSuccess('broadcasters', normalizedDetail.entities, thunk));
    }
  } catch (e) {
    yield put(actions.managementSetStatusFailure({[FORM_ERROR]: e.broadcaster[0]}, thunk));
  }
}

export function* getBroadcasterStatuses(api, {resource, needle}, {thunk}) {
  try {
    const broadcasters = yield call([api, api.get], `${resource}/${needle}/get_statuses/`);
    const normalizedBroadcasters = normalize(broadcasters, [schemas.broadcasters]);
    yield put(resourceUpdateSuccess('broadcasters', normalizedBroadcasters.entities, thunk));
  } catch (e) {
    yield put(actions.managementGetStatusesFailure(e, thunk));
  }
}

export function* inviteLinkRequest(api, {resource, needle}, {thunk}) {
  try {
    const link = yield call([api, api.get], `${resource}/${needle}/invite_code/`);
    const normalizedDetail = normalize(link, schemas[`${resource}InviteLinks`]);
    yield put(
      resourceDetailReadSuccess(`${resource}InviteLinks`, normalizedDetail.entities, thunk),
    );
  } catch (e) {
    yield put(actions.managementInviteLinkFailure(e, thunk));
  }
}

export function* setChatbotRequest(api, {code}, {thunk, campaign}) {
  try {
    const campaignData = yield call(
      [api, api.patch],
      `campaigns/${campaign}/set_chatbot_account/`,
      {code},
    );
    const normalizedCampaign = normalize(campaignData, schemas.campaigns);
    yield put(resourceUpdateSuccess('campaigns', normalizedCampaign.entities, thunk));
    yield call(history.push, `/campaigns/${campaign}/management`);
    if (campaignData.team) {
      // Need to update the `approved_chatbots` if the campaign has a team, otherwise
      // the select chatbot dropdown won't have the new chatbot to display.  Cleanest
      // way to ensure correct team data is just to request it from the server
      yield put(resourceDetailReadRequest('teams', campaignData.team));
    }
  } catch (e) {
    yield put(actions.managementSetChatbotFailure(e, thunk));
  }
}
export function* setTeamChatbotRequest(api, {code}, {thunk, team}) {
  try {
    const teamData = yield call([api, api.patch], `teams/${team}/set_default_chatbot/`, {code});
    const normalizedTeam = normalize(teamData, schemas.teams);
    yield put(resourceUpdateSuccess('teams', normalizedTeam.entities, thunk));
    yield call(history.push, '/dashboard');
  } catch (e) {
    yield put(actions.managementSetTeamChatbotFailure(e, thunk));
  }
}

export function* setApprovedChatbotRequest(api, {chatbot}, {thunk, campaign}) {
  try {
    const campaignData = yield call(
      [api, api.patch],
      `campaigns/${campaign}/set_approved_chatbot/`,
      {new_chatbot: chatbot},
    );
    const normalizedCampaign = normalize(campaignData, schemas.campaigns);
    yield put(resourceUpdateSuccess('campaigns', normalizedCampaign.entities, thunk));
  } catch (e) {
    yield put(actions.managementSetApprovedChatbotFailure(e, thunk));
  }
}
export function* setTeamApprovedChatbotRequest(api, {chatbot}, {thunk, team}) {
  try {
    const teamData = yield call([api, api.patch], `teams/${team}/set_approved_chatbot/`, {
      new_chatbot: chatbot,
    });
    const normalizedCampaign = normalize(teamData, schemas.teams);
    yield put(resourceUpdateSuccess('teams', normalizedCampaign.entities, thunk));
  } catch (e) {
    yield put(actions.managementSetTeamApprovedChatbotFailure(e, thunk));
  }
}

export function* setTeamMemberSquadRequest(api, {squad, broadcaster}, {thunk}) {
  try {
    const squadData = yield call([api, api.patch], `squads/${squad}/add_broadcaster/`, {
      broadcaster,
    });
    const normalizedDetail = normalize(squadData, schemas.squads);
    yield put(resourceUpdateSuccess('squads', normalizedDetail.entities, thunk));
  } catch (e) {
    const formError = {
      [FORM_ERROR]: e.non_field_errors ? e.non_field_errors[0] : JSON.stringify(e),
    };
    yield put(actions.managementSetTeamMemberSquadFailure(formError, thunk));
  }
}

export function* removeTeamMemberSquadRequest(api, {squad, broadcaster}, {thunk}) {
  try {
    const squadData = yield call([api, api.patch], `squads/${squad}/remove_broadcaster/`, {
      broadcaster,
    });
    const normalizedDetail = normalize(squadData, schemas.squads);
    yield put(resourceUpdateSuccess('squads', normalizedDetail.entities, thunk));
  } catch (e) {
    const formError = {
      [FORM_ERROR]: e.non_field_errors ? e.non_field_errors[0] : JSON.stringify(e),
    };
    yield put(actions.managementRemoveTeamMemberSquadFailure(formError, thunk));
  }
}

export function* setCampaignSquadRequest(api, {squad, campaign}, {thunkA, thunkB}) {
  try {
    const response = yield call([api, api.patch], `campaigns/${campaign}/add_squad/`, {squad});
    const broadcastersData = normalize(response.broadcasters, [schemas.broadcasters]);
    const campaignData = normalize(response.campaign, schemas.campaigns);
    yield put(resourceUpdateSuccess('broadcasters', broadcastersData.entities, thunkA));
    yield put(resourceUpdateSuccess('campaigns', campaignData.entities, thunkB));
  } catch (e) {
    const formError = {
      [FORM_ERROR]: e.non_field_errors ? e.non_field_errors[0] : JSON.stringify(e),
    };
    yield put(actions.managementSetCampaignSquadFailure(formError, thunkA));
    yield put(actions.managementSetCampaignSquadFailure(formError, thunkB));
  }
}

export function* removeCampaignSquadRequest(api, {squad, campaign}, {thunk}) {
  try {
    const campaignData = yield call([api, api.patch], `campaigns/${campaign}/remove_squad/`, {
      squad,
    });
    const normalizedDetail = normalize(campaignData, schemas.campaigns);
    yield put(resourceUpdateSuccess('campaigns', normalizedDetail.entities, thunk));
  } catch (e) {
    const formError = {
      [FORM_ERROR]: e.non_field_errors ? e.non_field_errors[0] : JSON.stringify(e),
    };
    yield put(actions.managementRemoveCampaignSquadFailure(formError, thunk));
  }
}

export function* watchSetBroadcasterStatus(api, {payload, meta}) {
  yield call(setBroadcasterStatus, api, payload, meta);
}

export function* watchGetBroadcasterStatuses(api, {payload, meta}) {
  yield call(getBroadcasterStatuses, api, payload, meta);
}

export function* watchInviteLinkRequest(api, {payload, meta}) {
  yield call(inviteLinkRequest, api, payload, meta);
}

export function* watchSetChatbotRequest(api, {payload, meta}) {
  yield call(setChatbotRequest, api, payload, meta);
}

export function* watchSetTeamChatbotRequest(api, {payload, meta}) {
  yield call(setTeamChatbotRequest, api, payload, meta);
}

export function* watchSetApprovedChatbotRequest(api, {payload, meta}) {
  yield call(setApprovedChatbotRequest, api, payload, meta);
}

export function* watchSetTeamApprovedChatbotRequest(api, {payload, meta}) {
  yield call(setTeamApprovedChatbotRequest, api, payload, meta);
}

export function* watchSetTeamMemberSquadRequest(api, {payload, meta}) {
  yield call(setTeamMemberSquadRequest, api, payload, meta);
}

export function* watchRemoveTeamMemberSquadRequest(api, {payload, meta}) {
  yield call(removeTeamMemberSquadRequest, api, payload, meta);
}

export function* watchSetCampaignSquadRequest(api, {payload, meta}) {
  yield call(setCampaignSquadRequest, api, payload, meta);
}

export function* watchRemoveCampaignSquadRequest(api, {payload, meta}) {
  yield call(removeCampaignSquadRequest, api, payload, meta);
}

export default function* sagas({api}) {
  yield takeEvery(actions.MANAGEMENT_SET_STATUS_REQUEST, watchSetBroadcasterStatus, api);
  yield takeEvery(actions.MANAGEMENT_GET_STATUSES_REQUEST, watchGetBroadcasterStatuses, api);
  yield takeEvery(actions.MANAGEMENT_INVITE_LINK_REQUEST, watchInviteLinkRequest, api);
  yield takeEvery(actions.MANAGEMENT_SET_CHATBOT_REQUEST, watchSetChatbotRequest, api);
  yield takeEvery(actions.MANAGEMENT_SET_TEAM_CHATBOT_REQUEST, watchSetTeamChatbotRequest, api);
  yield takeEvery(
    actions.MANAGEMENT_SET_APPROVED_CHATBOT_REQUEST,
    watchSetApprovedChatbotRequest,
    api,
  );
  yield takeEvery(
    actions.MANAGEMENT_SET_TEAM_APPROVED_CHATBOT_REQUEST,
    watchSetTeamApprovedChatbotRequest,
    api,
  );
  yield takeEvery(
    actions.MANAGEMENT_SET_TEAM_MEMBER_SQUAD_REQUEST,
    watchSetTeamMemberSquadRequest,
    api,
  );
  yield takeEvery(
    actions.MANAGEMENT_REMOVE_TEAM_MEMBER_SQUAD_REQUEST,
    watchRemoveTeamMemberSquadRequest,
    api,
  );
  yield takeEvery(actions.MANAGEMENT_SET_CAMPAIGN_SQUAD_REQUEST, watchSetCampaignSquadRequest, api);
  yield takeEvery(
    actions.MANAGEMENT_REMOVE_CAMPAIGN_SQUAD_REQUEST,
    watchRemoveCampaignSquadRequest,
    api,
  );
}
