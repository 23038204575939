import {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {CSSTransitionGroup} from 'react-transition-group';
import {Upload, Close} from 'mdi-material-ui';

import IconButton from 'components/components/IconButton';
import AdvWarning from 'components/components/AdvWarning';
import {getFileDisplayName} from 'utils/campaignTools';

const FileInput = ({input, allowedInputs, meta, placeholder = 'Choose an image', clearField}) => {
  const [hasFile, setHasFile] = useState(false);
  const onChange = useCallback(
    (e) => {
      setHasFile(true);
      input.onChange(e.target.files ? e.target.files[0] : {});
    },
    [input],
  );

  let displayName = placeholder;
  if (hasFile && input.value && input.value.name) {
    displayName = input.value.name;
  }
  if (hasFile && meta.initial) {
    displayName = getFileDisplayName(meta.initial);
  }

  const allowedInputTypes = useMemo(() => {
    switch (allowedInputs) {
      case 'live-graphics':
        return 'image/gif,image/jpeg,image/jpg,image/png,video/webm,video/mp4';
      case 'suggested-tweet':
      case 'banner-graphics':
        return 'image/jpeg,image/jpg,image/png';
      case 'fullscreen-video':
        return 'video/webm,video/mp4';
      case 'advocate-widget':
        return 'application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip';
      default:
        return 'image/gif,image/jpeg,image/jpg,image/png,video/webm,video/mp4';
    }
  }, [allowedInputs]);

  return (
    <div className="content-wrapper">
      <CSSTransitionGroup
        transitionName="slowFadeWarning"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {meta.touched && meta.error && meta.submitFailed && <AdvWarning>{meta.error}</AdvWarning>}
      </CSSTransitionGroup>
      <div className="file-wrapper">
        {input.value && (
          <Close
            className="remove"
            onClick={() => {
              setHasFile(false);
              clearField();
            }}
            tabIndex={0}
            role="button"
            aria-label="close"
          />
        )}
        <label className={hasFile ? 'hasFile file-label' : 'file-label'}>
          <IconButton label="file-input">
            <input
              className="file-input"
              type="file"
              accept={allowedInputTypes}
              value=""
              onChange={onChange}
            />
            <Upload />
          </IconButton>
          <span className="filename">{displayName}</span>
        </label>
      </div>
    </div>
  );
};

FileInput.propTypes = {
  input: PropTypes.object,
  allowedInputs: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  clearField: PropTypes.func,
};

export default FileInput;
