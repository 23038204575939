import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {isPending} from 'redux-saga-thunk';
import {complianceOverrideRequest} from 'store/actions';
import Button from 'components/molecules/Button';
import AdvModal from 'containers/AdvModal';
import useModal from 'hooks/useModal';

const BroadcasterComplianceOverrideModal = ({complianceAction, broadcaster}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => isPending(state, 'complianceOverrideRequest'));
  const [, modalHide] = useModal();

  const triggerComplianceOverride = useCallback(
    (broadcaster, action, versionId) =>
      dispatch(complianceOverrideRequest(broadcaster, action, versionId, complianceAction)),
    [complianceAction, dispatch],
  );

  return (
    <AdvModal
      name={`override-compliance-${complianceAction.component}-${broadcaster}`}
      contentLabel="Override Broadcaster Score"
    >
      <label htmlFor="override compliance score">
        {'Are you sure you want to override '}
        {broadcaster}
        &apos;s compliance?
      </label>
      <div className="extraText" />
      <div className="extraText">
        {'You may override '}
        {broadcaster}
        &apos;s compliance for component &quot;
        {complianceAction.name}
        {'" and mark that as '}
        {complianceAction.compliant ? 'noncompliant' : 'compliant'} {'if you are '}
        {complianceAction.compliant ? 'not satified' : 'satisfied'} with their execution.
      </div>
      <Button
        loading={loading}
        handleClick={() => {
          triggerComplianceOverride(
            broadcaster,
            complianceAction.compliant ? 'override_to_noncompliant' : 'override_to_compliant',
            complianceAction.activeVersion.id,
          );
          modalHide(`override-compliance-${complianceAction.component}-${broadcaster}`);
        }}
      >
        Mark as {complianceAction.compliant ? 'Noncompliant' : 'Compliant'}
      </Button>
    </AdvModal>
  );
};

BroadcasterComplianceOverrideModal.propTypes = {
  complianceAction: PropTypes.object,
  broadcaster: PropTypes.string,
};

export default BroadcasterComplianceOverrideModal;
