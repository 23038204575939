import {take, call, put, fork} from 'redux-saga/effects';
import {resourceUpdateSuccess} from '../resources/actions';
import {ASSIGN_BROADCASTER_LINK_REQUEST, assignBroadcasterLinkFailure} from './actions';

export function* executeAssignBroadcasterLinkRequest(api, broadcasterUsername, link, thunk) {
  const ASSIGN_BROADCASTER_LINK_ENDPOINT_URL = 'assign-broadcaster-link/';
  const requestData = {
    link,
    broadcasterUsername,
  };

  try {
    const data = yield call([api, api.post], ASSIGN_BROADCASTER_LINK_ENDPOINT_URL, requestData);
    const broadcasterData = {broadcasters: {}};
    broadcasterData.broadcasters[broadcasterUsername] = data;
    // this endpoint returns data identical to a UserDetailRequest
    // so we use the existing reducers etc.
    yield put(resourceUpdateSuccess('broadcaster', broadcasterData, thunk));
  } catch (error) {
    yield put(assignBroadcasterLinkFailure({error, thunk}));
  }
}

export function* watchAssignBroadcasterLinkRequest(api) {
  while (true) {
    const {
      payload: {broadcasterUsername, link},
      meta: {thunk},
    } = yield take(ASSIGN_BROADCASTER_LINK_REQUEST);
    yield call(executeAssignBroadcasterLinkRequest, api, broadcasterUsername, link, thunk);
  }
}

export default function* sagas({api}) {
  yield fork(watchAssignBroadcasterLinkRequest, api);
}
