import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';

import UserRoute from './UserRoute';
import {fromProfile} from 'store/selectors';

const AdminRoute = ({component, ...rest}) => {
  const isAdmin = useSelector((state) => fromProfile.isAdmin(state));

  if (!isAdmin) {
    return <Redirect {...rest} to="/dashboard" />;
  }

  return <UserRoute {...rest} ManagerComponent={component} />;
};

AdminRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default AdminRoute;
