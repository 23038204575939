import {useEffect, useState, useMemo, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {metricsRawDataRequest} from 'store/actions';
import {fromMetrics} from 'store/selectors';
import {getResourceSelector} from 'store/resources/selectors';
import {useCampaign, useCampaignLabels, useBroadcasters} from 'hooks';
import {useDeepMemo} from 'hooks/utils';

const componentTypeSelector = getResourceSelector('componentTypes');

const useCampaignFilters = () => {
  const dispatch = useDispatch();
  const {campaignSlug} = useParams();

  useEffect(() => {
    dispatch(metricsRawDataRequest('clicks', {campaign: campaignSlug}));
  }, [campaignSlug, dispatch]);

  const campaign = useCampaign();
  const labels = useCampaignLabels();
  const componentTypes = useSelector((state) => componentTypeSelector(state));
  const rawClicks = useSelector((state) => fromMetrics.getRaw(state, 'clicks'));
  const campaignBroadcasters = useBroadcasters();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [lastUsedFilter, setLastUsedFilter] = useState('');
  const [uniqueClicksOnly, setUniqueClicksOnly] = useState(false);
  const [selectedComponentTypes, setSelectedComponentTypes] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [selectedBroadcasters, setSelectedBroadcasters] = useState([]);

  const filtersFromClicks = useMemo(() => {
    // Generates filters from the list of unique ComponentTypes and generates Broadcasters from raw click data
    // Get all broadcaster usernames and ComponentType slugs from clicks
    const filterList = rawClicks.reduce(
      (filterLists, click) => ({
        componentTypes: filterLists.componentTypes.has(click.component_type)
          ? filterLists.componentTypes
          : filterLists.componentTypes.add(click.component_type),
        broadcasters:
          !click.broadcaster || filterLists.broadcasters.has(click.broadcaster)
            ? filterLists.broadcasters
            : filterLists.broadcasters.add(click.broadcaster),
      }),
      {componentTypes: new Set(), broadcasters: new Set()},
    );

    // convert broadcasters into objects for use in MultiSelect
    filterList.broadcasters = [...filterList.broadcasters].map((broadcaster) => ({
      username: broadcaster,
    }));

    filterList.componentTypes = componentTypes.filter((type) =>
      [...filterList.componentTypes].includes(type.slug),
    );

    return filterList;
  }, [componentTypes, rawClicks]);

  const activeBroadcasters = useDeepMemo(() => {
    return campaignBroadcasters
      ?.filter((broadcaster) =>
        broadcaster?.campaign_statuses[campaignSlug]
          ? ['Active'].includes(broadcaster.campaign_statuses[campaignSlug].status)
          : false,
      )
      .map((broadcaster) => broadcaster.username);
  }, [campaignBroadcasters, campaignSlug]);

  // Merge broadcaster list from click data with campaign broadcasters,
  // so no active broadcasters are left out
  const broadcasters = useDeepMemo(() => {
    const filterList = [...filtersFromClicks.broadcasters];
    const usernames = new Set(filterList.map((b) => b.username));

    activeBroadcasters.forEach((broadcaster) => {
      if (!usernames.has(broadcaster)) {
        filterList.push({username: broadcaster});
        usernames.add(broadcaster);
      }
    });

    filterList.sort((a, b) => a.username.localeCompare(b.username));
    return filterList;
  }, [activeBroadcasters, filtersFromClicks]);

  const absStart = useMemo(
    () => campaign?.start_date || new Date().toISOString(),
    [campaign?.start_date],
  );
  const absEnd = useMemo(
    () => campaign?.end_date || new Date().toISOString(),
    [campaign?.end_date],
  );

  useEffect(() => {
    setStartDate(campaign?.start_date || new Date().toISOString());
    setEndDate(campaign?.end_date || new Date().toISOString());
  }, [campaign]);

  useEffect(() => {
    setSelectedBroadcasters(broadcasters);
    setSelectedComponentTypes(filtersFromClicks.componentTypes);
  }, [filtersFromClicks, broadcasters]);

  const initialFilters = useMemo(
    () => ({
      componentTypes: filtersFromClicks.componentTypes,
      broadcasters,
      absStart,
      absEnd,
      labels,
    }),
    [absEnd, absStart, filtersFromClicks, labels, broadcasters],
  );

  const updateBroadcasterFilter = useCallback((selectedBroadcasters) => {
    setSelectedBroadcasters(selectedBroadcasters);
    setSelectedLabels([]);
    setLastUsedFilter('channels');
  }, []);

  const updateBroadcasterFilterByLabels = useCallback((selectedLabels) => {
    const selectedUsernames = selectedLabels.reduce(
      (totalBroadcasters, label) => [...totalBroadcasters, ...label.broadcasters],
      [],
    );
    setSelectedBroadcasters(
      selectedUsernames.map((broadcaster) => ({
        username: broadcaster,
      })),
    );
    setSelectedLabels(selectedLabels);
    setLastUsedFilter('labels');
  }, []);

  const updateDateFilter = useCallback((newStartDate, newEndDate) => {
    setIsFiltered(true);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, []);

  const filters = useMemo(
    () => ({
      startDate,
      endDate,
      uniqueClicksOnly,
      selectedComponentTypes,
      selectedLabels,
      selectedBroadcasters,
    }),
    [
      endDate,
      selectedBroadcasters,
      selectedComponentTypes,
      selectedLabels,
      startDate,
      uniqueClicksOnly,
    ],
  );

  return {
    campaign,
    initialFilters,
    filters,
    updateBroadcasterFilter,
    updateBroadcasterFilterByLabels,
    setSelectedComponentTypes,
    updateDateFilter,
    setUniqueClicksOnly,
    isFiltered,
    lastUsedFilter,
    setIsFiltered,
  };
};

export default useCampaignFilters;
