import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import Helmet from 'react-helmet';

import {authCheckStatus} from 'store/actions';
import NotFoundPage from 'components/components/NotFoundPage';
import {
  AdminRoute,
  BroadcasterRoute,
  CampaignManageRoute,
  ManagerRoute,
  PrivateRoute,
  RootRoute,
  UserManageRoute,
  UserRoute,
} from 'components/components/Route';
import Login from 'components/views/Login';
import Auth from 'components/views/Auth';
import Invite from 'components/views/Login/components/Invite';

import ManagerProfile from 'components/views/Manager/pages/ManagerProfile';
import OverallAdmin from 'components/views/Manager/pages/OverallAdmin';

import ManagerDashboard from 'components/views/Dashboard/pages/ManagerDashboard';

import CampaignOverview from 'components/views/Campaign/pages/CampaignOverview';
import CampaignChannels from 'components/views/Campaign/pages/CampaignChannels';
import CampaignComponents from 'components/views/Campaign/pages/CampaignComponents';
import CampaignMetrics from 'components/views/Campaign/pages/CampaignMetrics';
import CampaignCompliance from 'components/views/Campaign/pages/CampaignCompliance';
import CampaignManagement from 'components/views/Campaign/pages/CampaignManagement';

import BroadcasterCampaigns from 'components/views/Broadcaster/pages/BroadcasterCampaigns';
import BroadcasterChatbot from 'components/views/Broadcaster/pages/BroadcasterChatbot';
import BroadcasterDashboard from 'components/views/Broadcaster/pages/BroadcasterDashboard';
import BroadcasterLinks from 'components/views/Broadcaster/pages/BroadcasterLinks';
import BroadcasterPanels from 'components/views/Broadcaster/pages/BroadcasterPanels';
import BroadcasterProfile from 'components/views/Broadcaster/pages/BroadcasterProfile';
import BroadcasterTwitter from 'components/views/Broadcaster/pages/BroadcasterTwitter';
import BroadcasterStreamPreview from 'components/views/Broadcaster/pages/BroadcasterStreamPreview';
import BroadcasterWatchParty from 'components/views/Broadcaster/pages/BroadcasterWatchParty';

import UserAdmin from 'components/views/Manager/pages/UserAdmin';

import ShopifyInstallPage from 'components/views/Shopify/pages/ShopifyInstallPage';
import ShopifyConfirmPage from 'components/views/Shopify/pages/ShopifyConfirmPage';

import SignUp from 'components/views/SignUp';

import {images} from 'adv-theme';

const App = () => {
  const dispatch = useDispatch();

  dispatch(authCheckStatus());

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div id="root">
      <Helmet titleTemplate="%s | Advocate">
        <title>Welcome!</title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <link rel="icon" href={images.faviconLogo || images.squareLogo} />
      </Helmet>
      <Switch>
        <RootRoute path="/" exact />
        <Route path="/login" component={Login} />
        <Route path="/auth" component={Auth} />
        <Route path="/invite/:inviteKey" component={() => <Invite to="campaign" />} />
        <Route path="/join/:inviteKey" component={() => <Invite to="team" />} />
        <Route path="/sign-up" component={SignUp} />
        <UserRoute
          path="/dashboard"
          BroadcasterComponent={BroadcasterDashboard}
          ManagerComponent={ManagerDashboard}
        />
        <UserRoute
          path="/profile"
          BroadcasterComponent={BroadcasterProfile}
          ManagerComponent={ManagerProfile}
        />
        <ManagerRoute path="/campaigns/:campaignSlug/metrics" component={CampaignMetrics} />
        <ManagerRoute path="/campaigns/:campaignSlug/channels" component={CampaignChannels} />
        <ManagerRoute path="/campaigns/:campaignSlug/components" component={CampaignComponents} />
        <CampaignManageRoute
          path="/campaigns/:campaignSlug/management"
          component={CampaignManagement}
        />
        <ManagerRoute path="/campaigns/:campaignSlug/compliance" component={CampaignCompliance} />
        <ManagerRoute path="/campaigns/:campaignSlug" component={CampaignOverview} />
        <UserManageRoute path="/managers" component={UserAdmin} />
        <Route path="/shopify/install" component={ShopifyInstallPage} />
        <PrivateRoute path="/shopify/confirm" component={ShopifyConfirmPage} />
        <BroadcasterRoute path="/stream-preview" component={BroadcasterStreamPreview} />
        <BroadcasterRoute path="/campaigns" component={BroadcasterCampaigns} />
        <BroadcasterRoute path="/panels" component={BroadcasterPanels} />
        <BroadcasterRoute path="/twitter" component={BroadcasterTwitter} />
        <BroadcasterRoute path="/chatbot" component={BroadcasterChatbot} />
        <BroadcasterRoute path="/links" component={BroadcasterLinks} />
        <BroadcasterRoute path="/watch-party" component={BroadcasterWatchParty} />
        <AdminRoute path="/admin" component={OverallAdmin} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  );
};

export default App;
