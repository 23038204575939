import {useState} from 'react';
import {useSelector} from 'react-redux';
import {isPending} from 'redux-saga-thunk';
import {CSSTransitionGroup} from 'react-transition-group';
import {fromResources, fromProfile} from 'store/selectors';
import {useCampaigns, useBroadcasterActions, useComponents} from 'hooks';
import EmptySplash from 'components/molecules/EmptySplash';
import Button from 'components/molecules/Button';
import TweetPreview from 'components/molecules/TweetPreview';
import TwitterProfileDisplay from '../../components/TwitterProfileDisplay';
import BroadcasterComponentTemplate from '../../components/BroadcasterComponentTemplate';
import BroadcasterTwitterAction from './components/BroadcasterTwitterAction';
import {AdvModal} from 'containers';
import ActionCompleteForm from '../../forms/ActionCompleteForm';
import {Twitter} from 'mdi-material-ui';
import {getTweetIdFromUrl} from 'utils/campaignTools';

const BroadcasterTwitter = () => {
  const campaigns = useCampaigns();
  const loading = useSelector(
    (state) =>
      (isPending(state, 'componentsListRead') &&
        !fromResources.resourceExists('components')(state)) ||
      (isPending(state, 'broadcasterActionsListRead') &&
        !fromResources.resourceExists('broadcasterActions')(state)),
  );
  const actions = useBroadcasterActions();
  const components = useComponents();
  const profile = useSelector((state) => fromProfile.getFullProfile(state));

  const twitterComponents = components
    .filter(
      (component) =>
        ['suggested-tweet', 'suggested-retweet'].includes(component.type) &&
        !component.is_deleted &&
        component.is_ready,
    )
    .map((component) => ({
      ...component,
      campaign: campaigns.find((c) => c.slug === component.campaign),
    }));

  const filteredActions = actions
    .filter((action) => ['twitter-send-tweet', 'twitter-retweet'].includes(action.type.slug))
    .map((action) => ({
      ...action,
      component: twitterComponents.find((c) => c.id === action.version.component_id),
    }));

  const filteredUndeletedActions = filteredActions.filter((a) => !a.completion_time);

  const sentTweets = filteredActions
    .map((a) => (a.completion_time ? a.completion_data.tweet_url : null))
    .filter((a) => !!a);

  const isBlocked = !(profile && profile.profile.has_twitter_oauth && profile.profile.twitter);

  const [selectedView, setSelectedView] = useState(
    filteredUndeletedActions.length === 0 && sentTweets.length > 0
      ? 'Sent Tweets'
      : 'Required Actions',
  );

  return (
    <BroadcasterComponentTemplate loading={loading} loadBroadcasterActions loadComponents>
      <span className="typeSection">
        <h2 className="typeSectionName twitter">
          <Twitter />
          {' Twitter Campaign Actions'}
        </h2>
        <a href="https://twitter.com/?lang=en" target="_blank" rel="noopener noreferrer">
          <Button type="hasIcon">Go To Twitter</Button>
        </a>
      </span>
      <div className="broadcasterComponents">
        {sentTweets.length > 0 && (
          <div className="subNavbar">
            <div className="subNavbarItemWrapper">
              {['Required Actions', 'Sent Tweets'].map((tab) => (
                <div
                  className={`subNavbarItem ${tab === selectedView ? 'active' : ''}`}
                  key={tab}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setSelectedView(tab);
                  }}
                >
                  {tab}
                </div>
              ))}
            </div>
          </div>
        )}

        {isBlocked && (
          <div className="blockedMessage">
            <h3>
              Connect your Twitter account on your profile to send Tweets Automatically
              <TwitterProfileDisplay alternateMessage="Connect Twitter" />
            </h3>
          </div>
        )}

        {selectedView === 'Required Actions' && filteredUndeletedActions.length > 0 && (
          <CSSTransitionGroup
            transitionName="actionCompleted"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={1500}
          >
            {filteredUndeletedActions
              .sort((a, b) => (b.version.created > a.version.created ? 1 : -1))
              .map((action, index) => (
                <BroadcasterTwitterAction
                  action={action}
                  index={index + 1}
                  username={profile && profile.username}
                  isBlocked={isBlocked}
                  component={action.component}
                  blockedMessage="Connect your Twitter account on your profile to send Tweets"
                  key={`action-${action.id}`}
                />
              ))}
          </CSSTransitionGroup>
        )}
        {selectedView === 'Sent Tweets' &&
          sentTweets.map((tweet) => (
            <span key={getTweetIdFromUrl(tweet)}>
              <TweetPreview tweetId={getTweetIdFromUrl(tweet)} />
            </span>
          ))}
      </div>

      {selectedView === 'Required Actions' && filteredUndeletedActions.length === 0 && (
        <EmptySplash loading={loading}>
          <h3>You&apos;ve completed all your required actions!</h3>
        </EmptySplash>
      )}

      {/* Confirm Modals */}
      {filteredActions.map((action) => (
        <AdvModal
          name={`action-${action.id}-complete`}
          key={`action-${action.id}-complete`}
          contentLabel="Component Confirm"
        >
          <ActionCompleteForm
            action={action}
            initialData={action}
            modalName={`action-${action.id}-complete`}
          />
        </AdvModal>
      ))}
    </BroadcasterComponentTemplate>
  );
};

export default BroadcasterTwitter;
