import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

const NavbarTab = ({children, target, isDisabled}) => (
  <NavLink
    className={`navbar-item ${isDisabled ? 'is-disabled' : ''}`}
    tabIndex={-1}
    activeClassName="is-active"
    to={target}
    exact
  >
    {children}
  </NavLink>
);

NavbarTab.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  target: PropTypes.node.isRequired,
};

export default NavbarTab;
