import {useMemo} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FeaturedCampaignItem from '../FeaturedCampaignItem';
import {useCampaign} from 'hooks';

const FeaturedCampaignsList = ({broadcaster, featuredCampaigns}) => {
  const campaign = useCampaign();

  const [pastFeatures, currentFeatures, upcomingFeatures] = useMemo(() => {
    const now = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const past = [];
    const current = [];
    const future = [];

    featuredCampaigns.forEach((feature) => {
      if (feature.end_time < now) {
        past.push(feature);
      } else if (feature.start_time > now) {
        future.push(feature);
      } else {
        current.push(feature);
      }
    });

    return [past, current, future];
  }, [featuredCampaigns]);

  return (
    <div>
      <h1 className="featureListTitle">{broadcaster.username}&apos;s featured campaigns</h1>

      <div>
        {currentFeatures.length > 0 && (
          <>
            <h2 className="featureSectionTitle">Current Features</h2>
            <div className="featureSection">
              {currentFeatures.map((feature) => (
                <FeaturedCampaignItem
                  feature={feature}
                  editable
                  key={feature.id}
                  campaign={campaign}
                />
              ))}
            </div>
          </>
        )}

        {upcomingFeatures.length > 0 && (
          <>
            <h2 className="featureSectionTitle">Upcoming Features</h2>
            <div className="featureSection">
              {upcomingFeatures.map((feature) => (
                <FeaturedCampaignItem
                  feature={feature}
                  editable
                  key={feature.id}
                  campaign={campaign}
                />
              ))}
            </div>
          </>
        )}

        {pastFeatures.length > 0 && (
          <>
            <h2 className="featureSectionTitle">Past Features</h2>
            <div className="featureSection">
              {pastFeatures.map((feature) => (
                <FeaturedCampaignItem feature={feature} key={feature.id} campaign={campaign} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

FeaturedCampaignsList.propTypes = {
  broadcaster: PropTypes.object,
  featuredCampaigns: PropTypes.arrayOf(PropTypes.object),
};

export default FeaturedCampaignsList;
