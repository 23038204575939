import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {resourceUpdateRequest} from 'store/actions';
import {isPending, hasFailed} from 'redux-saga-thunk';

import Button from 'components/molecules/Button';
import AdvModal from 'containers/AdvModal';
import useModal from 'hooks/useModal';

const ReactivateModal = ({manager}) => {
  const dispatch = useDispatch();
  const [, modalHide] = useModal();
  const loading = useSelector((state) => isPending(state, 'managersUpdate'));
  const failed = useSelector((state) => hasFailed(state, 'managersUpdate'));
  const modalName = `reactivate-${manager.id}`;
  const updateRequest = useCallback(
    () =>
      dispatch(
        resourceUpdateRequest('managers', manager.id, {
          ...manager,
          user: {
            ...manager.user,
            is_active: true,
          },
        }),
      ).then(() => dispatch(modalHide(modalName))),
    [manager, dispatch, modalHide, modalName],
  );
  return (
    <AdvModal name={modalName} contentLabel={`${manager.email} Reactivate Modal`}>
      <div className="deleteLabel">{`Reactivate user "${manager.email}"?`}</div>
      {failed && (
        <div className="help is-danger centered">
          Couldn&apos;t reactivate user. Please try again later
        </div>
      )}
      <Button type="cancel" handleClick={() => modalHide(modalName)}>
        Cancel
      </Button>
      <Button loading={!!loading} handleClick={updateRequest}>
        Reactivate
      </Button>
    </AdvModal>
  );
};

ReactivateModal.propTypes = {
  manager: PropTypes.object.isRequired,
};

export default ReactivateModal;
