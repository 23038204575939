/* eslint-disable camelcase */
export const TWITTER_URL_REQUEST = 'TWITTER_URL_REQUEST';
export const TWITTER_URL_FAILURE = 'TWITTER_URL_FAILURE';

export const twitterURLRequest = () => ({
  type: TWITTER_URL_REQUEST,
  meta: {thunk: 'twitterUrl'},
});

export const twitterURLFailure = (error, thunk) => ({
  type: TWITTER_URL_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});

export const TWITTER_CONNECT_REQUEST = 'TWITTER_CONNECT_REQUEST';
export const TWITTER_CONNECT_SUCCESS = 'TWITTER_CONNECT_SUCCESS';
export const TWITTER_CONNECT_FAILURE = 'TWITTER_CONNECT_FAILURE';

export const twitterConnectRequest = (oAuthToken, oAuthVerifier) => ({
  type: TWITTER_CONNECT_REQUEST,
  payload: {oAuthToken, oAuthVerifier},
  meta: {thunk: 'twitterConnect'},
});

export const twitterConnectSuccess = (thunk) => ({
  type: TWITTER_CONNECT_SUCCESS,
  meta: {thunk},
});

export const twitterConnectFailure = (error, thunk) => ({
  type: TWITTER_CONNECT_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});

export const DISCORD_CONNECT_REQUEST = 'DISCORD_CONNECT_REQUEST';
export const DISCORD_CONNECT_SUCCESS = 'DISCORD_CONNECT_SUCCESS';
export const DISCORD_CONNECT_FAILURE = 'DISCORD_CONNECT_FAILURE';

export const discordConnectRequest = (code, callback_url) => ({
  type: DISCORD_CONNECT_REQUEST,
  payload: {code, callback_url},
  meta: {thunk: 'discordConnect'},
});

export const discordConnectSuccess = (thunk) => ({
  type: DISCORD_CONNECT_SUCCESS,
  meta: {thunk},
});

export const discordConnectFailure = (error, thunk) => ({
  type: DISCORD_CONNECT_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});
