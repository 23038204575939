import PropTypes from 'prop-types';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ContentCopy, LinkVariant, Check} from 'mdi-material-ui';
import useModal from 'hooks/useModal';
import IconButton from 'components/components/IconButton';

const CustomLinkComponentPreview = ({component, version, action}) => {
  const [modalShow] = useModal();

  const link = version.config.source_keyword
    ? `${version.link
        .split('/')
        .splice(0, version.link.split('/').length - 1)
        .join('/')}/${version.config.source_keyword}`
    : version.link;

  return (
    <div className={`componentPreviewWrapper customLink ${action ? 'hasBottomBar' : ''}`}>
      <div className="componentPreview">
        <div className="topBar">{component && <h2>{component.name}</h2>}</div>
        <div className="topBar">
          <LinkVariant />
          <h2>{link}</h2>
          <CopyToClipboard text={link}>
            <div className="copySection">
              <ContentCopy />
              <span className="tooltip">Copied!</span>
            </div>
          </CopyToClipboard>
        </div>
        {component && <p>{component.description}</p>}
      </div>
      {action && (
        <div className="bottomBar">
          <h4>Mark Link As Used</h4>
          <IconButton label="help" handleClick={() => modalShow(`action-${action.id}-complete`)}>
            <Check />
          </IconButton>
        </div>
      )}
      {component && component.campaign && (
        <h4 className="extraDetails">{component.campaign.name}</h4>
      )}
    </div>
  );
};

CustomLinkComponentPreview.propTypes = {
  component: PropTypes.object,
  version: PropTypes.object,
  action: PropTypes.object,
};

export default CustomLinkComponentPreview;
