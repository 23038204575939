import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import NavbarTab from './components/NavbarTab';
import {images} from 'adv-theme';
import {isCampaignEnded} from 'utils/numberFormats';

import {fromResources} from 'store/selectors';
import usePermissions from 'hooks/usePermissions';

const Navbar = () => {
  const {campaignSlug} = useParams();
  const {canEditCampaigns} = usePermissions();
  const isOpen = useState(true);
  const campaign = useSelector((state) => {
    return fromResources.getResourceDetail(state, 'campaigns', campaignSlug);
  });

  const menuClass = `navbar-menu ${isOpen ? 'is-active expand' : ''}`;
  const navbarItems = [
    {text: 'Overview', target: `/campaigns/${campaignSlug}`, requiresEditPermissions: false},
    {
      text: 'Components',
      target: `/campaigns/${campaignSlug}/components`,
      requiresEditPermissions: false,
    },
    {
      text: 'Channels',
      target: `/campaigns/${campaignSlug}/channels`,
      requiresEditPermissions: false,
    },
    {
      text: 'Metrics',
      target: `/campaigns/${campaignSlug}/metrics`,
      requiresEditPermissions: false,
    },
    {
      text: 'Compliance',
      target: `/campaigns/${campaignSlug}/compliance`,
      requiresEditPermissions: false,
    },
    {
      text: 'Management',
      target: `/campaigns/${campaignSlug}/management`,
      requiresEditPermissions: true,
      disabled: isCampaignEnded(campaign),
    },
  ];

  return (
    <nav className="navbar">
      <div className={menuClass}>
        {navbarItems.map((item) =>
          !item.requiresEditPermissions || canEditCampaigns ? (
            <NavbarTab
              isDisabled={item.disabled}
              key={`navbar-tab-${item.text}`}
              target={item.target}
            >
              {item.text}
            </NavbarTab>
          ) : null,
        )}
        {images && images.iconLogo && (
          <img className="iconLogo" src={images.iconLogo} alt="Advocate" />
        )}
      </div>
    </nav>
  );
};

export default Navbar;
