import {isEmpty} from 'lodash';
import {formatPercentage} from 'utils/numberFormats';

const formatEngagementStats = (campaign, overviewMetrics, overviewComparisonMetrics) => {
  const stats =
    !isEmpty(overviewMetrics) && !isEmpty(overviewComparisonMetrics)
      ? [
          {
            key: 'total-clicks',
            label: 'Total Clicks',
            value: overviewMetrics.microsite_visits,
            oldValue: overviewComparisonMetrics.microsite_visits - overviewMetrics.microsite_visits,
            firstThreshold: 0.01,
            secondThreshold: 0.3,
          },
          {
            key: 'avg-cvm',
            label: 'Average CVM',
            value: overviewMetrics.twitch_viewable_minutes
              ? overviewMetrics.microsite_visits /
                (overviewMetrics.twitch_viewable_minutes / 1000000)
              : 0,
            tooltip: 'Clicks per Million Viewed Minutes',
          },
        ]
      : [];
  if (campaign?.has_conversions && !isEmpty(overviewMetrics)) {
    stats.push(
      {
        key: 'total-conversions',
        label: 'Total Conversions',
        value: overviewMetrics.total_conversions,
      },
      {
        key: 'avg-cr',
        label: 'Conversion Rate',
        value: overviewMetrics.microsite_visits
          ? `${formatPercentage(
              (overviewMetrics.total_conversions / overviewMetrics.microsite_visits) * 100,
            )}%`
          : '0%',
      },
      {
        key: 'mvm-cr',
        label: 'Conversions By MVM',
        value: overviewMetrics.twitch_viewable_minutes
          ? overviewMetrics.total_conversions / (overviewMetrics.twitch_viewable_minutes / 1000000)
          : 0,
      },
    );
  }
  if (!isEmpty(overviewMetrics) && overviewMetrics.video_impressions) {
    stats.push({
      key: 'stream-impressions',
      label: 'Streamed Video Impressions',
      value: overviewMetrics.video_impressions,
    });
  }
  if (!isEmpty(overviewMetrics) && overviewMetrics.impressions) {
    stats.push({
      key: 'livegraphic-impressions',
      label: 'Live Graphic Impressions',
      value: overviewMetrics.impressions,
      tooltip: `Impressions calculated as ${campaign.seconds_per_impression} seconds of viewing`,
    });
  }
  if (!isEmpty(overviewMetrics) && overviewMetrics.display_count) {
    stats.push({
      key: 'livegraphic-display-count',
      label: 'Live Graphic Display Count',
      value: overviewMetrics.display_count,
      tooltip: 'Number of times graphics shown in stream',
    });
  }
  if (
    !isEmpty(overviewMetrics) &&
    overviewMetrics.impressions &&
    overviewMetrics.todays_impressions
  ) {
    stats.push({
      key: 'livegraphic-todays-impressions',
      label: "Today's Impressions",
      value: overviewMetrics.todays_impressions,
      tooltip: '"Today" is calculated using UTC, not local timezone',
    });
  }
  return stats;
};

export default formatEngagementStats;
