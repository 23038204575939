import PropTypes from 'prop-types';

const EmptySplash = ({loading, children}) => (
  <div className={loading ? 'emptySplash is-loading' : 'emptySplash'}>
    {children}
    <div className="adv-logo" />
  </div>
);

EmptySplash.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
};

export default EmptySplash;
