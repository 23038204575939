import PropTypes from 'prop-types';

const InlineSelect = ({
  options,
  currentValue,
  handleChange,
  formatFunction,
  loading,
  failed,
  errorMessage,
  type,
}) => {
  const formattedOptions = formatFunction ? options.map((o) => formatFunction(o)) : options;
  return (
    <div className={`inline-select-wrapper ${type}`}>
      {failed && <span>{errorMessage}</span>}
      <div className={`inline-select ${loading ? 'loading' : 'hasArrow'}`}>
        <select
          onChange={(e) => handleChange(e.target.value)}
          value={currentValue}
          disabled={loading}
        >
          {currentValue === null && <option value={null}>-</option>}
          {formattedOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

InlineSelect.propTypes = {
  options: PropTypes.array,
  currentValue: PropTypes.string,
  handleChange: PropTypes.func,
  formatFunction: PropTypes.func,
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,
  type: PropTypes.string,
};

export default InlineSelect;
