import PropTypes from 'prop-types';
import {formatPercentage, formatNumber} from 'utils/numberFormats';

const compare = (oldVal, newVal, firstThreshold, secondThreshold) => {
  const diff = (newVal - oldVal) / oldVal;
  if (Number.isFinite(diff) && diff > firstThreshold) {
    return diff > secondThreshold ? 'doubleGreen' : 'green';
  }
  if (diff < -1 * firstThreshold) {
    return diff < -1 * secondThreshold ? 'doubleRed' : 'red';
  }
  return 'neutral';
};

const getTooltip = (oldVal, newVal) => {
  const diff = (newVal - oldVal) / oldVal;
  if (diff > 0 && oldVal !== 0) {
    return `${formatPercentage(diff * 100)}% increase from ${formatNumber(oldVal)}`;
  }
  if (oldVal === 0) {
    return `increase from ${formatNumber(oldVal)}`;
  }
  return `${formatPercentage(diff * 100)}% decrease from ${formatNumber(oldVal)}`;
};

const ComparisonIndicator = ({oldValue, newValue, firstThreshold, secondThreshold}) => (
  <span className="comparisonIndicator">
    <div
      className={`comparisonArrow ${compare(oldValue, newValue, firstThreshold, secondThreshold)}`}
    />
    {compare(oldValue, newValue, firstThreshold, secondThreshold) !== 'neutral' && (
      <div className="tooltip">{getTooltip(oldValue, newValue)}</div>
    )}
  </span>
);

ComparisonIndicator.propTypes = {
  oldValue: PropTypes.number.isRequired,
  newValue: PropTypes.number.isRequired,
  secondThreshold: PropTypes.number.isRequired,
  firstThreshold: PropTypes.number.isRequired,
};

export default ComparisonIndicator;
