import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';

import {fromProfile} from 'store/selectors';
import Sidebar from './components/Sidebar';

const BaseTemplate = ({children}) => {
  Chatra('show');

  const profile = useSelector((state) => fromProfile.getFullProfile(state));

  useEffect(() => {
    if (profile) {
      Chatra('setIntegrationData', {
        username: profile.username,
        email: profile.email,
      });
    }
  }, [profile]);

  return (
    <div className="base-template-wrapper">
      <Sidebar />
      <div className="base-template-content">{children}</div>
    </div>
  );
};

BaseTemplate.propTypes = {
  children: PropTypes.node,
};

export default BaseTemplate;
