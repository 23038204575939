import {mergeWith, omit} from 'lodash';
import {initialState} from './selectors';
import {
  RESOURCE_CREATE_SUCCESS,
  RESOURCE_LIST_READ_SUCCESS,
  RESOURCE_DETAIL_READ_SUCCESS,
  RESOURCE_UPDATE_SUCCESS,
  RESOURCE_DELETE_SUCCESS,
  RESOURCE_ADD,
  RESOURCE_BATCH_UPDATE_SUCCESS,
} from './actions';

export default (state = initialState, {type, payload, meta}) => {
  switch (type) {
    case RESOURCE_CREATE_SUCCESS:
    case RESOURCE_LIST_READ_SUCCESS:
    case RESOURCE_DETAIL_READ_SUCCESS:
    case RESOURCE_UPDATE_SUCCESS:
    case RESOURCE_BATCH_UPDATE_SUCCESS:
    case RESOURCE_ADD:
      return mergeWith({}, state, payload, (objValue, srcValue) => {
        if (Array.isArray(srcValue)) {
          return srcValue;
        }
        return undefined;
      });
    case RESOURCE_DELETE_SUCCESS:
      return Object.keys(state).includes(meta.resource)
        ? {
            ...state,
            [meta.resource]: omit(state[meta.resource], payload.needle),
          }
        : state;
    default:
      return state;
  }
};
