import {useEffect, useState, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {intersection} from 'lodash';

import {fromMetrics} from 'store/selectors';
import {isPending} from 'redux-saga-thunk';
import {
  metricsDataRequest,
  resourceBatchUpdateRequest,
  resourceListReadRequest,
} from 'store/actions';
import {
  sortBroadcasters,
  hasValidStatus,
  broadcasterGamesAndCountries,
} from 'utils/broadcasterTools';
import usePermissions from 'hooks/usePermissions';

import {useCampaign, useCampaignLabels, useBroadcasters} from 'hooks';

const useCampaignChannels = () => {
  const {campaignSlug} = useParams();
  const dispatch = useDispatch();

  const campaign = useCampaign();
  const broadcasters = useBroadcasters();
  const labels = useCampaignLabels();

  const broadcasterStats = useSelector((state) => fromMetrics.getMetric(state, 'channels'));
  const userLoading = useSelector(
    (state) =>
      isPending(state, `campaignsDetailRead`) ||
      isPending(state, `broadcastersListRead`) ||
      isPending(state, 'profileRequest'),
  );
  const labelsLoading = useSelector((state) => isPending(state, 'labelsBatchUpdate'));

  const submitUpdatedLabel = (newLabel) =>
    dispatch(resourceBatchUpdateRequest('labels', [newLabel]));

  useEffect(() => {
    dispatch(metricsDataRequest('channels', {campaign: campaignSlug}));
  }, [campaignSlug, dispatch]);

  useEffect(() => {
    dispatch(resourceListReadRequest('featuredCampaigns', {campaign: campaignSlug}));
  }, [campaignSlug, dispatch]);

  const [selectedDateRange, setSelectedDateRange] = useState(campaign?.team ? '30' : 'all');
  const [selectedChannelsView, setSelectedChannelsView] = useState(true);
  const [editingLabel, setEditingLabel] = useState({broadcasters: []});
  const [selectedSort, setSelectedSort] = useState('total-clicks');
  const [gameCountryFilter, setGameCountryFilter] = useState('');
  const [multiselectMode, setMultiselectMode] = useState(false);
  const [showInactives, setShowInactives] = useState(false);
  const [channelsFilter, setChannelsFilter] = useState('');
  const {canEditCampaigns} = usePermissions();

  const options = {
    selectedChannelsView: {val: selectedChannelsView, set: setSelectedChannelsView},
    multiselectMode: {val: multiselectMode, set: setMultiselectMode},
    editingLabel: {val: editingLabel, set: setEditingLabel},
  };

  const filters = {
    selectedDateRange: {val: selectedDateRange, set: setSelectedDateRange},
    showInactives: {val: showInactives, set: setShowInactives},
    gameCountryFilter: {set: setGameCountryFilter},
    channelsFilter: {set: setChannelsFilter},
    selectedSort: {set: setSelectedSort},
  };

  const filteredBroadcastersByStatus = useMemo(() => {
    return broadcasters.filter((broadcaster) =>
      hasValidStatus(broadcaster, showInactives, campaignSlug),
    );
  }, [broadcasters, campaignSlug, showInactives]);

  const filteredBroadcastersByLabelOrName = useMemo(() => {
    const filteredLabels = labels.filter(
      (label) =>
        label.broadcasters.length >= 1 &&
        label.name.toLowerCase().indexOf(channelsFilter.toLowerCase()) !== -1,
    );
    const filteredChannelsFromLabels = filteredLabels.map((label) => label.broadcasters).flat();

    return filteredBroadcastersByStatus.filter(
      (broadcaster) =>
        filteredChannelsFromLabels.includes(broadcaster.username.toLowerCase()) ||
        broadcaster.username.toLowerCase().indexOf(channelsFilter.toLowerCase()) !== -1,
    );
  }, [channelsFilter, filteredBroadcastersByStatus, labels]);

  const filteredBroadcastersByGameOrCountry = useMemo(() => {
    return filteredBroadcastersByStatus.filter(
      (broadcaster) =>
        !gameCountryFilter ||
        broadcasterGamesAndCountries(broadcaster, broadcasterStats).indexOf(
          gameCountryFilter.toLowerCase(),
        ) !== -1,
    );
  }, [broadcasterStats, filteredBroadcastersByStatus, gameCountryFilter]);

  const sortedBroadcasters = useMemo(() => {
    return intersection(
      filteredBroadcastersByGameOrCountry,
      filteredBroadcastersByLabelOrName,
    ).sort(sortBroadcasters(broadcasterStats, selectedDateRange, selectedSort));
  }, [
    broadcasterStats,
    filteredBroadcastersByGameOrCountry,
    filteredBroadcastersByLabelOrName,
    selectedDateRange,
    selectedSort,
  ]);

  return {
    campaign,
    labelsLoading,
    labels,
    broadcasterStats,
    userLoading,
    campaignSlug,
    readOnly: !canEditCampaigns,
    submitUpdatedLabel,
    options,
    filters,
    broadcasters: sortedBroadcasters,
  };
};

export default useCampaignChannels;
