import {schema} from 'normalizr';

export const broadcasters = new schema.Entity('broadcasters', {}, {idAttribute: 'username'});

export const labels = new schema.Entity('labels');

export const campaigns = new schema.Entity(
  'campaigns',
  {
    labels: [labels],
  },
  {idAttribute: 'slug'},
);

export const squads = new schema.Entity('squads');

export const teams = new schema.Entity('teams', {
  squads: [squads],
});

export const user = new schema.Entity(
  'user',
  {
    campaigns: [campaigns],
    broadcasters: [broadcasters],
    teams: [teams],
  },
  {idAttribute: 'username'},
);

export const componentTypes = new schema.Entity('componentTypes', {}, {idAttribute: 'slug'});

export const campaignLinks = new schema.Entity(
  'campaignLinks',
  {},
  {
    processStrategy: (entity, parent) => ({
      ...entity,
      active: parent.active,
      campaign: parent.campaign,
    }),
  },
);

export const componentVersions = new schema.Entity(
  'componentVersions',
  {
    link: campaignLinks,
  },
  {
    processStrategy: (entity, parent) => ({
      ...entity,
      campaign: parent.campaign,
      active: parent.versions ? parent.versions[0].id === entity.id && !parent.is_deleted : false,
    }),
  },
);

export const components = new schema.Entity(
  'components',
  {
    type: componentTypes,
    versions: [componentVersions],
  },
  {
    processStrategy: (entity) => ({
      ...entity,
      activeVersion: entity.versions ? entity.versions[0] : {},
    }),
  },
);

export const events = new schema.Entity('campaignEvents');

export const campaignEvents = {
  results: new schema.Array(events),
};

export const broadcasterActions = new schema.Entity('broadcasterActions');

export const clicks = new schema.Entity('clicks');

export const nextChatbotPosts = new schema.Entity(
  'nextChatbotPosts',
  {},
  {idAttribute: 'username'},
);

export const textWidgets = new schema.Entity('textWidgets');
export const imageWidgets = new schema.Entity('imageWidgets');
export const groupWidgets = new schema.Entity('groupWidgets');
export const videoWidgets = new schema.Entity('videoWidgets');

export const multiWidgetArray = new schema.Array(
  {
    text: textWidgets,
    image: imageWidgets,
    group: groupWidgets,
    video: videoWidgets,
  },
  (input) => input.type,
);

export const dctas = new schema.Entity(
  'dctas',
  {
    widgets: multiWidgetArray,
  },
  {idAttribute: 'component'},
);

export const reports = new schema.Entity('reports');

export const campaignsInvitations = new schema.Entity(
  'campaignsInvitations',
  {},
  {idAttribute: 'slug'},
);

export const teamsInvitations = new schema.Entity('teamsInvitations');

export const campaignsInviteLinks = new schema.Entity(
  'inviteLinks',
  {},
  {idAttribute: 'campaign_slug'},
);

export const teamsInviteLinks = new schema.Entity('inviteLinks', {}, {idAttribute: 'team_id'});

export const campaignInviteLinks = new schema.Entity('inviteLinks', {}, {idAttribute: 'key'});

export const teamInviteLinks = new schema.Entity('inviteLinks', {}, {idAttribute: 'key'});

export const compliance = new schema.Entity('compliance', {}, {idAttribute: 'name'});

export const moderatorStatus = new schema.Entity(
  'moderatorStatus',
  {},
  {idAttribute: 'broadcaster'},
);

export const chatbotStatus = new schema.Entity(
  'chatbotStatus',
  {},
  {
    processStrategy: (entity) => entity.status,
    idAttribute: () => 'status',
  },
);

export const watchParties = new schema.Entity('watchParties');

export const managers = new schema.Entity('managers');

export const managerInvitations = new schema.Entity('managerInvitations');

export const featuredCampaigns = new schema.Entity('featuredCampaigns');
