import {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {useDispatch} from 'react-redux';

import AdvInput from 'components/molecules/AdvInput';
import Button from 'components/molecules/Button';
import {required, min, useValidator} from 'services/validation';
import {resourceUpdateRequest} from 'store/actions';

const EditTeamForm = ({team}) => {
  const dispatch = useDispatch();

  const validate = useValidator({
    name: [required],
    seconds_per_impression: [required, min(0)],
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        dispatch(resourceUpdateRequest('teams', team.id, data, undefined, true));
      } catch (error) {
        return error;
      }
    },
    [team, dispatch],
  );

  return (
    <>
      <label>Edit Team Settings</label>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          name: team.name,
          seconds_per_impression: team.seconds_per_impression,
        }}
        validate={validate}
      >
        {({handleSubmit, submitting, error, submitSucceeded}) => (
          <form onSubmit={handleSubmit}>
            <Field name="name" component={AdvInput} placeholder="Enter Team Name" />

            <div className="inputWithLabelWrapper">
              <label htmlFor={`team-impressions-${team.id}`}>Seconds Per Impression</label>
              <Field
                name="seconds_per_impression"
                type="number"
                component={AdvInput}
                id={`team-impressions-${team.id}`}
              />
            </div>

            <Button loading={!!submitting}>
              {!submitting && submitSucceeded ? 'Update Succeeded!' : 'Update Team'}
            </Button>
            {error && <div className="help is-danger centered">{error}</div>}
          </form>
        )}
      </Form>
    </>
  );
};

EditTeamForm.propTypes = {
  team: PropTypes.object,
};

export default EditTeamForm;
