import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import BaseTemplate from 'components/components/BaseTemplate';
import LoadingBar from 'components/molecules/LoadingBar';
import {resourceListReadRequest} from 'store/actions';

const BroadcasterComponentTemplate = ({
  children,
  loading,
  loadComponents,
  loadBroadcasterActions,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (loadComponents) {
      dispatch(resourceListReadRequest('components'));
    }
    if (loadBroadcasterActions) {
      dispatch(resourceListReadRequest('broadcasterActions'));
    }
  }, [dispatch, loadBroadcasterActions, loadComponents]);

  return (
    <BaseTemplate>
      <div className="BroadcasterDashboardPage componentPages">
        <div className="topDashboardSection" />
        <div className={`loadingBarWrapper ${loading ? 'loading' : ''}`}>
          <LoadingBar message="Loading Components" />
        </div>
        <div className={`broadcasterComponentsSections ${loading ? 'is-loading' : ''}`}>
          {children}
        </div>
      </div>
    </BaseTemplate>
  );
};

BroadcasterComponentTemplate.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  loadComponents: PropTypes.bool,
  loadBroadcasterActions: PropTypes.bool,
};

export default BroadcasterComponentTemplate;
