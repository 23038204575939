import {useCallback} from 'react';
import {Form, Field} from 'react-final-form';
import {authResetRequestRequest} from 'store/actions';
import {required, email, createValidator} from 'services/validation';
import {CSSTransitionGroup} from 'react-transition-group';
import {Email} from 'mdi-material-ui';
import {useDispatch} from 'react-redux';

import LoginInput from '../LoginInput';
import Button from 'components/components/Button';

const ResetRequestForm = () => {
  const dispatch = useDispatch();

  const onSubmit = useCallback((data) => dispatch(authResetRequestRequest(data.email)), [dispatch]);

  const validate = createValidator({
    email: [required, email],
  });

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, submitting, error, submitSucceeded}) => (
        <form className="resetRequestForm" onSubmit={handleSubmit}>
          <Field
            name="email"
            label="email"
            placeholder="EMAIL"
            component={LoginInput}
            icon={<Email />}
          />

          {submitSucceeded && <div className="success">Email Sent!</div>}
          {submitSucceeded && <Button link="/login/manager">Return to Login</Button>}
          {!submitSucceeded && <Button loading={!!submitting}>Request Password Reset</Button>}
          <CSSTransitionGroup
            transitionName="slowFade"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {error && <div className="help is-danger centered">{error}</div>}
          </CSSTransitionGroup>
        </form>
      )}
    </Form>
  );
};

export default ResetRequestForm;
