import PropTypes from 'prop-types';
import {Filter} from 'mdi-material-ui';

const TextFilter = ({handleChange, placeholder}) => (
  <div className="textFilterWrapper">
    <Filter />
    <input type="text" className="textFilter" placeholder={placeholder} onChange={handleChange} />
  </div>
);

TextFilter.propTypes = {
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default TextFilter;
