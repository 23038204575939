import {useEffect} from 'react';
import PropTypes from 'prop-types';

const TweetPreview = ({tweetId}) => {
  useEffect(() => {
    const createTweet = () => {
      window.twttr.widgets.createTweet(
        tweetId,
        document.getElementById(`tweet-preview-${tweetId}`),
        {
          cards: 'hidden',
          conversation: 'none',
          width: 300,
          align: 'center',
        },
      );
    };

    if (window.twttr && window.twttr.widgets) {
      createTweet();
    } else {
      window.twttr = ((d, s, id) => {
        const fjs = d.getElementsByTagName(s)[0];
        const t = window.twttr || {};
        if (d.getElementById(id)) return t;
        const js = d.createElement(s);
        js.id = id;
        js.src = 'https://platform.twitter.com/widgets.js';
        fjs.parentNode.insertBefore(js, fjs);

        t._e = [];
        t.ready = (f) => {
          t._e.push(f);
        };

        return t;
      })(document, 'script', 'twitter-wjs');

      window.twttr.ready(createTweet);
    }
  }, [tweetId]);

  return <div id={`tweet-preview-${tweetId}`} />;
};

TweetPreview.propTypes = {
  tweetId: PropTypes.string.isRequired,
};

export default TweetPreview;
