import PropTypes from 'prop-types';
import MultiPageModal from 'components/components/MultiPageModal';
import PageOne from './components/PageOne';
import PageTwo from './components/PageTwo';
import PageFour from './components/PageFour';
import useCampaignCreationForm from './hooks/useCampaignCreationForm';

const BrandCampaignCreationForm = ({isUpdating, campaign, modalName}) => {
  const {validate, onSubmit} = useCampaignCreationForm({isUpdating, campaign});

  const pages = [
    (form) => <PageOne campaign={campaign} isUpdating={isUpdating} form={form} />,
    !isUpdating && ((form) => <PageTwo isUpdating={isUpdating} form={form} />),
    !isUpdating && ((form) => <PageFour form={form} />),
  ];

  return (
    <MultiPageModal
      name={modalName}
      contentLabel="Create New Campaign"
      onSubmit={onSubmit}
      isUpdating={isUpdating}
      validate={validate}
      pages={pages}
      initialValues={{seconds_per_impression: 15}}
    />
  );
};

BrandCampaignCreationForm.propTypes = {
  isUpdating: PropTypes.bool,
  campaign: PropTypes.object,
  modalName: PropTypes.string,
};

export default BrandCampaignCreationForm;
