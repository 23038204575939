import PropTypes from 'prop-types';
import {formatNumber} from 'utils/numberFormats';

const getMessageText = (version, chatbotUsername) => {
  /*
   * Replaces {{link}} with Link Target
   */
  const messageSplit = version.message.split('{{link}}');

  if (version.link) {
    return (
      <span>
        {chatbotUsername && <span className="highlight">{`${chatbotUsername}: `}</span>}
        {messageSplit[0]}
        <span className="highlight">{messageSplit.length > 1 && version.link.target}</span>
        {messageSplit.length > 1 && messageSplit[1]}
      </span>
    );
  }
  return version.message;
};

const ChatbotComponentPreview = ({component, version, chatbotUsername, totalChatQueueTime}) => (
  <div className="componentPreviewWrapper">
    <div className="componentPreview">
      <div className="topBar">
        {component.type === 'twitch-chat-bot-command' ? (
          <h2>{`Command !${component.name}`}</h2>
        ) : (
          <h2>{`Posted every ${formatNumber(totalChatQueueTime / 60)} minutes`}</h2>
        )}
      </div>
      <p>{getMessageText(version, chatbotUsername)}</p>
    </div>
    <h4 className="extraDetails">{component?.campaign?.name}</h4>
  </div>
);

ChatbotComponentPreview.propTypes = {
  component: PropTypes.object,
  version: PropTypes.object,
  chatbotUsername: PropTypes.string,
  totalChatQueueTime: PropTypes.number,
};

export default ChatbotComponentPreview;
