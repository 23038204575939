import {CSSTransitionGroup} from 'react-transition-group';

import EmptySplash from 'components/molecules/EmptySplash';
import SubNavbar from 'components/molecules/SubNavbar';
import BroadcasterComponentTemplate from '../../components/BroadcasterComponentTemplate';
import {AdvModal} from 'containers';
import ActionCompleteForm from '../../forms/ActionCompleteForm';
import {Twitch} from 'mdi-material-ui';
import PanelSetupSteps from './components/PanelSetupSteps';
import BroadcasterPanelRowItem from './components/BroadcasterPanelRowItem';
import useBroadcasterPanels from './hooks/useBroadcasterPanels';

const BroadcasterPanels = () => {
  const {
    loading,
    imageDimensions,
    setImageDimensions,
    selectedView,
    setSelectedView,
    filteredActions,
    bannerComponents,
  } = useBroadcasterPanels();

  return (
    <BroadcasterComponentTemplate loading={loading} loadComponents loadBroadcasterActions>
      <h2 className="typeSectionName twitch">
        <Twitch />
        {' Twitch Panels'}
      </h2>
      <div className="broadcasterComponents panels">
        <SubNavbar
          tabs={['Required Actions', 'My Banners']}
          selectedTab={selectedView}
          handleClick={(tab) => {
            setSelectedView(tab);
            setImageDimensions([]);
          }}
        />
        {selectedView === 'Required Actions' && filteredActions.length > 0 && (
          <div className="broadcasterComponentsQueue">
            <CSSTransitionGroup
              transitionName="actionCompleted"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={1500}
            >
              <PanelSetupSteps />
              {filteredActions
                .sort((a, b) => (b.version.created > a.version.created ? 1 : -1))
                .map((action, index) => (
                  <BroadcasterPanelRowItem
                    key={`action-${action.id}`}
                    component={
                      bannerComponents.find((c) => action.version.component_id === c.id) || {}
                    }
                    action={action}
                  />
                ))}
            </CSSTransitionGroup>
          </div>
        )}
        {bannerComponents.length > 0
          ? selectedView === 'My Banners' && (
              <div
                className="broadcasterComponentsQueue panels flexColumn"
                style={{
                  minHeight: '300px',
                  height: Math.max(
                    ...imageDimensions.reduce(
                      (acc, val, index) => {
                        acc[index % 3] = val[1] + 53 + acc[index % 3];
                        return acc;
                      },
                      [20, 20, 20],
                    ),
                  ),
                }}
              >
                {bannerComponents.map((banner, index) => (
                  <div
                    className="minimalBannerPreview"
                    key={banner.id}
                    style={{
                      top: `${imageDimensions
                        .slice(0, index)
                        .reduce(
                          (acc, val, valIndex) =>
                            acc + (index % 3 === valIndex % 3 ? val[1] + 53 : 0),
                          180,
                        )}px`,
                      left: `${(index % 3) * 347 + 50}px`,
                    }}
                  >
                    <img
                      src={banner.activeVersion.image}
                      alt=""
                      onLoad={({target: img}) => {
                        setImageDimensions([
                          ...imageDimensions,
                          [img.offsetWidth, img.offsetHeight],
                        ]);
                      }}
                    />
                    <p>{banner.activeVersion.link}</p>
                  </div>
                ))}
              </div>
            )
          : selectedView === 'My Banners' && (
              <EmptySplash>
                <h3>You have no Twitch Panels</h3>
              </EmptySplash>
            )}
      </div>

      <div className="panelsOff">
        <EmptySplash loading={loading}>
          <h3>Please setup your Twitch panels on desktop</h3>
        </EmptySplash>
      </div>

      {selectedView === 'Required Actions' && filteredActions.length === 0 && (
        <EmptySplash loading={loading}>
          <h3>You&apos;ve completed all your required actions!</h3>
        </EmptySplash>
      )}

      {/* Confirm Modals */}
      {filteredActions.map((action) => (
        <AdvModal
          name={`action-${action.id}-complete`}
          key={`action-${action.id}-complete`}
          contentLabel="Component Confirm"
        >
          <ActionCompleteForm
            action={action}
            initialData={action}
            modalName={`action-${action.id}-complete`}
          />
        </AdvModal>
      ))}
    </BroadcasterComponentTemplate>
  );
};

export default BroadcasterPanels;
