import {isEmpty} from 'lodash';

const formatViewershipStats = (broadcasters, overviewMetrics, overviewComparisonMetrics) => {
  return !isEmpty(overviewMetrics)
    ? [
        {
          key: 'total-vm',
          label: 'Total Viewable Minutes',
          value: overviewMetrics.twitch_viewable_minutes,
          oldValue:
            overviewComparisonMetrics.twitch_viewable_minutes -
            overviewMetrics.twitch_viewable_minutes,
          firstThreshold: 0.01,
          secondThreshold: 0.2,
        },
        {
          key: 'campaign-participants',
          label: 'Campaign Participants',
          value: broadcasters?.length,
        },
        {
          key: 'peak-viewers',
          label: 'Peak Concurrent Viewers',
          value: overviewMetrics.twitch_peak_viewers,
        },
        {
          key: 'avg-viewers',
          label: 'Avg Concurrent Viewers',
          value: overviewMetrics.twitch_average_viewers,
        },
      ]
    : [];
};

export default formatViewershipStats;
