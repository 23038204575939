import PropTypes from 'prop-types';
import {isPending, hasFailed} from 'redux-saga-thunk';
import {useSelector, useDispatch} from 'react-redux';
import history from 'localHistory';

import {resourceDeleteRequest, resourceUpdateRequest} from 'store/actions';

import Button from 'components/molecules/Button';
import AdvModal from 'containers/AdvModal';
import useModal from 'hooks/useModal';

const hasEndButton = (campaign) => !campaign?.end_date || new Date(campaign.end_date) > new Date();

const CampaignDeleteModal = ({modalName, campaign, extraText, warning, redirectFunc}) => {
  const dispatch = useDispatch();
  const [, modalHide] = useModal();
  const loading = useSelector((state) => isPending(state, 'campaignsDelete'));
  const deleteFailed = useSelector((state) => hasFailed(state, 'campaignsDelete'));
  const updateFailed = useSelector((state) => hasFailed(state, 'campaignsUpdate'));
  const endLoading = useSelector((state) => isPending(state, 'campaignsUpdate'));

  const currentTime = new Date();
  const deleteCampaign = () => dispatch(resourceDeleteRequest('campaigns', campaign.slug));
  const endCampaign = () =>
    dispatch(
      resourceUpdateRequest('campaigns', campaign.slug, {
        ...campaign,
        end_date: currentTime.toISOString(),
      }),
    ).then(() => {
      modalHide(modalName);
      history.push(`/campaigns/${campaign.slug}`);
    });

  return (
    <AdvModal name={modalName} contentLabel={`${campaign?.name} Delete Modal`}>
      <div className="deleteLabel">
        {hasEndButton(campaign)
          ? `End or delete campaign ${campaign?.name}?`
          : `Delete campaign ${campaign?.name}?`}
      </div>
      {warning && <div className="warning">WARNING</div>}
      {extraText && <div className="extraText">{extraText}</div>}
      {(updateFailed || deleteFailed) && (
        <div className="help is-danger centered">
          Couldn’t end or delete campaign. Please try again later
        </div>
      )}
      {hasEndButton(campaign) && (
        <Button loading={!!endLoading} handleClick={endCampaign}>
          End Campaign
        </Button>
      )}
      <Button
        loading={!!loading}
        handleClick={() => deleteCampaign().then(() => redirectFunc(true))}
      >
        Delete Campaign
      </Button>
    </AdvModal>
  );
};

CampaignDeleteModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  campaign: PropTypes.object,
  warning: PropTypes.bool,
  extraText: PropTypes.string,
  redirectFunc: PropTypes.func,
};

export default CampaignDeleteModal;
