/* eslint-disable jsx-a11y/media-has-caption */
import {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getCookie} from 'redux-cookie';
import PropTypes from 'prop-types';

import RemoveLabelModal from 'components/components/RemoveLabelModal';
import ReactivateModal from './ReactivateModal';
import {AccountCheck, AccountRemove, UndoVariant} from 'mdi-material-ui';
import {SquareEditOutline, AccountMultiplePlus} from 'mdi-material-ui';

import {fromResources} from 'store/selectors';
import DeleteButton from 'components/components/DeleteButton';
import IconButton from 'components/components/IconButton';
import TweetPreview from 'components/molecules/TweetPreview';
import {getTweetIdFromUrl} from 'utils/campaignTools';
import useModal from 'hooks/useModal';
import {useCampaignLabels} from 'hooks';
import isEmpty from 'lodash/isEmpty';

const getMessageText = (version, chatbotUsername, campaignLink) => {
  /*
   * Replaces {{link}} with Link Target
   */
  const messageSplit = version.message.split('{{link}}');
  if (version.link) {
    return (
      <span>
        {chatbotUsername && <span className="highlight">{`${chatbotUsername}: `}</span>}
        {messageSplit[0]}
        <span className="highlight">
          {messageSplit.length > 1 && campaignLink ? campaignLink.target : ''}
        </span>
        {messageSplit.length > 1 && messageSplit[1]}
      </span>
    );
  }
  return version.message;
};

const getTitle = (component) => component.name;

const getSecondBar = (component, version, campaignLink) => {
  switch (component.type.slug || component.type) {
    case 'suggested-tweet':
    case 'suggested-retweet':
      return getMessageText(version, null, campaignLink);
    case 'twitch-chat-bot':
      return undefined;
    default:
      return version && version.link && campaignLink
        ? campaignLink.target !== 'undefined' && campaignLink.target
        : '';
  }
};

const getMainText = (component, version, chatbotUsername, campaignLink) => {
  /*
   * Displays embedded tweet if the component is a Suggested Retweet,
   * Nothing if its a Suggest Tweet,
   * and in all other circumstances passes the information to `getMessageText`
   */
  switch (component.type.slug || component.type) {
    case 'suggested-retweet':
      return <TweetPreview tweetId={getTweetIdFromUrl(version.message)} />;
    case 'suggested-tweet':
      return undefined;
    case 'twitch-chat-bot':
    case 'twitch-chat-bot-command':
      return <p>{getMessageText(version, chatbotUsername, campaignLink)}</p>;
    default:
      return <p>{getMessageText(version, null, campaignLink)}</p>;
  }
};

const getIcons = (component, modalShow, labels, version) => (
  <span>
    <DeleteButton handleClick={() => modalShow(`component-${component.id}-delete`)} />
    <IconButton
      label="viewing"
      tooltip="Select component groups"
      handleClick={() => modalShow(`labels-component-${component.id}`)}
    >
      <AccountMultiplePlus />
    </IconButton>
    {component.type !== 'suggested-tweet' && component.type !== 'suggested-retweet' && (
      <IconButton
        label="edit"
        tooltip="Edit component properties"
        handleClick={() => modalShow(`component-${component.id}-update`)}
      >
        <SquareEditOutline />
      </IconButton>
    )}
  </span>
);

const getIframeUrl = (component, version, watchParty) => {
  const controlPage = 'manager.html';

  if (watchParty && !isEmpty(watchParty)) {
    return `${watchParty.current_version.source}${controlPage}`;
  }

  if (version?.config?.widget_root) {
    return `${version.config.widget_root}${controlPage}`;
  }

  return undefined;
};

const getIframeToken = (watchParty, token) => {
  if (watchParty && !isEmpty(watchParty)) {
    return watchParty.api_key;
  }

  return token;
};

const isDailyCapReached = (component) => {
  return !!component.daily_cap_reached;
};

const ComponentPreview = ({
  isSelected,
  handleImgOnClick,
  version,
  showButtons,
  componentType,
  component,
  hasCheckMark,
  campaignLink,
  chatbotUsername,
}) => {
  const dispatch = useDispatch();
  const labels = useCampaignLabels();
  const [modalShow] = useModal();
  const token = dispatch(getCookie('token'));
  const filteredLabels = labels.filter((label) => label.components.includes(component.id));
  const [imageSize, setImageSize] = useState(null);

  const watchParty = useSelector((state) =>
    fromResources.getResourceDetail(state, 'watchParties', version?.config?.watch_party_id),
  );

  const onImageLoad = ({target: image}) => {
    setImageSize(`${image.naturalWidth} x ${image.naturalHeight}`);
  };

  const getFileExt = (filename) => {
    const tokens = filename.split('.');
    return tokens[tokens.length - 1].toLowerCase();
  };

  const getImageComponent = (imageUrl, isVersioned) => {
    if (imageUrl) {
      const extension = getFileExt(imageUrl);

      switch (extension) {
        case 'jpg':
        case 'png':
        case 'gif':
        case 'jpeg':
          return (
            <img
              src={imageUrl}
              alt=""
              className={isVersioned ? 'clickable' : undefined}
              onLoad={onImageLoad}
            />
          );
        case 'mp4':
        case 'webm':
          return (
            <video
              src={imageUrl}
              className={isVersioned ? 'clickable' : undefined}
              autoPlay
              loop
              muted
            />
          );
        default:
          return undefined;
      }
    }
    return undefined;
  };

  const imageComponent = version
    ? getImageComponent(version.image, componentType.is_versioned)
    : undefined;
  const secondBar = getSecondBar(component, version, campaignLink);
  const isEnded = Date.parse(component.end_time) < +new Date();

  const iframeUrl = getIframeUrl(component, version, watchParty);
  const iframeToken = getIframeToken(watchParty, token);

  return (
    <div className={isSelected ? 'componentPreviewWrapper is-selected' : 'componentPreviewWrapper'}>
      <div className="componentPreview">
        <div className="topBar">
          <h2>{getTitle(component)}</h2>
          {showButtons &&
            !isEnded &&
            getIcons(
              component,
              modalShow,
              labels.filter((label) => label.components.includes(component.id)),
              version,
            )}
          {isEnded && (
            <IconButton
              label="reactivate"
              tooltip="Reactivate component"
              handleClick={() => modalShow(`reactivate-component-${component.id}`)}
            >
              <UndoVariant />
            </IconButton>
          )}
          {hasCheckMark && component.compliance && componentType.slug === 'banner-graphics' && (
            <span className={`checkMark ${!component.compliance.compliance ? 'invalid' : ''}`}>
              {component.compliance.compliance ? <AccountCheck /> : <AccountRemove />}
            </span>
          )}
        </div>
        {isDailyCapReached(component) && <h4 className="cappedText greenPulse">DAILY CAPPED</h4>}
        {secondBar && (
          <div
            className={
              componentType.slug === 'suggested-tweet' ? 'secondBar suggestedTweet' : 'secondBar'
            }
          >
            <div className="secondBarContent">{secondBar}</div>
          </div>
        )}
        {componentType.slug === 'live-graphics' && (
          <div className="secondBar">
            <div className="secondBarContent">{imageSize}</div>
          </div>
        )}
        {getMainText(component, version, chatbotUsername, campaignLink)}
        {imageComponent && (
          <div
            tabIndex={-1}
            role="switch"
            aria-label="image"
            aria-checked
            onClick={handleImgOnClick}
          >
            {imageComponent}
          </div>
        )}
        {iframeUrl && ( // eslint-disable-line camelcase
          <iframe
            src={iframeUrl} // eslint-disable-line camelcase
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            width="100%"
            height="100%"
            title={component.name}
            onLoad={(event) => {
              event.target.contentWindow.postMessage(
                JSON.stringify({widgetId: component.id, token: iframeToken}),
                '*',
              );
            }}
          />
        )}
      </div>
      {showButtons && !isEnded && (
        <div className="labelSection">
          {filteredLabels &&
            filteredLabels.map((label) => (
              <span className="labelItem" key={`label-${label.name}`}>
                <h4>
                  {label.name}
                  <DeleteButton
                    handleClick={() => modalShow(`version-${version.id}-${label.name}-remove`)}
                  />
                </h4>
              </span>
            ))}
        </div>
      )}
      {showButtons && (
        <h4 className="extraDetails">
          {component &&
            !component.is_ready &&
            (Date.parse(component.end_time) < +new Date()
              ? 'component ended'
              : 'component pending')}
          {version.config && version.config.is_editable ? 'editable by broadcasters' : ''}
        </h4>
      )}

      {isEnded && showButtons && (
        <ReactivateModal modalName={`reactivate-component-${component.id}`} component={component} />
      )}

      {/* Delete Label Modals */}
      {filteredLabels &&
        filteredLabels.map((label) => (
          <RemoveLabelModal
            key={`remove-label-modal-${label.id}`}
            modalName={`version-${version.id}-${label.name}-remove`}
            label={label}
            removeFromField="components"
            removeVal={component.id}
            valueName={component.name}
          />
        ))}
    </div>
  );
};

ComponentPreview.propTypes = {
  isSelected: PropTypes.bool,
  handleImgOnClick: PropTypes.func,
  imageComponent: PropTypes.node,
  componentType: PropTypes.object.isRequired,
  version: PropTypes.object,
  component: PropTypes.object,
  showButtons: PropTypes.bool,
  hasCheckMark: PropTypes.bool,
  chatbotUsername: PropTypes.string,
  campaignLink: PropTypes.object,
};

export default ComponentPreview;
