import {useState, useMemo, useCallback} from 'react';
import {Field, Form} from 'react-final-form';

import TermsOfService from './components/TermsOfService';
import Button from 'components/components/Button';
import Checkbox from 'components/components/Checkbox';
import {CSSTransitionGroup} from 'react-transition-group';
import {useDispatch, useSelector} from 'react-redux';
import history from 'localHistory';

import {profileUpdateRequest} from 'store/actions';
import {fromProfile} from 'store/selectors';

const Tos = () => {
  const dispatch = useDispatch();
  const [hasReadTos, setHasReadTos] = useState(false);

  const initialValues = useMemo(
    () => ({
      email_receive_advocate_updates: true,
      profile: {email_receive_sponsorship_offers: true},
    }),
    [],
  );

  const profile = useSelector((state) => fromProfile.getFullProfile(state));
  const isManager = useSelector((state) => fromProfile.isManager(state));
  const isBroadcaster = useSelector((state) => fromProfile.isBroadcaster(state));

  const onSubmit = useCallback(
    (data) => {
      const now = new Date();
      return dispatch(
        profileUpdateRequest({
          ...profile,
          ...data,
          tos_agreed: data.tos_agreed ? now.toISOString() : null,
        }),
      );
    },
    [dispatch, profile],
  );

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({handleSubmit, submitting, error, form, submitError}) => (
        <form
          onSubmit={(data) =>
            handleSubmit(data)?.then(() => {
              const {submitSucceeded} = form.getState();
              submitSucceeded && history.push('/');
            })
          }
        >
          <TermsOfService isManager={isManager} setHasReadTos={setHasReadTos} />
          <div className={`checkboxField loginExtras ${hasReadTos ? '' : 'disabled'}`}>
            <Field
              name="tos_agreed"
              component={Checkbox}
              disabled={!hasReadTos}
              label="I have read and agreed to the Terms of Service"
            />
          </div>

          {isBroadcaster && (
            <div className="loginExtras checkboxField">
              <Field
                name="profile.email_receive_sponsorship_offers"
                component={Checkbox}
                label="Email me about sponsorship offers"
              />
            </div>
          )}

          <div className="loginExtras checkboxField">
            <Field
              name="email_receive_advocate_updates"
              component={Checkbox}
              label="Email me about Advocate updates"
            />
          </div>

          <Button loading={!!submitting} disabled={!form.getState().values['tos_agreed']}>
            I Agree
          </Button>
          <CSSTransitionGroup
            transitionName="slowFade"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {(error || submitError) && (
              <div className="help is-danger centered">{error || submitError}</div>
            )}
          </CSSTransitionGroup>
        </form>
      )}
    </Form>
  );
};

export default Tos;
