import {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isPending} from 'redux-saga-thunk';

import {fromMetrics, fromProfile, fromResources} from 'store/selectors';
import {metricsDataRequest, resourceListReadRequest} from 'store/actions';
import BaseTemplate from 'components/components/BaseTemplate';
import LoadingBar from 'components/molecules/LoadingBar';
import TeamPreviewWrapper from './components/TeamPreviewWrapper';
import DashboardTopStats from '../../components/DashboardTopStats';
import {CSSTransitionGroup} from 'react-transition-group';
import {useTeams, useCampaigns} from 'hooks';
import CampaignPreviewSection from '../../components/CampaignPreviewSection';

const getBroadcastersByTeam = (team, broadcasters) =>
  broadcasters.filter(
    (broadcaster) =>
      broadcaster.team_statuses &&
      Object.keys(broadcaster.team_statuses).includes(team.id.toString()),
  );

const ManagerDashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resourceListReadRequest('broadcasters'));
    dispatch(resourceListReadRequest('teams'));
    dispatch(resourceListReadRequest('campaigns'));
    dispatch(metricsDataRequest('team-game-viewership'));
    dispatch(metricsDataRequest('team-broadcasters'));
    dispatch(metricsDataRequest('dashboard-campaigns'));
  }, [dispatch]);

  const teams = useTeams();
  const campaigns = useCampaigns();
  const broadcasters = useSelector((state) => fromResources.getResourceList(state, 'broadcasters'));
  const userType = useSelector((state) => fromProfile.getUserType(state));
  const isAdmin = useSelector((state) => fromProfile.isAdmin(state));
  const broadcasterStats = fromMetrics.getMetric('team-broadcasters');
  const campaignStats = fromMetrics.getMetric('dashboard-campaigns');
  const userLoading = useSelector((state) => isPending(state, 'profileRequest'));

  const sortTeams = useCallback(
    (teamA, teamB) => {
      if (isAdmin && teamA.is_demo && !teamB.is_demo) return -1;
      if (isAdmin && !teamA.is_demo && teamB.is_demo) return 1;
      return (
        getBroadcastersByTeam(teamB, broadcasters).length -
        getBroadcastersByTeam(teamA, broadcasters).length
      );
    },
    [broadcasters, isAdmin],
  );

  return (
    <BaseTemplate>
      <div className="clientDashboardPage">
        <DashboardTopStats
          campaigns={campaigns}
          campaignStats={campaignStats}
          broadcasterStats={broadcasterStats}
        />
        {teams &&
          teams
            .sort(sortTeams)
            .map((team) => (
              <TeamPreviewWrapper
                key={team.id}
                team={team}
                broadcasters={getBroadcastersByTeam(team, broadcasters)}
                campaigns={campaigns.filter((campaign) => campaign.team === team.id)}
              />
            ))}
        {teams && teams.length === 0 && (
          <CampaignPreviewSection campaigns={campaigns} userType={userType} />
        )}
        <CSSTransitionGroup
          transitionName="slowFadeLoadingBar"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {userLoading && <LoadingBar message="Loading Teams" />}
        </CSSTransitionGroup>
      </div>
    </BaseTemplate>
  );
};

export default ManagerDashboard;
