import {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {CircleEditOutline, Check} from 'mdi-material-ui';
import {useDispatch} from 'react-redux';
import {Form} from 'react-final-form';
import {CSSTransitionGroup} from 'react-transition-group';

import {resourceDeleteRequest, resourceUpdateRequest} from 'store/actions';
import {formatDateTime} from 'utils/numberFormats';
import DeleteButton from 'components/components/DeleteButton';
import DateTimeField from 'components/molecules/DateTimeField';

const FeaturedCampaignItem = ({feature, editable, campaign}) => {
  const [showStartEditOverlay, setShowStartEditOverlay] = useState(false);
  const [showEndEditOverlay, setShowEndEditOverlay] = useState(false);
  const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = useCallback(
    async () => await dispatch(resourceDeleteRequest('featuredCampaigns', feature.id)),
    [dispatch, feature.id],
  );

  const onEditSubmit = useCallback(
    async (data) => {
      try {
        await dispatch(resourceUpdateRequest('featuredCampaigns', feature.id, data));
      } catch (error) {
        return error;
      }
    },
    [dispatch, feature],
  );

  const cancelStartEdit = useCallback((e) => {
    e.preventDefault();
    setShowStartEditOverlay(false);
  }, []);

  const cancelEndEdit = useCallback((e) => {
    e.preventDefault();
    setShowEndEditOverlay(false);
  }, []);

  return (
    <div
      className={`featureItem ${editable ? '' : 'ended'} ${showDeleteOverlay ? 'showDelete' : ''} ${
        showStartEditOverlay || showEndEditOverlay ? 'showEdit' : ''
      }`}
    >
      {editable && (
        <>
          <div className="featureItemOverlay" />
          <CSSTransitionGroup
            transitionName="slideOverlay"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {showDeleteOverlay && (
              <div className="deleteOverlayContent">
                <span>Delete this feature?</span>

                <div className="editDesktop" tabIndex={-1} role="button" onClick={handleDelete}>
                  <Check />
                </div>
                <DeleteButton
                  tabIndex={-1}
                  extraClass="removeDesktop"
                  handleClick={() => setShowDeleteOverlay(false)}
                />
              </div>
            )}
          </CSSTransitionGroup>

          <CSSTransitionGroup
            transitionName="slideOverlay"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {showStartEditOverlay && (
              <Form onSubmit={onEditSubmit} initialValues={feature}>
                {({handleSubmit, form}) => (
                  <form
                    onSubmit={(data) => {
                      handleSubmit(data)?.then(() => {
                        const {submitSucceeded} = form.getState();
                        submitSucceeded && setShowStartEditOverlay(false);
                      });
                    }}
                    className="editOverlayContent"
                  >
                    <DateTimeField
                      fieldName="start_time"
                      placeholder="Starts"
                      specifyTime
                      absoluteStart={campaign ? campaign.start_date : null}
                      absoluteEnd={campaign ? campaign.end_date : null}
                      initialValue={feature.start_time}
                      title="Starts"
                      appendToBody
                    />

                    <button className="editDesktop" tabIndex={-1}>
                      <Check />
                    </button>
                    <DeleteButton
                      tabIndex={-1}
                      extraClass="removeDesktop"
                      handleClick={cancelStartEdit}
                    />
                  </form>
                )}
              </Form>
            )}
          </CSSTransitionGroup>

          <CSSTransitionGroup
            transitionName="slideOverlay"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {showEndEditOverlay && (
              <Form onSubmit={onEditSubmit} initialValues={feature}>
                {({handleSubmit, form}) => (
                  <form
                    onSubmit={(data) => {
                      handleSubmit(data)?.then(() => {
                        const {submitSucceeded} = form.getState();
                        submitSucceeded && setShowEndEditOverlay(false);
                      });
                    }}
                    className="editOverlayContent"
                  >
                    <DateTimeField
                      fieldName="end_time"
                      placeholder="Ends"
                      title="Ends"
                      specifyTime
                      absoluteStart={campaign ? campaign.start_date : null}
                      absoluteEnd={campaign ? campaign.end_date : null}
                      initialValue={feature.end_time}
                      appendToBody
                    />

                    <button className="editDesktop" tabIndex={-1}>
                      <Check />
                    </button>
                    <DeleteButton
                      tabIndex={-1}
                      extraClass="removeDesktop"
                      handleClick={cancelEndEdit}
                    />
                  </form>
                )}
              </Form>
            )}
          </CSSTransitionGroup>
        </>
      )}

      <div className="topSection">
        <div className="featureItemDates">
          <div className="featureItemTitle">
            <div className="featureTitleGroup">
              <h2>{formatDateTime(feature.start_time)}</h2>
              {editable && (
                <div
                  className="editDesktop"
                  tabIndex={-1}
                  role="button"
                  onClick={() => setShowStartEditOverlay(true)}
                >
                  <CircleEditOutline />
                </div>
              )}
              <h2>-</h2>
            </div>
            <div className="featureTitleGroup">
              <h2>{formatDateTime(feature.end_time)}</h2>
              {editable && (
                <div
                  className="editDesktop"
                  tabIndex={-1}
                  role="button"
                  onClick={() => setShowEndEditOverlay(true)}
                >
                  <CircleEditOutline />
                </div>
              )}
            </div>
          </div>
        </div>

        {editable && (
          <>
            <DeleteButton
              tabIndex={-1}
              extraClass="removeDesktop"
              handleClick={() => setShowDeleteOverlay(true)}
            />
          </>
        )}
      </div>
    </div>
  );
};

FeaturedCampaignItem.propTypes = {
  feature: PropTypes.object,
  editable: PropTypes.bool,
  campaign: PropTypes.object,
};

export default FeaturedCampaignItem;
