import BaseTemplate from 'components/components/BaseTemplate';
import ManagerProfileForm from '../../forms/ManagerProfileForm';

const ManagerProfile = () => {
  return (
    <BaseTemplate>
      <div className="profilePage">
        <div className="profileLeftPan">
          <ManagerProfileForm />
          <div className="footer">
            <a href="https://adv.gg/docs/terms" rel="noreferrer noopener">
              <h4>Terms of Service</h4>
            </a>
            <a href="https://adv.gg/docs/privacy" rel="noreferrer noopener" target="_blank">
              <h4>Privacy Policy</h4>
            </a>
          </div>
        </div>
      </div>
    </BaseTemplate>
  );
};

export default ManagerProfile;
