import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isObject} from 'lodash';
import {DEACTIVATED} from 'store/management/campaignStatuses';

import BroadcasterParticipationForm from './components/BroadcasterParticipationForm';
import AutoSuggestLocal from 'components/components/AutoSuggest/AutoSuggestLocal';
import {fromResources} from 'store/selectors';
import {managementSetStatusRequest, resourceAdd} from 'store/actions';
import {required, useValidator} from 'services/validation';
import useModal from 'hooks/useModal';

const RemoveBroadcasterForm = (props) => {
  const {team, broadcasters} = props;
  const teamCampaigns = useSelector((state) =>
    fromResources.getResourceListByTeam(state, 'campaigns', team.id),
  );
  const [, modalHide] = useModal();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (data) => {
      const teamBroadcasters = broadcasters.map((b) => b.username);
      // throw an error if the broadcaster is not on the team
      if (
        isObject(data.broadcaster)
          ? !teamBroadcasters.includes(data.broadcaster.username)
          : !teamBroadcasters.includes(data.broadcaster)
      ) {
        return {[FORM_ERROR]: 'Please select a valid team member!'};
      }
      return dispatch(
        managementSetStatusRequest(
          // data is a  broadcaster object if selected from the autosuggest dropdown
          // otherwise data is a broadcaster username from the text input
          'teams',
          'remove',
          isObject(data.broadcaster) ? data.broadcaster.username : data.broadcaster,
          team.id,
        ),
      );
    },
    [broadcasters, dispatch, team.id],
  );

  const onSubmitSuccess = useCallback(
    (result) => {
      // set all campaigns on this team to DEACTIVATED for the removed broadcaster
      const broadcaster = Object.keys(result.broadcasters)[0];
      dispatch(
        resourceAdd({
          broadcasters: {
            [broadcaster]: {
              campaign_statuses: teamCampaigns.reduce(
                (statuses, campaign) => ({
                  ...statuses,
                  [campaign.slug]: {status: DEACTIVATED},
                }),
                {},
              ),
            },
          },
        }),
      );
    },
    [dispatch, teamCampaigns],
  );

  const validate = useValidator({
    broadcaster: [required],
  });

  const componentProps = {
    suggestionSet: broadcasters,
    filterField: 'username',
    longerThan: 0,
  };

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, form, error, submitting, submitError}) => (
        <BroadcasterParticipationForm
          title="Remove a Broadcaster from your Team"
          buttonText="Remove"
          successMsg="Broadcaster Removed!"
          handleSubmit={(data) =>
            handleSubmit(data)?.then((data) => {
              onSubmitSuccess(data);
              modalHide(`remove-broadcaster-from-team-${team.id}`);
            })
          }
          error={error || submitError}
          submitting={submitting}
          component={AutoSuggestLocal}
          componentProps={componentProps}
          {...props}
        />
      )}
    </Form>
  );
};

RemoveBroadcasterForm.propTypes = {
  broadcasters: PropTypes.arrayOf(PropTypes.object),
  team: PropTypes.object,
};

export default RemoveBroadcasterForm;
