import PropTypes from 'prop-types';

const OverviewSection = ({title, sectionClass, children}) => (
  <div className={sectionClass ? `overviewSection ${sectionClass}` : 'overviewSection'}>
    <span className="titleBar">
      <h1>{title}</h1>
    </span>
    {children}
  </div>
);

OverviewSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  sectionClass: PropTypes.string,
};

export default OverviewSection;
