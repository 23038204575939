import Button from 'components/components/Button';
import {useLocation} from 'react-router-dom';

const SplitLogin = () => {
  const location = useLocation();

  return (
    <>
      <h4 className="versionIdentifier">Advocate V2.0</h4>
      <h2>Login as a</h2>
      <Button
        link={{
          pathname: '/login/manager',
          state: {from: location.state ? location.state.from : null},
        }}
      >
        Manager
      </Button>
      <Button link="/login/broadcaster">Broadcaster</Button>
    </>
  );
};

export default SplitLogin;
