import PropTypes from 'prop-types';

const TertiaryOption = ({optionText, onClick, disabled}) => (
  <div className="tertiaryOption" role="button" tabIndex={0} onClick={onClick}>
    {!disabled && <p>{optionText}</p>}
  </div>
);

TertiaryOption.propTypes = {
  onClick: PropTypes.func,
  optionText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TertiaryOption;
