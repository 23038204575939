import * as d3 from 'd3';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {isPending} from 'redux-saga-thunk';

import LoadingBar from 'components/molecules/LoadingBar';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {sassVariables} from 'adv-theme';

// Takes raw data and converts it to chart-ready data with the correct
// filters, dates and time key format applied
// inputData: {time [datetime], broadcaster [string], component_type [string], id [int], country [string]}
// output: { key [string], value [int], time [datetime]}
const formatData = (inputData, timeKeyFormat, cumulative) => {
  const toHourKey = d3.timeFormat(timeKeyFormat);
  const fromHourKey = d3.timeParse(timeKeyFormat);

  const formattedData = inputData;

  // Group data by time key
  const groupedData = d3
    .nest()
    .key((d) => {
      const time = new Date(d.time);
      return toHourKey(time);
    })
    .rollup((v) => v.length)
    .entries(formattedData)
    .sort((x, y) => d3.ascending(x.key, y.key));

  if (cumulative) {
    return groupedData.reduce((acc, item) => {
      acc.push([
        Date.parse(fromHourKey(item.key)),
        ((acc.length && acc[acc.length - 1][1]) || 0) + item.value,
      ]);
      return acc;
    }, []);
  }

  return groupedData.map((item) => [Date.parse(fromHourKey(item.key)), item.value]);
};

const AttributionOverTimeChart = ({
  tooltipFormat,
  timeKeyFormat,
  dateTimeFormatXAxis,
  cumulative,
  options,
  title,
  data,
  unit,
}) => {
  const chartData = formatData(data, timeKeyFormat, cumulative);
  const loading = useSelector((state) => isPending(state, `${unit}RawData`));

  const defaultOptions = {
    chart: {
      type: 'areaspline',
      height: 250,
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: dateTimeFormatXAxis || {day: '%e. %b'},
      showInLegend: false,
      labels: {
        enabled: chartData.length !== 0,
      },
    },
    colors: [sassVariables.primary],
    title: {
      text: undefined,
    },
    yAxis: {
      title: {
        text: undefined,
      },
      minRange: 50,
      min: 0,
      gridLineColor: '000',
      alternateGridColor: '#f7f7ff',
      offset: 0,
    },
    tooltip: {
      headerFormat: `<b>{point.x:${tooltipFormat || '%Y-%m-%d'}}</b><br>`,
      pointFormat: `{point.y} ${unit.replace(/^\w/, (c) => c.toUpperCase())}`,
      shared: true,
      enabled: chartData.length !== 0,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, sassVariables.primary],
            [1, '#ffffff'],
          ],
        },
        marker: {
          radius: 0,
          states: {
            hover: {
              enabled: chartData.length !== 0,
            },
          },
        },
        lineWidth: 2,
      },
    },
    series: [
      {
        data: chartData.length === 0 ? [[0, 0]] : chartData,
        showInLegend: false,
      },
    ],
  };

  return (
    <div className={`campaignMetricGraph ${loading ? 'loading' : ''}`}>
      <div className="topBar">
        <h2 className="title">{title}</h2>
      </div>
      {loading && <LoadingBar />}
      <div className="mainChart">
        <HighchartsReact highcharts={Highcharts} options={{...options, ...defaultOptions}} />
      </div>
    </div>
  );
};

AttributionOverTimeChart.propTypes = {
  loading: PropTypes.bool,
  cumulative: PropTypes.bool,
  timeKeyFormat: PropTypes.string,
  tooltipFormat: PropTypes.string,
  dateTimeFormatXAxis: PropTypes.object,
  options: PropTypes.object,
  title: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  unit: PropTypes.string,
};

export default AttributionOverTimeChart;
