import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Field, Form} from 'react-final-form';
import {resourceCreateRequest, resourceUpdateRequest, modalHide} from 'store/actions';
import {required, useValidator, maxLength} from 'services/validation';

import Button from 'components/molecules/Button';
import {useCampaignLabels} from 'hooks';
import AutoSuggestLocal from 'components/components/AutoSuggest/AutoSuggestLocal';

const AttachLabelForm = ({campaignSlug, broadcaster, component, title, labels}) => {
  const dispatch = useDispatch();
  const campaignLabels = useCampaignLabels();

  const onSubmit = useCallback(
    (data) => {
      let {label} = data;

      if (broadcaster) {
        if (label.id) {
          // If the label.id exists, the label has already been created and we just want to add
          // the broadcaster to the existing label
          return dispatch(
            resourceUpdateRequest('labels', label.id, {
              ...label,
              broadcasters: [...label.broadcasters, broadcaster],
            }),
          );
        }
        // If there's no label.id, it's a new label and needs to be created
        return dispatch(
          resourceCreateRequest('labels', {
            name: label,
            broadcasters: [broadcaster],
            campaign: campaignSlug,
            components: [],
          }),
        );
      }

      if (component) {
        // if a label was not selected from the autosuggest, but the label name matches an existing label
        if (typeof label === 'string') {
          const matchingLabel = campaignLabels.find((labelc) => labelc.name === label);
          if (matchingLabel) {
            label = matchingLabel;
          }
        }
        // If the label.id exists, the label has already been created and we just want to add
        // the broadcaster to the existing label
        if (label.id) {
          return dispatch(
            resourceUpdateRequest('labels', label.id, {
              ...label,
              components: [...label.components, component.id],
            }),
          );
        }
        // If there's no label.id, it's a new label and needs to be created
        return dispatch(
          resourceCreateRequest('labels', {
            name: label,
            broadcasters: [],
            campaign: campaignSlug,
            components: [component.id],
          }),
        );
      }
    },
    [broadcaster, campaignLabels, campaignSlug, component, dispatch],
  );

  const onSubmitSuccess = useCallback(() => {
    broadcaster && dispatch(modalHide(`new-label-${broadcaster}`));
    component && dispatch(modalHide(`labels-component-${component.id}`));
  }, [broadcaster, component, dispatch]);

  const validate = useValidator({
    label: [required, maxLength(32)],
  });

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, submitting, error, submitError, form}) => (
        <form
          onSubmit={(data) =>
            handleSubmit(data)?.then(() => {
              const {submitSucceeded} = form.getState();
              submitSucceeded && onSubmitSuccess();
            })
          }
          className="new-label-form"
        >
          <label htmlFor="Add Group Form">{`Add ${title} to a group`}</label>
          <Field
            component={AutoSuggestLocal}
            name="label"
            placeholder="Enter a new group name or select an existing one"
            suggestionSet={campaignLabels}
            filterField="name"
            displayParam="name"
            longerThan={0}
            SuggestionComponent={({suggestion}) => (
              <div className="autosuggest-suggestion">{suggestion.name}</div>
            )}
            allowRaw
          />
          <Button loading={!!submitting}>Add To Group</Button>
          <div className="extraText">
            Grouped components will only appear for broadcasters inside that group
          </div>
          {(error || submitError) && (
            <div className="help is-danger centered">{error || submitError}</div>
          )}
          {labels && labels.length > 0 && (
            <div className="labelSection">
              {labels.map((label) => (
                <span className="labelItem" key={`label-${label.name}`}>
                  <h4>{label.name}</h4>
                </span>
              ))}
            </div>
          )}
        </form>
      )}
    </Form>
  );
};

AttachLabelForm.propTypes = {
  title: PropTypes.string,
  campaignSlug: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.object),
  broadcaster: PropTypes.string,
  component: PropTypes.object,
};

export default AttachLabelForm;
