// Actions for sending username/password based logins (Clients/Brands and Admins)
export const AUTH_CLIENT_LOGIN_REQUEST = 'AUTH_CLIENT_LOGIN_REQUEST';
export const AUTH_CLIENT_LOGIN_FAILED = 'AUTH_CLIENT_LOGIN_FAILED';

export const authClientLoginRequest = (data, next) => ({
  type: AUTH_CLIENT_LOGIN_REQUEST,
  payload: data,
  meta: {thunk: 'client-login', next},
});

export const authClientLoginFailed = (error, thunk) => ({
  type: AUTH_CLIENT_LOGIN_FAILED,
  payload: error,
  error: true,
  meta: {thunk},
});

// Actions for sending logout requests to server
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_FAILED = 'AUTH_LOGOUT_FAILED';

export const authLogoutRequest = () => ({
  type: AUTH_LOGOUT_REQUEST,
  meta: {thunk: 'logout'},
});

export const authLogoutFailed = (error, thunk) => ({
  type: AUTH_LOGOUT_FAILED,
  error: true,
  payload: error,
  meta: {thunk},
});

// Actions for sending the authentication token from twitch to the server.
// Used for broadcaster OAuth2-based logins
export const AUTH_OAUTH2_CODE_REQUEST = 'AUTH_OAUTH2_CODE_REQUEST';
export const AUTH_OAUTH2_CODE_FAILED = 'AUTH_OAUTH2_CODE_FAILED';

export const authOAuth2CodeRequest = (code, provider, params, next) => ({
  type: AUTH_OAUTH2_CODE_REQUEST,
  payload: {code, provider, params},
  meta: {thunk: 'oauth2Code', next},
});

export const authOAuth2CodeFailed = (error, thunk) => ({
  type: AUTH_OAUTH2_CODE_FAILED,
  meta: {thunk},
  error: true,
  payload: error,
});

// Actions for getting cached auth data from locally-stored cookies and updating the isAuthenticated store
export const AUTH_CHECK_STATUS = 'CHECK_AUTH_STATUS';
export const AUTH_SET_STATUS = 'AUTH_SET_STATUS';

export const authCheckStatus = () => ({
  type: AUTH_CHECK_STATUS,
});

export const authSetStatus = (isAuthenticated) => ({
  type: AUTH_SET_STATUS,
  payload: {isAuthenticated},
});

// Actions for orchestrating all associated actions on login/login, including:
//   - setting cookies
//   - updating profile stores
//   - setting isAuthenticated in Redux store
export const AUTH_COMPLETE_LOGIN = 'AUTH_COMPLETE_LOGIN';
export const AUTH_COMPLETE_LOGOUT = 'AUTH_COMPLETE_LOGOUT';

export const authCompleteLogin = (token, stayLoggedIn, thunk, next) => ({
  type: AUTH_COMPLETE_LOGIN,
  meta: {
    thunk,
    token,
    stayLoggedIn,
    next,
  },
});

export const authCompleteLogout = (thunk) => ({
  type: AUTH_COMPLETE_LOGOUT,
  meta: {thunk},
});

// Actions for hitting a protected server endpoint to verify that a user is authenticated
// Used purely for development testing
export const AUTH_TEST_REQUEST = 'AUTH_TEST_REQUEST';
export const AUTH_TEST_COMPLETE = 'AUTH_TEST_COMPLETE';

export const authTestRequest = () => ({
  type: AUTH_TEST_REQUEST,
  meta: {thunk: 'authTest'},
});

export const authTestComplete = (errorContent, error, thunk) => ({
  type: AUTH_TEST_COMPLETE,
  payload: errorContent,
  error: !!error,
  meta: {thunk},
});

export const AUTH_RESET_REQUEST_REQUEST = 'AUTH_RESET_REQUEST_REQUEST';
export const AUTH_RESET_REQUEST_SUCCESS = 'AUTH_RESET_REQUEST_SUCCESS';
export const AUTH_RESET_REQUEST_FAILURE = 'AUTH_RESET_REQUEST_FAILURE';

export const authResetRequestRequest = (email) => ({
  type: AUTH_RESET_REQUEST_REQUEST,
  payload: {email},
  meta: {thunk: 'resetRequest'},
});

export const authResetRequestSuccess = (thunk) => ({
  type: AUTH_RESET_REQUEST_SUCCESS,
  meta: {thunk},
});

export const authResetRequestFailure = (error, thunk) => ({
  type: AUTH_RESET_REQUEST_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAILURE = 'AUTH_RESET_PASSWORD_FAILURE';

export const authResetPasswordRequest = (data) => ({
  type: AUTH_RESET_PASSWORD_REQUEST,
  payload: data,
  meta: {thunk: 'passwordReset'},
});

export const authResetPasswordSuccess = (thunk) => ({
  type: AUTH_RESET_PASSWORD_SUCCESS,
  meta: {thunk},
});

export const authResetPasswordFailure = (error, thunk) => ({
  type: AUTH_RESET_PASSWORD_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

export const AUTH_SIGN_UP_REQUEST = 'AUTH_SIGN_UP_REQUEST';
export const AUTH_SIGN_UP_SUCCESS = 'AUTH_SIGN_UP_SUCCESS';
export const AUTH_SIGN_UP_FAILURE = 'AUTH_SIGN_UP_FAILURE';

export const authSignUpRequest = (data) => ({
  type: AUTH_SIGN_UP_REQUEST,
  payload: data,
  meta: {thunk: 'signUp'},
});

export const authSignUpSuccess = (thunk) => ({
  type: AUTH_SIGN_UP_SUCCESS,
  meta: {thunk},
});

export const authSignUpFailure = (error, thunk) => ({
  type: AUTH_SIGN_UP_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});

// Sends email confirmation code to server
export const AUTH_EMAIL_CONFIRMATION_REQUEST = 'AUTH_EMAIL_CONFIRMATION_REQUEST';
export const AUTH_EMAIL_CONFIRMATION_SUCCESS = 'AUTH_EMAIL_CONFIRMATION_SUCCESS';
export const AUTH_EMAIL_CONFIRMATION_FAILED = 'AUTH_EMAIL_CONFIRMATION_FAILED';

export const authEmailConfirmationRequest = (key) => ({
  type: AUTH_EMAIL_CONFIRMATION_REQUEST,
  payload: {key},
  meta: {thunk: 'emailConfirmation'},
});

export const authEmailConfirmationSuccess = (thunk) => ({
  type: AUTH_EMAIL_CONFIRMATION_SUCCESS,
  meta: {thunk},
});

export const authEmailConfirmationFailed = (error, thunk) => ({
  type: AUTH_EMAIL_CONFIRMATION_FAILED,
  meta: {thunk},
  error: true,
  payload: error,
});
