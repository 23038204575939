import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {isPending, hasFailed} from 'redux-saga-thunk';
import {twitterURLRequest} from 'store/actions';
import {Twitter} from 'mdi-material-ui';

import Button from 'components/molecules/Button';

const getButtonMessaging = (screenName, hasTwitterOAuth, failed, alternateMessage) => {
  if (failed) {
    return 'Could not connect to Twitter';
  }
  if (!!screenName && hasTwitterOAuth) {
    return 'Change';
  }
  return alternateMessage || 'Connect your Twitter account';
};

const TwitterProfileDisplay = ({screenName, hasTwitterOAuth, alternateMessage}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => isPending(state, 'twitterUrl'));
  const failed = useSelector((state) => hasFailed(state, 'twitterUrl'));

  return (
    <div className="socialDisplay twitter">
      <Twitter />
      {!!screenName && (
        <div className="userDisplay">
          <h4>{`${screenName}`}</h4>
        </div>
      )}
      <Button handleClick={() => dispatch(twitterURLRequest())} loading={loading}>
        {getButtonMessaging(screenName, hasTwitterOAuth, failed, alternateMessage)}
      </Button>
    </div>
  );
};

TwitterProfileDisplay.propTypes = {
  screenName: PropTypes.string,
  alternateMessage: PropTypes.string,
  hasTwitterOAuth: PropTypes.bool,
};

export default TwitterProfileDisplay;
