import {put, call, takeEvery} from 'redux-saga/effects';

import {resourceUpdateSuccess} from 'store/resources/actions';
import {
  complianceAuditSuccess,
  complianceAuditFailure,
  COMPLIANCE_AUDIT_REQUEST,
  complianceOverrideSuccess,
  complianceOverrideFailure,
  COMPLIANCE_OVERRIDE_REQUEST,
} from './actions';

export function* triggerComplianceAudit(api, data, {thunk}) {
  try {
    yield call([api, api.post], 'trigger-compliance/', data);
    yield put(complianceAuditSuccess(thunk));
  } catch (e) {
    yield put(complianceAuditFailure(e, thunk));
  }
}

export function* triggerComplianceOverride(api, data, {thunk, existingCompliance, broadcaster}) {
  try {
    const response = yield call([api, api.post], 'override-compliance/', data);
    const newCompliance = {
      compliance: {
        [broadcaster]: {
          compliance: {
            [response.component_id]: {
              ...existingCompliance,
              status: response.verbiage,
              last_updated: response.audit_time,
              compliant: response.compliance,
            },
          },
        },
      },
    };
    yield put(resourceUpdateSuccess('compliance', newCompliance, thunk));
    yield put(complianceOverrideSuccess(thunk));
  } catch (e) {
    yield put(complianceOverrideFailure(e, thunk));
  }
}

export function* watchComplianceAudit(api, {payload, meta}) {
  yield call(triggerComplianceAudit, api, payload, meta);
}

export function* watchComplianceOverride(api, {payload, meta}) {
  yield call(triggerComplianceOverride, api, payload, meta);
}

export default function* sagas({api}) {
  yield takeEvery(COMPLIANCE_AUDIT_REQUEST, watchComplianceAudit, api);
  yield takeEvery(COMPLIANCE_OVERRIDE_REQUEST, watchComplianceOverride, api);
}
