import {initialState} from './selectors';
import {
  METRICS_DATA_SUCCESS,
  METRICS_PAYMENT_SUCCESS,
  METRICS_RAW_DATA_SUCCESS,
  METRICS_CLEAR_ARRAY_METRIC,
  METRICS_RESET_METRIC,
  METRICS_RAW_DATA_CLEAR,
} from './actions';

export default (state = initialState, {type, payload = {}, meta}) => {
  switch (type) {
    case METRICS_DATA_SUCCESS:
      return {
        ...state,
        [meta.metric]: payload,
      };
    case METRICS_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: payload,
      };
    case METRICS_RAW_DATA_SUCCESS:
      return {
        ...state,
        raw: {
          ...state.raw,
          [meta.dataType]: payload,
        },
      };
    case METRICS_RAW_DATA_CLEAR:
      return {...state, raw: {...state.raw, [meta.dataType]: []}};
    case METRICS_CLEAR_ARRAY_METRIC:
      return {...state, [meta.metric]: []};
    case METRICS_RESET_METRIC:
      return {
        ...state,
        [meta.metric]: Object.keys(state[meta.metric] || {}).reduce(
          (metric, key) => ({
            ...metric,
            [key]: 0,
          }),
          {},
        ),
      };
    default:
      return state;
  }
};
