const IconCampaign = () => (
  <svg version="1.1" viewBox="0 0 612 547" enableBackground="new 0 0 612 547">
    <circle display="none" fill="#D1D3D4" cx="304" cy="284.272" r="161" />
    <g>
      <path
        d="M512.328,68.925c6.244,0,11.308,5.374,11.31,12.005l0.145,388.316c0.003,6.633-5.06,12.013-11.305,12.015l-403.381,0.15
      c0,0-0.002,0-0.002,0c-6.246,0-11.309-5.375-11.312-12.007L97.638,81.089c-0.002-6.633,5.06-12.012,11.306-12.014l403.38-0.149
      C512.325,68.925,512.327,68.925,512.328,68.925 M512.329,33.925h-0.005l-403.394,0.149c-25.536,0.01-46.302,21.106-46.292,47.027
      l0.145,388.316c0.009,25.913,20.785,46.994,46.311,46.994l403.397-0.15c12.583-0.004,24.784-5.303,33.473-14.536
      c8.272-8.792,12.824-20.331,12.819-32.494l-0.145-388.314C558.63,55.007,537.856,33.925,512.329,33.925L512.329,33.925z"
      />
    </g>
    <path
      d="M471.374,184.102c0-38.68-31.354-70.034-70.033-70.034c-17.96,0-34.334,6.768-46.73,17.882
    c-2.548-0.845-5.258-1.291-8.052-1.291c-7.38,0-14.752,3.139-20.227,8.612L141.16,324.447
    c-10.826,10.826-11.474,27.796-1.442,37.828l80.985,80.986c4.624,4.624,10.875,7.17,17.602,7.17c7.382,0,14.755-3.14,20.228-8.613
    l185.177-185.174c6.734-6.735,9.527-15.846,8.264-24.187C463.983,219.886,471.374,202.862,471.374,184.102z M401.341,149.068
    c19.317,0,35.033,15.716,35.033,35.034s-15.716,35.034-35.033,35.034s-35.033-15.716-35.033-35.034S382.023,149.068,401.341,149.068
    z"
    />
  </svg>
);

export default IconCampaign;
