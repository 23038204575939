/* eslint-disable no-restricted-syntax */
import {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {uniqWith} from 'lodash';

import AdvWarning from 'components/components/AdvWarning';
import {CSSTransitionGroup} from 'react-transition-group';

const SelectOrCreate = ({input, meta, handleOnChange, items, disabled}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selection, setSelection] = useState(input.value);
  const refInput = useRef();

  const propagateUpdate = (newValue) => {
    if (input) {
      input.onChange(newValue);
    }
    if (handleOnChange) {
      handleOnChange(newValue);
    }
    setMenuOpen(false);
  };

  const updateManualSelection = (event) => {
    if (event.target.value === '') {
      setSelection(null);
      propagateUpdate(null);
    } else {
      setSelection(event.target.value);
      propagateUpdate(event.target.value);
    }
    setMenuOpen(false);
  };

  const updateSelectSelection = (item) => {
    setSelection(item);
    propagateUpdate(item);
  };

  const filteredItems = uniqWith(items, (a, b) => a.target === b.target).filter(
    (i) => !!i.target && !['undefined', 'null'].includes(i.target),
  );

  return (
    // Only the inner input is focusable, clicks from the outer component
    // should focus the inner text input
    <div
      className="adv-multiselect-wrapper focusWithin"
      role="button"
      tabIndex={-1}
      onBlur={(e) => {
        // closes the menu on a click outside of the multiselect wrapper
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setMenuOpen(false);
        }
      }}
    >
      <CSSTransitionGroup
        transitionName="slowFadeWarning"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {meta && meta.touched && meta.error && meta.submitFailed && (
          <AdvWarning>{meta.error}</AdvWarning>
        )}
      </CSSTransitionGroup>
      <div
        className={`adv-multiselect ${disabled ? 'disabled' : ''}`}
        role="button"
        tabIndex={-1}
        onClick={(e) => {
          // toggles the menu on a multiselect click
          if (e.target.className !== null && !disabled) {
            setMenuOpen(!menuOpen);
          }
          refInput.current.focus();
        }}
      >
        <input
          type="text"
          ref={refInput}
          disabled={disabled}
          placeholder="Select or enter a target link"
          value={selection}
          onChange={(e) => updateManualSelection(e)}
        />
      </div>
      {items && (
        <div className={`${menuOpen ? 'open ' : ''}menu`}>
          {filteredItems.map((item) => (
            <div
              className="item"
              onClick={() => updateSelectSelection(item.target)}
              role="button"
              tabIndex={0}
              key={item.id}
              data-value={item.target}
            >
              <span className="non-clickable">{item.target}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

SelectOrCreate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  input: PropTypes.object,
  handleOnChange: PropTypes.func,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
};

export default SelectOrCreate;
