import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {without} from 'lodash';

import Button from 'components/molecules/Button';
import AdvModal from 'containers/AdvModal';
import {resourceUpdateRequest} from 'store/actions';
import {isPending, hasFailed} from 'redux-saga-thunk';
import useModal from 'hooks/useModal';

const RemoveLabelModal = ({modalName, label, removeVal, valueName, removeFromField}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => isPending(state, 'labelsUpdate'));
  const failed = useSelector((state) => hasFailed(state, 'labelsUpdate'));
  const [, modalHide] = useModal();
  const removeRequest = useCallback(
    () =>
      dispatch(
        resourceUpdateRequest('labels', label.id, {
          ...label,
          [removeFromField]: without(label[removeFromField], removeVal),
        }),
      ).then(() => modalHide(modalName)),
    [dispatch, label, modalHide, modalName, removeFromField, removeVal],
  );
  return (
    <AdvModal name={modalName} contentLabel={`Remove ${label.name}`}>
      <label htmlFor="remove label" className="remove-label">
        {'Remove '}
        <strong>{valueName}</strong>
        {' from group '}
        <strong>{label.name}</strong>?
      </label>
      {failed && (
        <div className="help is-danger center">
          Couldn’t remove from group. Please try again later
        </div>
      )}
      <Button type="cancel" handleClick={() => modalHide(modalName)}>
        Cancel
      </Button>
      <Button loading={!!loading} handleClick={removeRequest}>
        Remove
      </Button>
    </AdvModal>
  );
};

RemoveLabelModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  removeVal: PropTypes.any.isRequired,
  removeFromField: PropTypes.string,
  valueName: PropTypes.string.isRequired,
};

export default RemoveLabelModal;
