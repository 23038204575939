export const CHATBOT_POST_REQUEST = 'CHATBOT_POST_REQUEST';
export const CHATBOT_POST_SUCCESS = 'CHATBOT_POST_SUCCESS';
export const CHATBOT_POST_FAILURE = 'CHATBOT_POST_FAILURE';

export const chatbotPostRequest = (data) => ({
  type: CHATBOT_POST_REQUEST,
  payload: data,
  meta: {
    thunk: 'chatbotPost',
  },
});

export const chatbotPostSuccess = (thunk) => ({
  type: CHATBOT_POST_SUCCESS,
  meta: {thunk},
});

export const chatbotPostFailure = (error, thunk) => ({
  type: CHATBOT_POST_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});

export const TRIGGER_FULLSCREEN_VIDEO_REQUEST = ' TRIGGER_FULLSCREEN_VIDEO_REQUEST';
export const TRIGGER_FULLSCREEN_VIDEO_SUCCESS = ' TRIGGER_FULLSCREEN_VIDEO_SUCCESS';
export const TRIGGER_FULLSCREEN_VIDEO_FAILURE = ' TRIGGER_FULLSCREEN_VIDEO_FAILURE';

export const triggerFullscreenVideoRequest = (data) => ({
  type: TRIGGER_FULLSCREEN_VIDEO_REQUEST,
  payload: data,
  meta: {
    thunk: 'triggerFullscreenVideo',
  },
});

export const triggerFullscreenVideoSuccess = (thunk) => ({
  type: TRIGGER_FULLSCREEN_VIDEO_SUCCESS,
  meta: {thunk},
});

export const triggerFullscreenVideoFailure = (error, thunk) => ({
  type: TRIGGER_FULLSCREEN_VIDEO_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});
