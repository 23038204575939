import {useState} from 'react';
import PropTypes from 'prop-types';
import {ContentCopy, EyeOutline, EyeOffOutline} from 'mdi-material-ui';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Button from 'components/molecules/Button';
import {useDispatch, useSelector} from 'react-redux';
import {apiKeyGenerateRequest} from 'store/actions';
import {isPending} from 'redux-saga-thunk';

const APIKeyForm = ({apiKey}) => {
  const [keyHidden, setKeyHidden] = useState(true);
  const obfuscatedKey = apiKey ? apiKey.replace(/\w/g, '•') : '';
  const dispatch = useDispatch();
  const generateKey = () => dispatch(apiKeyGenerateRequest());
  const loading = useSelector((state) => isPending(state, 'apiKeyGenerate'));

  return (
    <div className="apiKeyForm">
      <h2>API key </h2>
      <div className="apiKey">
        <div>
          <p>{keyHidden ? obfuscatedKey : apiKey}</p>
          <CopyToClipboard text={apiKey}>
            <div className="iconSection">
              <ContentCopy />
              <span className="tooltip">Copied!</span>
            </div>
          </CopyToClipboard>
          <div className="iconSection" onClick={() => setKeyHidden(!keyHidden)}>
            {keyHidden ? <EyeOutline /> : <EyeOffOutline />}
          </div>
        </div>
      </div>
      <Button disabled={loading} handleClick={generateKey}>
        Generate {apiKey ? 'New' : ''} Key
      </Button>
    </div>
  );
};

APIKeyForm.propTypes = {
  apiKey: PropTypes.string,
};

export default APIKeyForm;
