import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fromProfile} from 'store/selectors';
import {isPending} from 'redux-saga-thunk';
import {CSSTransitionGroup} from 'react-transition-group';
import {NavLink} from 'react-router-dom';
import {HashLink as Link} from 'react-router-hash-link';
import {resourceListReadRequest, browsersourceTriggerRequest} from 'store/actions';

import LoadingBar from 'components/molecules/LoadingBar';
import EmptySplash from 'components/molecules/EmptySplash';
import BroadcasterControlPanel from 'components/organisms/BroadcasterControlPanel';
import BaseTemplate from 'components/components/BaseTemplate';
import {AlertCircle, Twitch, Twitter, Link as LinkIcon} from 'mdi-material-ui';
import AvatarMetrics from './components/AvatarMetrics';
import Invitation from './components/Invitation';
import BrowsersourceBroadcasterAction from './components/BrowsersourceBroadcasterAction';
import {
  useCampaigns,
  useComponentVersions,
  useComponents,
  useBroadcasterActions,
  useCampaignInvitations,
  useTeamInvitations,
} from 'hooks';

const BroadcasterDashboard = () => {
  const dispatch = useDispatch();
  const triggerVideo = (componentID) => dispatch(browsersourceTriggerRequest(componentID));
  const refreshBroadcasterActions = () =>
    dispatch(resourceListReadRequest('broadcasterActions', {completed: false}));

  useEffect(() => {
    dispatch(resourceListReadRequest('campaignsInvitations'));
    dispatch(resourceListReadRequest('teamsInvitations'));
    dispatch(resourceListReadRequest('components', {active: true}));
    dispatch(resourceListReadRequest('campaigns', {include_metrics: true}));
    dispatch(resourceListReadRequest('broadcasterActions', {completed: false}));
    dispatch(resourceListReadRequest('watchParties'));
  }, [dispatch]);

  const profile = useSelector((state) => fromProfile.getFullProfile(state));
  const username = profile.username;

  const campaigns = useCampaigns();
  const loading = useSelector((state) => isPending(state, 'componentsListRead'));
  const versions = useComponentVersions();
  const components = useComponents();
  const actions = useBroadcasterActions();
  const campaignInvitations = useCampaignInvitations();
  const teamInvitations = useTeamInvitations();

  const videoComponents = components.filter(
    (component) =>
      component.type === 'fullscreen-video' && !component.is_deleted && component.is_ready,
  );

  const filteredActions = actions.filter((action) => !action.completion_time);

  const actionsByType = {
    default: [],
    panels: [],
    tweets: [],
    links: [],
  };

  filteredActions.forEach((action) => {
    if (
      action.type.slug === 'banner-update' ||
      action.type.slug === 'banner-add' ||
      action.type.slug === 'banner-remove'
    ) {
      actionsByType.panels.push(action);
    } else if (
      action.type.slug === 'twitter-send-tweet' ||
      action.type.slug === 'twitter-retweet'
    ) {
      actionsByType.tweets.push(action);
    } else if (action.type.slug === 'custom-link-add') {
      actionsByType.links.push(action);
    } else {
      actionsByType.default.push(action);
    }
  });

  return (
    <BaseTemplate>
      <div className="BroadcasterDashboardPage dashboardPage">
        <div className="topDashboardSection">
          <div className="content">
            <AvatarMetrics />
          </div>
        </div>
        <CSSTransitionGroup
          transitionName="slowFadeLoadingBar"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {loading && <LoadingBar message="Loading User Info" />}
        </CSSTransitionGroup>
        <div className="dashboardContent">
          <div className="dashboardQueue">
            {Object.values(actionsByType).flat().length === 0 && campaigns.length !== 0 ? (
              <EmptySplash loading={loading}>
                <h3>You&apos;ve completed all your assigned actions!</h3>
              </EmptySplash>
            ) : (
              <h4>
                <b>Your Action Queue</b>
              </h4>
            )}

            {actionsByType.default.length > 0 && (
              <div
                className={`broadcasterComponentsQueue defaultActions ${
                  loading ? 'is-loading' : ''
                }`}
              >
                <CSSTransitionGroup
                  transitionName="actionCompleted"
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={1500}
                >
                  {actionsByType.default.map((action) => (
                    <BrowsersourceBroadcasterAction
                      key={action.id}
                      username={username}
                      action={action}
                    />
                  ))}
                </CSSTransitionGroup>
              </div>
            )}

            {actionsByType.panels.length > 0 && (
              <NavLink to="/panels" className="actionBanner twitch">
                <div className="shadow" />
                <h4>
                  <Twitch />
                  <b>{actionsByType.panels.length}</b>
                  {actionsByType.panels.length > 1
                    ? ' banner graphic actions to complete!'
                    : ' banner graphic action to complete!'}
                </h4>
                <AlertCircle />
              </NavLink>
            )}

            {actionsByType.tweets.length > 0 && (
              <NavLink to="/twitter" className="actionBanner twitter">
                <div className="shadow" />
                <h4>
                  <Twitter />
                  <b>{actionsByType.tweets.length}</b>
                  {actionsByType.tweets.length > 1
                    ? ' twitter actions to complete!'
                    : ' twitter action to complete!'}
                </h4>
                <AlertCircle />
              </NavLink>
            )}

            {actionsByType.links.length > 0 && (
              <NavLink to="/links" className="actionBanner twitch">
                <div className="shadow" />
                <h4>
                  <LinkIcon />
                  <b>{actionsByType.links.length}</b>
                  {actionsByType.links.length > 1
                    ? ' custom link actions to complete!'
                    : ' custom link action to complete!'}
                </h4>
                <AlertCircle />
              </NavLink>
            )}

            {campaignInvitations.map((invite) => (
              <Invitation
                onInvite={() => {
                  setTimeout(() => refreshBroadcasterActions(), 500);
                }}
                invite={invite}
                invitationType="Campaign"
                key={invite.slug}
              />
            ))}

            {teamInvitations.map((invite) => (
              <Invitation
                onInvite={() => {
                  setTimeout(() => refreshBroadcasterActions(), 2000);
                }}
                invite={invite}
                invitationType="Team"
                key={invite.id}
              />
            ))}
          </div>

          {campaigns.length === 0 && (
            <div className="broadcasterEmptySplash">
              <EmptySplash loading={loading}>
                <h2>Looking for a campaign?</h2>
                <h2>
                  {'Tell us more about yourself at '}
                  <a href="mailto:contact@adv.gg?Subject=Partnership">contact@adv.gg</a>!
                </h2>
              </EmptySplash>
            </div>
          )}

          {actionsByType.default.length === 0 && (
            <div className="dashboardRightPane">
              <BroadcasterControlPanel
                videoComponents={videoComponents}
                versions={versions}
                triggerVideo={triggerVideo}
                loading={loading}
              />
              {campaigns.length > 0 && (
                <h4>
                  <b>Your Active Campaigns: </b>
                </h4>
              )}
              {campaigns.map((c) => (
                <div key={c.slug} className="campaignShortcut">
                  <Link key={c.slug} className="campaignItem" to={`/campaigns#${c.slug}`}>
                    {c.name}
                  </Link>
                  {/* <div className="campaignMetrics">
                    {c.metrics.conversions ? (
                      <p>
                        {'Conversions generated: '}
                        <b>{formatNumber(c.metrics.conversions)}</b>
                      </p>
                    ) : (
                      c.metrics.clicks > 0 && (
                        <p>
                          {'Clicks generated: '}
                          <b>{formatNumber(c.metrics.clicks)}</b>
                        </p>
                      )
                    )}
                    {c.metrics.minutes_streamed > 0 && (
                      <p>
                        {'Hours streamed: '}
                        <b>{formatNumber(c.twitch_viewership.minutes_streamed / 60)}</b>
                      </p>
                    )}
                    {c.twitch_viewership.average_viewers > 0 && (
                      <p>
                        {'Average viewers: '}
                        <b>{formatNumber(c.twitch_viewership.average_viewers)}</b>
                      </p>
                    )}
                    {c.twitch_viewership.recorded_broadcasts > 0 && (
                      <p>
                        {'Broadcasts recorded: '}
                        <b>{formatNumber(c.twitch_viewership.recorded_broadcasts)}</b>
                      </p>
                    )}
                  </div> */}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </BaseTemplate>
  );
};

export default BroadcasterDashboard;
