import PropTypes from 'prop-types';
import {Check} from 'mdi-material-ui';
import DownloadButton from 'components/components/DownloadButton';
import CopyButton from 'components/components/CopyButton';
import IconButtonButton from 'components/components/IconButtonButton';
import useModal from 'hooks/useModal';

const BroadcasterPanelRowItem = ({action, component}) => {
  const [modalShow] = useModal();

  return (
    <div className="broadcasterPanelRowItemWrapper">
      <div className="broadcasterPanelRowItem">
        <img src={action.version.image} alt="" />
        <div className="content">
          <div className="bottomBar">
            <IconButtonButton
              label="help"
              handleClick={() => modalShow(`action-${action.id}-complete`)}
            >
              <Check />
            </IconButtonButton>
            <h4>Mark As Complete</h4>
          </div>
          {action.type.slug !== 'banner-remove' && (
            <>
              <div className="actionRow">
                <p>Download Image</p>
                <DownloadButton href={action.version.image} />
              </div>
              <div className="actionRow">
                <p>Copy Link: {action.version.link}</p>
                <CopyButton content={action.version.link} />
              </div>
            </>
          )}
          <h3>{action.type.name}</h3>
        </div>
      </div>
      {component.campaign && <h4 className="campaign">{`Campaign ${component.campaign.name}`}</h4>}
    </div>
  );
};

BroadcasterPanelRowItem.propTypes = {
  action: PropTypes.object,
  component: PropTypes.object,
};

export default BroadcasterPanelRowItem;
