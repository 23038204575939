import CampaignTemplate from '../CampaignTemplate';

const CampaignNotFoundPage = () => {
  return (
    <CampaignTemplate>
      <div className="errorPage campaignNotFound has-text-centered">
        <h1>404</h1>
        <h2>Campaign Not Found</h2>
      </div>
    </CampaignTemplate>
  );
};

export default CampaignNotFoundPage;
