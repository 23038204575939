import PropTypes from 'prop-types';
import {CheckBold, CheckboxBlankOutline} from 'mdi-material-ui';
import {CSSTransitionGroup} from 'react-transition-group';

const Checkbox = ({onCheck, label, onAnyClick, disabled, input, ...rest}) => {
  const {value, onChange} = input;
  return (
    <label role="button" className="checkboxWrapper">
      <input
        type="checkbox"
        {...rest}
        onChange={() => !disabled && onChange(!value)}
        disabled={disabled}
        tabIndex={disabled ? -1 : 0}
      />
      <div
        className={`checkbox ${value ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
        role="button"
        checked={value}
        onClick={onCheck}
      >
        <CSSTransitionGroup
          transitionName="slowFadeWarning"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {value ? <CheckBold /> : <CheckboxBlankOutline />}
        </CSSTransitionGroup>
      </div>
      <h3>{label}</h3>
    </label>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.bool,
  onCheck: PropTypes.func,
  onAnyClick: PropTypes.bool,
};

export default Checkbox;
