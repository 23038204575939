import PropTypes from 'prop-types';
import Checkbox from 'components/atoms/Checkbox';
import {Field} from 'react-final-form';

const CheckboxField = ({fieldName, description}) => (
  <div className="autoActiveCheckbox">
    <Field
      name="config"
      component={Checkbox}
      checkboxclass="muiCheckbox"
      type="checkbox"
      format={(value) => (value ? value[fieldName] : undefined)}
      parse={(value) => ({[fieldName]: value})}
    />
    <p>{description}</p>
  </div>
);

CheckboxField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default CheckboxField;
