import PropTypes from 'prop-types';

import Select from 'components/molecules/Select';

const ObjectSelect = ({
  objects,
  input,
  meta,
  disabled,
  defaultText,
  keyField,
  valueField,
  textField,
}) =>
  !!objects.length && (
    <Select input={input} meta={meta} disabled={disabled}>
      <option value="">{defaultText}</option>
      {objects.map((obj) => (
        <option key={obj[keyField]} value={obj[valueField]}>
          {obj[textField]}
        </option>
      ))}
    </Select>
  );

ObjectSelect.propTypes = {
  objects: PropTypes.arrayOf(PropTypes.object),
  input: PropTypes.object,
  meta: PropTypes.object,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultText: PropTypes.string,
  keyField: PropTypes.string,
  valueField: PropTypes.string,
  textField: PropTypes.string,
};

export default ObjectSelect;
