import PropTypes from 'prop-types';

const Button = ({handleClick, children, type, loading, disabled}) => {
  const className = loading ? `${type} is-loading` : type;

  return (
    <button
      onClick={handleClick}
      onKeyPress={handleClick}
      className={`advButton ${className}`}
      disabled={loading || disabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  handleClick: PropTypes.func,
  children: PropTypes.any,
  type: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
