import PropTypes from 'prop-types';
import {useCallback, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Field, Form} from 'react-final-form';
import {required, createValidator} from 'services/validation';
import {isDone} from 'redux-saga-thunk';
import {triggerFullscreenVideoRequest} from 'store/actions';

import MultiSelect from 'components/molecules/MultiSelect';
import Select from 'components/molecules/Select';
import Button from 'components/molecules/Button';

const TriggerComponentForm = ({broadcasters, endpoint, components, componentType}) => {
  const dispatch = useDispatch();
  const validate = createValidator({
    broadcasters: [required],
    component: [required],
  });
  const done = useSelector((state) => isDone(state, endpoint));
  const [selectedBroadcasters, setSelectedBroadcasters] = useState([]);

  const onSubmit = useCallback(
    (data) => {
      if (endpoint === 'triggerFullscreenVideo') {
        return dispatch(
          triggerFullscreenVideoRequest({
            ...data,
            broadcasters: data.broadcasters.map((b) => b.username),
          }),
        );
      }
      return null;
    },
    [dispatch, endpoint],
  );

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, submitting, error}) => (
        <form onSubmit={handleSubmit}>
          <label
            htmlFor={`trigger ${componentType.name} component`}
          >{`Trigger ${componentType.name} Component`}</label>
          <Field
            component={MultiSelect}
            title="Select Broadcasters"
            name="broadcasters"
            items={broadcasters}
            selectedItems={selectedBroadcasters}
            handleOnChange={setSelectedBroadcasters}
            itemIDField="username"
            itemNameField="username"
          />
          <Field component={Select} name="component">
            <option value="">{`Select a ${componentType.name}`}</option>
            {components.map((component) => (
              <option key={component.id} value={component.id}>
                {component.name}
              </option>
            ))}
          </Field>

          {error && <div className="help is-danger centered">{error}</div>}
          {done && <div className="success">Component Triggered!</div>}
          <Button loading={!!submitting}>Trigger</Button>
        </form>
      )}
    </Form>
  );
};

TriggerComponentForm.propTypes = {
  broadcasters: PropTypes.arrayOf(PropTypes.object),
  components: PropTypes.arrayOf(PropTypes.object),
  componentType: PropTypes.object,
  endpoint: PropTypes.string,
};

export default TriggerComponentForm;
