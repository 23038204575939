import PropTypes from 'prop-types';
import {useRef} from 'react';

const LabelSelect = ({handleChange, children, label, defaultValue}) => {
  const selectRef = useRef(null);
  return (
    <div className="labelSelect">
      {label && <h4 className="sortBy">{label}</h4>}
      <div className="adv-select">
        <select
          ref={selectRef}
          defaultValue={defaultValue}
          onChange={(e) => handleChange(e.target.value)}
        >
          {children}
        </select>
      </div>
    </div>
  );
};

LabelSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  children: PropTypes.any,
  defaultValue: PropTypes.any,
  label: PropTypes.string,
};

export default LabelSelect;
