import PropTypes from 'prop-types';

import EmptySplash from 'components/molecules/EmptySplash';
import GameMetricsTable from './components/GameMetricsTable';
import {formatPercentage} from 'utils/numberFormats';

const GameBreakdown = ({clicks, components, gameViewership, isFiltered, conversions, campaign}) => {
  const gameClicksTable = {};
  const gameConversionsTable = {};
  const gameClicksCountryTable = {};

  clicks &&
    clicks.forEach((click) => {
      gameClicksTable[click.game_name]
        ? (gameClicksTable[click.game_name] += 1)
        : (gameClicksTable[click.game_name] = 1);
      if (gameClicksCountryTable[click.game_name]) {
        gameClicksCountryTable[click.game_name][click.country]
          ? (gameClicksCountryTable[click.game_name][click.country] += 1)
          : (gameClicksCountryTable[click.game_name][click.country] = 1);
      } else {
        gameClicksCountryTable[click.game_name] = {};
        gameClicksCountryTable[click.game_name][click.country] = 1;
      }
    });

  campaign &&
    campaign.has_conversions &&
    conversions &&
    conversions.forEach((conversion) => {
      gameConversionsTable[conversion.game_name]
        ? (gameConversionsTable[conversion.game_name] += 1)
        : (gameConversionsTable[conversion.game_name] = 1);
    });

  const gameTopBroadcastersTable = {};

  gameViewership &&
    gameViewership.forEach((game) => {
      gameTopBroadcastersTable[game.name] = game.top_contributors;
    });

  const gameTable = gameViewership.map((game) => ({
    ...game,
    clicks: gameClicksTable[game.name],
    conversions: gameConversionsTable[game.name],
  }));

  const tableData = gameTable.map((game) => {
    const gameData = [game.name, game.viewable_minutes / 60, game.minutes_broadcast / 60];
    if (clicks) {
      gameData.push(game.clicks, game.clicks / (game.viewable_minutes / 1000000));
    }
    if (campaign && campaign.has_conversions) {
      gameData.push(
        game.conversions,
        `${formatPercentage((game.conversions / game.clicks) * 100)}%`,
      );
    }
    return {
      game: game.name,
      data: gameData,
    };
  });

  const tableHeaders = ['Name', 'Watched Hours', 'Hours Broadcast'];
  if (clicks) {
    tableHeaders.push('Total Clicks', 'CVM');
  }
  if (campaign && campaign.has_conversions) {
    tableHeaders.push('Total Conversions', 'Conversion Rate');
  }

  return (
    <div className={`clickBreakdown ${!gameViewership.length ? 'empty' : 0}`}>
      {gameViewership.length ? (
        <GameMetricsTable
          headers={tableHeaders}
          metrics={tableData}
          isFiltered={isFiltered}
          expandableSectionData={{
            'Top Countries': gameClicksCountryTable,
            'Top Contributors': gameTopBroadcastersTable,
          }}
        />
      ) : (
        <EmptySplash>
          <h3>No streamed games found</h3>
        </EmptySplash>
      )}
    </div>
  );
};

GameBreakdown.propTypes = {
  campaign: PropTypes.object,
  clicks: PropTypes.arrayOf(PropTypes.object),
  conversions: PropTypes.arrayOf(PropTypes.object),
  components: PropTypes.arrayOf(PropTypes.object),
  gameViewership: PropTypes.arrayOf(PropTypes.object),
  isFiltered: PropTypes.bool,
  filterStart: PropTypes.string,
  filterEnd: PropTypes.string,
};

export default GameBreakdown;
