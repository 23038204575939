/* eslint-disable camelcase */
import {call, put, takeEvery} from 'redux-saga/effects';
import {setCookie} from 'redux-cookie';

import {
  TWITTER_CONNECT_REQUEST,
  twitterConnectSuccess,
  twitterConnectFailure,
  TWITTER_URL_REQUEST,
  twitterURLFailure,
  profileCompleteSetUserData,
  DISCORD_CONNECT_REQUEST,
  discordConnectSuccess,
  discordConnectFailure,
} from 'store/actions';
import history from 'localHistory';

// Since Twitter OAuth is OAuth1, we actually need to fetch the
// approval URL from the service first
export function* twitterGetURL(api, {thunk}) {
  try {
    const response = yield call([api, api.get], 'twitter/request-token/');
    put(
      setCookie('advSourceDomain', window.location.host, {
        path: '/',
        domain: window.location.host.includes('localhost') ? 'localhost' : '.adv.gg',
      }),
    );
    window.location.href = response.authorization_url;
  } catch (error) {
    yield put(twitterURLFailure(error, thunk));
  }
}

export function* twitterConnect(api, {thunk}, {oAuthToken, oAuthVerifier}) {
  try {
    const response = yield call([api, api.post], 'twitter/connect/', {
      oauth_token: oAuthToken,
      oauth_verifier: oAuthVerifier,
    });
    yield put(profileCompleteSetUserData(response.user, true));
    yield put(twitterConnectSuccess(thunk));
    yield call(history.push, '/profile');
  } catch (error) {
    yield put(twitterConnectFailure(error, thunk));
  }
}

export function* discordConnect(api, {thunk}, {code, callback_url}) {
  try {
    const response = yield call([api, api.post], 'discord/connect/', {
      code,
      callback_url,
    });
    yield put(profileCompleteSetUserData(response.user, true));
    yield put(discordConnectSuccess(thunk));
    yield call(history.push, '/profile');
  } catch (error) {
    yield put(discordConnectFailure(error, thunk));
  }
}

export function* watchTwitterURLRequest(api, {meta}) {
  yield call(twitterGetURL, api, meta);
}

export function* watchTwitterConnect(api, {meta, payload}) {
  yield call(twitterConnect, api, meta, payload);
}

export function* watchDiscordConnect(api, {meta, payload}) {
  yield call(discordConnect, api, meta, payload);
}

export default function* sagas({api}) {
  yield takeEvery(TWITTER_URL_REQUEST, watchTwitterURLRequest, api);
  yield takeEvery(TWITTER_CONNECT_REQUEST, watchTwitterConnect, api);
  yield takeEvery(DISCORD_CONNECT_REQUEST, watchDiscordConnect, api);
}
