import PropTypes from 'prop-types';

import AttributionOverTimeChart from 'components/charts/AttributionOverTimeChart';
import CVMOverTimeChart from 'components/charts/CVMOverTimeChart';
import CampaignMetricCard from 'components/organisms/CampaignMetricCard';
import {LinkVariant, Earth, PlusBoxMultiple} from 'mdi-material-ui';
import {useComponents} from 'hooks';
import {getFrequencyTableFromClicks, getLabeledCountryClicksFromTable} from 'utils/campaignTools';

const ClickBreakdown = ({clicks, isFiltered, campaign, rawViewership}) => {
  const totalClicks = clicks ? clicks.length : 0;
  const tables = getFrequencyTableFromClicks(clicks);
  const finalCountryTable = getLabeledCountryClicksFromTable(tables);
  const components = useComponents();

  return (
    <div className="clickBreakdown">
      <CampaignMetricCard
        metrics={finalCountryTable}
        emptyMessage="No clicks found"
        isFiltered={isFiltered}
        metricType="broadcast"
        title="Clicks By Country"
        icon={<Earth />}
        loadingThunk="clicksRawData"
        withPercentage={totalClicks}
      />
      <CampaignMetricCard
        metrics={tables.targetLinks.map((entry) => ({
          label: entry[0],
          value: entry[1],
        }))}
        emptyMessage="No clicks found"
        isFiltered={isFiltered}
        metricType="broadcast"
        title="Clicks By Target Link"
        icon={<LinkVariant />}
        loadingThunk="clicksRawData"
      />
      <CampaignMetricCard
        metrics={tables.components.map((entry) => ({
          id: entry[0],
          label:
            components && components.find((i) => i.id === entry[0])
              ? components.find((i) => i.id === entry[0]).name
              : entry[0],
          value: entry[1],
        }))}
        emptyMessage="No clicks found"
        isFiltered={isFiltered}
        metricType="broadcast"
        title="Clicks By Component"
        icon={<PlusBoxMultiple />}
        loadingThunk={`components-${campaign.slug}-true`}
      />
      {
        <AttributionOverTimeChart
          title="Clicks Over Time"
          name="clicks-over-time"
          timeKeyFormat="%Y-%m-%d-%H"
          data={clicks}
          unit="clicks"
          cumulative
        />
      }
      {
        <AttributionOverTimeChart
          title="Clicks By Time of Day"
          name="clicks-by-time-of-day"
          timeKeyFormat="%H"
          tooltipFormat="%H:00"
          dateTimeFormatXAxis={{hour: '%H:%M', day: '%H:%M'}}
          data={clicks}
          unit="clicks"
        />
      }
      {
        <CVMOverTimeChart
          title="CVM By Day"
          name="cvm-by-day"
          timeKeyFormat="%Y-%m-%d"
          clicks={clicks}
          views={rawViewership}
        />
      }
    </div>
  );
};

ClickBreakdown.propTypes = {
  campaign: PropTypes.object,
  clicks: PropTypes.arrayOf(PropTypes.object),
  rawViewership: PropTypes.arrayOf(PropTypes.object),
  isFiltered: PropTypes.bool,
  filterStart: PropTypes.string,
  filterEnd: PropTypes.string,
};

export default ClickBreakdown;
