const IconAdmin = () => (
  <svg version="1.1" viewBox="0 0 612 547">
    <circle display="none" fill="#D1D3D4" cx="304" cy="284.272" r="161" />
    <g>
      <path
        d="M512.328,68.925c6.244,0,11.308,5.374,11.31,12.005l0.145,388.316c0.003,6.633-5.06,12.013-11.305,12.015l-403.381,0.15
       c0,0-0.002,0-0.003,0c-6.245,0-11.308-5.375-11.311-12.007L97.638,81.089c-0.002-6.633,5.06-12.012,11.306-12.014l403.38-0.149
       C512.325,68.925,512.327,68.925,512.328,68.925 M512.329,33.925h-0.005l-403.394,0.149c-25.536,0.01-46.302,21.106-46.292,47.027
       l0.145,388.316c0.009,25.913,20.785,46.994,46.311,46.994l403.397-0.15c12.583-0.004,24.784-5.303,33.473-14.536
       c8.272-8.792,12.824-20.331,12.819-32.494l-0.145-388.314C558.63,55.007,537.856,33.925,512.329,33.925L512.329,33.925z"
      />
    </g>
    <path
      fill="none"
      stroke="#000000"
      strokeWidth="35"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M306.001,401"
    />
    <path
      fill="none"
      stroke="#000000"
      strokeWidth="35"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M310.711,134.669c0,0-17.837,34-105.145,24v122c0,0-0.617,69.5,53.511,104l51.634,31"
    />
    <path
      fill="none"
      stroke="#000000"
      strokeWidth="35"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M310.711,134.669c0,0,17.838,34,105.145,24v122c0,0,0.617,69.5-53.512,104l-51.633,31"
    />
  </svg>
);

export default IconAdmin;
