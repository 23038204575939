/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {MANAGER, BROADCASTER} from 'store/profile/userTypes';

import {
  IconLogout,
  IconAdmin,
  IconDashboard,
  IconProfile,
  IconCampaign,
  IconStream,
  IconUsers,
} from 'components/icons';
import Hamburger from './components/Hamburger';
import {Twitch, Twitter, Link, TelevisionClassic} from 'mdi-material-ui';
import {CSSTransitionGroup} from 'react-transition-group';
import {isCampaignEnded} from 'utils/numberFormats';
import {NavLink} from 'react-router-dom';
import {images} from 'adv-theme';
import usePermissions from 'hooks/usePermissions';

import {fromProfile, fromResources} from 'store/selectors';
import {authLogoutRequest} from 'store/actions';

const Sidebar = () => {
  const {campaignSlug} = useParams();
  const dispatch = useDispatch();
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [campaignOpen, setCampaignOpen] = useState(-1);
  const [teamOpen, setTeamOpen] = useState(-1);
  const [campaignFilter, setCampaignFilter] = useState('');

  const profile = useSelector((state) => fromProfile.getFullProfile(state));
  const userType = profile.user_type;
  const selectedCampaign = campaignSlug;
  const campaigns = useSelector((state) => fromResources.getResourceList(state, 'campaigns'));
  const teams = useSelector((state) => fromResources.getResourceList(state, 'teams'));
  const username = profile.username;
  const availableComponents = profile.available_components;

  const toggleFunc = () => setIsMobileOpen(!isMobileOpen);
  const logout = () => dispatch(authLogoutRequest());

  const visibleCampaigns = campaigns.filter((campaign) => !isCampaignEnded(campaign));
  const filteredCampaigns = campaignFilter
    ? campaigns.filter(
        (campaign) => campaign.name.toLowerCase().indexOf(campaignFilter.toLowerCase()) !== -1,
      )
    : visibleCampaigns;

  const toggleCampaignDropdown = (campaignNumber) => {
    if (campaignNumber === campaignOpen) {
      setCampaignOpen(-1);
    } else {
      setCampaignOpen(campaignNumber);
    }
  };

  const toggleTeamDropdown = (teamNumber) => {
    if (teamNumber === teamOpen) {
      setTeamOpen(-1);
    } else {
      setTeamOpen(teamNumber);
      setCampaignOpen(-1);
    }
  };

  const sidebarView = isMobileOpen ? 'navbar-menu is-active expand' : 'navbar-menu';
  const defaultLinks = [
    {text: 'Profile', icon: <IconProfile />, target: '/profile'},
    {
      text: 'Dashboard',
      icon: <IconDashboard />,
      target: '/dashboard',
    },
  ];

  const hasCampaigns = campaigns.length === 0;
  const broadcasterLinks = [
    {
      text: 'Campaigns',
      icon: <IconCampaign />,
      target: '/campaigns',
      disabled: hasCampaigns,
    },
    {
      text: 'Panels',
      icon: <Twitch />,
      target: '/panels',
      disabled: !availableComponents?.includes('banner-graphics'),
    },
    {
      text: 'Chatbot',
      icon: <Twitch />,
      target: '/chatbot',
      disabled:
        !availableComponents?.includes('twitch-chat-bot') &&
        !availableComponents?.includes('twitch-chat-bot-command'),
    },
    ...(availableComponents?.includes('custom-link')
      ? [
          {
            text: 'Custom Links',
            icon: <Link />,
            target: '/links',
            disabled: !availableComponents?.includes('custom-link'),
          },
        ]
      : []),
    {
      text: 'Twitter',
      icon: <Twitter />,
      target: '/twitter',
      disabled:
        !availableComponents?.includes('suggested-tweet') &&
        !availableComponents?.includes('suggested-retweet'),
    },
    ...(availableComponents?.includes('watch-party')
      ? [
          {
            text: 'Watch Party',
            icon: <TelevisionClassic />,
            target: '/watch-party',
            disabled: !availableComponents?.includes('watch-party'),
          },
        ]
      : []),
    {
      text: 'Stream Preview',
      icon: <IconStream />,
      target: '/stream-preview',
      disabled: !availableComponents?.includes('live-graphics'),
    },
  ];

  const adminLinks = [{text: 'Admin', icon: <IconAdmin />, target: '/admin'}];

  const campaignItems = [
    {text: 'Overview', target: '', adminOnly: false},
    {text: 'Components', target: 'components', adminOnly: false},
    {text: 'Channels', target: 'channels', adminOnly: false},
    {text: 'Metrics', target: 'metrics', adminOnly: false},
    {text: 'Fulfillment', target: 'compliance', adminOnly: false},
    {text: 'Management', target: 'management', adminOnly: false},
  ];

  const {canViewUsers} = usePermissions();
  const userAdminLinks = [{text: 'Managers', icon: <IconUsers />, target: '/managers'}];

  const getLinks = (userType) => {
    if (userType === BROADCASTER) return [...defaultLinks, ...broadcasterLinks];

    if (profile.is_admin) return [...defaultLinks, ...userAdminLinks, ...adminLinks];

    if (canViewUsers) return [...defaultLinks, ...userAdminLinks];

    return defaultLinks;
  };
  const sortedCampaigns = filteredCampaigns.sort((campaignA, campaignB) => {
    if (campaignA.team === campaignB.team) {
      return campaignA.name > campaignB.name ? 1 : -1;
    }
    return campaignA.team > campaignB.team ? -1 : 1;
  });

  const availableTeams = [...new Set(campaigns.map((c) => c.team).filter((t) => t))];
  const multipleTeams = availableTeams.length > 1;

  return (
    <div
      className="sidebar is-narrow column"
      role="button"
      tabIndex={-1}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          isMobileOpen && toggleFunc();
        }
      }}
    >
      <Hamburger isOpen={isMobileOpen} clickFunc={toggleFunc} />
      <div
        className={`sidebar is-narrow column ${
          profile.is_admin ? 'is-admin' : ''
        } ${sidebarView} has-${userType !== BROADCASTER ? campaigns.length : 1}-campaigns`}
      >
        <aside className="menu">
          <div className="menu-label menu-header">
            <img
              className={images.squareLogo ? 'squareLogo' : 'fullSquareLogo'}
              src={images.squareLogo || images.fullLogo}
              alt="Advocate"
            />
            <h3>
              {' '}
              {username}
              <span
                className="logoutButton"
                tabIndex={0}
                aria-label="logout"
                role="button"
                onClick={logout}
              >
                <IconLogout />
                <p>Log Out</p>
              </span>
            </h3>
            <hr className="menu-header-divider" />
          </div>
          {campaigns.length > 10 && userType !== BROADCASTER && profile.is_admin && (
            <input
              type="text"
              className="textFilter"
              placeholder="Filter Campaigns"
              value={campaignFilter}
              onChange={(e) => setCampaignFilter(e.target.value)}
            />
          )}

          <ul className="menu-list">
            {/* Default Links */}
            {getLinks(userType).map((link) => (
              <li
                key={`sidebar-item-link-${link.text}`}
                className={`sidebarItemWrapper ${link.disabled ? 'disabled' : ''}`}
              >
                <NavLink
                  to={!link.disabled && link.target}
                  activeClassName="is-active"
                  onClick={toggleFunc}
                >
                  <div className={`sidebarItem ${!link.disabled ? 'disabled' : ''}`}>
                    {link.icon}
                    {link.text}
                  </div>
                </NavLink>
              </li>
            ))}
            {/* Teams */}
            {profile.is_admin &&
              availableTeams.map((team) => {
                const hasTeamLogo = teams && teams.find((t) => t.id === team);
                const teamCampaigns = sortedCampaigns.filter((c) => c.team === team);

                return (
                  hasTeamLogo &&
                  teamCampaigns.length > 0 && (
                    <span
                      key={`team${team}`}
                      className={`sidebarItemWrapper brand ${teamOpen === team ? 'selected' : ''}`}
                    >
                      <div
                        className={
                          team === teamOpen
                            ? 'sidebarItemMenu is-open'
                            : 'sidebarItemMenu is-not-open'
                        }
                      >
                        <span
                          className="sidebarItem sidebarItemButton"
                          role="button"
                          tabIndex={0}
                          onClick={() => toggleTeamDropdown(team)}
                        >
                          <a
                            role="button"
                            tabIndex={-1}
                            className={teamOpen === team ? 'is-active' : ''}
                          >
                            {hasTeamLogo && hasTeamLogo.logo ? (
                              <img src={hasTeamLogo.logo} className="logoImage" alt="" />
                            ) : (
                              <IconCampaign />
                            )}
                            <div className="campaignName">{hasTeamLogo.name}</div>
                          </a>
                          <div className="arrowBox">
                            <span className="arrow" />
                          </div>
                        </span>
                        <CSSTransitionGroup
                          transitionName="slideSidebar"
                          transitionEnterTimeout={500}
                          transitionLeaveTimeout={500}
                        >
                          {teamOpen === team && (
                            <div>
                              <div className="subMenu">
                                {teamCampaigns.map((campaign) => (
                                  <li
                                    key={campaign.slug}
                                    className={`sidebarItemWrapper brand ${
                                      selectedCampaign === campaign.slug && team === teamOpen
                                        ? 'selected'
                                        : ''
                                    }`}
                                  >
                                    <div
                                      className={
                                        team === teamOpen && campaignOpen === campaign.slug
                                          ? 'sidebarItemMenu is-open'
                                          : 'sidebarItemMenu is-not-open'
                                      }
                                    >
                                      <span
                                        className={`sidebarItem sidebarItemButton ${
                                          isCampaignEnded(campaign) ? 'ended' : ''
                                        }`}
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => toggleCampaignDropdown(campaign.slug)}
                                      >
                                        <NavLink
                                          to={`/campaigns/${campaign.slug}`}
                                          activeClassName="is-active"
                                          onClick={toggleFunc}
                                        >
                                          <IconCampaign />
                                          <div className="campaignName">{campaign.name}</div>
                                        </NavLink>
                                        <div className="arrowBox">
                                          <span className="arrow" />
                                        </div>
                                      </span>
                                      <CSSTransitionGroup
                                        transitionName="slideSidebar"
                                        transitionEnterTimeout={500}
                                        transitionLeaveTimeout={500}
                                      >
                                        {team === teamOpen && campaignOpen === campaign.slug && (
                                          <div>
                                            <div className="subMenu">
                                              {campaignItems
                                                .filter(
                                                  (item) =>
                                                    (!item.adminOnly || profile.is_admin) &&
                                                    (item.target !== 'management' ||
                                                      !isCampaignEnded(campaign)),
                                                )
                                                .map((item) => (
                                                  <div
                                                    className="sidebarTab"
                                                    key={item.text}
                                                    id={`/campaigns/${campaign.slug}`}
                                                  >
                                                    <NavLink
                                                      to={`/campaigns/${campaign.slug}/${item.target}`}
                                                      tabIndex={-1}
                                                      exact
                                                      activeClassName="is-active-tab"
                                                      onClick={toggleFunc}
                                                    >
                                                      {item.text}
                                                    </NavLink>
                                                  </div>
                                                ))}
                                            </div>
                                          </div>
                                        )}
                                      </CSSTransitionGroup>
                                    </div>
                                  </li>
                                ))}
                              </div>
                            </div>
                          )}
                        </CSSTransitionGroup>
                      </div>
                    </span>
                  )
                );
              })}
            {/* Active Campaigns */}
            {userType !== BROADCASTER &&
              sortedCampaigns
                .filter((c) => !profile.is_admin || !c.team)
                .map((campaign) => {
                  const hasTeamLogo = teams && teams.find((t) => t.id === campaign.team);
                  return (
                    <li
                      key={campaign.slug}
                      className={`sidebarItemWrapper brand ${
                        selectedCampaign === campaign.slug ? 'selected' : ''
                      }`}
                    >
                      <div
                        className={
                          campaignOpen === campaign.slug
                            ? 'sidebarItemMenu is-open'
                            : 'sidebarItemMenu is-not-open'
                        }
                      >
                        <span
                          className={`sidebarItem sidebarItemButton ${
                            isCampaignEnded(campaign) ? 'ended' : ''
                          }`}
                          role="button"
                          tabIndex={0}
                          onClick={() => toggleCampaignDropdown(campaign.slug)}
                        >
                          <NavLink
                            to={`/campaigns/${campaign.slug}`}
                            activeClassName="is-active"
                            onClick={toggleFunc}
                          >
                            {multipleTeams && hasTeamLogo && hasTeamLogo.logo ? (
                              <img src={hasTeamLogo.logo} className="logoImage" alt="" />
                            ) : (
                              <IconCampaign />
                            )}
                            <div className="campaignName">{campaign.name}</div>
                          </NavLink>
                          <div className="arrowBox">
                            <span className="arrow" />
                          </div>
                        </span>
                        <CSSTransitionGroup
                          transitionName="slideSidebar"
                          transitionEnterTimeout={500}
                          transitionLeaveTimeout={500}
                        >
                          {campaignOpen === campaign.slug && (
                            <div>
                              <div className="subMenu">
                                {campaignItems
                                  .filter(
                                    (item) =>
                                      (!item.adminOnly ||
                                        profile.is_admin ||
                                        userType === MANAGER) &&
                                      (item.target !== 'management' || !isCampaignEnded(campaign)),
                                  )
                                  .map((item) => (
                                    <div
                                      className="sidebarTab"
                                      key={item.text}
                                      id={`/campaigns/${campaign.slug}`}
                                    >
                                      <NavLink
                                        to={`/campaigns/${campaign.slug}/${item.target}`}
                                        tabIndex={-1}
                                        exact
                                        activeClassName="is-active-tab"
                                        onClick={toggleFunc}
                                      >
                                        {item.text}
                                      </NavLink>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                        </CSSTransitionGroup>
                      </div>
                    </li>
                  );
                })}
          </ul>
          {userType === BROADCASTER && (
            <p className="supportEmail">Any questions? Contact us at admin@adv.gg</p>
          )}
        </aside>
      </div>
    </div>
  );
};

export default Sidebar;
