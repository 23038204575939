import PropTypes from 'prop-types';
import useCampaignCreationForm from './hooks/useCampaignCreationForm';
import MultiPageModal from 'components/components/MultiPageModal';
import PageOne from './components/PageOne';
import PageThree from './components/PageThree';
import PageTwo from './components/PageTwo';
import PageFour from './components/PageFour';

const SponsorCampaignCreationForm = ({
  team,
  isUpdating,
  campaign,
  squads,
  teamBroadcasters,
  initialValues,
  modalName,
}) => {
  const {validate, onSubmit} = useCampaignCreationForm({isUpdating, campaign, team});
  const pages = [
    (form) => <PageOne campaign={campaign} team={team} isUpdating={isUpdating} form={form} />,
  ];

  !isUpdating &&
    pages.push(
      (form) => <PageTwo team={team} isUpdating={isUpdating} form={form} />,
      (form) => (
        <PageThree team={team} squads={squads} teamBroadcasters={teamBroadcasters} form={form} />
      ),
      (form) => <PageFour team={team} form={form} />,
    );

  return (
    <MultiPageModal
      name={modalName}
      contentLabel="Add New Sponsor"
      onSubmit={onSubmit}
      initialValues={{
        ...initialValues,
        team_auto_add: true,
        action_notifications: true,
      }}
      isUpdating={isUpdating}
      validate={validate}
      pages={pages}
    />
  );
};

SponsorCampaignCreationForm.propTypes = {
  team: PropTypes.object,
  initialValues: PropTypes.object,
  isUpdating: PropTypes.bool,
  campaign: PropTypes.object,
  modalName: PropTypes.string,
  squads: PropTypes.arrayOf(PropTypes.object),
  teamBroadcasters: PropTypes.arrayOf(PropTypes.object),
};

export default SponsorCampaignCreationForm;
