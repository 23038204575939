import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import AdvModal from 'containers/AdvModal';
import DCTAForm from './components/DCTAForm';

import {fromResources} from 'store/selectors';
import {resourceListReadRequest} from 'store/actions';

const getInitialValues = (textWidgets) =>
  textWidgets.reduce(
    (obj, widget) => ({
      ...obj,
      [`text-widget-${widget.id}`]: widget.text,
    }),
    {},
  );

const DCTAFormModal = ({modalName, component}) => {
  const dispatch = useDispatch();

  const dcta = useSelector((state) =>
    fromResources.getResourceDetail(state, 'dctas', component.id),
  );
  const textWidgets = useSelector((state) =>
    dcta ? fromResources.getResourceListByDCTAId(state, 'textWidgets', dcta['id)']) : undefined,
  );

  useEffect(() => {
    dispatch(resourceListReadRequest('dctas', {component: component.id}));
  }, [component.id, dispatch]);

  return (
    <AdvModal name={modalName} contentLabel={`Update ${component.name} DCTA`}>
      {dcta && (
        <DCTAForm
          initialValues={getInitialValues(textWidgets)}
          component={component}
          textWidgets={textWidgets}
          dcta={dcta}
        />
      )}
    </AdvModal>
  );
};

DCTAFormModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  component: PropTypes.object.isRequired,
};

export default DCTAFormModal;
