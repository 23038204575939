import PropTypes from 'prop-types';
import UserRoute from './UserRoute';

const BroadcasterRoute = ({component, ...rest}) => (
  <UserRoute {...rest} BroadcasterComponent={component} />
);

BroadcasterRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default BroadcasterRoute;
