import PropTypes from 'prop-types';

import {NavLink} from 'react-router-dom';
import {Tag, CircleEditOutline} from 'mdi-material-ui';

import DeleteButton from 'components/components/DeleteButton';
import SponsorCampaignCreationForm from 'components/components/CampaignCreationForm/SponsorCampaignCreationForm';
import CampaignDeleteModal from 'components/components/CampaignDeleteModal';
import useModal from 'hooks/useModal';

import {
  getCampaignProgress,
  isCampaignStarted,
  isCampaignEnded,
  formatDate,
} from 'utils/numberFormats';

const CampaignPreview = ({campaign, isDeletable, team}) => {
  const [modalShow] = useModal();
  return (
    <div className={isCampaignEnded(campaign) ? 'campaignPreview inactive' : 'campaignPreview'}>
      <div className="topSection">
        <div className="campaignName">
          <NavLink to={`/campaigns/${campaign.slug}`}>
            <Tag />
            <h1>{campaign.name}</h1>
            {!campaign.is_finite && !team && (
              <h2 className="isDesktop">
                <b>{isCampaignStarted(campaign) ? 'Active Campaign' : 'Upcoming Campaign'}</b>
                {` - ${isCampaignStarted(campaign) ? 'Started' : 'Starts'} ${formatDate(
                  campaign.start_date,
                )}`}
              </h2>
            )}
            {campaign.is_finite && !team && (
              <h2 className="isDesktop">
                <b>
                  {isCampaignEnded(campaign)
                    ? 'Campaign Complete'
                    : `${Math.floor(100 * getCampaignProgress(campaign))}% Campaign Progress`}
                </b>
              </h2>
            )}
          </NavLink>
          {isDeletable && (
            <div
              className="editMobile"
              tabIndex={-1}
              role="button"
              onClick={() => modalShow(`campaign-${campaign.slug}-update`)}
            >
              <CircleEditOutline />
            </div>
          )}
          {isDeletable && (
            <DeleteButton
              tabIndex={-1}
              extraClass="removeMobile"
              handleClick={() => modalShow(`campaign-${campaign.slug}-delete`)}
            />
          )}
        </div>
        {isDeletable && (
          <div
            className="editDesktop"
            tabIndex={-1}
            role="button"
            onClick={() => modalShow(`campaign-${campaign.slug}-update`)}
          >
            <CircleEditOutline />
          </div>
        )}
        {isDeletable && (
          <DeleteButton
            tabIndex={-1}
            extraClass="removeDesktop"
            handleClick={() => modalShow(`campaign-${campaign.slug}-delete`)}
          />
        )}
        {!team && <div className="percentageBarBackground" />}
        {campaign.is_finite && !team && (
          <div
            className={`percentageBar is-${Math.floor(
              100 * getCampaignProgress(campaign),
            )}-complete`}
          />
        )}
      </div>
      <SponsorCampaignCreationForm
        modalName={`campaign-${campaign.slug}-update`}
        initialValues={{
          ...campaign,
          start_hour: new Date(campaign.start_date).getHours(),
          end_hour: new Date(campaign.end_date).getHours(),
        }}
        campaign={campaign}
        isUpdating
        team={team}
      />

      {isDeletable && (
        <CampaignDeleteModal
          modalName={`campaign-${campaign.slug}-delete`}
          campaign={campaign}
          key={`campaign-${campaign.slug}-delete`}
          warning
          extraText="This cannot be undone. Ended campaigns will still be visible, but deleted campaigns will no longer appear. If you have any concerns, contact an Advocate admin."
        />
      )}
    </div>
  );
};

CampaignPreview.propTypes = {
  campaign: PropTypes.object.isRequired,
  isDeletable: PropTypes.bool,
  team: PropTypes.object,
};

export default CampaignPreview;
