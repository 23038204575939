/* eslint-disable no-restricted-syntax */
import {groupBy, differenceBy, isEmpty} from 'lodash';

import lookup from 'country-code-lookup';
import {sortByKey} from './general';

// gets the currently active ComponentVersion of a normalized component
export const getActiveVersion = (component, versions) => {
  const activeVersion = versions.filter((version) => version.id === component.versions[0]);
  return activeVersion[0];
};

// gets a property from the active version of a normalized component
export const getActiveVersionProp = (component, versions, prop) => {
  const activeVersion = getActiveVersion(component, versions);
  return activeVersion ? activeVersion[prop] : undefined;
};

// Adds a value to a list in the stats object, skipping if the value is already there
export const addToStatList = (version, listName, value) => {
  const oldValues = version.stats[listName] || [];
  return {
    ...version,
    stats: {...version.stats, [listName]: [...new Set([...oldValues, value])]},
  };
};

// check if a completed version is marked as completed
export const isCompleted = (component, versions) => {
  const activeVersion = getActiveVersion(component, versions);

  if (!activeVersion) return false;
  return (
    activeVersion.actions.total > 0 &&
    activeVersion.actions.total === activeVersion.actions.completed
  );
};

// Checks if a single component is in history: meaning it's deleted
export const inHistory = (component) => component.is_deleted;

// If a component set has either deleted or completed components, it should return that it has a history
export const hasHistory = (components, versions) => {
  for (const component of components) {
    if (inHistory(component, versions)) return true;
  }

  return false;
};

// given a array of ComponentVersions, merges those with the same content_id
export const getDisplayVersions = (versions) => {
  const contentGroups = groupBy(versions, 'content_id');
  return Object.keys(contentGroups)
    .map((contentId) =>
      contentGroups[contentId].reduce(
        (a, b) => ({
          ...b,
          sequence_number: a.sequence_number
            ? Math.max(a.sequence_number, b.sequence_number)
            : b.sequence_number,
        }),
        {},
      ),
    )
    .sort(sortByKey('sequence_number', true));
};

export const getTweetIdFromUrl = (url) => {
  const tokens = url.split('/');
  return tokens[tokens.length - 1].split('?')[0];
};

export const getImageNameFromUrl = (url) => {
  const tokens = url.split(/\/|\./);
  return tokens[tokens.length - 2];
};

export const getChangedLabels = (originalLabels, newLabels, component) => {
  const labelsToUpdate = [];

  // For every label that was in the original list of labels but is no longer listed,
  // REMOVE this component from that label and add it to the labelsToUpdate array
  const diffA = differenceBy(originalLabels, newLabels, 'id');
  for (const deletedLabel of diffA) {
    deletedLabel.components = deletedLabel.components.filter((c) => c !== component.id);
    labelsToUpdate.push(deletedLabel);
  }

  // For every label in the new list but not in the original list
  // ADD this component to the list and add it to the labelsToUpdate array
  const diffB = differenceBy(newLabels, originalLabels, 'id');
  for (const newLabel of diffB) {
    newLabel.components.push(component.id);
    labelsToUpdate.push(newLabel);
  }

  return labelsToUpdate;
};

export const getFrequencyTableFromClicks = (clicks) => {
  const tables = {countries: [], targetLinks: [], components: []};
  // generate frequency tables for countries, target links, and components
  clicks.forEach((click) => {
    // find the index of the property value in the frequency table
    let index = tables.countries.findIndex((i) => i[0] === click.country);
    // if the value exists, add one to the count, otherwise create the value entry
    index > -1
      ? (tables.countries[index] = [click.country, tables.countries[index][1] + 1])
      : tables.countries.push([click.country, 1]);

    // do the same for target links
    index = tables.targetLinks.findIndex((i) => i[0] === click.target);
    index > -1
      ? (tables.targetLinks[index] = [click.target, tables.targetLinks[index][1] + 1])
      : tables.targetLinks.push([click.target, 1]);

    // do the same for components
    index = tables.components.findIndex((i) => i[0] === click.component_id);
    index > -1
      ? (tables.components[index] = [click.component_id, tables.components[index][1] + 1])
      : tables.components.push([click.component_id, 1]);
  });

  // sort all three frequency tables
  Object.values(tables).forEach((item) => item.sort((a, b) => (a[1] > b[1] ? -1 : 1)));

  // countries should have an 'other' value for <1% clicks
  const totalClicks = clicks ? clicks.length : 0;
  const countriesWithOther = tables.countries.reduce(
    (acc, current) => {
      current[1] >= 0.01 * totalClicks
        ? acc.push(current)
        : (acc[0] = ['Other', acc[0][1] + current[1]]);
      return acc;
    },
    [['Other', 0]],
  );

  // Place 'other' value last
  tables.countries = countriesWithOther.sort((a, b) => (b[0] === 'Other' || a[1] > b[1] ? -1 : 1));

  if (tables.countries[tables.countries.length - 1][1] === 0) {
    tables.countries.pop();
  }

  return tables;
};

export const getLabeledCountryClicksFromTable = (tables) => {
  const getCountryLabel = (entry) => {
    switch (entry) {
      case 'Other':
        return 'Other';
      case null:
        return 'Null';
      default:
        return lookup.byIso(entry) ? lookup.byIso(entry).country : 'Not Found';
    }
  };
  return tables.countries.map((entry) => ({
    label: getCountryLabel(entry[0]),
    value: entry[1],
    key: entry[0],
  }));
};

export const constructTeamFromSquads = (teams, squadsIn, broadcasters) => {
  const getSquads = (squads) =>
    squads.map((squad) => ({
      name: squad.name,
      key: squad.id,
      content: squad.roster.map((broadcaster) => ({
        name: broadcaster,
      })),
    }));
  const rosters = squadsIn ? squadsIn.map((squad) => squad.roster).flat() : [];
  return teams
    ? teams.map((t) => ({
        id: t.id,
        name: t.name,
        content: squadsIn ? getSquads(squadsIn) : [],
        broadcasters: broadcasters.filter(
          (b) =>
            b.team_statuses[t.id] &&
            b.team_statuses[t.id].status === 'Active' &&
            !rosters.includes(b.username),
        ),
      }))
    : [];
};

export const getFileDisplayName = (value) => {
  const tokens = value.replace(/%2F/g, '/').split('/');
  return tokens[tokens.length - 1];
};

// currently doesn't support broadcasters with dual platforms. If there are any
// numbers for mixer we consider them a Mixer streamer, otherwise Twitch by default
export const getBroadcasterPlatforms = (stats) => {
  const hasMetrics = (metric) => Object.values(metric).reduce((a, b) => (a + b > 0 ? b : 0), 0);
  return stats && stats.mixer_viewership && hasMetrics(stats.mixer_viewership) ? 'mixer' : 'twitch';
};

export const mergeViewership = (viewership) => {
  const platforms = ['twitch'];
  if (isEmpty(viewership)) return viewership;
  const totalHoursBroadcast = platforms.reduce(
    (acc, val) => viewership[val].hours_broadcast + acc,
    0,
  );
  return {
    viewable_minutes: platforms.reduce((acc, val) => viewership[val].viewable_minutes + acc, 0),
    hours_broadcast: totalHoursBroadcast,
    peak_viewers: platforms.reduce((acc, val) => Math.max(viewership[val].peak_viewers, acc), 0),
    average_viewers: Math.ceil(
      platforms.reduce(
        (acc, val) =>
          viewership[val].average_viewers *
            (viewership[val].hours_broadcast / totalHoursBroadcast) +
          acc,
        0,
      ),
    ),
    num_broadcasts: platforms.reduce((acc, val) => viewership[val].num_broadcasts + acc, 0),
  };
};

export const mergeBroadcasterViewership = (viewership) => {
  const platforms = ['twitch'];
  const totalMinutesBroadcast = platforms.reduce(
    (acc, val) =>
      viewership[`${val}_viewership`]
        ? viewership[`${val}_viewership`].minutes_streamed + acc
        : acc,
    0,
  );

  return [
    {
      label: 'Hours Streamed',
      value: totalMinutesBroadcast / 60,
    },
    {
      label: 'Average Viewers',
      value: Math.ceil(
        platforms.reduce(
          (acc, val) =>
            viewership[`${val}_viewership`]
              ? viewership[`${val}_viewership`].average_viewers *
                  (viewership[`${val}_viewership`].minutes_streamed / totalMinutesBroadcast) +
                acc
              : acc,
          0,
        ),
      ),
    },
    {
      label: 'Viewable Minutes',
      value: platforms.reduce(
        (acc, val) =>
          viewership[`${val}_viewership`]
            ? viewership[`${val}_viewership`].average_viewers *
                viewership[`${val}_viewership`].minutes_streamed +
              acc
            : acc,
        0,
      ),
    },
    {
      label: 'Recorded Broadcasts',
      value: platforms.reduce(
        (acc, val) =>
          viewership[`${val}_viewership`]
            ? viewership[`${val}_viewership`].recorded_broadcasts *
                viewership[`${val}_viewership`].recorded_broadcasts +
              acc
            : 0,
        0,
      ),
    },
  ];
};

export const getLinkText = (version) => {
  if (version.link) {
    return version.link.startsWith('http') ? version.link : `https://${version.link}`;
  }

  return '';
};

export const isDailyCapped = (campaign) => {
  return !!campaign.daily_cap_reached;
};
