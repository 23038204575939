import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Field, Form} from 'react-final-form';
import {required, createValidator} from 'services/validation';
import {chatbotPostRequest} from 'store/actions';

import TextArea from 'components/molecules/TextArea';
import Select from 'components/molecules/Select';
import Button from 'components/molecules/Button';

const ChatbotPostForm = ({broadcasters, campaign}) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (data) => {
      return data.broadcaster === '__ALL_LIVE__'
        ? dispatch(
            chatbotPostRequest('campaign', {
              campaign: campaign.slug,
              text: data.text,
            }),
          )
        : dispatch(
            chatbotPostRequest('channel', {
              ...data,
              chatbot_channel: campaign.twitch_chatbot_account,
            }),
          );
    },
    [campaign.slug, campaign.twitch_chatbot_account, dispatch],
  );

  const validate = createValidator({
    text: [required],
  });

  const initialValues = {broadcaster: '__ALL_LIVE__'};

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
      {({handleSubmit, submitting, done, error, form, submitError}) => (
        <form
          onSubmit={(data) => handleSubmit(data)?.then(() => form.reset())}
          className="chatbot-post-form"
        >
          <h4>Post a message to the chatbot</h4>
          <Field component={Select} name="broadcaster">
            <option value="__ALL_LIVE__">All Live Broadcasters</option>
            {broadcasters.map((broadcaster) => (
              <option key={broadcaster.username} value={broadcaster.username}>
                {broadcaster.username}
              </option>
            ))}
          </Field>
          <Field component={TextArea} name="text" placeholder="Enter chatbot message" />

          {(error || submitError) && (
            <div className="help is-danger centered">{error || submitError}</div>
          )}
          {done && <div className="success">Message Posted!</div>}
          <Button loading={!!submitting}>Post</Button>
        </form>
      )}
    </Form>
  );
};

ChatbotPostForm.propTypes = {
  broadcasters: PropTypes.arrayOf(PropTypes.object),
  campaign: PropTypes.object,
};

export default ChatbotPostForm;
