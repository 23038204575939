export const ASSIGN_BROADCASTER_LINK_REQUEST = 'ASSIGN_BROADCASTER_LINK_REQUEST';
export const ASSIGN_BROADCASTER_LINK_SUCCESS = 'UPDATE_BROADCASTER_LINK_SUCCESS';
export const ASSIGN_BROADCASTER_LINK_FAILURE = 'UPDATE_BROADCASTER_LINK_FAILURE';

export const assignBroadcasterLinkRequest = ({broadcasterUsername, link}) => ({
  type: ASSIGN_BROADCASTER_LINK_REQUEST,
  payload: {
    broadcasterUsername,
    link,
  },
  meta: {thunk: 'assign-broadcaster-link'},
});

export const assignBroadcasterLinkFailure = ({error, thunk}) => ({
  type: ASSIGN_BROADCASTER_LINK_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});

export const assignBroadcasterLinkSuccess = ({data, thunk}) => ({
  type: ASSIGN_BROADCASTER_LINK_SUCCESS,
  payload: data,
  meta: {thunk},
});
