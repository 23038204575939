import {useCallback, useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {isPending} from 'redux-saga-thunk';
import {isEmpty} from 'lodash';

import DatePicker from 'components/molecules/DatePicker';
import MultiSelect from 'components/molecules/MultiSelect';
import GameBreakdown from 'components/organisms/GameBreakdown';
import AdvCheckbox from 'components/atoms/AdvCheckbox';
import Button from 'components/molecules/Button';
import useCampaignFilters from './hooks/useCampaignFilters';
import useClickMetrics from './hooks/useClickMetrics';
import useConversionMetrics from './hooks/useConversionMetrics';
import useEngagementMetrics from './hooks/useEngagementMetrics';
import useViewershipMetrics from './hooks/useViewershipMetrics';

import CampaignTemplate from '../../components/CampaignTemplate';
import ClickBreakdown from './components/ClickBreakdown';
import ReportsOverview from './components/ReportsOverview';
import MetricsOverview from './components/MetricsOverview';
import FilterBar from './components/FilterBar';
import CampaignNotFoundPage from '../../components/CampaignNotFoundPage';
import {mergeViewership} from 'utils/campaignTools';

const CampaignMetrics = () => {
  const [selectedView, setSelectedView] = useState('Campaign');
  const subTabs = ['Campaign', 'Clicks', 'Games', 'Reports'];

  const {
    campaign,
    initialFilters,
    filters,
    updateBroadcasterFilter,
    updateBroadcasterFilterByLabels,
    setSelectedComponentTypes,
    updateDateFilter,
    setUniqueClicksOnly,
    isFiltered,
    lastUsedFilter,
    setIsFiltered,
  } = useCampaignFilters();

  const {filteredClicks, clickComponentTotals: totalClicks, applyClickFilters} = useClickMetrics();
  const {filteredConversions, applyConversionFilters} = useConversionMetrics();
  const {socialMetrics: social} = useEngagementMetrics();
  const {viewership, gameViewership, videoViewership, rawViewership, filterViewership} =
    useViewershipMetrics();

  const applyFilters = useCallback(() => {
    setIsFiltered(true);
    filterViewership(filters, initialFilters);
    applyClickFilters(filters);
    campaign?.has_conversions && applyConversionFilters(filters);
  }, [
    applyClickFilters,
    applyConversionFilters,
    campaign?.has_conversions,
    filterViewership,
    filters,
    setIsFiltered,
    initialFilters,
  ]);

  const userLoading = useSelector((state) => isPending(state, 'profileRequest'));

  // Labels used to convert raw data labels to labels for presentation with formatMetrics()
  const viewershipLabels = useMemo(
    () => ({
      viewable_minutes: 'Viewable Minutes',
      hours_broadcast: 'Content Hours Broadcast',
      peak_viewers: 'Peak Concurrent Viewers',
      average_viewers: 'Average Concurrent Viewers',
      num_broadcasts: 'Number of Broadcasts',
    }),
    [],
  );

  const socialLabels = useMemo(
    () => ({
      total_favorites: 'Total Favorites',
      total_retweets: 'Total Retweets',
    }),
    [],
  );

  const impressions = useMemo(
    () =>
      viewership?.impressions
        ? Object.keys(viewership.impressions).map((key) => ({
            label: key,
            value: viewership.impressions[key],
          }))
        : null,
    [viewership.impressions],
  );

  const displayCount = useMemo(
    () =>
      viewership?.display_count
        ? Object.keys(viewership.display_count).map((key) => ({
            label: key,
            value: viewership.display_count[key],
          }))
        : null,
    [viewership.display_count],
  );
  // Creates an array of presentational labels and their appropriate values from the data set
  const formatMetrics = useCallback(
    (metricSet, metricLabels) =>
      Object.keys(metricLabels).map((slug) => ({
        label: metricLabels[slug],
        value: metricSet[slug],
      })),
    [],
  );

  // Prepends a 'totals' entry to a formatted metric set
  const addTotalToMetricSet = useCallback(
    (metricSet, metricLabels, totalLabel) => {
      const formattedMetrics = formatMetrics(metricSet, metricLabels);
      return [
        {
          label: totalLabel,
          value: formattedMetrics.reduce((sum, metric) => sum + metric.value, 0),
        },
        ...formattedMetrics,
      ];
    },
    [formatMetrics],
  );

  const getMetricsView = useCallback(
    (selectedView) => {
      switch (selectedView) {
        case 'Clicks':
          return (
            <ClickBreakdown
              campaign={campaign}
              clicks={filteredClicks}
              rawViewership={rawViewership}
              isFiltered={isFiltered}
            />
          );
        case 'Games':
          return (
            <GameBreakdown
              campaign={campaign}
              clicks={filteredClicks}
              conversions={filteredConversions}
              gameViewership={Object.keys(gameViewership).reduce(
                (acc, val) => acc.concat(gameViewership[val]),
                [],
              )}
              isFiltered={isFiltered}
            />
          );
        case 'Reports':
          return <ReportsOverview campaign={campaign} />;
        default:
          return (
            <MetricsOverview
              campaign={campaign}
              isFiltered={isFiltered}
              filterStart={filters.startDate}
              filterEnd={filters.endDate}
              filteredClicks={filteredClicks}
              filteredConversions={filteredConversions}
              viewership={formatMetrics(mergeViewership(viewership), viewershipLabels)}
              impressions={impressions}
              videoViewership={videoViewership}
              rawViewership={rawViewership}
              social={addTotalToMetricSet(social, socialLabels, 'Total Engagements')}
              totalClicks={totalClicks}
              conversions={filteredConversions}
              displayCount={displayCount}
            />
          );
      }
    },
    [
      addTotalToMetricSet,
      campaign,
      filteredClicks,
      filteredConversions,
      filters.endDate,
      filters.startDate,
      formatMetrics,
      gameViewership,
      impressions,
      displayCount,
      isFiltered,
      rawViewership,
      social,
      socialLabels,
      totalClicks,
      videoViewership,
      viewership,
      viewershipLabels,
    ],
  );

  if (!userLoading && isEmpty(campaign)) return <CampaignNotFoundPage />;

  return (
    <CampaignTemplate>
      <div className="campaignMetricsPage">
        <div className="subNavbar">
          <div className="subNavbarItemWrapper">
            {subTabs.map((tab) => (
              <div
                className={`subNavbarItem ${tab === selectedView ? 'active' : ''}`}
                key={tab}
                role="button"
                tabIndex={0}
                onClick={() => setSelectedView(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
        </div>
        <FilterBar invisible={selectedView === 'Reports'}>
          <AdvCheckbox
            isChecked={filters.uniqueClicksOnly}
            onCheck={setUniqueClicksOnly}
            useTempValue
            label="Unique IPs Only"
          />
          <DatePicker
            color="tertiary"
            absoluteStart={initialFilters.absStart}
            absoluteEnd={initialFilters.absEnd}
            onDatesChange={updateDateFilter}
          />
          {!!initialFilters.componentTypes.length && (
            <MultiSelect
              title="Component Types"
              minimized
              items={initialFilters.componentTypes}
              selectedItems={filters.selectedComponentTypes}
              handleOnChange={setSelectedComponentTypes}
              itemIDField="slug"
              itemNameField="name"
            />
          )}
          {!!initialFilters.broadcasters.length && (
            <MultiSelect
              title="Broadcasters"
              minimized
              hasClearSelection
              items={initialFilters.broadcasters}
              isHighlighted={!!initialFilters.labels.length && lastUsedFilter === 'channels'}
              selectedItems={filters.selectedBroadcasters}
              handleOnChange={updateBroadcasterFilter}
              itemIDField="username"
              itemNameField="username"
            />
          )}
          {!!initialFilters.labels.length && <h4>or</h4>}
          {!!initialFilters.labels.length && (
            <MultiSelect
              title="Groups"
              minimized
              items={initialFilters.labels}
              isHighlighted={lastUsedFilter === 'labels'}
              selectedItems={filters.selectedLabels}
              handleOnChange={updateBroadcasterFilterByLabels}
              itemIDField="name"
              itemNameField="name"
            />
          )}
          <Button handleClick={applyFilters}>Apply</Button>
        </FilterBar>
        {getMetricsView(selectedView)}
      </div>
    </CampaignTemplate>
  );
};

export default CampaignMetrics;
