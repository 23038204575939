import {call, put, takeEvery} from 'redux-saga/effects';

import {
  SHOPIFY_URL_REQUEST,
  shopifyURLFailure,
  SHOPIFY_CONFIRM_REQUEST,
  shopifyConfirmFailure,
  shopifyConfirmSuccess,
} from 'store/actions';

export function* shopifyGetURL(api, {thunk}, {queryString}) {
  try {
    const response = yield call([api, api.get], `shopify/install/${queryString}`);
    window.location.href = response.url;
  } catch (error) {
    yield put(shopifyURLFailure(error, thunk));
  }
}

export function* shopifyConfirm(api, {thunk}, {queryString}) {
  try {
    yield call([api, api.get], `shopify/confirm/${queryString}`);
    yield put(shopifyConfirmSuccess(thunk));
  } catch (error) {
    yield put(shopifyConfirmFailure(error, thunk));
  }
}

export function* watchShopifyGetUrl(api, {meta, payload}) {
  yield call(shopifyGetURL, api, meta, payload);
}

export function* watchShopifyConfirm(api, {meta, payload}) {
  yield call(shopifyConfirm, api, meta, payload);
}

export default function* sagas({api}) {
  yield takeEvery(SHOPIFY_URL_REQUEST, watchShopifyGetUrl, api);
  yield takeEvery(SHOPIFY_CONFIRM_REQUEST, watchShopifyConfirm, api);
}
