import PropTypes from 'prop-types';
import {Field} from 'react-final-form';

import Checkbox from 'components/atoms/Checkbox';
import SubSelect from 'components/molecules/SubSelect';
import {constructTeamFromSquads} from 'utils/campaignTools';

const PageThree = ({form, team, squads, teamBroadcasters}) => {
  return (
    <div className="page page3">
      <label htmlFor="Campaign Creation Form">
        Add New
        {team ? ' Sponsor' : ' Campaign'} - Participation
      </label>
      {team && (
        <div className="teamAutoAdd">
          <div className="teamAutoAddCheckbox">
            <Field name="team_auto_add" component={Checkbox} checkboxclass="muiCheckbox" />
            <p>Automatically add all team broadcasters</p>
          </div>
        </div>
      )}
      <Field
        component={SubSelect}
        disabled={form.getState().values['team_auto_add']}
        selectionInput={constructTeamFromSquads([team], squads, teamBroadcasters)}
        name="broadcaster_participation"
      />
      <p className="extraText">
        Add all team members to this sponsor campaign, or select broadcasters and squads
        individually from your team.
      </p>
      {form.getState().values['broadcaster_participation'] && (
        <p className="extraText">
          {form.getState().values['team_auto_add']
            ? 'All'
            : form.getState().values['broadcaster_participation'].broadcasters_to_add.length}{' '}
          {!form.getState().values['team_auto_add'] ? 'total' : ''} broadcasters selected
        </p>
      )}
    </div>
  );
};

PageThree.propTypes = {
  form: PropTypes.any,
  team: PropTypes.object,
  squads: PropTypes.arrayOf(PropTypes.object),
  teamBroadcasters: PropTypes.arrayOf(PropTypes.object),
};

export default PageThree;
