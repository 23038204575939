import {useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {isPending, isComplete} from 'redux-saga-thunk';
import {resourceListReadRequest, complianceAuditRequest} from 'store/actions';
import usePermissions from 'hooks/usePermissions';

import {
  useCampaign,
  useComponents,
  useBroadcasters,
  useComponentVersions,
  useBroadcasterActions,
  useCompliance,
  useChatbotModStatus,
} from 'hooks';

const useCampaignCompliance = () => {
  const dispatch = useDispatch();
  const {campaignSlug} = useParams();
  const campaign = useCampaign();
  const broadcasters = useBroadcasters();
  const components = useComponents();
  const modStatuses = useChatbotModStatus();
  const compliance = useCompliance();
  const actions = useBroadcasterActions();
  const versions = useComponentVersions();
  const {canEditCampaigns} = usePermissions();

  const validComponents = useMemo(() => [...new Set(actions.map((a) => a.component))], [actions]);
  const componentsWithCompliance = components.filter(
    (c) => !c.is_deleted && validComponents.includes(c.name),
  );

  const pageLoading = useSelector(
    (state) =>
      isPending(state, `broadcastersActionsListRead`) || isPending(state, `complianceListRead`),
  );
  const complianceAuditLoading = useSelector((state) => isPending(state, 'complianceAuditTrigger'));
  const complianceAuditComplete = useSelector((state) =>
    isComplete(state, 'complianceAuditTrigger'),
  );
  const userLoading = useSelector((state) => isPending(state, 'profileRequest'));
  const readOnly = !canEditCampaigns;

  useEffect(() => {
    dispatch(resourceListReadRequest('compliance', {campaign: campaignSlug}));
    dispatch(
      resourceListReadRequest('broadcasterActions', {
        campaign: campaignSlug,
      }),
    );
  }, [campaignSlug, dispatch]);

  const triggerComplianceAudit = () => dispatch(complianceAuditRequest({campaign: campaignSlug}));

  return {
    campaign,
    readOnly,
    broadcasters,
    components,
    compliance,
    actions,
    modStatuses,
    userLoading,
    pageLoading,
    validComponents,
    versions,
    complianceAuditComplete,
    complianceAuditLoading,
    triggerComplianceAudit,
    componentsWithCompliance,
  };
};

export default useCampaignCompliance;
