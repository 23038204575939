import PropTypes from 'prop-types';

import NewReportForm from '../../../../forms/NewReportForm';
import ReportList from './components/ReportList';

const ReportsOverview = ({campaign}) => (
  <div className="metricsOverviewBreakdown">
    <ReportList campaign={campaign} />
    <div className="reportCustomization form">
      <NewReportForm />
    </div>
  </div>
);

ReportsOverview.propTypes = {
  campaign: PropTypes.object,
  hasReports: PropTypes.bool,
};

export default ReportsOverview;
