import {useCallback} from 'react';

const useRenderPreview = ({originalAssetDimensions, setOriginalAssetDimensions, scalingFactor}) => {
  // Record initial asset dimensions after loading image to re-scale as necessary
  const onImgLoad = useCallback(
    ({target: img}) => {
      if (!originalAssetDimensions[img.id]) {
        setOriginalAssetDimensions({
          ...originalAssetDimensions,
          [img.id]: {
            height: img.naturalHeight || img.videoHeight,
            width: img.naturalWidth || img.videoWidth,
          },
        });
      }
    },
    [originalAssetDimensions, setOriginalAssetDimensions],
  );

  const getStyles = useCallback(
    (id, scale) => {
      return originalAssetDimensions[id]
        ? {
            width: originalAssetDimensions[id].width * scalingFactor * (scale || 1),
            height: originalAssetDimensions[id].height * scalingFactor * (scale || 1),
          }
        : {
            width: 0,
          };
    },
    [originalAssetDimensions, scalingFactor],
  );

  const makeImage = useCallback(
    (image, rotation) => {
      const getFileExt = (filename) => {
        const tokens = filename.split('.');
        return tokens[tokens.length - 1].toLowerCase();
      };
      const extension = getFileExt(image.image_url);

      switch (extension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          return (
            <img
              onLoad={onImgLoad}
              id={`${rotation.id}-${image.version_id}`}
              style={getStyles(`${rotation.id}-${image.version_id}`, rotation.scale)}
              onMouseDown={(event) => event.preventDefault && event.preventDefault()}
              draggable={false}
              className="browsersource-image"
              src={image.image_url}
              alt=""
            />
          );
        case 'mp4':
        case 'webm':
          return (
            <video
              onLoadedData={onImgLoad}
              id={`${rotation.id}-${image.version_id}`}
              style={getStyles(`${rotation.id}-${image.version_id}`, rotation.scale)}
              draggable={false}
              src={image.image_url}
              muted
              autoPlay
              loop
            />
          );
        default:
          return '';
      }
    },
    [getStyles, onImgLoad],
  );

  return {
    makeImage,
  };
};

export default useRenderPreview;
