/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';

const SubNavbar = ({handleClick, tabs, selectedTab}) => (
  <div className="subNavbar">
    <div className="subNavbarItemWrapper">
      {tabs.map((tab) => (
        <div
          className={`subNavbarItem ${tab === selectedTab ? 'active' : ''}`}
          key={tab}
          role="button"
          tabIndex={0}
          onClick={() => handleClick(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  </div>
);

SubNavbar.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string),
  selectedTab: PropTypes.string,
  handleClick: PropTypes.func,
};

export default SubNavbar;
