import {MANAGER, BROADCASTER} from './userTypes';

export const initialState = {};

export const getFullProfile = (state = initialState) => state;

export const getUserType = (state = initialState) => state.user_type;

export const getUsername = (state = initialState) => state.username;

export const getEmail = (state = initialState) => state.email;

export const isManager = (state = initialState) => +state.user_type === MANAGER;

export const isBroadcaster = (state = initialState) => +state.user_type === BROADCASTER;

export const isAdmin = (state = initialState) => !!state.is_admin;

export const getBrowsersource = (state = initialState) =>
  state.profile ? state.profile.browsersource : undefined;

export const getProfileImage = (state = initialState) =>
  state.profile ? state.profile.profile_image : undefined;

export const tosAgreed = (state = initialState) => !!state.tos_agreed;

export const getTwitter = (state = initialState) => state.profile.twitter;

export const getApplicationStatus = (state = initialState) =>
  !!(
    state.profile &&
    state.profile.application_full_name &&
    state.profile.application_date_of_birth &&
    state.profile.application_stream_content_type &&
    state.profile.application_streams_on_which_platforms
  );

export const canEditCampaigns = (state = initialState) =>
  state.permissions_campaigns === 0 || !!state.is_admin;

export const canEditUsers = (state = initialState) =>
  state.permissions_users === 0 || !!state.is_admin;

export const canViewUsers = (state = initialState) =>
  state.permissions_users <= 1 || !!state.is_admin;

export const canEditTeams = (state = initialState) =>
  state.permissions_teams === 0 || !!state.is_admin;
