import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {fromMetrics} from 'store/selectors';
import {metricsDataRequest} from 'store/actions';

const useEngagementMetrics = () => {
  const dispatch = useDispatch();
  const {campaignSlug} = useParams();

  useEffect(() => {
    dispatch(metricsDataRequest('social', {campaign: campaignSlug}));
  }, [campaignSlug, dispatch]);

  const socialMetrics = useSelector((state) => fromMetrics.getMetric(state, 'social'));

  return {
    socialMetrics,
  };
};

export default useEngagementMetrics;
