import PropTypes from 'prop-types';

const StaticDateRanges = ({handleChange, dateRanges, selectedRange}) => (
  <span className="staticDateRanges inline-select hasArrow">
    <select className="select" value={selectedRange} onChange={(e) => handleChange(e.target.value)}>
      {dateRanges.map((range) => (
        <option className="option" key={range} value={range}>
          {Number.isInteger(parseInt(range, 10)) ? `Last ${range} Days` : 'All Time'}
        </option>
      ))}
    </select>
  </span>
);

StaticDateRanges.propTypes = {
  handleChange: PropTypes.func.isRequired,
  dateRanges: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedRange: PropTypes.string.isRequired,
};

export default StaticDateRanges;
