import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import {useMemo, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {resourceUpdateRequest} from 'store/actions';
import {fromProfile} from 'store/selectors';
import Button from 'components/molecules/Button';
import useModal from 'hooks/useModal';

const getCompletionAction = (action) => {
  switch (action.type.slug) {
    case 'twitter-send-tweet':
      return 'send_tweet';
    case 'twitter-retweet':
      return 'send_retweet';
    default:
      return 'mark_complete';
  }
};

const ActionCompleteForm = ({action, modalName}) => {
  const dispatch = useDispatch();
  const [, modalHide] = useModal();
  const twitter = useSelector((state) => fromProfile.getTwitter(state));
  const formText = useMemo(() => {
    switch (action.type.slug) {
      case 'twitter-send-tweet':
      case 'twitter-retweet':
        return (
          <label htmlFor="send tweet">
            Send this tweet through <strong>{twitter}</strong>?
          </label>
        );
      default:
        return (
          <label htmlFor="complete action">
            Are you sure you want to mark this action as complete?
          </label>
        );
    }
  }, [action.type.slug, twitter]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        await dispatch(
          resourceUpdateRequest('broadcasterActions', action.id, {
            ...data,
            completion_data: action.completion_data,
            completion_action: getCompletionAction(action),
          }),
        );
      } catch (error) {
        return error;
      }
    },
    [action, dispatch],
  );

  return (
    <Form onSubmit={onSubmit}>
      {({submitting, handleSubmit, error, submitError, form}) => (
        <form
          onSubmit={(data) =>
            handleSubmit(data)?.then(() => {
              const {submitSucceeded} = form.getState();
              submitSucceeded && modalHide(modalName);
            })
          }
        >
          {formText}

          <Button loading={!!submitting}>Confirm</Button>
          {(error || submitError) && (
            <div className="help is-danger centered">{error || submitError}</div>
          )}
        </form>
      )}
    </Form>
  );
};

ActionCompleteForm.propTypes = {
  action: PropTypes.object,
  modalName: PropTypes.string,
};

export default ActionCompleteForm;
