import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Field, Form} from 'react-final-form';

import Button from 'components/molecules/Button';
import AdvInput from 'components/molecules/AdvInput';
import Select from 'components/molecules/Select';
import Checkbox from 'components/atoms/Checkbox';
import {Email, MessageProcessing, Bell} from 'mdi-material-ui';
import {CSSTransitionGroup} from 'react-transition-group';

import {profileUpdateRequest} from 'store/actions';
import {fromProfile} from 'store/selectors';
import {required, email, isTwitterHandle, phone, createValidator} from 'services/validation';

const BroadcasterProfileForm = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => fromProfile.getFullProfile(state));

  const fieldValidators = createValidator({
    twitter: [isTwitterHandle],
    email: [required, email],
    phone_number: [phone],
  });

  const validate = useCallback(
    (values) => {
      const selected = values.profile?.selected_notifications;

      if (selected === 'S' && !values.phone_number) {
        return {profile: {selected_notifications: 'You must add a phone number'}};
      }
      if (selected === 'D' && !values.profile.discord) {
        return {
          profile: {
            selected_notifications: 'You must connect your discord account',
          },
        };
      }

      return fieldValidators(values);
    },
    [fieldValidators],
  );

  const onSubmit = useCallback(
    (data) => {
      dispatch(profileUpdateRequest(data));
    },
    [dispatch],
  );

  return (
    <Form onSubmit={onSubmit} validate={validate} enableReinitialize initialValues={profile}>
      {({handleSubmit, submitting, error, dirty, form}) => (
        <form onSubmit={handleSubmit} className="profileForm">
          <h2>Email Preferences</h2>
          <div className="labelCheckbox">
            <p>Send me sponsorship campaign offers</p>
            <Field
              name="profile.email_receive_sponsorship_offers"
              component={Checkbox}
              checkboxclass="muiCheckbox"
            />
          </div>
          <div className="labelCheckbox">
            <p>Send me updates about the Advocate platform</p>
            <Field
              name="email_receive_advocate_updates"
              component={Checkbox}
              checkboxclass="muiCheckbox"
            />
          </div>

          <h2>Notification Settings</h2>
          <p>Email:</p>
          <Field name="email" placeholder="Email Address" icon={<Email />} component={AdvInput} />
          <p>Phone Number:</p>
          <Field
            name="phone_number"
            placeholder="Phone Number"
            icon={<MessageProcessing />}
            component={AdvInput}
          />
          <p>Preferred notification method:</p>
          <Field component={Select} name="profile.selected_notifications" icon={<Bell />}>
            <option value="E">Email</option>
            <option value="S">SMS</option>
            <option value="D">Discord</option>
          </Field>

          <CSSTransitionGroup
            transitionName="fade"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {dirty && (
              <Button loading={!!submitting}>
                {form.getState().submitSucceeded ? 'Profile Updated!' : 'Update Profile'}
              </Button>
            )}
          </CSSTransitionGroup>
          {error && <div className="help is-danger centered">{error}</div>}
        </form>
      )}
    </Form>
  );
};

export default BroadcasterProfileForm;
