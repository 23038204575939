import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {isPending} from 'redux-saga-thunk';
import {resourceUpdateRequest} from 'store/actions';

import Button from 'components/molecules/Button';
import TweetPreview from 'components/molecules/TweetPreview';

const getTweetIdFromUrl = (url) => {
  const tokens = url.split('/');
  return tokens[tokens.length - 1];
};

const getMessageText = (version) => {
  const messageSplit = version.message.split('{{link}}');
  return (
    <span>
      {messageSplit[0]}
      <span className="highlight">
        {messageSplit.length > 1 && version.link ? version.link : ''}
      </span>
      {messageSplit.length > 1 && messageSplit[1]}
    </span>
  );
};

const getFileComponent = (fileUrl) => {
  const tokens = fileUrl.split('.');
  const extension = tokens[tokens.length - 1].toLowerCase();

  switch (extension) {
    case 'jpg':
    case 'png':
    case 'gif':
    case 'jpeg':
      return <img src={fileUrl} alt="" />;
    case 'mp4':
    case 'webm':
      return (
        <video muted src={fileUrl} autoPlay loop>
          <track kind="captions" />
        </video>
      );
    default:
      return undefined;
  }
};

const VersionPreview = ({version, component, canReactivate, isVersioned}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => isPending(state, `reactivate-${version.id}`));
  const reactivate = () =>
    dispatch(
      resourceUpdateRequest(
        'components',
        component.id,
        {reactivated_version_id: version.id},
        `reactivate-${version.id}`,
      ),
    );
  return (
    <div className="versionPreview">
      {(version.image && getFileComponent(version.image)) ||
        (component.type !== 'suggested-retweet' && (
          <div className="chatMessage">
            <h4>{getMessageText(version)}</h4>
          </div>
        ))}
      {component.type === 'suggested-retweet' && (
        <TweetPreview tweetId={getTweetIdFromUrl(version.message)} />
      )}
      <div className="rightContent">
        {isVersioned ? (
          <>
            <h2>{component.name}</h2>
            <h2>{`Version ${version.content_id + 1}`}</h2>
          </>
        ) : (
          <h2>
            {`${component.type === 'twitch-chat-bot' ? 'Posted every ' : ''}
            20
            ${component.type === 'twitch-chat-bot' ? ' minutes' : ''}`}
          </h2>
        )}
        {!isVersioned && <h2>{version.created.split('T')[0]}</h2>}
        {version.link && <h2>{version.link}</h2>}
        {canReactivate && (
          <Button loading={loading} handleClick={reactivate}>
            Reactivate
          </Button>
        )}
      </div>
    </div>
  );
};

VersionPreview.propTypes = {
  version: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  reactivate: PropTypes.func.isRequired,
  canReactivate: PropTypes.bool,
  loading: PropTypes.bool,
  isVersioned: PropTypes.bool,
};

export default VersionPreview;
