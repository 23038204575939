export const METRICS_DATA_REQUEST = 'METRICS_DATA_REQUEST';
export const METRICS_DATA_SUCCESS = 'METRICS_DATA_SUCCESS';
export const METRICS_DATA_FAILURE = 'METRICS_DATA_FAILURE';

export const metricsDataRequest = (metricName, params, thunk) => ({
  type: METRICS_DATA_REQUEST,
  payload: params,
  meta: {
    metric: metricName,
    thunk: thunk || `${metricName}MetricsRequest`,
  },
});

export const metricsRequestSuccess = (data, thunk, metric) => ({
  type: METRICS_DATA_SUCCESS,
  payload: data,
  meta: {thunk, metric},
});

export const metricsRequestFailure = (error, thunk) => ({
  type: METRICS_DATA_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});

export const METRICS_CLEAR_ARRAY_METRIC = 'METRICS_CLEAR_ARRAY_METRIC';
export const METRICS_RESET_METRIC = 'METRICS_RESET_METRIC';

export const metricsClearArrayMetric = (metricName) => ({
  type: METRICS_CLEAR_ARRAY_METRIC,
  meta: {metric: metricName},
});

export const metricsResetMetric = (metricName) => ({
  type: METRICS_RESET_METRIC,
  meta: {metric: metricName},
});

export const METRICS_PAYMENT_REQUEST = 'METRICS_PAYMENT_REQUEST';
export const METRICS_PAYMENT_SUCCESS = 'METRICS_PAYMENT_SUCCESS';
export const METRICS_PAYMENT_FAILURE = 'METRICS_PAYMENT_FAILURE';

export const metricsPaymentRequest = () => ({
  type: METRICS_PAYMENT_REQUEST,
  meta: {thunk: 'metrics-payment'},
});

export const metricsPaymentFailure = (error, thunk) => ({
  type: METRICS_PAYMENT_FAILURE,
  payload: error,
  meta: {thunk},
});

export const metricsPaymentSuccess = (data, thunk) => ({
  type: METRICS_PAYMENT_SUCCESS,
  payload: data,
  meta: {thunk},
});

export const METRICS_RAW_DATA_REQUEST = 'METRICS_RAW_DATA_REQUEST';
export const METRICS_RAW_DATA_SUCCESS = 'METRICS_RAW_DATA_SUCCESS';
export const METRICS_RAW_DATA_FAILURE = 'METRICS_RAW_DATA_FAILURE';
export const METRICS_RAW_DATA_CLEAR = 'METRICS_RAW_DATA_CLEAR';

export const metricsRawDataRequest = (dataType, params) => ({
  type: METRICS_RAW_DATA_REQUEST,
  payload: {params},
  meta: {
    dataType,
    thunk: `${dataType}RawData`,
  },
});

export const metricsRawDataSuccess = (data, dataType, thunk) => ({
  type: METRICS_RAW_DATA_SUCCESS,
  payload: data,
  meta: {thunk, dataType},
});

export const metricsRawDataFailure = (error, thunk) => ({
  type: METRICS_RAW_DATA_FAILURE,
  payload: {...error},
  error: true,
  meta: {thunk},
});

export const metricsRawDataClear = (dataType) => ({
  type: METRICS_RAW_DATA_CLEAR,
  meta: {dataType},
});
