import {put, call, takeEvery} from 'redux-saga/effects';

import {
  chatbotPostSuccess,
  chatbotPostFailure,
  CHATBOT_POST_REQUEST,
  triggerFullscreenVideoSuccess,
  triggerFullscreenVideoFailure,
  TRIGGER_FULLSCREEN_VIDEO_REQUEST,
} from './actions';

export function* postChatbot(api, data, {target, thunk}) {
  try {
    yield call([api, api.post], `post-chatbot/${target}/`, data);
    yield put(chatbotPostSuccess(thunk));
  } catch (e) {
    yield put(chatbotPostFailure(e, thunk));
  }
}

export function* watchPostChatbot(api, {payload, meta}) {
  yield call(postChatbot, api, payload, meta);
}

export function* triggerFullscreenVideo(api, data, {thunk}) {
  try {
    yield call([api, api.post], 'video-trigger/', data);
    yield put(triggerFullscreenVideoSuccess(thunk));
  } catch (e) {
    yield put(triggerFullscreenVideoFailure(e, thunk));
  }
}

export function* watchTriggerFullscreenVideo(api, {payload, meta}) {
  yield call(triggerFullscreenVideo, api, payload, meta);
}

export default function* sagas({api}) {
  yield takeEvery(CHATBOT_POST_REQUEST, watchPostChatbot, api);
  yield takeEvery(TRIGGER_FULLSCREEN_VIDEO_REQUEST, watchTriggerFullscreenVideo, api);
}
