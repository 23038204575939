import {PlusCircle} from 'mdi-material-ui';

import LoadingBar from 'components/molecules/LoadingBar';
import Button from 'components/molecules/Button';
import BrowsersourcePreviewLink from 'components/organisms/BrowsersourcePreviewLink';
import EmptySplash from 'components/molecules/EmptySplash';
import BroadcasterList from 'components/organisms/BroadcasterList';
import BroadcasterListRow from 'components/organisms/BroadcasterListRow';
import AdvCheckbox from 'components/atoms/AdvCheckbox';
import InlineSelect from 'components/molecules/InlineSelect';
import {AdvModal} from 'containers';
import TriggerComponentForm from '../../forms/TriggerComponentForm';
import CampaignLabelForm from '../../forms/CampaignLabelForm';
import ComponentFormModal from './components/ComponentFormModal';
import ComponentTypeSelectionModal from './components/ComponentTypeSelectionModal';
import ComponentTypeSection from './components/ComponentTypeSection';
import CampaignTemplate from '../../components/CampaignTemplate';
import CampaignNotFoundPage from '../../components/CampaignNotFoundPage';
import {formatExactTime} from 'utils/numberFormats';
import {isEmpty, isEqual} from 'lodash';
import useCampaignComponents from './hooks/useCampaignComponents';

import {CSSTransitionGroup} from 'react-transition-group';

const CampaignComponents = () => {
  const {
    campaign,
    components,
    filteredComponents,
    nextChatbotPosts,
    labels,
    broadcasters,
    modalShow,
    modalClose,
    userLoading,
    labelsLoading,
    submitUpdatedLabel,
    loading,
    campaignIsEditable,
    componentTypesSorted,
    shownComponentTypes,
    multiselectMode,
    toggleMultiselectMode,
    showArchived,
    toggleShowArchived,
    editingLabel,
    updateEditingLabel,
    activeComponents,
  } = useCampaignComponents();

  if (!userLoading && isEmpty(campaign)) return <CampaignNotFoundPage />;

  return (
    <CampaignTemplate>
      <CSSTransitionGroup
        transitionName="slowFadeLoadingBar"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {(userLoading || loading) && <LoadingBar message="Loading Components" />}
      </CSSTransitionGroup>
      <div
        className={
          userLoading || loading ? 'campaignComponentsPage is-loading' : 'campaignComponentsPage'
        }
      >
        {campaignIsEditable && (
          <div className="preset_campaign-page-options">
            <div>
              {components.length > activeComponents.length && (
                <AdvCheckbox
                  isChecked={showArchived}
                  onCheck={() => toggleShowArchived(!showArchived)}
                  label="Show Past Components"
                />
              )}
              {multiselectMode && labels.length > 0 && (
                <InlineSelect
                  type="large"
                  handleChange={(value) =>
                    updateEditingLabel(
                      labels.find((l) => l.name === value) || {
                        ...editingLabel,
                        campaign: campaign.slug,
                        name: value,
                      },
                    )
                  }
                  currentValue={editingLabel ? editingLabel.name : null}
                  options={labels}
                  formatFunction={(label) => ({...label, value: label.name})}
                />
              )}
              {!multiselectMode &&
                broadcasters.length > 0 &&
                shownComponentTypes.map((t) => t.slug).includes('fullscreen-video') && (
                  <Button handleClick={() => modalShow('fullscreen-video-trigger')}>
                    Trigger Fullscreen Video
                  </Button>
                )}
              {!multiselectMode && broadcasters.length > 0 && (
                <Button handleClick={() => modalShow('live-graphic-preview')} type="noMobile">
                  Preview Live Graphics
                </Button>
              )}
              {multiselectMode && (
                <Button handleClick={() => modalShow('campaign-label-add')}>Add New Group</Button>
              )}
              {multiselectMode && (
                <Button
                  loading={labelsLoading}
                  handleClick={() => {
                    !isEmpty(editingLabel)
                      ? submitUpdatedLabel(editingLabel).then(
                          toggleMultiselectMode(!multiselectMode),
                        )
                      : toggleMultiselectMode(!multiselectMode);
                  }}
                  disabled={isEqual(
                    editingLabel,
                    labels.find((l) => l.name === editingLabel.name),
                  )}
                >
                  Save Group Changes
                </Button>
              )}
              {components.length > 0 && (
                <Button
                  type={multiselectMode ? 'cancel' : ''}
                  handleClick={() => {
                    toggleMultiselectMode(!multiselectMode);
                    updateEditingLabel(labels[0] || {components: []});
                  }}
                >
                  {!multiselectMode ? 'Manage Groups' : 'Exit Group selection'}
                </Button>
              )}
              {!multiselectMode &&
                shownComponentTypes.find((t) => t.slug === 'twitch-chat-bot') &&
                nextChatbotPosts.length > 0 && (
                  <Button handleClick={() => modalShow('chatbot-post-preview')}>
                    Upcoming Chatbot Posts
                  </Button>
                )}
              {!multiselectMode && (
                <Button type="hasIcon" handleClick={() => modalShow('add-new-component')}>
                  <PlusCircle />
                  Add New Component
                </Button>
              )}
            </div>
          </div>
        )}
        <div className="componentsPageContent">
          {shownComponentTypes.length > 0 ? (
            shownComponentTypes.map((type) => (
              <ComponentTypeSection
                key={`component-type-${type.slug}`}
                hasButtons={campaignIsEditable}
                componentType={type}
                showArchived={showArchived}
                channelCount={broadcasters ? broadcasters.length : 0}
                components={filteredComponents.filter((component) => component.type === type.slug)}
                multiselectMode={multiselectMode}
                campaign={campaign}
                handleLabelUpdate={updateEditingLabel}
                editingLabel={{
                  ...editingLabel,
                  components: editingLabel.components ? [editingLabel.components].flat() : [],
                }}
              />
            ))
          ) : (
            <EmptySplash>
              <h2>You have no components on this campaign!</h2>
              <h2>Add a new component to get started</h2>
            </EmptySplash>
          )}
        </div>
      </div>

      {/* Select component type to create modal */}
      {campaignIsEditable && (
        <ComponentTypeSelectionModal
          campaign={campaign}
          modalShow={modalShow}
          modalClose={modalClose}
          componentTypes={componentTypesSorted}
        />
      )}

      {/* Fullscreen video trigger modal */}
      {!multiselectMode &&
        broadcasters.length > 0 &&
        shownComponentTypes.map((t) => t.slug).includes('fullscreen-video') &&
        campaignIsEditable && (
          <AdvModal name="fullscreen-video-trigger" contentLabel="Fullscreen Video Trigger">
            <TriggerComponentForm
              componentType={shownComponentTypes.find((t) => t.slug === 'fullscreen-video')}
              components={filteredComponents.filter(
                (component) => component.type === 'fullscreen-video',
              )}
              broadcasters={broadcasters}
              endpoint="triggerFullscreenVideo"
            />
          </AdvModal>
        )}

      {/* Livegraphic preview modals */}
      {campaignIsEditable && (
        <AdvModal name="live-graphic-preview" contentLabel="Preview Live Graphics">
          {campaign && (
            <BrowsersourcePreviewLink
              broadcasters={broadcasters.map((b) => b.username)}
              browsersourcePreviewUrl={campaign.browsersource_preview_url}
            />
          )}
        </AdvModal>
      )}

      {/* Upcoming chatbot posts modal */}
      {shownComponentTypes.find((t) => t.slug === 'twitch-chat-bot') &&
        nextChatbotPosts.length > 0 && (
          <AdvModal name="chatbot-post-preview" contentLabel="Upcoming Chatbot Posts">
            <label htmlFor="chatbot posts">See Upcoming Chatbot Posts</label>
            <BroadcasterList type="invite-list">
              {nextChatbotPosts
                .sort((a, b) => (b.next_post > a.next_post ? -1 : 1))
                .map((post) => (
                  <BroadcasterListRow
                    key={post.username}
                    extraContent={post.component ? post.component.name : ''}
                    broadcaster={post}
                    hourTimeStamp={post.next_post}
                    formatTime={formatExactTime}
                  />
                ))}
            </BroadcasterList>
          </AdvModal>
        )}

      {/* Create component modal */}
      {campaignIsEditable &&
        componentTypesSorted.map(
          (type) =>
            campaign?.available_components?.includes(type.slug) && (
              <ComponentFormModal
                key={`${type.slug}-add`}
                modalName={`${type.slug}-add`}
                componentType={type}
                campaign={campaign}
              />
            ),
        )}

      {/* Create group modal */}
      {campaignIsEditable && multiselectMode && (
        <AdvModal name="campaign-label-add" contentLabel="Create New Campaign Group">
          <CampaignLabelForm campaign={campaign.slug} setSelectedLabel={updateEditingLabel} />
        </AdvModal>
      )}

      <AdvModal name="no-chatbot-warning" contentLabel="No Chatbot Found">
        <label>No Twitch Chatbot Account Found</label>
        <div className="extraText">
          A valid Twitch account is required in order for the Advocate Chatbot to post. Please
          authorize a new chatbot account from the campaign settings on the management tab.
        </div>
        <Button handleClick={() => modalClose('no-chatbot-warning')}>Got It</Button>
      </AdvModal>
    </CampaignTemplate>
  );
};

export default CampaignComponents;
