import PropTypes from 'prop-types';
import {Close} from 'mdi-material-ui';

const DeleteButton = ({handleClick, extraClass}) => (
  <div
    className={`deleteButton remove ${extraClass}`}
    aria-label="delete"
    role="button"
    tabIndex={-1}
    onClick={handleClick}
  >
    <Close />
  </div>
);

DeleteButton.propTypes = {
  handleClick: PropTypes.func,
  extraClass: PropTypes.string,
};

export default DeleteButton;
