export const initialState = {
  payments: {},
  raw: {},
  social: {},
  'fullscreen-video-plays': [],
};

const emptyArray = [];
const emptyObject = {};

export const getMetrics = (state = initialState) => {
  return state;
};

export const getMetric = (state = initialState, metricName) => {
  return state[metricName] || emptyObject;
};

export const getPaymentData = (state = initialState) => {
  return state.payments;
};

export const getRaw = (state = initialState, dataType) => {
  return state.raw && state.raw[dataType] ? state.raw[dataType] : emptyArray;
};
