/* eslint-disable react/no-array-index-key */
import {useState} from 'react';
import PropTypes from 'prop-types';

import Button from 'components/molecules/Button';

const Pagination = ({itemsPerPage, initialItems, items, hasShowAll, children}) => {
  const [showAll, setShowAll] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [prevItemsLength, setPrevItemsLength] = useState(null);

  if (items.length !== prevItemsLength) {
    setSelectedIndex(0);
    setPrevItemsLength(items.length);
  }

  return (
    <div className="pagination">
      <div className="flexContainer">
        {initialItems.length !== 0
          ? items.slice(
              showAll ? 0 : selectedIndex * itemsPerPage,
              showAll ? items.length : selectedIndex * itemsPerPage + itemsPerPage,
            )
          : children}
      </div>
      {items.length > itemsPerPage && (
        <div className="pageFooterWrapper">
          {hasShowAll && (
            <Button
              selectedIndex={showAll}
              handleClick={() => setShowAll(!showAll)}
              type="isPressed"
            >
              {showAll ? 'Hide All' : 'Show All'}
            </Button>
          )}
          <div className="pageFooter">
            {!showAll &&
              [...Array(Math.ceil(items.length / itemsPerPage))].map((e, i) => (
                <button
                  className={`pageIndex ${selectedIndex === i ? 'selected' : ''}`}
                  onClick={() => setSelectedIndex(i)}
                  key={i}
                >
                  {i + 1}
                </button>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  children: PropTypes.any,
  itemsPerPage: PropTypes.number,
  initialItems: PropTypes.arrayOf(PropTypes.any),
  items: PropTypes.arrayOf(PropTypes.node),
  hasShowAll: PropTypes.bool,
};

export default Pagination;
