export const SHOPIFY_URL_REQUEST = 'SHOPIFY_URL_REQUEST';
export const SHOPIFY_URL_FAILURE = 'SHOPIFY_URL_FAILURE';

export const shopifyURLRequest = (queryString) => ({
  type: SHOPIFY_URL_REQUEST,
  meta: {thunk: 'shopifyUrl'},
  payload: {queryString},
});

export const twitterURLFailure = (error, thunk) => ({
  type: SHOPIFY_URL_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});

export const SHOPIFY_CONFIRM_REQUEST = 'SHOPIFY_CONFIRM_REQUEST';
export const SHOPIFY_CONFIRM_FAILURE = 'SHOPIFY_CONFIRM_FAILURE';
export const SHOPIFY_CONFIRM_SUCCESS = 'SHOPIFY_CONFIRM_SUCCESS';

export const shopifyConfirmRequest = (queryString) => ({
  type: SHOPIFY_CONFIRM_REQUEST,
  payload: {queryString},
  meta: {thunk: 'shopifyConfirm'},
});

export const shopifyConfirmFailure = (error, thunk) => ({
  type: SHOPIFY_CONFIRM_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});

export const shopifyConfirmSuccess = (thunk) => ({
  type: SHOPIFY_CONFIRM_SUCCESS,
  meta: {thunk},
});
