import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Field, Form} from 'react-final-form';
import {resourceAdd} from 'store/actions';
import {useValidator} from 'services/validation';

import TextArea from 'components/molecules/TextArea';
import Button from 'components/molecules/Button';

const EditTweetForm = ({cancel, linkToShare, initialValues, action}) => {
  const dispatch = useDispatch();

  const validateLink = useCallback(
    (value) => {
      if (
        action?.version?.link &&
        !value.includes(action.version.link) &&
        !value.includes('{{link}}')
      ) {
        return `Your tweet must include "${action.version.link}`;
      }
    },
    [action],
  );

  const validate = useValidator({
    tweet_text: [validateLink],
  });

  const onSubmit = useCallback(
    (data) => {
      dispatch(
        resourceAdd({
          broadcasterActions: {
            [action.id]: {...action, completion_data: data},
          },
        }),
      );
      cancel();
    },
    [action, cancel, dispatch],
  );

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
      {({handleSubmit, submitting, error}) => (
        <form onSubmit={handleSubmit} className="editTweetForm">
          {linkToShare && (
            <p>
              {'Tweet must include link '}
              <b>{linkToShare}</b>
            </p>
          )}
          <Field name="tweet_text" component={TextArea} rows={6} maxLength={280} wrap="hard" />
          <div className="buttonLine">
            <Button type="cancel" handleClick={cancel}>
              Cancel
            </Button>
            <Button loading={!!submitting}>Update</Button>
          </div>
          {error && <div className="help is-danger centered">{error}</div>}
        </form>
      )}
    </Form>
  );
};

EditTweetForm.propTypes = {
  linkToShare: PropTypes.string,
  cancel: PropTypes.func,
  initialValues: PropTypes.object,
  action: PropTypes.object,
};

export default EditTweetForm;
