import {useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {fromMetrics} from 'store/selectors';
import {
  metricsRawDataRequest,
  metricsDataRequest,
  metricsClearArrayMetric,
  metricsResetMetric,
  metricsRawDataClear,
} from 'store/actions';

const useViewershipMetrics = () => {
  const dispatch = useDispatch();
  const {campaignSlug} = useParams();

  useEffect(() => {
    const queryParams = {
      campaign: campaignSlug,
    };

    dispatch(metricsDataRequest('viewership', queryParams));
    dispatch(
      metricsRawDataRequest('rollup-twitch-viewership', {
        ...queryParams,
        time_period: 'day',
      }),
    );
    dispatch(metricsDataRequest('fullscreen-video-plays', queryParams));
    dispatch(metricsDataRequest('campaign-game-viewership', queryParams));
  }, [campaignSlug, dispatch]);

  const viewership = useSelector((state) => fromMetrics.getMetric(state, 'viewership'));
  const gameViewership = useSelector((state) =>
    fromMetrics.getMetric(state, 'campaign-game-viewership'),
  );
  const videoViewership = useSelector((state) =>
    fromMetrics.getMetric(state, 'fullscreen-video-plays'),
  );
  const rawViewership = useSelector((state) =>
    fromMetrics.getRaw(state, 'rollup-twitch-viewership'),
  );

  const filterViewership = useCallback(
    (filters, initialFilters) => {
      const queryParams = {
        campaign: campaignSlug,
        date_from: filters.startDate,
        date_to: filters.endDate,
      };

      // For performance and data accurayce reasons, only add broadcaster filter
      // to query if some broadcasters were actually removed from the query
      if (filters.selectedBroadcasters.length !== initialFilters.broadcasters.length) {
        queryParams.broadcasters = filters.selectedBroadcasters.map((b) => b.username);
      }

      // because the backend can't tell the difference between no broadcaster filter and filtering all broadcasters,
      // In the case of no selected broadcasters we should just clear the local metric
      if (filters.selectedBroadcasters.length > 0 || !filters.selectedBroadcasters) {
        dispatch(metricsDataRequest('viewership', queryParams));
        dispatch(
          metricsRawDataRequest('rollup-twitch-viewership', {
            ...queryParams,
            time_period: 'day',
          }),
        );
        dispatch(metricsDataRequest('fullscreen-video-plays', queryParams));
        dispatch(metricsDataRequest('campaign-game-viewership', queryParams));
      } else {
        dispatch(metricsResetMetric('viewership'));
        dispatch(metricsClearArrayMetric('campaign-game-viewership'));
        dispatch(metricsRawDataClear('rollup-twitch-viewership'));
      }
    },
    [campaignSlug, dispatch],
  );

  return {
    viewership,
    gameViewership,
    videoViewership,
    rawViewership,
    filterViewership,
  };
};

export default useViewershipMetrics;
