import {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {useDispatch} from 'react-redux';

import {required, email, useValidator} from 'services/validation';
import {useTeams} from 'hooks';
import {resourceCreateRequest, resourceUpdateRequest} from 'store/actions';
import AdvInput from 'components/molecules/AdvInput';
import Button from 'components/components/Button';
import MultiSelect from 'components/molecules/MultiSelect';
import PermissionsField from '../PermissionsField';

const initialValues = {
  permissions_campaigns: 1,
  permissions_teams: 1,
  permissions_users: 2,
};

const ManagerInvitationForm = ({invitation, onSubmitSuccess}) => {
  const dispatch = useDispatch();
  const teams = useTeams();
  const selectedTeams = teams.filter((team) =>
    invitation ? invitation.teams.includes(team.id) : false,
  );

  const validate = useValidator({
    email: [required, email],
  });

  const onSubmit = useCallback(
    async (data) => {
      const teamIds = data.teams.map((team) => team.id);
      const updatedData = {...data, teams: teamIds};

      try {
        if (invitation) {
          await dispatch(resourceUpdateRequest('managerInvitations', invitation.id, updatedData));
        } else {
          await dispatch(resourceCreateRequest('managerInvitations', updatedData));
        }
      } catch (error) {
        return error;
      }
    },
    [dispatch, invitation],
  );

  return (
    <Form onSubmit={onSubmit} initialValues={invitation || initialValues} validate={validate}>
      {({handleSubmit, submitting, error, form}) => (
        <form
          onSubmit={(data) => {
            handleSubmit(data)?.then(() => {
              const {submitSucceeded} = form.getState();
              submitSucceeded && onSubmitSuccess();
            });
          }}
        >
          <Field
            name="email"
            placeholder="Email"
            component={AdvInput}
            disabled={invitation}
            tooltip={
              invitation
                ? 'Email address cannot be edited. Please delete invitation and make a new one if you need to change the email address.'
                : undefined
            }
          />
          <Field
            name="teams"
            title="Teams"
            items={teams}
            component={MultiSelect}
            selectedItems={selectedTeams}
          />
          <Field
            name="permissions_campaigns"
            label="Campaigns"
            permissionsList={['readWrite', 'readOnly']}
            component={PermissionsField}
          />
          <Field
            name="permissions_teams"
            label="Teams"
            permissionsList={['readWrite', 'readOnly']}
            component={PermissionsField}
          />
          <Field
            name="permissions_users"
            label="Users"
            permissionsList={['readWrite', 'readOnly', 'noAccess']}
            component={PermissionsField}
          />
          <Button loading={!!submitting}>{invitation ? 'Update Invitation' : 'Send Invite'}</Button>
          {error && <div className="help is-danger centered">{error}</div>}
        </form>
      )}
    </Form>
  );
};

ManagerInvitationForm.propTypes = {
  invitation: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
};

export default ManagerInvitationForm;
