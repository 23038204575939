import {Route, Redirect, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {BROADCASTER} from 'store/profile/userTypes';
import {isEmpty} from 'lodash';

import {fromProfile, fromResources} from 'store/selectors';

const RootRoute = ({...rest}) => {
  const location = useLocation();
  const applicationComplete = useSelector((state) => fromProfile.getApplicationStatus(state));
  const campaigns = useSelector((state) => fromResources.getResourceList(state, 'campaigns'));
  const profile = useSelector((state) => fromProfile.getFullProfile(state));
  const userType = useSelector((state) => fromProfile.getUserType(state));

  return (
    <Route
      {...rest}
      render={() => {
        if (
          userType === BROADCASTER &&
          !isEmpty(profile.profile) &&
          campaigns.length === 0 &&
          !profile.profile.current_team &&
          !applicationComplete
        ) {
          return <Redirect to={{pathname: '/profile', state: {from: location}}} />;
        }
        return <Redirect to="/dashboard" />;
      }}
    />
  );
};

export default RootRoute;
