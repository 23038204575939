import {authCompleteLogout} from './actions';

const tokenMismatchMiddleware =
  ({dispatch}) =>
  (next) =>
  (action) => {
    if (action.error && action.payload && action.payload.detail === 'Invalid token.') {
      dispatch(authCompleteLogout());
      return undefined;
    }

    return next(action);
  };

export default tokenMismatchMiddleware;
