import {takeEvery, call, put} from 'redux-saga/effects';
import {kebabCase} from 'lodash';

import {
  METRICS_PAYMENT_REQUEST,
  metricsPaymentSuccess,
  metricsPaymentFailure,
  METRICS_RAW_DATA_REQUEST,
  metricsRawDataSuccess,
  metricsRawDataFailure,
  METRICS_DATA_REQUEST,
  metricsRequestSuccess,
  metricsRequestFailure,
} from './actions';

export function* dataRequest(api, metric, params, thunk) {
  const METRICS_ENDPOINT_URL = `metrics/${metric}/`;
  try {
    const data = yield call([api, api.get], METRICS_ENDPOINT_URL, {params});
    yield put(metricsRequestSuccess(data, thunk, metric));
  } catch (error) {
    yield put(metricsRequestFailure(error, thunk));
  }
}

export function* paymentRequest(api, thunk) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;

  try {
    const data = yield call([api, api.get], `metrics/payments/${currentYear}/${currentMonth}`);
    yield put(metricsPaymentSuccess(data, thunk));
  } catch (error) {
    yield put(metricsPaymentFailure(error, thunk));
  }
}

export function* rawDataRequest(api, {params}, {dataType, thunk}) {
  try {
    const data = yield call([api, api.get], `${kebabCase(dataType)}/`, {
      params,
    });
    yield put(metricsRawDataSuccess(data, dataType, thunk));
  } catch (error) {
    yield put(metricsRawDataFailure(error, thunk));
  }
}

// Watchers
export function* watchMetricsDataRequest(api, {payload, meta}) {
  yield call(dataRequest, api, meta.metric, payload, meta.thunk);
}

export function* watchMetricsPaymentRequest(api, {payload, meta}) {
  yield call(paymentRequest, api, meta.thunk);
}

export function* watchRawDataRequest(api, {payload, meta}) {
  yield call(rawDataRequest, api, payload, meta);
}

export default function* sagas({api}) {
  yield takeEvery(METRICS_RAW_DATA_REQUEST, watchRawDataRequest, api);
  yield takeEvery(METRICS_DATA_REQUEST, watchMetricsDataRequest, api);
  yield takeEvery(METRICS_PAYMENT_REQUEST, watchMetricsPaymentRequest, api);
}
