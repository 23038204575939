import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {chunk, flatten} from 'lodash';

import {
  createValidator,
  isDateBeforeDate,
  required,
  maxLength,
  legalFilename,
} from 'services/validation';
import {isPending} from 'redux-saga-thunk';
import {resourceCreateRequest} from 'store/actions';
import {formatDateOfBirth} from 'utils/numberFormats';
import {useBroadcasters, useCampaign} from 'hooks';

const requiresBroadcasters = ['broadcaster_table', 'impressions_by_broadcaster'];

const useNewReportForm = () => {
  const dispatch = useDispatch();
  const campaign = useCampaign();
  const broadcasters = useBroadcasters();
  const loading = useSelector((state) => isPending(state, 'reportsCreate'));

  const initialValues = useMemo(
    () => ({
      sections: {
        clicks_by_target_table: {
          include: true,
        },
        clicks_by_type_table: {
          include: true,
        },
        clicks_by_country_table: {
          include: true,
          limit: 10,
        },
        clicks_by_component_table: {
          include: false,
        },
        broadcaster_table: {
          include: true,
          optionalSections: [
            {
              id: 'watched_hours',
              name: 'watched hours',
            },
            {
              id: 'hours_broadcast',
              name: 'hours broadcast',
            },
            {
              id: 'average_viewers',
              name: 'average viewers',
            },
            {
              id: 'clicks',
              name: 'clicks',
            },
            {
              id: 'cvm',
              name: 'cvm',
            },
            {
              id: 'num_broadcasts',
              name: 'broadcast count',
            },
          ],
        },
        impressions_by_broadcaster: {
          include: false,
        },
        weekly_data_table: {
          include: true,
          optionalSections: [
            {
              id: 'viewable_minutes',
              name: 'VMs',
            },
            {
              id: 'clicks',
              name: 'clicks',
            },
          ],
        },
        monthly_data_table: {
          include: false,
          optionalSections: [],
        },
        daily_data_table: {
          include: false,
          optionalSections: [],
        },
      },
    }),
    [],
  );

  const reportSections = useCallback(
    (campaign, broadcasters, startTime, isPDF, filterEmptyRows, customSections) => {
      let sections = isPDF
        ? [
            {
              section_type: 'header',
              report_header: campaign,
            },
          ]
        : [];

      sections.push({
        section_type: 'campaign_parameters',
        start: startTime,
      });

      const contentSections = Object.keys(customSections).reduce((acc, key) => {
        const customSection = customSections[key];
        return customSection.include
          ? [
              ...acc,
              {
                section_type: key,
                ...(customSection.limit && {limit: customSection.limit}),
                ...(filterEmptyRows && {filter_empty: filterEmptyRows}),
                ...(requiresBroadcasters.includes(key) && {broadcasters}),
                ...(customSection.optionalSections && {
                  columns: customSection.optionalSections.map((o) => o.id),
                }),
              },
            ]
          : acc;
      }, []);

      sections.push(...contentSections);

      // chunk sections with more than 3 columns into separate sections for legibility
      if (isPDF) {
        sections = sections.map((section) => {
          if (!(section.columns && section.columns.length > 3)) return section;

          const chunks = chunk(section.columns, 3);
          return chunks.map((chunk) => ({...section, columns: chunk}));
        }, {});
      }
      return flatten(sections);
    },
    [],
  );

  const onSubmit = useCallback(
    (data) => {
      const defaultName = `${campaign.name}: ${formatDateOfBirth(
        data.start_time || campaign.start_date,
      )} - ${formatDateOfBirth(data.end_time || new Date())}`;
      const broadcastersArray = broadcasters.map((b) => b.username);
      const fullData = {
        name: data.name || defaultName,
        ...(data.start_time && {start: data.start_time}),
        ...(data.end_time && {end: data.end_time}),
        type: data.type,
        campaigns: [campaign.slug],
        sections: reportSections(
          campaign.name,
          broadcastersArray,
          campaign.start_date,
          data.type === 'P',
          data.empty_rows,
          data.sections,
        ),
      };

      dispatch(resourceCreateRequest('reports', fullData));
    },
    [broadcasters, campaign.name, campaign.slug, campaign.start_date, dispatch, reportSections],
  );

  const validate = (values, props) =>
    createValidator({
      name: [maxLength(128), legalFilename],
      type: [required],
      start_time: [isDateBeforeDate(values && values.end_time)],
    })(values, props);

  return {
    onSubmit,
    validate,
    initialValues,
    loading,
    campaign,
  };
};

export default useNewReportForm;
