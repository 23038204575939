import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Check} from 'mdi-material-ui';
import {resourceUpdateRequest} from 'store/actions';
import useModal from 'hooks/useModal';

import {AdvModal} from 'containers';
import DownloadButton from 'components/components/DownloadButton';
import CopyButton from 'components/components/CopyButton';
import Button from 'components/components/Button';

const ManualTweetModal = ({action}) => {
  const dispatch = useDispatch();
  const [, modalHide] = useModal();

  const onSubmit = useCallback(
    (data) =>
      dispatch(
        resourceUpdateRequest('broadcasterActions', action.id, {
          ...data,
          completion_data: action.completion_data,
          completion_action: 'send_tweet',
        }),
      ),
    [action, dispatch],
  );

  return (
    <AdvModal name="manual-tweet" contentLabel="Send out Tweet manually">
      <Form onSubmit={onSubmit}>
        {({submitting, handleSubmit, error, submitError, form}) => (
          <form
            onSubmit={(data) =>
              handleSubmit(data)?.then(() => {
                const {submitSucceeded} = form.getState();
                submitSucceeded && modalHide('manual-tweet');
              })
            }
            className="manualTweet"
          >
            <div className="deleteLabel">Send out Tweet manually</div>
            <div className="actionRow">
              <p>
                Copy Tweet Content: {action.completion_data.tweet_text || action.version.message}
              </p>
              <CopyButton content={action.version.link} />
            </div>
            <div className="actionRow grey">
              <p>Download Image</p>
              <DownloadButton href={action.version.image} />
            </div>
            {action.version.image && <img src={action.version.image} alt="" />}
            <Button loading={!!submitting} icon={<Check />}>
              Mark as Completed
            </Button>
            {(error || submitError) && (
              <div className="help is-danger centered">{error || submitError}</div>
            )}
          </form>
        )}
      </Form>
    </AdvModal>
  );
};

ManualTweetModal.propTypes = {
  action: PropTypes.object,
};

export default ManualTweetModal;
