import {values} from 'lodash';

import {initialState} from './selectors';
import {RESOURCE_ADD} from '../resources/actions';

export default (state = initialState, {type, payload = {}}) => {
  switch (type) {
    case RESOURCE_ADD:
      // gets user data out of normalized schema form
      return {
        ...state,
        ...values(payload.user)[0],
      };
    default:
      return state;
  }
};
