import {useCallback} from 'react';
import isEmail from 'validator/lib/isEmail';
import isIn from 'validator/lib/isIn';
import isInt from 'validator/lib/isInt';
import isURL from 'validator/lib/isURL';
import blacklist from 'validator/lib/blacklist';
import isMobilePhone from 'validator/lib/isMobilePhone';
import {get} from 'lodash';

const isEmpty = (value) => value === undefined || value === null || value === '';

const isString = (value) => typeof value === 'string' || value instanceof String;

export const email = (value) => !isEmpty(value) && !isEmail(value) && 'Invalid Email Address';

export const url = (value) => !isEmpty(value) && !isURL(blacklist(value, ' ')) && 'Invalid URL';

export const urlProtocol = (value) =>
  !isEmpty(value) &&
  isURL(value) &&
  !isURL(value, {require_protocol: true}) &&
  'URL must contain http or https';

export const noURLs = (value) =>
  !isEmpty(value) &&
  value.split(' ').reduce((acc, val) => acc || isURL(val), false) &&
  'Manual Link Entered';

const validGraphicTypes = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/tif',
  'image/jpg',
  'video/mp4',
  'video/webm',
];
export const isValidGraphic = (value) =>
  !isEmpty(value) &&
  !isString(value) &&
  (!/\.(gif|jpg|jpeg|tiff|png|mp4|webm)$/i.test(value.name) ||
    !validGraphicTypes.includes(value.type)) &&
  'Must be a valid graphic file type.';

const validJPGOrPNG = ['image/jpeg', 'image/png', 'image/jpg'];
export const isValidBannerImage = (value) =>
  !isEmpty(value) &&
  !isString(value) &&
  (!/\.(gif|jpg|jpeg|tiff|png|mp4|webm)$/i.test(value.name) ||
    !validJPGOrPNG.includes(value.type)) &&
  'Must be a valid graphic file type.';

const validMovieTypes = ['video/mp4', 'video/webm'];
export const isValidMovie = (value) =>
  !isEmpty(value) &&
  !isString(value) &&
  (!/\.(mp4|webm)$/i.test(value.name) || !validMovieTypes.includes(value.type)) &&
  'Must be a valid movie file.';

export const required = (value) => isEmpty(value) && 'Required Field';

export const noNullString = (value) =>
  ['undefined', 'null'].includes(value) && 'Invalid field value';

export const minLength = (min) => (value) =>
  !isEmpty(value) && value.length < min && `Must be at least ${min} characters`;

export const maxLength = (max) => (value) =>
  !isEmpty(value) && value.length > max && `Must be no more than ${max} characters`;

export const minSelection = (min) => (value) =>
  !isEmpty(value) &&
  value.length < min &&
  `Must select at least ${min} option${min > 1 ? 's' : ''}`;

export const integer = (value) => !isInt(value) && 'Must be an integer';

export const oneOf = (values) => (value) =>
  !isIn(value, values) && `Must be one of: ${values.join(', ')}`;

export const match = (field) => (value, data) =>
  data && value !== data[field] && 'Passwords must match';

export const excludeCharacter = (character) => (value) =>
  value && value.includes(character) && `Cannot include '${character}'`;

export const onlyOne = (fields) => (value, data) =>
  [value, ...fields.map((field) => data[field])].filter((x) => !isEmpty(x)).length !== 1 &&
  'You must add at least a youtube url or video file, but not both';

export const isTwitterHandle = (value) =>
  !isEmpty(value) && !/@/g.test(value) && 'Enter a valid twitter handle (@ required)';

export const isValidCommandName = (value) => {
  if (!value || !value.command) return 'Required Field';
  if (/^!/.test(value.command)) return 'Commands cannot begin with !';
  if (/\s/.test(value.command)) return 'Commands cannot include spaces';
  return false;
};

export const isTweet = (value) =>
  !isEmpty(value) &&
  !/https?:\/\/(www\.)?twitter\.com\/[a-zA-Z0-9_]{1,15}\/status\/\d+/g.test(value) &&
  'Enter a valid tweet URL';

export const min = (minVal) => (value) =>
  !isEmpty(value) && value < minVal && `Must be bigger than ${minVal}`;

export const max = (maxVal) => (value) =>
  !isEmpty(value) && value > maxVal && `Must be smaller than ${maxVal}`;

export const isURLSafe = (value) =>
  /^[-a-zA-Z0-9_]+$/.test(value) && 'Only letters, numbers, dashes, and underscores are allowed';

export const isValidDuration = (value) => {
  if (!value || !value.duration) return 'Required Field';
  if (!isInt(value.duration.toString())) return 'Duration must be an integer';
  if (value.duration <= 1) return 'Duration must be greater than 1 second';
  return false;
};

export const isDateBeforeDate = (endDate) => (value) =>
  endDate && value >= endDate && 'End date must be greater than start date';

const dateFormatRegex = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
export const isValidDateFormat = (value) =>
  !isEmpty(value) && !value.match(dateFormatRegex) && 'Must be a valid date of form YYYY/MM/DD';

const youtubeUrlRegex =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const isYoutubeVideoUrl = (value) =>
  !isEmpty(value) && !value.match(youtubeUrlRegex) && 'Must be a valid Youtube video URL';

const vimeoUrlRegex =
  /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
export const isVimeoVideoUrl = (value) =>
  !isEmpty(value) && !value.match(vimeoUrlRegex) && 'Must be a valid Vimeo URL';

export const isVimeoOrYoutubeVideoUrl = (value) =>
  !isEmpty(value) &&
  !value.match(vimeoUrlRegex) &&
  !value.match(youtubeUrlRegex) &&
  'Must be a valid Vimeo or Youtube URL';

export const phone = (value) =>
  !isEmpty(value) &&
  !isMobilePhone(value, 'any', {strictMode: true}) &&
  "Must match format '+12223334444'";

const join = (rules) => (value, data) =>
  rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];

export const createValidator =
  (rules) =>
  (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key]));
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };

export const alphanumeric = (value) =>
  !/^[\w-]*$/.test(value ? value.source_keyword : '') && 'Invalid keyword';

export const legalFilename = (value) => !/^[\w- .]*$/.test(value) && 'Incorrect filename';

export const useValidator = (rules) =>
  useCallback(
    (data = {}) => {
      const errors = {};
      Object.keys(rules).forEach((key) => {
        const rule = join([].concat(rules[key]));
        const error = rule(get(data, key), data);
        if (error) {
          errors[key] = error;
        }
      });
      return errors;
    },
    [rules],
  );
