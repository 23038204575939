/* eslint-disable no-restricted-syntax */
import {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'lodash';

import AdvWarning from 'components/components/AdvWarning';
import Button from 'components/molecules/Button';
import {CSSTransitionGroup} from 'react-transition-group';
import {Close} from 'mdi-material-ui';

const MultiSelect = ({
  input,
  meta,
  minimized,
  items,
  itemIDField = 'id',
  itemNameField = 'name',
  title,
  hasClearSelection,
  isHighlighted,
  selectedItems: selectedItemsInitial,
  handleOnChange,
}) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [selectedItems, setSelectedItems] = useState(selectedItemsInitial);

  useEffect(() => {
    setSelectedItems(selectedItemsInitial);
  }, [selectedItemsInitial]);

  useEffect(() => {
    if (input && !isEqual(input.value, selectedItems)) {
      input.onChange(selectedItems);
    }
  }, [input, selectedItems]);

  const updateReduxFormValue = useCallback(
    (selectedItems) => {
      if (input) {
        input.onChange(selectedItems);
      }

      if (handleOnChange) {
        handleOnChange(selectedItems);
      }
    },
    [handleOnChange, input],
  );

  const addSelection = useCallback(
    (selectedItem, selectedItems) => {
      const newSelection = selectedItems.concat(selectedItem);
      updateReduxFormValue(newSelection);
      setSelectedItems(newSelection);
    },
    [updateReduxFormValue],
  );

  const removeSelection = useCallback(
    (selectedItem, selectedItems) => {
      const newSelection = selectedItems.filter((i) => !isEqual(i, selectedItem));
      updateReduxFormValue(newSelection);
      setSelectedItems(newSelection);
    },
    [updateReduxFormValue],
  );

  const clearSelection = useCallback(() => {
    updateReduxFormValue([]);
    setSelectedItems([]);
  }, [updateReduxFormValue]);

  const isSelected = (item) => {
    for (const selectedItem of selectedItems) {
      if (isEqual(item, selectedItem)) return true;
    }

    return false;
  };

  return (
    <div
      className={isHighlighted ? 'adv-multiselect-wrapper highlighted' : 'adv-multiselect-wrapper'}
      id="adv-multiselect-wrapper"
      onBlur={(e) => {
        // closes the menu on a click outside of the multiselect wrapper
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setMenuOpened(false);
        }
      }}
    >
      <CSSTransitionGroup
        transitionName="slowFadeWarning"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {meta && meta.touched && meta.error && meta.submitFailed && (
          <AdvWarning>{meta.error}</AdvWarning>
        )}
      </CSSTransitionGroup>
      <div
        className={`${minimized ? 'minimized ' : ''}adv-multiselect`}
        id="adv-multiselect"
        role="button"
        tabIndex={0}
        onClick={(e) => {
          // toggles the menu on a multiselect click
          if (e.target.className !== null) {
            setMenuOpened(!menuOpened);
          }
        }}
      >
        {(minimized || selectedItems.length === 0) && <h3>{title}</h3>}
        {selectedItems &&
          selectedItems.map((item) => (
            <a key={item[itemIDField]} data-value={item[itemNameField]} className="selectedItem">
              {item[itemNameField]}
              <Button>
                <Close
                  className="removeItem"
                  tabIndex={-1}
                  onClick={() => removeSelection(item, selectedItems)}
                />
              </Button>
            </a>
          ))}
      </div>
      <div className={`${menuOpened ? 'open ' : ''}${minimized ? 'minimized ' : ''}menu`}>
        {hasClearSelection && (
          <div
            className="clearSelection"
            onClick={() => clearSelection()}
            role="button"
            tabIndex={0}
          >
            <span className="clearSelectionText">Clear Selection</span>
          </div>
        )}
        {items.map((item) => (
          <div
            className={isSelected(item) ? 'item selected' : 'item'}
            onClick={isSelected(item) ? () => {} : () => addSelection(item, selectedItems)}
            role="button"
            tabIndex={0}
            key={item[itemIDField]}
            data-value={item}
          >
            <span className="non-clickable">{item[itemNameField]}</span>
            <span
              className="clickable"
              role="button"
              tabIndex={-1}
              onClick={() => removeSelection(item, selectedItems)}
            >
              {item[itemNameField]}
              <p>x</p>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

MultiSelect.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  items: PropTypes.arrayOf(PropTypes.object),
  input: PropTypes.object,
  minimized: PropTypes.bool,
  handleOnChange: PropTypes.func,
  disabled: PropTypes.bool,
  hasClearSelection: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  meta: PropTypes.object,
  itemIDField: PropTypes.string,
  itemNameField: PropTypes.string,
  title: PropTypes.string,
};

export default MultiSelect;
