// https://github.com/diegohaz/arc/wiki/Redux-modules
import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import createCookieMiddleware from './cookies/cookieMiddleware';
import middlewares from './middlewares';
import reducer from './reducer';
import sagas from './sagas';

const configureStore = (initialState, services = {}, cookies) => {
  const sagaMiddleware = createSagaMiddleware();
  const cookieMiddleware = createCookieMiddleware(cookies);

  const composeEnhancers =
    IS_DEV && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  const enhancers = applyMiddleware(...middlewares, cookieMiddleware, sagaMiddleware);

  const store = createStore(reducer, initialState, composeEnhancers(enhancers));
  let sagaTask = sagaMiddleware.run(sagas, services);

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      const nextReducer = require('./reducer').default;
      store.replaceReducer(nextReducer);
    });
    module.hot.accept('./sagas', () => {
      const nextSagas = require('./sagas').default;
      sagaTask.cancel();
      sagaTask.done.then(() => {
        sagaTask = sagaMiddleware.run(nextSagas, services);
      });
    });
  }

  return store;
};

export default configureStore;
