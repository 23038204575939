import {useMemo, useCallback} from 'react';
import {Form, Field} from 'react-final-form';
import {Link, useLocation} from 'react-router-dom';

import {LockOutline, AccountOutline} from 'mdi-material-ui';
import {CSSTransitionGroup} from 'react-transition-group';
import Checkbox from 'components/components/Checkbox';
import Button from 'components/components/Button';
import LoginInput from '../LoginInput';
import {useDispatch} from 'react-redux';

import {authClientLoginRequest} from 'store/actions';
import {required, useValidator} from 'services/validation';

const ManagerLogin = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const validate = useValidator({
    username: [required],
    password: [required],
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        return await dispatch(
          authClientLoginRequest(data, location.state ? location.state.from : null),
        );
      } catch (error) {
        return error;
      }
    },
    [dispatch, location.state],
  );

  const initialValues = useMemo(() => ({stayLoggedIn: true}), []);

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
      {({handleSubmit, submitting, submitError}) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="username"
            label="username"
            placeholder="Email"
            icon={<AccountOutline />}
            component={LoginInput}
          />
          <Field
            name="password"
            label="password"
            placeholder="Password"
            autosuggest="current-password"
            type="password"
            icon={<LockOutline />}
            component={LoginInput}
          />
          <div className="loginExtras">
            <Field
              name="stayLoggedIn"
              defaultValue={true}
              component={Checkbox}
              label="Keep Me Signed In"
            />
            <h3 className="forgotPassword">
              <Link to="/login/reset-request">Forgot Password?</Link>
            </h3>
          </div>

          <Button loading={!!submitting}>Sign-In</Button>
          <CSSTransitionGroup
            transitionName="slowFade"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {submitError && <div className="help is-danger centered">{submitError}</div>}
          </CSSTransitionGroup>
        </form>
      )}
    </Form>
  );
};

export default ManagerLogin;
