import {useEffect, useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isPending} from 'redux-saga-thunk';

import {resourceListReadRequest, resourceUpdateRequest} from 'store/actions';
import {fromResources} from 'store/selectors';
import {useTeams} from 'hooks';

const useUserAdmin = () => {
  const dispatch = useDispatch();
  const teams = useTeams();

  useEffect(() => {
    dispatch(resourceListReadRequest('managers'));
  }, [dispatch]);

  useEffect(() => {
    dispatch(resourceListReadRequest('managerInvitations'));
  }, [dispatch]);

  const managers = useSelector((state) => fromResources.getResourceList(state, 'managers'));
  const [currentManagers, inactiveManagers] = useMemo(() => {
    const current = [];
    const inactive = [];

    managers.forEach((manager) => {
      if (manager.user.is_active) {
        current.push(manager);
      } else {
        inactive.push(manager);
      }
    });

    return [current, inactive];
  }, [managers]);

  const managerInvitations = useSelector((state) =>
    fromResources.getResourceList(state, 'managerInvitations'),
  );

  const loading = useSelector(
    (state) =>
      isPending(state, 'managersListReadRequest') ||
      isPending(state, 'managerInvitationsListReadRequest'),
  );

  const deleteManager = useCallback(
    (manager) =>
      dispatch(
        resourceUpdateRequest('managers', manager.id, {
          ...manager,
          user: {
            ...manager.user,
            is_active: false,
          },
        }),
      ),
    [dispatch],
  );

  return {
    currentManagers,
    inactiveManagers,
    managerInvitations,
    teams,
    loading,
    deleteManager,
  };
};

export default useUserAdmin;
