import {useState} from 'react';
import PropTypes from 'prop-types';
import {CheckBold, CheckboxBlankOutline} from 'mdi-material-ui';
import {CSSTransitionGroup} from 'react-transition-group';

const AdvCheckbox = ({isChecked, onCheck, label, useTempValue, onAnyClick}) => {
  const [tempValue, setTempValue] = useState(null);
  const value = tempValue !== null ? tempValue : !!isChecked;
  const onClick = () => {
    onCheck(!value);
    useTempValue && setTempValue(!tempValue);
  };

  return (
    <span
      role="button"
      tabIndex={0}
      className={`advCheckboxWrapper ${onAnyClick ? 'wrapperClick' : ''} ${value ? 'checked' : ''}`}
      onClick={onAnyClick && onClick}
    >
      <div
        className={`advCheckbox ${value ? 'checked' : ''}`}
        role="button"
        tabIndex={-1}
        checked={value}
        onClick={!onAnyClick ? onClick : undefined}
      >
        <CSSTransitionGroup
          transitionName="slowFadeWarning"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {value ? <CheckBold /> : <CheckboxBlankOutline />}
        </CSSTransitionGroup>
      </div>
      {label && <h4>{label}</h4>}
    </span>
  );
};

AdvCheckbox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  useTempValue: PropTypes.bool,
  onCheck: PropTypes.func,
  onAnyClick: PropTypes.bool,
};

export default AdvCheckbox;
