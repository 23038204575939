import PropTypes from 'prop-types';

import {images} from 'adv-theme';

const BroadcasterSuggestion = ({suggestion}) => (
  <div className="broadcaster-suggestion-item">
    <img
      className="broadcaster-suggestion-image"
      src={suggestion.profile_image ? suggestion.profile_image : images.squareLogo}
      alt={`${suggestion.username} profile`}
    />
    <span className="broadcaster-suggestion-username">{suggestion.username}</span>
  </div>
);

BroadcasterSuggestion.propTypes = {
  suggestion: PropTypes.object,
};

export default BroadcasterSuggestion;
