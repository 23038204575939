import PropTypes from 'prop-types';

const Hamburger = ({isOpen, clickFunc}) => {
  const burgerClass = isOpen ? 'navbar-burger is-active' : 'navbar-burger';

  return (
    <div className={burgerClass} onClick={clickFunc} role="presentation">
      <span />
      <span />
      <span />
    </div>
  );
};

Hamburger.propTypes = {
  isOpen: PropTypes.bool,
  clickFunc: PropTypes.func,
};

export default Hamburger;
