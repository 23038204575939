import {differenceWith, isEqual, isEmpty} from 'lodash';

export const sortByKey = (key, reverse) => (a, b) => {
  if (a[key] < b[key]) {
    return reverse ? 1 : -1;
  }
  if (b[key] < a[key]) {
    return reverse ? -1 : 1;
  }

  return 0;
};

// sort an array of strings case insensitive
export const sortCaseInsensitive = (array) =>
  array.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

export const isArrayEqual = (x, y) => isEmpty(differenceWith(x, y, isEqual));

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);
