import {useSelector} from 'react-redux';

import {fromProfile} from 'store/selectors';

const usePermissions = () => {
  const isAdmin = useSelector((state) => fromProfile.isAdmin(state));
  const canEditCampaigns = useSelector((state) => fromProfile.canEditCampaigns(state)) || isAdmin;
  const canEditUsers = useSelector((state) => fromProfile.canEditUsers(state)) || isAdmin;
  const canViewUsers = useSelector((state) => fromProfile.canViewUsers(state)) || isAdmin;
  const canEditTeams = useSelector((state) => fromProfile.canEditTeams(state)) || isAdmin;

  return {canEditCampaigns, canViewUsers, canEditUsers, canEditTeams};
};

export default usePermissions;
