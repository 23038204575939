import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {resourceUpdateRequest} from 'store/actions';
import {isPending, hasFailed} from 'redux-saga-thunk';

import Button from 'components/molecules/Button';
import AdvModal from 'containers/AdvModal';
import useModal from 'hooks/useModal';

const ReactivateModal = ({modalName, component}) => {
  const dispatch = useDispatch();
  const [, modalHide] = useModal();
  const loading = useSelector((state) => isPending(state, 'componentsUpdate'));
  const failed = useSelector((state) => hasFailed(state, 'componentsUpdate'));
  const updateRequest = useCallback(
    () =>
      dispatch(
        resourceUpdateRequest('components', component.id, {
          ...component,
          end_time: null,
          end_signal_send: null,
        }),
      ).then(() => dispatch(modalHide(modalName))),
    [component, dispatch, modalHide, modalName],
  );
  return (
    <AdvModal name={modalName} contentLabel={`${component.name} Reactivate Modal`}>
      <div className="deleteLabel">{`Reactivate component "${component.name}"?`}</div>
      {failed && (
        <div className="help is-danger centered">
          Couldn&apos;t reactivate component. Please try again later
        </div>
      )}
      <Button type="cancel" handleClick={() => modalHide(modalName)}>
        Cancel
      </Button>
      <Button loading={!!loading} handleClick={updateRequest}>
        Reactivate
      </Button>
    </AdvModal>
  );
};

ReactivateModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  component: PropTypes.object.isRequired,
};

export default ReactivateModal;
