import {Fragment} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Filter} from 'mdi-material-ui';
import {isPending} from 'redux-saga-thunk';

import {formatNumber} from 'utils/numberFormats';
import {CSSTransitionGroup} from 'react-transition-group';

const CampaignMetricCard = ({
  loadingThunk,
  title,
  icon,
  metrics,
  isFiltered,
  hasTopBar,
  withPercentage,
  sectionBar,
  emptyMessage,
}) => {
  const loading = useSelector((state) => isPending(state, loadingThunk));
  return (
    <div className={loading ? 'campaignMetric is-loading' : 'campaignMetric'}>
      <div className="topBar">
        <h2>
          {icon}
          {title}
        </h2>
        <CSSTransitionGroup
          transitionName="fade"
          transitionEnterTimeout={250}
          transitionLeaveTimeout={0}
        >
          {isFiltered && <Filter />}
        </CSSTransitionGroup>
      </div>
      <div className="content">
        {!loading && metrics && metrics.length > 0
          ? metrics.map((metrics, index) => (
              <Fragment key={metrics.label}>
                {index === sectionBar && <div className="divider" />}
                <div
                  className={index === 0 && hasTopBar ? 'highlighted statRow' : 'statRow'}
                  key={metrics.id || metrics.label}
                >
                  <p>{`${metrics.label}: `}</p>
                  {!!withPercentage && (
                    <p>
                      ({formatNumber((metrics.value / withPercentage) * 100)}
                      %)
                    </p>
                  )}
                  <p>
                    <strong>{formatNumber(metrics.value)}</strong>
                  </p>
                </div>
              </Fragment>
            ))
          : emptyMessage && !loading && <p className="emptyMessage">{emptyMessage}</p>}
      </div>
    </div>
  );
};

CampaignMetricCard.propTypes = {
  title: PropTypes.string.isRequired,
  loadingThunk: PropTypes.string,
  isFiltered: PropTypes.bool,
  hasTopBar: PropTypes.bool,
  icon: PropTypes.element,
  metrics: PropTypes.arrayOf(PropTypes.object),
  withPercentage: PropTypes.number,
  sectionBar: PropTypes.number,
  emptyMessage: PropTypes.string,
};

export default CampaignMetricCard;
