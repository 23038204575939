import PropTypes from 'prop-types';

const LoadingBar = ({message}) => (
  <div className="loadingBar">
    <h2>{message}</h2>
    <div className="dot dot1" />
    <div className="dot dot2" />
    <div className="dot dot3" />
    <div className="dot dot4" />
  </div>
);

LoadingBar.propTypes = {
  message: PropTypes.string,
};

LoadingBar.defaultProps = {
  message: 'Loading',
};

export default LoadingBar;
