import {put, call, takeEvery} from 'redux-saga/effects';

import {
  browsersourceTriggerSuccess,
  browsersourceTriggerFailure,
  BROWSERSOURCE_TRIGGER_REQUEST,
  browsersourcePositioningSaveSuccess,
  browsersourcePositioningSaveFailure,
  BROWSERSOURCE_POSITIONING_SAVE_REQUEST,
  browsersourcePositioningReadSuccess,
  browsersourcePositioningReadFailure,
  BROWSERSOURCE_POSITIONING_READ_REQUEST,
  browsersourceTestPatternFailure,
  BROWSERSOURCE_TEST_PATTERN_REQUEST,
} from './actions';

export function* triggerVideo(api, data, {target, thunk}) {
  try {
    yield call([api, api.post], 'video-trigger/', data);
    yield put(browsersourceTriggerSuccess(thunk));
  } catch (e) {
    yield put(browsersourceTriggerFailure(e, thunk));
  }
}

export function* saveBrowsersourcePositioning(api, {data}, {thunk}) {
  try {
    const newPositions = yield call([api, api.put], 'rotations/bulk_update/', data);
    yield put(browsersourcePositioningSaveSuccess(newPositions, thunk));
  } catch (error) {
    yield put(browsersourcePositioningSaveFailure(error, thunk));
  }
}

export function* readBrowsersourcePositioning(api, {data}, {thunk}) {
  try {
    const response = yield call([api, api.get], 'rotations/', data);
    yield put(browsersourcePositioningReadSuccess(response, thunk));
  } catch (error) {
    yield put(browsersourcePositioningReadFailure(error, thunk));
  }
}

export function* flashTestPattern(api, {thunk}) {
  try {
    yield call([api, api.post], 'browsersource-test-pattern/');
  } catch (e) {
    yield put(browsersourceTestPatternFailure(thunk));
  }
}

export function* watchTriggerVideo(api, {payload, meta}) {
  yield call(triggerVideo, api, payload, meta);
}

export function* watchBrowsersourcePositioningSaveRequest(api, {payload, meta}) {
  yield call(saveBrowsersourcePositioning, api, payload, meta);
}

export function* watchBrowsersourcePositioningReadRequest(api, {payload, meta}) {
  yield call(readBrowsersourcePositioning, api, payload, meta);
}
export function* watchTestPattern(api, {meta}) {
  yield call(flashTestPattern, api, meta);
}

export default function* sagas({api}) {
  yield takeEvery(BROWSERSOURCE_TRIGGER_REQUEST, watchTriggerVideo, api);
  yield takeEvery(
    BROWSERSOURCE_POSITIONING_SAVE_REQUEST,
    watchBrowsersourcePositioningSaveRequest,
    api,
  );
  yield takeEvery(
    BROWSERSOURCE_POSITIONING_READ_REQUEST,
    watchBrowsersourcePositioningReadRequest,
    api,
  );
  yield takeEvery(BROWSERSOURCE_TEST_PATTERN_REQUEST, watchTestPattern, api);
}
