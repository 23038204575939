const IconUsers = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 612 547">
    <g>
      <path
        d="M558.6,80.9c0-25.9-20.8-47-46.3-47h0l-403.4,0.1c-25.5,0-46.3,21.1-46.3,47l0.1,388.3c0,25.9,20.8,47,46.3,47l403.4-0.2
c12.6,0,24.8-5.3,33.5-14.5c8.3-8.8,12.8-20.3,12.8-32.5L558.6,80.9z M512.5,481.3l-403.4,0.1c0,0,0,0,0,0c-6.2,0-11.3-5.4-11.3-12
L97.6,81.1c0-6.6,5.1-12,11.3-12l403.4-0.1c0,0,0,0,0,0c6.2,0,11.3,5.4,11.3,12l0.1,388.3C523.8,475.9,518.7,481.3,512.5,481.3z"
      />
    </g>
    <g>
      <g>
        <path d="M339.7,167.1c-1.3-4.9-2.9-9.7-4.8-14.3c-3,3.4-5.7,7.2-8,11.2C331.2,164.8,335.5,165.8,339.7,167.1z" />
        <g>
          <path
            d="M504,363.2l-39.2-107.3c12.4-16.7,19.8-37.4,19.8-59.8c0-55.5-45.1-100.6-100.6-100.6c-26.6,0-50.9,10.4-68.9,27.4
c-18.3-19.3-44.2-31.4-72.9-31.4c-55.5,0-100.6,45.1-100.6,100.6c0,24,8.4,46,22.5,63.3l-37.9,103.8
c-3.3,9.1,1.4,19.1,10.4,22.4c2,0.7,4,1.1,6,1.1c7.1,0,13.8-4.4,16.4-11.5l33.5-91.7c6.2,3.5,12.8,6.4,19.8,8.6
c3.7,14.2,10.3,27.3,19.4,38.3l-37.9,103.8c-3.3,9.1,1.4,19.1,10.4,22.4c2,0.7,4,1.1,6,1.1c7.1,0,13.8-4.4,16.4-11.5
l33.5-91.7c3.4,1.9,6.9,3.7,10.5,5.2c10.7,4.5,22.3,7.2,34.4,7.8c1.5,0.1,3.1,0.1,4.7,0.1c5.5,0,11-0.5,16.3-1.3
c11.8-1.9,23-5.9,33-11.6c1.2-0.7,2.4-1.4,3.6-2.1l34.2,93.6c2.6,7.1,9.3,11.5,16.4,11.5c2,0,4-0.3,6-1.1
c9.1-3.3,13.8-13.4,10.4-22.4L390.6,323c6.4-8.6,11.4-18.2,14.8-28.6c11.3-2.5,21.9-6.8,31.5-12.8l34.2,93.6
c2.6,7.1,9.3,11.5,16.4,11.5c2,0,4-0.3,6-1.1C502.6,382.4,507.3,372.3,504,363.2z M210.2,249.3c-20-11.2-33.5-32.7-33.5-57.2
c0-36.2,29.4-65.6,65.6-65.6c20.9,0,39.6,9.9,51.6,25.1c-2.1,4.2-3.9,8.6-5.4,13.2C247.6,173.8,216,207.5,210.2,249.3z
M367,295.2c-4.9,8.8-11.8,16.3-20.1,22c-8.4,5.8-18.2,9.6-28.8,11c-1.4,0.2-2.9,0.3-4.3,0.4c-1.3,0.1-2.6,0.1-3.9,0.1
c-9.6,0-18.8-2.1-27-5.9c-13.7-6.2-25-17.1-31.7-30.6c-4.4-8.8-6.8-18.7-6.8-29.1c0-1.9,0.1-3.7,0.2-5.5
c2.1-24.5,17.6-45.2,39.2-54.6c7.4-3.2,15.4-5.1,23.9-5.4c0.8,0,1.5-0.1,2.3-0.1c2.9,0,5.8,0.2,8.7,0.6
c8.4,1.1,16.3,3.8,23.4,7.8c19.4,10.9,32.8,31.4,33.5,55.1c0,0.7,0.1,1.4,0.1,2.1C375.4,274.8,372.3,285.7,367,295.2z
M334.8,152.8c12-13.6,29.6-22.3,49.2-22.3c36.2,0,65.6,29.4,65.6,65.6c0,26.9-16.2,50-39.4,60.1c-2.9-42-31.7-77-70.5-89.1"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default IconUsers;
