import {useState} from 'react';
import {useSelector} from 'react-redux';

import BaseTemplate from 'components/components/BaseTemplate';
import {fromProfile} from 'store/selectors';
import BroadcasterProfileForm from '../../forms/BroadcasterProfileForm';
import TwitterProfileDisplay from '../../components/TwitterProfileDisplay';
import DiscordProfileDisplay from './components/DiscordProfileDisplay';
import BroadcasterApplicationForm from '../../forms/BroadcasterApplicationForm';
import APIKeyForm from '../../forms/ApiKeyForm';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ContentCopy} from 'mdi-material-ui';
import {CSSTransitionGroup} from 'react-transition-group';
import {formatDateOfBirth} from 'utils/numberFormats';
import {useCampaigns} from 'hooks';

const BroadcasterProfile = () => {
  const applicationComplete = useSelector((state) => fromProfile.getApplicationStatus(state));
  const [applicationOpen, setApplicationOpen] = useState(!applicationComplete);
  const profile = useSelector((state) => fromProfile.getFullProfile(state));
  const browsersourceMsg = useSelector((state) => fromProfile.getBrowsersource(state));
  const campaigns = useCampaigns();

  return (
    <BaseTemplate>
      <div className="profilePage">
        <CSSTransitionGroup
          transitionName="slideApplication"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {applicationOpen && !profile.profile.current_team && (
            <div>
              <div className="profileLeftPan">
                <div className="profileForm">
                  <h2>Member Details</h2>
                  <p>
                    You&apos;ll be eligible for campaigns once this is filled out! Check out our
                    discord and let us know if you have any quesitons!
                  </p>
                  <BroadcasterApplicationForm
                    initialValues={{
                      ...profile.profile,
                      application_date_of_birth: profile.profile.application_date_of_birth
                        ? formatDateOfBirth(profile.profile.application_date_of_birth)
                        : null,
                    }}
                    profile={profile}
                    handleSuccess={() => setApplicationOpen(false)}
                  />
                </div>
              </div>
            </div>
          )}
        </CSSTransitionGroup>
        <div className={`profileLeftPan ${!applicationOpen ? 'isFirst' : ''}`}>
          {profile.profile && profile.profile.current_team && (
            <div className="teamIdentifier">
              <h4>
                {'You are on team '}
                <b>{`${profile.profile.current_team.name}${
                  profile.profile.current_team.squad
                    ? ` - ${profile.profile.current_team.squad}`
                    : ''
                }`}</b>
              </h4>
              <img alt="" src={profile.profile && profile.profile.current_team.logo} />
            </div>
          )}
          <div className="profileBrowsersource">
            <h4>Your browsersource link: </h4>
            <div className="browsersourceLink">
              <h4>{browsersourceMsg}</h4>
              <CopyToClipboard text={browsersourceMsg}>
                <div className="copySection">
                  <span className="tooltip">Copied!</span>
                  <ContentCopy />
                </div>
              </CopyToClipboard>
            </div>
          </div>
          <TwitterProfileDisplay
            screenName={profile.profile && profile.profile.twitter}
            hasTwitterOAuth={profile.profile && profile.profile.has_twitter_oauth}
          />
          <DiscordProfileDisplay username={profile.profile && profile.profile.discord} />
          <BroadcasterProfileForm initialValues={profile} profile={profile} />
          <CSSTransitionGroup
            transitionName="slowFade"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {campaigns.length === 0 && applicationComplete && !profile.profile.current_team && (
              <h3 className="applicationSuccessMessage">
                Your partner application is submitted! We&apos;ll let you know when something
                becomes available through your notification method of choice!
              </h3>
            )}
          </CSSTransitionGroup>
          <APIKeyForm apiKey={profile.api_key} />
          {campaigns.length > 0 && (
            <div className="footer">
              {(applicationComplete || !applicationOpen) && !profile.profile.current_team && (
                <div
                  role="button"
                  className="applicationToggle"
                  tabIndex={0}
                  onClick={() => setApplicationOpen(!applicationOpen)}
                >
                  {applicationOpen ? 'Hide' : 'Edit'} Member Details
                </div>
              )}
              <div className="links">
                <a href="https://adv.gg/docs/terms" rel="noreferrer noopener">
                  <h4>Terms of Service</h4>
                </a>
                <a href="https://adv.gg/docs/privacy" rel="noreferrer noopener" target="_blank">
                  <h4>Privacy Policy</h4>
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="header" />
      </div>
    </BaseTemplate>
  );
};

export default BroadcasterProfile;
