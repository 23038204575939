import {unionWith} from 'lodash';
import {initialState} from './selectors';
import {
  BROWSERSOURCE_POSITIONING_READ_SUCCESS,
  BROWSERSOURCE_POSITIONING_SAVE_SUCCESS,
} from './actions';

export default (state = initialState, {type, payload = {}}) => {
  switch (type) {
    case BROWSERSOURCE_POSITIONING_READ_SUCCESS:
    case BROWSERSOURCE_POSITIONING_SAVE_SUCCESS:
      return {
        ...state,
        browsersource_positioning: unionWith(
          payload,
          state.browsersource_positioning,
          (rotationA, rotationB) => rotationA.id === rotationB.id,
        ),
      };
    default:
      return state;
  }
};
