import {initialState} from './selectors';
import {
  AUTOSUGGEST_SUGGESTIONS_SUCCESS,
  AUTOSUGGEST_SUGGESTIONS_CLEAR,
  AUTOSUGGEST_LOCAL_SUGGESTIONS,
} from './actions';

export default (state = initialState, {type, payload = {}}) => {
  switch (type) {
    case AUTOSUGGEST_SUGGESTIONS_SUCCESS:
    case AUTOSUGGEST_LOCAL_SUGGESTIONS:
      return payload;
    case AUTOSUGGEST_SUGGESTIONS_CLEAR:
      return initialState;
    default:
      return state;
  }
};
