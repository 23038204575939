export const WIDGETS_UPDATE_REQUEST = 'WIDGETS_UPDATE_REQUEST';
export const WIDGETS_UPDATE_SUCCESS = 'WIDGETS_UPDATE_SUCCESS';
export const WIDGETS_UPDATE_FAILURE = 'WIDGETS_UPDATE_FAILURE';

export const widgetsUpdateRequest = (widgets, dcta) => ({
  type: WIDGETS_UPDATE_REQUEST,
  payload: {widgets, dcta},
  meta: {thunk: 'widgetsUpdate'},
});

export const widgetsUpdateSuccess = (thunk) => ({
  type: WIDGETS_UPDATE_SUCCESS,
  meta: {thunk},
});

export const widgetsUpdateFailure = (error, thunk) => ({
  type: WIDGETS_UPDATE_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});
