import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {formatDateOfBirth} from 'utils/numberFormats';

const BroadcasterListRow = ({
  children,
  broadcaster,
  match,
  hourTimeStamp,
  extraContent,
  formatTime,
}) => (
  <tr key={broadcaster.username}>
    <td
      className="broadcaster-table-portrait"
      style={{
        backgroundImage: broadcaster.profile_image
          ? `url(${broadcaster.profile_image})`
          : 'url(images.squareLogo)',
      }}
    />
    <td className="broadcaster-table-name">
      <p>{broadcaster.username}</p>
      {extraContent && <p>{extraContent}</p>}
      <h4>{formatTime ? formatTime(hourTimeStamp) : formatDateOfBirth(hourTimeStamp)}</h4>
    </td>
    <td className="broadcaster-table-remove">{children}</td>
  </tr>
);

BroadcasterListRow.propTypes = {
  broadcaster: PropTypes.object.isRequired,
  children: PropTypes.node,
  match: PropTypes.object,
  hourTimeStamp: PropTypes.string,
  extraContent: PropTypes.string,
  formatTime: PropTypes.func,
};

export default withRouter(BroadcasterListRow);
