import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {fromResources} from 'store/selectors';
import {isPending} from 'redux-saga-thunk';

import {Twitch} from 'mdi-material-ui';
import EmptySplash from 'components/molecules/EmptySplash';
import ChatbotComponentPreview from './components/ChatbotComponentPreview';
import BroadcasterComponentTemplate from '../../components/BroadcasterComponentTemplate';
import {useComponents, useCampaigns} from 'hooks';

const BroadcasterChatbot = () => {
  const campaigns = useCampaigns();
  const loading = useSelector(
    (state) =>
      isPending(state, 'componentsListRead') && !fromResources.resourceExists('components')(state),
  );
  const components = useComponents();

  const chatComponents = components
    .filter(
      (component) =>
        ['twitch-chat-bot', 'twitch-chat-bot-command'].includes(component.type) &&
        !component.is_deleted &&
        component.is_ready,
    )
    .map((component) => ({
      ...component,
      campaign: campaigns.find((c) => c.slug === component.campaign),
    }));

  const totalChatQueueTime = useMemo(() => {
    const chatbots = chatComponents.filter((c) => c.type === 'twitch-chat-bot');
    return chatbots.reduce((acc, val) => acc + Number(val.config?.message_interval) || 20 * 60, 0);
  }, [chatComponents]);

  return (
    <BroadcasterComponentTemplate loading={loading} loadComponents>
      <h2 className="typeSectionName  twitch">
        <Twitch />
        {' Twitch Chatbot Messages'}
      </h2>
      {chatComponents.length > 0 && (
        <p className="topText">
          When you&apos;re live, these chatbot messages will be posted periodically in your chat, or
          available as chat commands. The chatbot will have been modded in your channel when you
          first signed in to ensure its ability to post
        </p>
      )}
      <div className="broadcasterComponents flex">
        {chatComponents.length > 0 ? (
          chatComponents
            .sort((a, b) => (a.type > b.type ? -1 : 0))
            .map((component) => (
              <ChatbotComponentPreview
                component={component}
                version={component.activeVersion}
                chatbotUsername={
                  component.campaign ? component.campaign.twitch_chatbot_account : 'advogg'
                }
                key={`component-${component.id}`}
                totalChatQueueTime={totalChatQueueTime}
              />
            ))
        ) : (
          <EmptySplash>
            <h3>You have no chatbot messages</h3>
          </EmptySplash>
        )}
      </div>
    </BroadcasterComponentTemplate>
  );
};

export default BroadcasterChatbot;
