import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

const SocialCallback = ({sendCallbackData, loading, failed}) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    sendCallbackData().then(
      () => {},
      (error) => setError(error._error || error.detail || error[0] || error),
    );
  }, [sendCallbackData]);

  return (
    <div>
      {!error && loading && <span>Loading...</span>}
      {error && <span>{JSON.stringify(error) || 'Failed!'}</span>}
    </div>
  );
};

SocialCallback.propTypes = {
  sendCallbackData: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  failed: PropTypes.bool,
};

export default SocialCallback;
