import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import AttributionOverTimeChart from 'components/charts/AttributionOverTimeChart';
import ViewershipChart from 'components/charts/ViewershipChart';
import Button from 'components/molecules/Button';
import CampaignMetricCard from 'components/organisms/CampaignMetricCard';
import {CamcorderBox, LinkBoxVariant, TwitterBox, PollBox} from 'mdi-material-ui';
import {formatPercentage} from 'utils/numberFormats';

const MetricsOverview = ({
  campaign,
  isFiltered,
  filteredClicks,
  filteredConversions,
  viewership,
  social,
  videoViewership,
  totalClicks,
  rawViewership,
  impressions,
  displayCount,
}) => {
  const [selectedChartView, setSelectedChartView] = useState(false);

  useEffect(() => {
    // trigger redraw of charts on a re-render
    window.dispatchEvent(new Event('resize'));
  });

  const totalConversions = filteredConversions ? filteredConversions.length : 0;
  const clickCount = filteredClicks ? filteredClicks.length : 0;

  let linkMetrics = totalClicks ? totalClicks.slice() : [];
  if (campaign && campaign.has_conversions) {
    linkMetrics.push(
      {
        label: 'Total Conversions',
        value: totalConversions,
      },
      {
        label: 'Conversion Rate',
        value: clickCount ? `${formatPercentage((totalConversions / clickCount) * 100)}%` : '0%',
      },
    );
  }
  if (
    campaign &&
    campaign.available_components &&
    !campaign.available_components.includes('custom-link')
  ) {
    linkMetrics = linkMetrics.filter((item) => item.label !== 'Custom Link Clicks');
  }

  let videoMetrics = [];
  if (
    campaign &&
    campaign.available_components &&
    campaign.available_components.includes('fullscreen-video')
  ) {
    videoMetrics = videoViewership?.map((video) => [
      {
        label: `${video.component} views`,
        value: video.views,
      },
      {
        label: `${video.component} plays`,
        value: video.plays,
      },
    ]);
  }

  const hasFullscreenVideos =
    (campaign &&
      campaign.available_components &&
      campaign.available_components.includes('fullscreen-video')) ||
    videoMetrics.length > 0;

  const hasSocial =
    (campaign &&
      campaign.available_components &&
      campaign.available_components.includes('suggested-tweet')) ||
    !!social[0]?.value;

  const hasLivegraphics =
    campaign &&
    campaign.available_components &&
    campaign.available_components.includes('live-graphics');

  return (
    <div className="metricsOverviewBreakdown">
      <CampaignMetricCard
        metrics={linkMetrics}
        isFiltered={isFiltered}
        title={campaign && campaign.has_conversions ? 'Clicks & Conversions' : 'Clicks'}
        icon={<LinkBoxVariant />}
        loadingThunk="clicksRawData"
        sectionBar={campaign && campaign.has_conversions ? linkMetrics.length - 2 : null}
        hasCustomLinks={
          campaign && campaign.available_components
            ? campaign.available_components.includes('custom-link')
            : false
        }
        hasTopBar
      />
      <CampaignMetricCard
        metrics={viewership}
        isFiltered={isFiltered}
        metricType="broadcast"
        title="Broadcasts"
        icon={<CamcorderBox />}
        loadingThunk="viewershipMetricsRequest"
        hasTopBar
      />
      {hasLivegraphics && !isEmpty(impressions) && (
        <CampaignMetricCard
          metrics={impressions}
          isFiltered={isFiltered}
          title="Live Graphic Impressions"
          icon={<CamcorderBox />}
          loadingThunk="viewershipMetricsRequest"
          hasTopBar
        />
      )}
      {hasLivegraphics && !isEmpty(displayCount) && (
        <CampaignMetricCard
          metrics={displayCount}
          isFiltered={isFiltered}
          title="Live Graphic Display Counts"
          icon={<CamcorderBox />}
          loadingThunk="viewershipMetricsRequest"
          hasTopBar
        />
      )}
      {hasSocial && (
        <CampaignMetricCard
          metrics={social}
          metricType="social"
          title="Social Media"
          icon={<TwitterBox />}
          loadingThunk="socialMetricsRequest"
        />
      )}
      {hasFullscreenVideos && (
        <CampaignMetricCard
          isFiltered={isFiltered}
          metrics={videoMetrics.flat()}
          metricType="video"
          title="Fullscreen Videos"
          icon={<PollBox />}
          loadingThunk="fullscreen-video-playsMetricsRequest"
          emptyMessage="No video plays found"
        />
      )}
      {campaign?.has_conversions && selectedChartView && (
        <AttributionOverTimeChart
          title="Conversions By Time of Day"
          name="conversions-by-time-of-day"
          timeKeyFormat="%H"
          tooltipFormat="%H:00"
          dateTimeFormatXAxis={{hour: '%H:%M', day: '%H:%M'}}
          data={filteredConversions}
          unit="conversions"
        />
      )}
      {campaign?.has_conversions && selectedChartView && (
        <AttributionOverTimeChart
          title="Conversions By Day"
          name="conversions-by-day"
          timeKeyFormat="%Y-%m-%d"
          data={filteredConversions}
          unit="conversions"
        />
      )}
      {!selectedChartView && (
        <AttributionOverTimeChart
          title="Clicks By Day"
          name="clicks-by-day"
          timeKeyFormat="%Y-%m-%d"
          data={filteredClicks}
          unit="clicks"
        />
      )}
      {!selectedChartView && rawViewership && (
        <ViewershipChart title="Viewership" name="views-by-day" views={rawViewership} />
      )}
      {campaign?.has_conversions && filteredConversions && filteredConversions.length > 0 && (
        <div className="fullWidth">
          <Button
            type={selectedChartView ? 'isNotPressed' : 'isPressed'}
            handleClick={() => setSelectedChartView(!selectedChartView)}
          >
            <h4>{selectedChartView ? 'Hide Conversion Charts' : 'Show Conversion Charts'}</h4>
          </Button>
        </div>
      )}
    </div>
  );
};

MetricsOverview.propTypes = {
  campaign: PropTypes.object,
  isFiltered: PropTypes.bool,
  filteredClicks: PropTypes.arrayOf(PropTypes.object),
  filteredConversions: PropTypes.arrayOf(PropTypes.object),
  viewership: PropTypes.arrayOf(PropTypes.object),
  impressions: PropTypes.arrayOf(PropTypes.object),
  social: PropTypes.arrayOf(PropTypes.object),
  videoViewership: PropTypes.arrayOf(PropTypes.object),
  totalClicks: PropTypes.arrayOf(PropTypes.object),
  rawViewership: PropTypes.arrayOf(PropTypes.object),
  displayCount: PropTypes.arrayOf(PropTypes.object),
  conversions: PropTypes.arrayOf(PropTypes.object),
};

export default MetricsOverview;
