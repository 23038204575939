import {useState} from 'react';
import {useSelector} from 'react-redux';
import {fromResources} from 'store/selectors';
import {isPending} from 'redux-saga-thunk';
import {useCampaigns, useComponents, useBroadcasterActions} from 'hooks';

const useBroadcasterPanels = () => {
  const components = useComponents();
  const campaigns = useCampaigns();
  const actions = useBroadcasterActions();
  const loading = useSelector(
    (state) =>
      (isPending(state, 'componentsListRead') &&
        !fromResources.resourceExists('components')(state)) ||
      (isPending(state, 'broadcasterActionsListRead') &&
        !fromResources.resourceExists('broadcasterActions')(state)),
  );

  const bannerComponents = components
    .filter(
      (component) =>
        component.type === 'banner-graphics' && !component.is_deleted && component.is_ready,
    )
    .map((component) => ({
      ...component,
      campaign: campaigns.find((c) => c.slug === component.campaign),
    }));

  const filteredActions = actions.filter(
    (action) =>
      !action.completion_time &&
      ['banner-update', 'banner-add', 'banner-remove'].includes(action.type.slug),
  );

  const [selectedView, setSelectedView] = useState(
    filteredActions.length === 0 && bannerComponents.length > 0 ? 'My Banners' : 'Required Actions',
  );
  const [imageDimensions, setImageDimensions] = useState([]);

  return {
    loading,
    imageDimensions,
    setImageDimensions,
    selectedView,
    setSelectedView,
    filteredActions,
    bannerComponents,
  };
};

export default useBroadcasterPanels;
