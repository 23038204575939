import PropTypes from 'prop-types';

const IconButton = ({children, handleClick, label, tooltip, text, extraClass}) => (
  <span className="iconButtonWrapper">
    <div
      className={`iconButton ${extraClass} ${text ? 'hasText' : ''}`}
      aria-label={label}
      role="button"
      tabIndex={-1}
      onClick={handleClick}
    >
      {tooltip && <h4 className="tooltip">{tooltip}</h4>}
      {children}
      {text && <p className="buttonText">{text}</p>}
    </div>
  </span>
);

IconButton.propTypes = {
  children: PropTypes.any.isRequired,
  tooltip: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string.isRequired,
  extraClass: PropTypes.string,
  handleClick: PropTypes.func,
};

export default IconButton;
