import PropTypes from 'prop-types';
import {HashLink as Link} from 'react-router-hash-link';

const Button = ({handleClick, style, color, loading, disabled, link, icon, children}) => {
  const className = `
    button ${loading ? 'isLoading' : ''} ${style || 'default'}Style ${color || 'primary'}Color ${
    icon && !children ? 'iconOnly' : ''
  }
  `;
  if (link) {
    return (
      <Link className={className} to={link}>
        {!loading && icon}
        {!loading && children && <span className="text">{children}</span>}
      </Link>
    );
  }
  return (
    <button
      onClick={!loading ? handleClick : undefined}
      onKeyPress={!loading ? handleClick : undefined}
      className={className}
      disabled={disabled}
    >
      {!loading && icon}
      {!loading && children && <span className="text">{children}</span>}
    </button>
  );
};

Button.propTypes = {
  handleClick: PropTypes.func,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.element,
  style: PropTypes.oneOf(['default', 'tertiary']),
  color: PropTypes.oneOf(['primary', 'twitter', 'twitch', 'cancel']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default Button;
