import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {fromProfile} from 'store/selectors';
import Button from 'components/molecules/Button';
import BrandCampaignCreationForm from 'components/components/CampaignCreationForm/BrandCampaignCreationForm';
import {isCampaignEnded, formatNumber} from 'utils/numberFormats';
import {intersection, pickBy} from 'lodash';
import {useBroadcasters, useCampaigns} from 'hooks';
import useModal from 'hooks/useModal';

const getTotalVMs = (broadcasterStats) => {
  const platforms = ['mixer', 'twitch'];
  return broadcasterStats.all
    ? Object.values(broadcasterStats.all).reduce(
        (acc, val) =>
          acc +
          platforms.reduce(
            (acc, platform) =>
              val[platform].hours_streamed * val[platform].average_viewers * 60 + acc,
            0,
          ),
        0,
      )
    : 0;
};

const getTotalClicks = (campaignStats, campaigns) =>
  campaignStats.all &&
  Object.keys(campaignStats.all).reduce(
    (acc, val) =>
      acc +
      (isCampaignEnded(campaigns.find((c) => c.slug === val))
        ? 0
        : campaignStats.all[val].microsite_visits),
    0,
  );

const getActiveCampaignCount = (campaigns) => {
  let total = 0;
  campaigns.forEach((campaign) => {
    total += isCampaignEnded(campaign) ? 0 : 1;
  });
  return total;
};

const DashboardTopStats = ({broadcasterStats, campaignStats, campaigns}) => {
  const broadcasters = useBroadcasters();
  const campaignList = useCampaigns();
  const [modalShow] = useModal();
  const isAdmin = useSelector((state) => fromProfile.isAdmin(state));

  // get broadcasters that are active on a live campaign, or active on a team
  const activeCampaigns = campaignList
    .filter((campaign) => !campaign.end_date || new Date(campaign.end_date) > new Date())
    .map((campaign) => campaign.slug);
  const filteredBroadcasters = broadcasters.filter(
    (broadcaster) =>
      (broadcaster.team_statuses &&
        Object.values(broadcaster.team_statuses).filter((team) => team.status === 'Active').length >
          0) ||
      intersection(
        activeCampaigns,
        Object.keys(pickBy(broadcaster.campaign_statuses, (c) => c.status === 'Active')),
      ).length > 0,
  );
  return (
    <div className="topStats">
      {isAdmin && (
        <Button handleClick={() => modalShow('campaign-creation')}>Create New Campaign</Button>
      )}
      {filteredBroadcasters && (
        <h3 className="cornerStat">
          Active Broadcasters:
          <b>{filteredBroadcasters.length}</b>
        </h3>
      )}
      {campaigns && (
        <h3 className="cornerStat">
          Total Campaigns:
          <b>{campaigns.length}</b>
        </h3>
      )}
      {campaigns && (
        <h3 className="cornerStat">
          Active Campaigns:
          <b>{getActiveCampaignCount(campaigns)}</b>
        </h3>
      )}
      {broadcasterStats && (
        <h3 className="cornerStat">
          Total Viewable Minutes:
          <b>{formatNumber(getTotalVMs(broadcasterStats))}</b>
        </h3>
      )}
      {campaignStats && (
        <h3 className="cornerStat">
          Total Clicks:
          <b>{formatNumber(getTotalClicks(campaignStats, campaigns))}</b>
        </h3>
      )}

      {/* Modals */}
      <BrandCampaignCreationForm modalName="campaign-creation" />
    </div>
  );
};

DashboardTopStats.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object),
  broadcasterStats: PropTypes.object,
  campaignStats: PropTypes.object,
};

export default DashboardTopStats;
