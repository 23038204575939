import {useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {fromMetrics} from 'store/selectors';
import StaticDateRanges from 'components/molecules/StaticDateRanges';
import GameBreakdown from 'components/organisms/GameBreakdown';
import TeamOverview from './components/TeamOverview';

const TeamPreviewWrapper = ({team, campaigns, broadcasters}) => {
  const gameViewership = useSelector((state) =>
    fromMetrics.getMetric(state, 'team-game-viewership'),
  );
  const [selectedView, setSelectedView] = useState('Team');
  const [selectedDateRange, setSelectedDateRange] = useState('30');
  const subTabs = ['Team', 'Games'];

  const teamView = useCallback(
    (selectedView) => {
      switch (selectedView) {
        case 'Games':
          return (
            <GameBreakdown
              gameViewership={
                gameViewership &&
                gameViewership[team.id] &&
                gameViewership[team.id][selectedDateRange]
                  ? Object.keys(gameViewership[team.id][selectedDateRange]).reduce(
                      (acc, val) => acc.concat(gameViewership[team.id][selectedDateRange][val]),
                      [],
                    )
                  : []
              }
            />
          );
        default:
          return (
            <TeamOverview
              key={team.id}
              team={team}
              broadcasters={broadcasters}
              campaigns={campaigns}
              selectedDateRange={selectedDateRange}
              handleDateRangeChange={setSelectedDateRange}
            />
          );
      }
    },
    [broadcasters, campaigns, gameViewership, selectedDateRange, team],
  );

  return (
    <div className="teamOverviewWrapper">
      <div className="subNavbar">
        <div className="dateRanges">
          <StaticDateRanges
            handleChange={setSelectedDateRange}
            dateRanges={['07', '30', '90', 'all']}
            selectedRange={selectedDateRange.toString()}
          />
        </div>
        <div className="subNavbarItemWrapper">
          {subTabs.map((tab) => (
            <div
              className={`subNavbarItem ${tab === selectedView ? 'active' : ''}`}
              key={tab}
              role="button"
              tabIndex={0}
              onClick={() => setSelectedView(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
      {teamView(selectedView)}
    </div>
  );
};

TeamPreviewWrapper.propTypes = {
  team: PropTypes.object,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  broadcasters: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TeamPreviewWrapper;
