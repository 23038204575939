import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import Button from 'components/molecules/Button';
import AdvInput from 'components/molecules/AdvInput';
import DeleteButton from 'components/components/DeleteButton';
import DeleteModal from 'components/organisms/DeleteModal';
import {required, createValidator} from 'services/validation';
import {resourceCreateRequest} from 'store/actions';
import useModal from 'hooks/useModal';

const SquadManagementForm = ({squads, team}) => {
  const dispatch = useDispatch();
  const [modalShow] = useModal();

  const onSubmit = useCallback(
    async (data) => {
      try {
        return await dispatch(resourceCreateRequest('squads', {name: data.name, team}));
      } catch (error) {
        return error;
      }
    },
    [dispatch, team],
  );

  const validate = createValidator({
    name: [required],
  });

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, submitting, error, form, submitError}) => (
        <form
          onSubmit={(data) =>
            handleSubmit(data)?.then(() => {
              const {submitSucceeded} = form.getState();
              submitSucceeded && form.reset();
            })
          }
          className="squad-management-form"
        >
          <label htmlFor="manage squads">Manage Squads</label>
          <p className="help">Enter a unique squad name</p>
          <div className="inlineField">
            <Field
              name="name"
              placeholder="Squad Name"
              type="text"
              offsetError
              component={AdvInput}
            />
            <Button loading={!!submitting}>Create Squad</Button>
          </div>
          <div className="squadList">
            {squads.map((squad) => (
              <div key={squad.id} className="squadListItem">
                <p>{squad.name}</p>
                <DeleteButton handleClick={() => modalShow(`team-squad-${squad.id}-delete`)} />
              </div>
            ))}
          </div>
          {(error || submitError) && (
            <div className="help is-danger centered">{error || submitError}</div>
          )}

          {squads.map((squad) => (
            <DeleteModal
              modalName={`team-squad-${squad.id}-delete`}
              resource="squads"
              objectType="squad"
              objectName={squad.name}
              objectNeedle={squad.id}
              key={`team-squad-${squad.id}-delete`}
            />
          ))}
        </form>
      )}
    </Form>
  );
};

SquadManagementForm.propTypes = {
  squads: PropTypes.arrayOf(PropTypes.object),
  team: PropTypes.number,
};

export default SquadManagementForm;
