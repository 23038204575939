export const AUTOSUGGEST_SUGGESTIONS_REQUEST = 'AUTOSUGGEST_SUGGESTIONS_REQUEST';
export const AUTOSUGGEST_SUGGESTIONS_SUCCESS = 'AUTOSUGGEST_SUGGESTIONS_SUCCESS';
export const AUTOSUGGEST_SUGGESTIONS_FAILURE = 'AUTOSUGGEST_SUGGESTIONS_FAILURE';

export const autosuggestSuggestionsRequest = (
  searchUrl,
  searchParam,
  searchValue,
  extraParams = {},
) => ({
  type: AUTOSUGGEST_SUGGESTIONS_REQUEST,
  payload: {
    searchUrl,
    params: {
      [searchParam]: searchValue,
      ...extraParams,
    },
  },
  meta: {thunk: 'autosuggestRequest'},
});

export const autosuggestSuggestionsSuccess = (suggestions, thunk) => ({
  type: AUTOSUGGEST_SUGGESTIONS_SUCCESS,
  payload: suggestions,
  meta: {thunk},
});

export const autosuggestSuggestionsFailure = (error, thunk) => ({
  type: AUTOSUGGEST_SUGGESTIONS_FAILURE,
  payload: error,
  error: true,
  meta: {thunk},
});

export const AUTOSUGGEST_SUGGESTIONS_CLEAR = 'AUTOSUGGEST_SUGGESTIONS_CLEAR';

export const autosuggestSuggestionsClear = () => ({
  type: AUTOSUGGEST_SUGGESTIONS_CLEAR,
});

// Used for filtering from suggestions already in the store rather than
// fetching from a remote server
export const AUTOSUGGEST_LOCAL_SUGGESTIONS = 'AUTOSUGGEST_LOCAL_SUGGESTIONS';

export const autosuggestLocalSuggestions = (suggestions) => ({
  type: AUTOSUGGEST_LOCAL_SUGGESTIONS,
  payload: suggestions,
});
