import {useMemo, useCallback} from 'react';
import {useCampaignResource} from 'hooks';

const useCampaignBroadcasterCompliance = ({actions, components, compliance}) => {
  const validTypes = useMemo(() => [...new Set(components.map((c) => c.type))], [components]);
  const broadcasterActions = useCampaignResource('broadcasterActions');
  const hasChatbotModCompliance = validTypes.some((type) =>
    ['twitch-chat-bot', 'twitch-chat-bot-command'].includes(type),
  );

  const getTwitterActionsPerBroadcaster = useCallback(
    (broadcaster) =>
      actions
        .filter(
          (a) =>
            ['twitter-retweet', 'twitter-send-tweet'].includes(a.type.slug) &&
            a.broadcaster === broadcaster,
        )
        .map((action) => {
          const matchingComponent = components.find((c) => c.name === action.component);
          return {
            name: matchingComponent ? matchingComponent.name : '',
            type:
              action.type.slug === 'twitter-send-tweet' ? 'suggested-tweet' : 'suggested-retweet',
            compliant: !!action.completion_time,
            last_updated: action.completion_time || action.creation_time,
            required: true,
          };
        }),
    [actions, components],
  );

  const broadcasterCompliance = useMemo(
    () =>
      compliance.map((broadcasterComplianceEntry) => {
        const {compliance, name, mod_status} = broadcasterComplianceEntry;
        const complianceValues = Object.values(compliance);
        return {
          ...broadcasterComplianceEntry,
          componentsCompliance: complianceValues.reduce((acc, componentCompliance) => {
            const component = components.find((c) => c.id === componentCompliance.component);
            if (!component) return {};
            return {
              ...acc,
              [component.id]: {
                ...componentCompliance,
                ...component,
              },
            };
          }, {}),
          actions: getTwitterActionsPerBroadcaster(name),
          overallCompliance:
            complianceValues.reduce(
              (acc, component) => (!component?.required || component?.compliant) && acc,
              true,
            ) &&
            (!hasChatbotModCompliance || mod_status === null || mod_status.status === 'Modded')
              ? 'compliant'
              : 'noncompliant',
        };
      }),
    [compliance, getTwitterActionsPerBroadcaster, hasChatbotModCompliance, components],
  );

  const compliantBroadcasterCount = useMemo(
    () => broadcasterCompliance.filter((b) => b.overallCompliance === 'compliant').length,
    [broadcasterCompliance],
  );
  const noncompliantBroadcasterCount = useMemo(
    () => broadcasterCompliance.filter((b) => b.overallCompliance !== 'compliant').length,
    [broadcasterCompliance],
  );

  return {
    broadcasterCompliance,
    compliantBroadcasterCount,
    noncompliantBroadcasterCount,
    broadcasterActions,
    hasChatbotModCompliance,
  };
};

export default useCampaignBroadcasterCompliance;
