import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fromMetrics, fromProfile} from 'store/selectors';
import {metricsPaymentRequest} from 'store/actions';

import {formatNumber} from 'utils/numberFormats';

const AvatarMetrics = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(metricsPaymentRequest());
  }, [dispatch]);

  const paymentData = useSelector((state) => fromMetrics.getPaymentData(state));
  const profileImage = useSelector(
    (state) => fromProfile.getProfileImage(state) || '/images/NewSquareIconSmall.png',
  );

  return (
    <div className="avatarMetrics">
      <img src={profileImage || '/images/NewSquareIconSmall.png'} alt="" />
      <div className="metrics">
        <h3>Your Monthly Stats</h3>
        {/* <h4>Listed amounts are estimates and subject to change</h4> */}
        <p>
          Minutes Watched:
          <b>
            {paymentData && paymentData.viewable_minutes >= 0
              ? ` ${formatNumber(paymentData.viewable_minutes)}`
              : '...'}
          </b>
        </p>
        <p>
          Clicks:
          <b>
            {paymentData && paymentData.clicks >= 0
              ? ` ${formatNumber(paymentData.clicks)}`
              : '...'}
          </b>
        </p>
        {/* disabled until payment business logic is decided */}
        {/* {paymentData && paymentData.payment > 0 && <p>Pay To Date: <b>
          {paymentData.payment >= 0 ? `${formatDollar(paymentData.payment)}` : '...'}
        </b></p>} */}
      </div>
    </div>
  );
};

export default AvatarMetrics;
