import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {isPending, hasFailed} from 'redux-saga-thunk';
import {setCookie} from 'redux-cookie';

import {fromResources} from 'store/selectors';
import AdvocateLoginLogo from '../AdvocateLoginLogo';
import BroadcasterLogin from '../BroadcasterLogin';
import {resourceDetailReadRequest} from 'store/actions';

const Invite = ({to}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const {inviteKey} = useParams();
  const location = useLocation();

  const invite = useSelector((state) =>
    fromResources.getResourceDetail(state, 'inviteLinks', inviteKey),
  );
  const inviteName = invite?.campaign_name || invite?.team_name || '';
  const loading = useSelector((state) => isPending(state, `${to}InviteLinksDetailRead`));
  const failed = useSelector((state) => hasFailed(state, `${to}InviteLinksDetailRead`));
  const setKeyCookie = () => dispatch(setCookie(`${to}InviteKey`, inviteKey, {path: '/'}));
  const setLabelCookie = (label) => dispatch(setCookie(`${to}InviteLabel`, label, {path: '/'}));

  useEffect(() => {
    dispatch(resourceDetailReadRequest(`${to}InviteLinks`, inviteKey)).then(
      () => {},
      (error) => {
        if (error.detail === 'Not found.') {
          setError('Could not find a matching invitation');
        } else if (error._error) {
          setError(error._error[0]); // can't connect to server error, created by api/index.js
        } else if (error.expires_at) {
          setError(error.expires_at[0]); // use expiration error from API
        } else {
          setError('We are currently unable to load this invitation'); // catchall
        }
      },
    );
  }, [dispatch, inviteKey, to]);

  const queryParams = new URLSearchParams(location.search);
  const queryLabel = queryParams.label;

  return (
    <div className="campaignInvitePage">
      <div className="loginView">
        <div className="loginWrapper">
          <div className="logoContainer">
            <AdvocateLoginLogo />
          </div>
          {!loading && !failed && !!inviteName && (
            <div className="inviteLogin">
              <h3>You&#39;ve been invited to join</h3>
              <h1>{inviteName}</h1>
              <div className="acceptButtons">
                <BroadcasterLogin
                  linkText="Accept Invite"
                  onClick={() => {
                    setKeyCookie();
                    queryLabel && setLabelCookie(queryLabel);
                  }}
                />
              </div>
              <div className="bubble bubble1" />
              <div className="bubble bubble2" />
              <div className="bubble bubble3" />
            </div>
          )}
          {error && <div className="help is-danger centered">{error}</div>}
        </div>
      </div>
    </div>
  );
};

Invite.propTypes = {
  to: PropTypes.oneOf(['team', 'campaign']),
};

export default Invite;
