import PropTypes from 'prop-types';
import {Field} from 'react-final-form';

import Button from 'components/molecules/Button';
import BroadcasterSuggestion from './components/BroadcasterSuggestion';

const BroadcasterParticipationForm = ({
  handleSubmit,
  submitting,
  error,
  submitSucceeded,
  destination,
  successMsg = 'Invite Sent!',
  buttonText = 'Send',
  title,
  componentProps,
  component,
}) => (
  <form onSubmit={handleSubmit} className="add-broadcaster-form">
    <label htmlFor={`Add Broadcaster To ${destination}`}>
      {title || `Send a ${destination} Invitation`}
    </label>
    <Field
      component={component}
      {...componentProps}
      allowRaw
      name="broadcaster"
      displayParam="username"
      placeholder="Select a broadcaster"
      SuggestionComponent={BroadcasterSuggestion}
    />

    {submitSucceeded && <div className="success">{successMsg}</div>}
    <Button loading={!!submitting}>{buttonText}</Button>
    {error && <div className="help is-danger centered">{error}</div>}
  </form>
);

BroadcasterParticipationForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  submitSucceeded: PropTypes.bool,
  destination: PropTypes.string,
  successMsg: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  componentProps: PropTypes.object,
  component: PropTypes.node,
};

export default BroadcasterParticipationForm;
