import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import IconButton from 'components/components/IconButton';
import {ContentCopy} from 'mdi-material-ui';

const CopyButton = ({content}) => (
  <CopyToClipboard text={content}>
    {/* some intermediary element is required before the button for the copy function to work */}
    <div>
      <IconButton label="copy">
        <ContentCopy />
      </IconButton>
    </div>
  </CopyToClipboard>
);

CopyButton.propTypes = {
  content: PropTypes.string.isRequired,
};

export default CopyButton;
