import {AUTH_SET_STATUS, AUTH_TEST_COMPLETE} from './actions';

export const initialState = {isAuthenticated: false};

export default (state = initialState, {type, payload = {}}) => {
  switch (type) {
    case AUTH_SET_STATUS:
      return {...state, isAuthenticated: payload.isAuthenticated};
    case AUTH_TEST_COMPLETE:
      return {...state, testResult: payload.msg};
    default:
      return state;
  }
};
