import {timeFormat} from 'd3';

export const formatNumber = (value) => {
  if (value) {
    if (value > 10000000) {
      return `${(value / 1000000).toLocaleString('en-US', {
        maximumFractionDigits: 2,
      })}M`;
    }
    return value.toLocaleString('en-US', {maximumFractionDigits: 0});
  }
  return '0';
};

export const formatPercentage = (value) => {
  if (value) {
    return value.toLocaleString('en-US', {maximumFractionDigits: 2});
  }
  return '0';
};

export const formatDollar = (value) => {
  if (value) {
    return `${parseFloat(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })}`;
  }
  return '$0.00';
};

export const formatDate = (value) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const msec = Date.parse(value);
  const d = new Date(msec);
  const today = new Date();
  return today.getYear() === d.getYear()
    ? `${months[d.getMonth()]} ${d.getDate()}`
    : `${months[d.getMonth()]} ${d.getDate()}, ${1900 + d.getYear()}`;
};

export const formatShortDate = (value) => {
  const shortTimeFormat = timeFormat('%b %d, %I:%M %p');
  const msec = Date.parse(value);
  const d = new Date(msec);
  return shortTimeFormat(d);
};

export const formatDateOfBirth = (value) => {
  const dobFormat = timeFormat('%Y/%m/%d');
  return dobFormat(new Date(value));
};

export const formatTime = (value) => {
  const msec = Date.parse(value);
  const d = new Date(msec);
  const hour = d.getHours();
  return hour >= 12 ? `${hour - 12 || 12}:00 PM` : `${hour || 12}:00 AM`;
};

export const formatExactTime = (value) => {
  const chatbotTimeFormat = timeFormat('%I:%M %p');
  return chatbotTimeFormat(new Date(value));
};

export const formatDateTime = (value) => {
  return `${formatDate(value)} ${formatTime(value)}`;
};

export const getDateDifference = (startIn, endIn) => {
  const startDate = new Date(Date.parse(startIn));
  const endDate = new Date(Date.parse(endIn));

  return endDate.getTime() - startDate.getTime();
};

export const getISODate = (date, hourKey) => {
  if (date) {
    if (typeof date === 'string') {
      const tempDate = new Date(date);
      tempDate.setHours(hourKey || 0);
      return tempDate.toISOString();
    }
    return date.hour(hourKey || 0).toISOString();
  }
  return undefined;
};

export const isCampaignEnded = (campaign) =>
  campaign && campaign.end_date ? getDateDifference(campaign.end_date, new Date()) > 0 : false;

export const isCampaignStarted = (campaign) =>
  campaign && campaign.start_date ? getDateDifference(campaign.start_date, new Date()) > 0 : false;

export const getCampaignProgress = (campaign) => {
  if (campaign) {
    if (campaign.end_date) {
      if (isCampaignEnded(campaign)) {
        return 1;
      }
      return (
        getDateDifference(campaign.start_date, new Date()) /
        getDateDifference(campaign.start_date, campaign.end_date)
      );
    }
    if (campaign.overview_metrics) {
      return campaign.overview_metrics.viewable_minutes / Number(campaign.target);
    }
  }
  return 0;
};
