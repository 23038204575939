import PropTypes from 'prop-types';

const LoginTemplate = ({children}) => {
  Chatra('hide');

  return (
    <div className="loginView">
      <div className="loginWrapper">{children}</div>
    </div>
  );
};

LoginTemplate.propTypes = {
  children: PropTypes.node,
};

export default LoginTemplate;
