import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {isPending, isDone} from 'redux-saga-thunk';

import ComparisonIndicator from 'components/components/ComparisonIndicator';
import AdvTooltip from 'components/components/AdvTooltip';
import {formatNumber} from 'utils/numberFormats';

const StatsTable = ({loadingThunk, metrics, showLoadingText}) => {
  const loading = useSelector(
    (state) => isPending(state, loadingThunk) || !isDone(state, loadingThunk),
  );

  return (
    <div
      className={
        loading && (!showLoadingText || metrics) && !metrics.length
          ? 'statsTable is-loading'
          : 'statsTable'
      }
    >
      <div className="content">
        {showLoadingText && loading && <p className="loadingText">Loading metrics...</p>}
        {metrics &&
          metrics.map((metric) => (
            <div className="statRow" key={metric.label}>
              {!!metric.tooltip && <AdvTooltip tooltip={metric.tooltip} />}
              <p>{`${metric.label}: `}</p>
              <p>
                <strong>{formatNumber(metric.value)}</strong>
              </p>
              {metric.oldValue > 0 && metric.firstThreshold && metric.secondThreshold ? (
                <ComparisonIndicator
                  oldValue={metric.oldValue}
                  newValue={metric.value}
                  firstThreshold={metric.firstThreshold}
                  secondThreshold={metric.secondThreshold}
                />
              ) : (
                ''
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

StatsTable.propTypes = {
  loadingThunk: PropTypes.string,
  metrics: PropTypes.any,
  showLoadingText: PropTypes.bool,
};

StatsTable.defaultProps = {
  metrics: {
    viewable_minutes: 0,
    average_viewers: 0,
    peak_viewers: 0,
  },
};

export default StatsTable;
