export const INVITATION_RESPONSE_REQUEST = 'INVITATION_RESPONSE_REQUEST';
export const INVITATION_RESPONSE_SUCCESS = 'INVITATION_RESPONSE_SUCCESS';
export const INVITATION_RESPONSE_FAILURE = 'INVITATION_RESPONSE_FAILURE';

export const invitationResponseRequest = (resource, needle, response) => ({
  type: INVITATION_RESPONSE_REQUEST,
  payload: {resource, needle, response},
  meta: {thunk: `invitation${response}${needle}`},
});

export const invitationResponseFailure = (error, thunk) => ({
  type: INVITATION_RESPONSE_FAILURE,
  error: true,
  payload: error,
  meta: {thunk},
});
