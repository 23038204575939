import {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {useDispatch} from 'react-redux';

import {required, useValidator} from 'services/validation';
import AutoSuggest from 'components/components/AutoSuggest/AutoSuggest';
import BroadcasterSuggestion from 'components/components/BroadcasterParticipationForm/components/BroadcasterParticipationForm/components/BroadcasterSuggestion';
import DateRangeField from 'components/molecules/DateRangeField';
import Button from 'components/molecules/Button';
import {resourceCreateRequest} from 'store/actions';

const FeaturedCampaignForm = ({campaign, onSubmitSuccess}) => {
  const dispatch = useDispatch();
  const validate = useValidator({
    broadcaster: [required],
    start_time: [required],
    end_time: [required],
  });

  const onSubmit = useCallback(
    async (data) => {
      const featureData = {
        broadcaster: data.broadcaster.username,
        campaign: campaign.slug,
        start_time: data.start_time,
        end_time: data.end_time,
      };

      try {
        await dispatch(resourceCreateRequest('featuredCampaigns', featureData));
      } catch (error) {
        return error;
      }
    },
    [campaign.slug, dispatch],
  );

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, error, submitting, form}) => (
        <form
          onSubmit={(data) => {
            handleSubmit(data)?.then(() => {
              const {submitSucceeded} = form.getState();
              submitSucceeded && onSubmitSuccess();
            });
          }}
          className="add-broadcaster-form"
        >
          <label>Feature This Campaign for Broadcaster</label>
          <Field
            component={AutoSuggest}
            searchUrl="broadcasters/search/"
            searchParam="username"
            displayParam="username"
            placeholder="Select a broadcaster"
            name="broadcaster"
            SuggestionComponent={BroadcasterSuggestion}
            extraParams={{
              campaign: campaign.slug,
            }}
            allowRaw
          />
          <DateRangeField
            specifyTime
            startAbsoluteStart={campaign ? campaign.start_date : null}
            startAbsoluteEnd={campaign ? campaign.end_date : null}
            endAbsoluteStart={campaign ? campaign.start_date : null}
            endAbsoluteEnd={campaign ? campaign.end_date : null}
            endPlaceholder="End Date"
          />
          <Button loading={!!submitting}>Submit</Button>
          {error && <div className="help is-danger centered">{error}</div>}
        </form>
      )}
    </Form>
  );
};

FeaturedCampaignForm.propTypes = {
  campaign: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
};

export default FeaturedCampaignForm;
