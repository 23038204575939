import Button from 'components/components/Button';

const ResetSuccess = () => (
  <>
    <h2>Your password was successfully reset</h2>
    <Button link="/login/manager">Return to Login</Button>
  </>
);

export default ResetSuccess;
