import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import Button from 'components/molecules/Button';
import Select from 'components/molecules/Select';
import DeleteButton from 'components/components/DeleteButton';
import {required, useValidator} from 'services/validation';
import {
  managementSetCampaignSquadRequest,
  managementRemoveCampaignSquadRequest,
} from 'store/actions';

const CampaignSquadsForm = ({campaign, squads}) => {
  const dispatch = useDispatch();
  const campaignSquads = squads.filter((s) => campaign.squads.includes(s.id));
  const availableSquads = squads.filter((s) => !campaign.squads.includes(s.id));

  const removeSquad = useCallback(
    (squad, campaign) => dispatch(managementRemoveCampaignSquadRequest(squad, campaign.slug)),
    [dispatch],
  );

  const validate = useValidator({
    squad: [required],
  });

  const onSubmit = async (data) => {
    return dispatch(managementSetCampaignSquadRequest(data.squad, campaign.slug));
  };

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, submitting, error, form}) => (
        <form onSubmit={handleSubmit} className="squad-management-form">
          <label htmlFor="manage squads">Manage Squads For This Campaign</label>
          {availableSquads.length > 0 && (
            <div className="inlineField">
              <Field component={Select} name="squad">
                <option value="">Select a squad to add</option>
                {availableSquads.map((squad) => (
                  <option key={squad.id} value={squad.id}>
                    {squad.name}
                  </option>
                ))}
              </Field>
              <Button loading={submitting}>Add Squad</Button>
            </div>
          )}
          <div className="squadList">
            {campaignSquads.map((squad) => (
              <div key={squad.id} className="squadListItem">
                <p>{squad.name}</p>
                <DeleteButton
                  handleClick={() => removeSquad(squad.id, campaign).then(() => form.reset())}
                />
              </div>
            ))}
          </div>
          {error && <div className="help is-danger centered">{error}</div>}
        </form>
      )}
    </Form>
  );
};

CampaignSquadsForm.propTypes = {
  squads: PropTypes.arrayOf(PropTypes.object),
  campaign: PropTypes.object,
};

export default CampaignSquadsForm;
