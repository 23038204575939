import MultiSelect from '../MultiSelect';

const minutes = [...Array(60).keys()];

const structuredMinutes = minutes.map((minute) => ({
  id: minute,
  name: `:${String(minute).padStart(2, '0')}`,
}));

const ScheduleField = (props) => {
  return (
    <MultiSelect
      {...props}
      items={structuredMinutes}
      title="Select minutes on which to trigger (optional)"
    />
  );
};

export default ScheduleField;
