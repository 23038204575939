import PropTypes from 'prop-types';

import AdvWarning from 'components/components/AdvWarning';
import AdvTooltip from 'components/components/AdvTooltip';
import {CSSTransitionGroup} from 'react-transition-group';

const AdvInput = ({
  input,
  meta,
  placeholder,
  type,
  icon,
  label,
  offsetError,
  tooltip,
  id,
  ...props
}) => (
  <div>
    <CSSTransitionGroup
      transitionName="slowFadeWarning"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={500}
    >
      {meta.touched && meta.error && meta.submitFailed && (
        <AdvWarning offsetError={offsetError}>{meta.error}</AdvWarning>
      )}
    </CSSTransitionGroup>
    <div className="advInput">
      {tooltip && <AdvTooltip className="input-tooltip" tooltip={tooltip} />}
      {label && <label htmlFor={id}>{label}</label>}
      {icon}
      <input
        id={id}
        aria-label={label || 'input'}
        type={type}
        placeholder={placeholder}
        {...input}
        {...props}
      />
    </div>
  </div>
);

AdvInput.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object,
  offsetError: PropTypes.bool,
  icon: PropTypes.element,
  tooltip: PropTypes.string,
  id: PropTypes.string,
};

AdvInput.defaultProps = {
  type: 'text',
};

export default AdvInput;
