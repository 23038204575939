import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import AdvInput from 'components/molecules/AdvInput';
import Button from 'components/molecules/Button';
import {widgetsUpdateRequest} from 'store/actions';
import useModal from 'hooks/useModal';

const DCTAForm = ({textWidgets, modalName, dcta}) => {
  const dispatch = useDispatch();
  const [, modalHide] = useModal();

  const onSubmit = useCallback(
    (data) => {
      // Update the text widget data with the updated text from the form
      // This logic will have to be updated when multiple widget types are handled from the form
      const widgetData = textWidgets.map((widget) => ({
        ...widget,
        text: data[`text-widget-${widget.id}`],
      }));
      return dispatch(widgetsUpdateRequest(widgetData, dcta));
    },
    [dcta, dispatch, textWidgets],
  );

  return (
    <Form onSubmit={onSubmit}>
      {({handleSubmit, submitting, error, submitError, form}) => (
        <form
          className="dcta-form"
          onSubmit={(data) =>
            handleSubmit(data)?.then(() => {
              const {submitSucceeded} = form.getState();
              submitSucceeded && modalHide(modalName);
            })
          }
        >
          {textWidgets.map((widget) => (
            <div key={widget.id} className="widget-form-wrapper">
              <label className="widget-form-label" htmlFor={`text-widget-${widget.id}`}>
                {widget.name}
              </label>
              <Field
                name={`text-widget-${widget.id}`}
                placeholder="Enter Text"
                component={AdvInput}
              />
            </div>
          ))}
          {(error || submitError) && (
            <div className="help is-danger centered">{error || submitError}</div>
          )}
          <Button loading={!!submitting}>Update</Button>
        </form>
      )}
    </Form>
  );
};
DCTAForm.propTypes = {
  textWidgets: PropTypes.arrayOf(PropTypes.object),
  dcta: PropTypes.string,
  modalName: PropTypes.string,
  component: PropTypes.object,
};

export default DCTAForm;
