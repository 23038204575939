import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {isPending, hasFailed, isDone} from 'redux-saga-thunk';

import {shopifyConfirmRequest} from 'store/actions';
import BaseTemplate from 'components/components/BaseTemplate';
import LoadingBar from 'components/molecules/LoadingBar';

const postInstallInstructions = `
**1**. Navigate to your campaigin the sidebar\n
**2**. Click on the "Components" tab at the top\n
**3**. Click the "Add New Component" button on the top right\n
**4**. Select "Conversion Popup" from the list of component types. \n
**5**. Fill in the necessary fields. \n
**6**. For the image, you can use jpgs, pngs, gifs, or webm files.\n
**7**. Click submit! \n
`;

const ShopifyConfirmPage = () => {
  const thunk = 'shopifyConfirm';
  const loading = useSelector((state) => isPending(state, thunk));
  const failed = useSelector((state) => hasFailed(state, thunk));
  const done = useSelector((state) => isDone(state, thunk));

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(shopifyConfirmRequest(location.search));
  }, [location, dispatch]);

  return (
    <BaseTemplate>
      {loading && <LoadingBar message="Finishing App Installation" />}
      {failed && (
        <div className="shopify-confirm-page">
          <h2>
            Could Not install Shopify App. Please contact an{' '}
            <a href="mailto:admin@adv.gg">Advocate Admin.</a>
          </h2>
        </div>
      )}
      {done && (
        <div className="shopify-confirm-page">
          <div>
            <h2>Congrats! You have successfully installed your Shopify app</h2>
            <p>
              The next step is to install a on-conversion component. To do so, follow these steps
              below.
            </p>

            <div className="instructions">
              <ReactMarkdown source={postInstallInstructions} />
              <img src="/images/help-app-install.png" alt="Conversion Popup instructions" />
            </div>
          </div>
        </div>
      )}
    </BaseTemplate>
  );
};

export default ShopifyConfirmPage;
