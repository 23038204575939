import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {isPending} from 'redux-saga-thunk';
import {useParams} from 'react-router-dom';
import {isCampaignEnded} from 'utils/numberFormats';
import usePermissions from 'hooks/usePermissions';

import {
  modalShow as modalShowAction,
  modalHide,
  resourceBatchUpdateRequest,
  resourceListReadRequest,
} from 'store/actions';

import {sortByKey} from 'utils/general';
import {
  useCampaign,
  useCampaignLabels,
  useComponentTypes,
  useNextChatbotPosts,
  useBroadcasters,
  useComponents,
} from 'hooks';

const useCampaignComponents = () => {
  const {campaignSlug} = useParams();
  const dispatch = useDispatch();
  const campaign = useCampaign();
  const componentTypes = useComponentTypes();
  const labels = useCampaignLabels();
  const nextChatbotPosts = useNextChatbotPosts();
  const broadcasters = useBroadcasters();
  const components = useComponents();
  const userLoading = useSelector((state) => isPending(state, 'profileRequest'));
  const labelsLoading = useSelector((state) => isPending(state, 'labelsBatchUpdate'));
  const loading = useSelector((state) => isPending(state, 'componentsListRequest'));
  const {canEditCampaigns} = usePermissions();

  const modalShow = (name) => dispatch(modalShowAction(name));
  const modalClose = (name) => dispatch(modalHide(name));
  const submitUpdatedLabel = (newLabel) =>
    dispatch(resourceBatchUpdateRequest('labels', [newLabel]));

  useEffect(() => {
    dispatch(resourceListReadRequest('nextChatbotPosts', {campaign: campaignSlug}));
  }, [campaignSlug, dispatch]);

  const [multiselectMode, toggleMultiselectMode] = useState(false);
  const [showArchived, toggleShowArchived] = useState(false);
  const [editingLabel, updateEditingLabel] = useState({components: []});

  const componentTypesSorted = componentTypes
    .filter(
      (componentType) =>
        !componentType.is_hidden && campaign?.available_components?.includes(componentType.slug),
    )
    .sort(sortByKey('sort_order', false));

  const campaignComponents = components.filter((component) =>
    campaign ? component.campaign === campaign.slug : true,
  );
  const activeComponents = campaignComponents.filter(
    (component) => !(component.end_time ? Date.parse(component.end_time) < +new Date() : false),
  );
  const filteredComponents = showArchived ? campaignComponents : activeComponents;

  // Returns an array of componentTypes that are available for the campaign and
  // include at least one component
  const shownComponentTypes = componentTypesSorted.filter(
    (type) =>
      campaign?.available_components?.includes(type.slug) &&
      filteredComponents.some((component) => component.type === type.slug && !component.is_deleted),
  );

  const readOnly = !canEditCampaigns;
  const campaignIsEditable = !isCampaignEnded(campaign) && !readOnly;

  return {
    campaign,
    components,
    filteredComponents,
    nextChatbotPosts,
    labels,
    broadcasters,
    modalShow,
    modalClose,
    userLoading,
    readOnly,
    labelsLoading,
    submitUpdatedLabel,
    loading,
    campaignIsEditable,
    shownComponentTypes,
    componentTypesSorted,
    multiselectMode,
    toggleMultiselectMode,
    showArchived,
    toggleShowArchived,
    editingLabel,
    updateEditingLabel,
    activeComponents,
  };
};

export default useCampaignComponents;
