/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import {Route, Redirect, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {MANAGER, BROADCASTER} from 'store/profile/userTypes';

import {fromAuth, fromProfile} from 'store/selectors';

const UserRoute = ({DefaultComponent, BroadcasterComponent, ManagerComponent, ...rest}) => {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => fromAuth.isAuthenticated(state));
  const tosAgreed = useSelector((state) => fromProfile.tosAgreed(state));
  const userType = useSelector((state) => fromProfile.getUserType(state));

  return (
    <Route
      {...rest}
      render={() => {
        const Default = () => {
          if (DefaultComponent) return <DefaultComponent />;
          return <Redirect to={{pathname: '/dashboard', state: {from: location}}} />;
        };

        if (!isAuthenticated)
          return <Redirect to={{pathname: '/login', state: {from: location}}} />;
        if (!tosAgreed) return <Redirect to={{pathname: '/login/tos', state: {from: location}}} />;

        switch (userType) {
          case MANAGER:
            return ManagerComponent ? <ManagerComponent /> : <Default />;
          case BROADCASTER:
            return BroadcasterComponent ? <BroadcasterComponent /> : <Default />;
          default:
            return <Redirect to={{pathname: '/login', state: {from: location}}} />;
        }
      }}
    />
  );
};

UserRoute.propTypes = {
  BroadcasterComponent: PropTypes.func,
  DefaultComponent: PropTypes.func,
  ManagerComponent: PropTypes.func,
};

export default UserRoute;
