import PropTypes from 'prop-types';

import AdvWarning from 'components/components/AdvWarning';
import {CSSTransitionGroup} from 'react-transition-group';

const Select = ({children, input, meta, disabled, icon, noError}) => (
  <div className="adv-select-wrapper">
    <CSSTransitionGroup
      transitionName="slowFadeWarning"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={500}
    >
      {!noError && meta && meta.touched && meta.error && meta.submitFailed && (
        <AdvWarning>{meta.error}</AdvWarning>
      )}
    </CSSTransitionGroup>
    <div className={`adv-select ${disabled ? 'disabled' : ''}`}>
      {icon}
      <select disabled={disabled} {...input}>
        {children}
      </select>
    </div>
  </div>
);

Select.propTypes = {
  children: PropTypes.node.isRequired,
  input: PropTypes.object,
  icon: PropTypes.node,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  noError: PropTypes.bool,
};

export default Select;
