import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import LabelSelect from 'components/components/LabelSelect';
import {fromResources} from 'store/selectors';
import {resourceListReadRequest} from 'store/actions';
import {Twitter, Twitch, Youtube, Tag} from 'mdi-material-ui';

const formatTime = (timeString) => {
  const eventTime = new Date(timeString);
  const timeDifference = (Date.now() - Date.parse(timeString)) / 1000;

  if (timeDifference > 86400) {
    return `${eventTime.getMonth() + 1}/${eventTime.getDate()}/${eventTime.getFullYear()}`;
  }
  if (timeDifference > 7200) {
    return `${Math.floor(timeDifference / 3600)} hours ago`;
  }
  if (timeDifference > 3600) {
    return `${Math.floor(timeDifference / 3600)} hour ago`;
  }
  return `${Math.floor(timeDifference / 60)} minutes ago`;
};

const getMessage = (type, broadcaster) => {
  switch (type) {
    case 'BROADCAST_START':
      return (
        <p className="message">
          <b>{broadcaster}</b> went live on Twitch!
        </p>
      );
    case 'BROADCAST_END':
      return (
        <p className="message">
          <b>{broadcaster}</b> completed a broadcast
        </p>
      );
    case 'SENT_TWEET':
      return (
        <p className="message">
          <b>{broadcaster}</b> sent out a Tweet
        </p>
      );
    case 'SENT_RETWEET':
      return (
        <p className="message">
          <b>{broadcaster}</b> sent out a Retweet
        </p>
      );
    case 'VOD_PUBLISHED':
      return (
        <p className="message">
          <b>{broadcaster}</b> published a new video
        </p>
      );
    case 'CAMPAIGN_START':
      return <p className="message">Your campaign is started!</p>;
    default:
      return '';
  }
};

const getIcon = (type) => {
  switch (type) {
    case 'BROADCAST_START':
    case 'BROADCAST_END':
      return (
        <div className="icon twitch">
          <Twitch />
        </div>
      );
    case 'SENT_TWEET':
    case 'SENT_RETWEET':
      return (
        <div className="icon twitter">
          <Twitter />
        </div>
      );
    case 'VOD_PUBLISHED':
      return (
        <div className="icon youtube">
          <Youtube />
        </div>
      );
    default:
      return (
        <div className="icon">
          <Tag />
        </div>
      );
  }
};

const CampaignLiveQueue = () => {
  const dispatch = useDispatch();
  const {campaignSlug} = useParams();
  const [selectedFilter, setSelectedFilter] = useState('');

  const queue = useSelector((state) =>
    fromResources.getResourceListByCampaign(state, 'campaignEvents', campaignSlug),
  );

  queue.sort((a, b) => b.created.localeCompare(a.created));

  useEffect(() => {
    dispatch(
      resourceListReadRequest('campaignEvents', {
        campaign: campaignSlug,
        limit: 100,
        offset: 0,
      }),
    );
  }, [campaignSlug, dispatch]);

  return (
    <div className="campaign-live-queue">
      <div className="content">
        {!queue.length && (
          <h3 className="emptyText">No events have happened yet, check again later!</h3>
        )}
        {queue
          .filter((item) => (selectedFilter ? selectedFilter === item.event_type : true))
          .map(
            (item, index) =>
              index < 6 && (
                <div key={item.id} className="campaign-queue-item">
                  {getIcon(item.event_type)}
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.broadcaster ? (
                      getMessage(item.event_type, item.broadcaster)
                    ) : (
                      <p className="message">{item.message}</p>
                    )}
                  </a>
                  {item.thumbnail && <img src={item.thumbnail} alt="" />}
                  {item.created && <p className="time">{formatTime(item.created)}</p>}
                </div>
              ),
          )}
      </div>
      <div className="bottomOptions">
        <div className="title">Latest Activity</div>
        {queue.length > 0 && (
          <LabelSelect label="Show" handleChange={(value) => setSelectedFilter(value)}>
            <option value="">All</option>
            {queue.find((item) => item.event_type === 'BROADCAST_END') && (
              <option value="BROADCAST_END">Twitch Broadcasts</option>
            )}
            {queue.find(
              (item) => item.event_type === 'SENT_TWEET' || item.event_type === 'SENT_RETWEET',
            ) && <option value="SENT_TWEET">Sent Tweets</option>}
            {queue.find((item) => item.event_type === 'VOD_PUBLISHED') && (
              <option value="VOD_PUBLISHED">Published VODs</option>
            )}
          </LabelSelect>
        )}
      </div>
    </div>
  );
};

export default CampaignLiveQueue;
