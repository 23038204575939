import {useState} from 'react';
import PropTypes from 'prop-types';
import {Plus} from 'mdi-material-ui';

const FilterBar = ({children, invisible}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`filterBar ${open ? 'open' : ''} ${invisible ? 'invisible' : ''}`}>
      <span className="filters">{children}</span>
      <div className="labelWrapper">
        <div className="label" role="button" tabIndex="-1" onClick={() => setOpen(!open)}>
          <Plus />
          <h4>Filters</h4>
        </div>
      </div>
    </div>
  );
};

FilterBar.propTypes = {
  children: PropTypes.node.isRequired,
  invisible: PropTypes.bool,
};

export default FilterBar;
