import {put, call, takeEvery} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import {FORM_ERROR} from 'final-form';
import {kebabCase} from 'lodash';

import * as actions from './actions';
import * as schema from './schemas';

export function* createResource(api, {data}, {resource, thunk}) {
  try {
    const detail = yield call([api, api.post], `${kebabCase(resource)}/`, data);
    const normalizedDetail = normalize(detail, schema[resource]);
    yield put(actions.resourceCreateSuccess(resource, normalizedDetail.entities, thunk));
  } catch (e) {
    const formError = {
      [FORM_ERROR]: e.non_field_errors ? e.non_field_errors[0] : JSON.stringify(e),
    };
    yield put(actions.resourceCreateFailure(resource, formError, thunk));
  }
}

export function* readResourceList(api, {params}, {resource, thunk, path}) {
  try {
    const list = yield call([api, api.get], `${path || kebabCase(resource)}/`.replace(/\$/g, '/'), {
      params,
    });
    const normalizedList = normalize(
      list,
      Array.isArray(list) ? [schema[resource]] : schema[resource],
    );
    yield put(actions.resourceListReadSuccess(resource, normalizedList.entities, thunk));
  } catch (e) {
    yield put(actions.resourceListReadFailure(resource, e, thunk));
  }
}

export function* readResourceDetail(api, {needle, params}, {resource, thunk}) {
  try {
    const detail = yield call([api, api.get], `${kebabCase(resource)}/${needle}/`, {params});
    const normalizedDetail = normalize(detail, schema[resource]);
    yield put(actions.resourceDetailReadSuccess(resource, normalizedDetail.entities, thunk));
  } catch (e) {
    yield put(actions.resourceDetailReadFailure(resource, e, thunk));
  }
}

export function* batchUpdateResource(api, {data}, {resource, thunk}) {
  try {
    const list = yield call([api, api.put], `${kebabCase(resource)}/batch_update/`, data);
    const normalizedList = normalize(
      list,
      Array.isArray(list) ? [schema[resource]] : schema[resource],
    );
    yield put(actions.resourceBatchUpdateSuccess(resource, normalizedList.entities, thunk));
  } catch (e) {
    yield put(actions.resourceBatchUpdateFailure(resource, e, thunk));
  }
}

export function* updateResource(api, {needle, data}, {resource, thunk, partial}) {
  const method = partial ? 'patch' : 'put';

  try {
    const detail = yield call([api, api[method]], `${kebabCase(resource)}/${needle}/`, data);
    const normalizedDetail = normalize(detail, schema[resource]);
    yield put(actions.resourceUpdateSuccess(resource, normalizedDetail.entities, thunk));
  } catch (e) {
    const formError = {
      [FORM_ERROR]: e.non_field_errors ? e.non_field_errors[0] : JSON.stringify(e),
    };
    yield put(actions.resourceUpdateFailure(resource, formError, thunk));
  }
}

export function* deleteResource(api, {needle}, {resource, thunk}) {
  try {
    yield call([api, api.delete], `${kebabCase(resource)}/${needle}/`);
    yield put(actions.resourceDeleteSuccess(resource, needle, thunk));
  } catch (e) {
    const formError = {
      [FORM_ERROR]: e.non_field_errors ? e.non_field_errors[0] : JSON.stringify(e),
    };
    yield put(actions.resourceDeleteFailure(resource, formError, thunk));
  }
}

export function* watchResourceCreateRequest(api, {payload, meta}) {
  yield call(createResource, api, payload, meta);
}

export function* watchResourceListReadRequest(api, {payload, meta}) {
  yield call(readResourceList, api, payload, meta);
}

export function* watchResourceBatchUpdateRequest(api, {payload, meta}) {
  yield call(batchUpdateResource, api, payload, meta);
}

export function* watchResourceDetailReadRequest(api, {payload, meta}) {
  yield call(readResourceDetail, api, payload, meta);
}

export function* watchResourceUpdateRequest(api, {payload, meta}) {
  yield call(updateResource, api, payload, meta);
}

export function* watchResourceDeleteRequest(api, {payload, meta}) {
  yield call(deleteResource, api, payload, meta);
}

export default function* sagas({api}) {
  yield takeEvery(actions.RESOURCE_CREATE_REQUEST, watchResourceCreateRequest, api);
  yield takeEvery(actions.RESOURCE_LIST_READ_REQUEST, watchResourceListReadRequest, api);
  yield takeEvery(actions.RESOURCE_BATCH_UPDATE_REQUEST, watchResourceBatchUpdateRequest, api);
  yield takeEvery(actions.RESOURCE_DETAIL_READ_REQUEST, watchResourceDetailReadRequest, api);
  yield takeEvery(actions.RESOURCE_UPDATE_REQUEST, watchResourceUpdateRequest, api);
  yield takeEvery(actions.RESOURCE_DELETE_REQUEST, watchResourceDeleteRequest, api);
}
