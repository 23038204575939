import {put, call, takeEvery} from 'redux-saga/effects';
import {normalize} from 'normalizr';

import {campaigns} from 'store/resources/schemas';
import {resourceAdd, resourceDeleteSuccess} from 'store/resources/actions';
import {invitationResponseFailure, INVITATION_RESPONSE_REQUEST} from './actions';

export function* invitationResponse(api, {resource, needle, response}, {thunk}) {
  try {
    const resp = yield call([api, api.patch], `${resource}/${needle}/${response}/`);

    if (response === 'accept' && resource === 'campaigns') {
      const normalizedDetail = normalize(resp, campaigns);
      yield put(resourceAdd(normalizedDetail.entitites));
    }

    if (response === 'accept' && resource === 'teams') {
      const normalizedDetail = normalize(resp, [campaigns]);
      yield put(resourceAdd(normalizedDetail.entitites));
    }

    yield put(resourceDeleteSuccess(`${resource}Invitations`, needle, thunk));
  } catch (e) {
    yield put(invitationResponseFailure(e, thunk));
  }
}

export function* watchInvitationResponse(api, {payload, meta}) {
  yield call(invitationResponse, api, payload, meta);
}

export default function* sagas({api}) {
  yield takeEvery(INVITATION_RESPONSE_REQUEST, watchInvitationResponse, api);
}
