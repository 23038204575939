import PropTypes from 'prop-types';
import moment from 'moment';
import {range} from 'lodash';

const MonthRenderer = ({start, end, month, onMonthSelect, onYearSelect}) => {
  const startYear = start ? moment(start).year() : moment().year() - 1;
  const endYear = end ? moment(end).year() : moment().year() + 1;
  const years = range(startYear, endYear + 1);

  return (
    <div className="date-select-wrapper">
      <div className="adv-select-wrapper">
        <div className="adv-select">
          <select
            className="with-gap"
            value={month.month()}
            onChange={(e) => onMonthSelect(month, e.target.value)}
          >
            {moment.months().map((label, value) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="adv-select-wrapper">
        <div className="adv-select">
          <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

MonthRenderer.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  month: PropTypes.object,
  onMonthSelect: PropTypes.func,
  onYearSelect: PropTypes.func,
};

export default MonthRenderer;
