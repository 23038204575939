import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {isPending} from 'redux-saga-thunk';
import * as d3 from 'd3';

import LoadingBar from 'components/molecules/LoadingBar';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {sassVariables} from 'adv-theme';

// Takes raw click and viewership data and converts it to CVM by single Day, or
// cumulatively the runnign average CVM
const formatClickData = (clickData, views, timeKeyFormat, cumulative) => {
  const toHourKey = d3.timeFormat(timeKeyFormat);

  const formattedClicks = clickData;

  // Group data by time key
  const groupedData = d3
    .nest()
    .key((d) => {
      const time = new Date(d.time);
      return toHourKey(time);
    })
    .rollup((v) => v.length)
    .entries(formattedClicks)
    .sort((x, y) => d3.ascending(x.key, y.key));

  if (cumulative) {
    const clicksByTime = groupedData.reduce((acc, item) => {
      const dateArray = item.key.split('-');
      acc.push([
        Date.UTC(dateArray[0], dateArray[1] - 1, dateArray[2]),
        ((acc.length && acc[acc.length - 1][1]) || 0) + item.value,
      ]);
      return acc;
    }, []);
    const viewsByTime = views.reduce((acc, item) => {
      acc.push([
        item.day * 1000,
        ((acc.length && acc[acc.length - 1][1]) || 0) + item.avg_concurrents
          ? (item.avg_concurrents * 60 * 24) / 1000000
          : 0,
      ]);
      return acc;
    }, []);
    return viewsByTime.map((view, index) => [view[0], clicksByTime[index][1] / view[1]]);
  }

  const clicksByTime = groupedData
    .map((item) => {
      const dateArray = item.key.split('-');
      return [Date.UTC(dateArray[0], dateArray[1] - 1, dateArray[2]), item.value];
    })
    .reduce((acc, day) => ({...acc, [day[0]]: day[1]}), {});

  return views.map((view) => [
    view.day * 1000,
    view.avg_concurrents
      ? clicksByTime[view.day * 1000] || 0 / ((view.avg_concurrents * 60 * 24) / 1000000)
      : 0,
  ]);
};

const CVMOverTimeChart = ({timeKeyFormat, dateTimeFormatXAxis, options, title, clicks, views}) => {
  const loading = useSelector(
    (state) => isPending(state, 'clicksRawData') || isPending(state, 'viewsRawData'),
  );
  const chartData = useMemo(
    () => formatClickData(clicks, views, timeKeyFormat),
    [clicks, timeKeyFormat, views],
  );

  const defaultOptions = useMemo(
    () => ({
      chart: {
        type: 'spline',
        height: 250,
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: dateTimeFormatXAxis || {day: '%e. %b'},
        showInLegend: false,
        labels: {
          enabled: chartData.length !== 0,
        },
      },
      colors: [sassVariables.primary],
      title: {
        text: undefined,
      },
      yAxis: {
        title: {
          text: undefined,
        },
        minRange: 50,
        min: 0,
        gridLineColor: '000',
        alternateGridColor: '#f7f7ff',
        offset: 0,
      },
      tooltip: {
        pointFormat: '{point.y} CVM',
        shared: true,
        valueDecimals: 0,
        enabled: chartData.length !== 0,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        spline: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, sassVariables.primary],
              [1, '#ffffff'],
            ],
          },
          marker: {
            radius: 0,
            states: {
              hover: {
                enabled: chartData.length !== 0,
              },
            },
          },
          lineWidth: 2,
        },
        series: {
          connectNulls: true,
        },
      },
      series: [
        {
          data: chartData.length === 0 ? [[0, 0]] : chartData,
          showInLegend: false,
        },
      ],
    }),
    [chartData, dateTimeFormatXAxis],
  );

  return (
    <div className={`campaignMetricGraph ${loading ? 'loading' : ''}`}>
      <div className="topBar">
        <h2 className="title">{title}</h2>
      </div>
      {loading && <LoadingBar />}
      <div className="mainChart">
        <HighchartsReact highcharts={Highcharts} options={{...options, ...defaultOptions}} />
      </div>
    </div>
  );
};

CVMOverTimeChart.propTypes = {
  timeKeyFormat: PropTypes.string,
  dateTimeFormatXAxis: PropTypes.object,
  options: PropTypes.object,
  title: PropTypes.string,
  clicks: PropTypes.arrayOf(PropTypes.object),
  views: PropTypes.arrayOf(PropTypes.object),
};

export default CVMOverTimeChart;
