import {useState} from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Button from 'components/molecules/Button';
import ComponentPreview from 'components/organisms/ComponentPreview';
import {formatDate} from 'utils/numberFormats';
import AdvModal from 'containers/AdvModal';

const CampaignProposalModal = ({invite, subTabs, paymentData, acceptInvite, declineInvite}) => {
  const [selectedTab, setSelectedTab] = useState('Description');
  const [selectedComponentType, setSelectedComponentType] = useState(0);
  const options = ['Description'];
  if (invite.general_guidelines) options.push('Guidelines');
  if (invite.requirements) options.push('Requirements');
  if (invite.components.length > 0) options.push('Asset Previews');

  const componentsByType = invite.components.reduce((acc, component) => {
    if (!component.is_deleted) {
      acc[component.type.slug]
        ? acc[component.type.slug].push(component)
        : (acc[component.type.slug] = [component]);
    }
    return acc;
  }, {});

  return (
    <AdvModal
      name={`accept-invite-${invite.slug}`}
      contentLabel="acceptInvite"
      extraClass="campaignProposal"
    >
      <h3>You&apos;ve Been Invited To A Campaign!</h3>
      <h1>{invite.name}</h1>
      <div className="subNavbar">
        <div className="subNavbarItemWrapper">
          {options.map((tab) => (
            <div
              className={`subNavbarItem ${tab === selectedTab ? 'active' : ''}`}
              key={tab}
              role="button"
              tabIndex={0}
              onClick={() => setSelectedTab(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
        {selectedTab === 'Description' && (
          <div className="content">
            <p>
              <b>Start Date: </b>
              {formatDate(invite.start_date)}
            </p>
            <p>
              <b>End Date: </b>
              {invite.end_date ? formatDate(invite.end_date) : 'TBD'}
            </p>
            {/* disabled until payment business logic is decided */}
            {/* <p><b>Compensation: </b>{invite.payment_per_mvm !== '0.00' ? invite.payment_per_mvm : 'TBD'}</p> */}
            {invite.client && (
              <p>
                <b>Client: </b>
                {invite.client}
              </p>
            )}
            {invite.website && (
              <p>
                <b>Website: </b>
                {invite.website}
              </p>
            )}
            <ReactMarkdown source={invite.description} />
            {/* disabled until payment business logic is decided */}
            {/* {paymentData && invite.payment_per_mvm !== '0.00' && <p>Your average viewable minutes per month is <b>{formatNumber(paymentData.average_monthly_mvm)}</b> based on your last 3 months of streaming. This campaign will earn you an estimate of <b>{formatDollar(paymentData.average_monthly_mvm * invite.payment_per_mvm)}</b> per month from this campaign if you maintain this average.</p>} */}
          </div>
        )}
        {selectedTab === 'Guidelines' && (
          <div className="content">
            <b>Campaign Guidelines</b>
            <ReactMarkdown source={invite.general_guidelines} />
          </div>
        )}
        {selectedTab === 'Requirements' && (
          <div className="content">
            <b>Campaign Requirements</b>
            <ReactMarkdown source={invite.requirements} />
          </div>
        )}
        {selectedTab === 'Asset Previews' && invite.components.length > 0 && (
          <div className="content">
            <div className="subNavbar simple">
              <div className="subNavbarItemWrapper">
                {Object.keys(componentsByType).map((tab, index) => (
                  <div
                    className={`subNavbarItem ${index === selectedComponentType ? 'active' : ''}`}
                    key={tab}
                    role="button"
                    tabIndex={0}
                    onClick={() => setSelectedComponentType(index)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
              <div className="content">
                {componentsByType[Object.keys(componentsByType)[selectedComponentType]].map(
                  (component) => (
                    <ComponentPreview
                      component={component}
                      componentType={component.type}
                      key={component.id}
                      version={component.versions[0]}
                      showButtons={false}
                      chatbotUsername={invite.twitch_chatbot_account}
                    />
                  ),
                )}
              </div>
            </div>
          </div>
        )}
        {selectedTab !== 'Asset Previews' && (
          <Button handleClick={acceptInvite}>Accept Campaign</Button>
        )}
        {selectedTab !== 'Asset Previews' && (
          <Button handleClick={declineInvite} type="cancel">
            Decline Offer
          </Button>
        )}
      </div>
    </AdvModal>
  );
};

CampaignProposalModal.propTypes = {
  subTabs: PropTypes.arrayOf(PropTypes.string),
  acceptInvite: PropTypes.func.isRequired,
  declineInvite: PropTypes.func.isRequired,
  paymentData: PropTypes.object,
  invite: PropTypes.object,
};

export default CampaignProposalModal;
