import {useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {isPending} from 'redux-saga-thunk';
import {useParams} from 'react-router-dom';
import {setCookie} from 'redux-cookie';
import history from 'localHistory';

import {managementSetStatusRequest, resourceListReadRequest} from 'store/actions';
import {fromProfile, fromResources} from 'store/selectors';
import {useCampaign, useTeam, useSquads, useBroadcasters} from 'hooks';

const useCampaignManagement = () => {
  const {campaignSlug} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resourceListReadRequest('teams'));
  }, [campaignSlug, dispatch]);

  const campaign = useCampaign();
  const broadcasters = useSelector((state) => fromResources.getResourceList(state, 'broadcasters'));
  const campaignBroadcasters = useBroadcasters();
  const team = useTeam();
  const squads = useSquads();

  const teamBroadcasters = useMemo(
    () =>
      team
        ? broadcasters.filter(
            (broadcaster) =>
              broadcaster.team_statuses && broadcaster.team_statuses[team.id]?.status === 'Active',
          )
        : [],
    [broadcasters, team],
  );

  const userLoading = useSelector((state) => isPending(state, 'profileRequest'));
  const pending = useSelector((state) => state.thunk.pending || {});
  const isAdmin = useSelector((state) => fromProfile.isAdmin(state));

  const activateBroadcaster = (broadcaster) =>
    dispatch(managementSetStatusRequest('campaigns', 'activate', broadcaster, campaignSlug));
  const deactivateBroadcaster = (broadcaster) =>
    dispatch(managementSetStatusRequest('campaigns', 'deactivate', broadcaster, campaignSlug));

  const setChatbot = () => {
    dispatch(setCookie('setForCampaign', campaignSlug, {path: '/'}));
    history.push('/login/broadcaster');
  };

  return {
    campaign,
    campaignBroadcasters,
    teamBroadcasters,
    team,
    squads,
    userLoading,
    activateBroadcaster,
    deactivateBroadcaster,
    setChatbot,
    pending,
    isAdmin,
  };
};

export default useCampaignManagement;
