/* eslint-disable no-restricted-syntax, no-nested-ternary, prefer-promise-reject-errors */
// https://github.com/diegohaz/arc/wiki/API-service
import 'isomorphic-fetch';
import {stringify} from 'query-string';
import merge from 'lodash/merge';

export const parseJSON = (response) =>
  new Promise((resolve) =>
    response.json().then(
      (json) =>
        resolve({
          status: response.status,
          ok: response.ok,
          json,
        }),
      () =>
        resolve({
          status: response.status,
          ok: response.ok,
          json: {_error: response.statusText},
        }),
    ),
  );

const checkHasFile = (data) => {
  if (data) {
    for (const key of Object.keys(data)) {
      if (data[key] instanceof File) {
        return true;
      }
    }
  }

  return false;
};

export const getFormData = (value) => {
  if (value instanceof File) {
    return value;
  }

  if (value instanceof Object) {
    return JSON.stringify(value);
  }

  return value;
};

export const handleFileData = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, getFormData(data[key]));
  });

  return formData;
};

export const parseSettings = ({method = 'get', data, locale, ...otherSettings} = {}) => {
  // Data without files should be sent as JSON.  Data with files needs to be converted
  // to formData and sent without ContentType set
  const hasFile = checkHasFile(data);

  const headers = hasFile
    ? {'accept-language': locale}
    : {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'accept-language': locale,
      };

  const settings = merge(
    {
      body: data ? (hasFile ? handleFileData(data) : JSON.stringify(data)) : undefined,
      method: method.toUpperCase(),
      headers,
    },
    otherSettings,
  );
  return settings;
};

export const parseEndpoint = (endpoint, params) => {
  const url = endpoint.indexOf('http') === 0 ? endpoint : API_URL + endpoint;
  const querystring = params ? `?${stringify(params)}` : '';
  return `${url}${querystring}`;
};

const api = {};

// Possibly better way of handling server error messages:
// inspired by: https://github.com/github/fetch/issues/203
api.request = (endpoint, {params, ...settings} = {}) =>
  new Promise((resolve, reject) => {
    fetch(parseEndpoint(endpoint, params), parseSettings(settings))
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          return resolve(response.json);
        }

        if (response.json.non_field_errors) {
          response.json._error = response.json.non_field_errors;
        }

        return reject(response.json);
      })
      .catch(() =>
        reject({
          _error: ["Can't connect to the server. Please try again later"],
        }),
      );
  });
['delete', 'get'].forEach((method) => {
  api[method] = (endpoint, settings) => api.request(endpoint, {method, ...settings});
});
['post', 'put', 'patch'].forEach((method) => {
  api[method] = (endpoint, data, settings) => api.request(endpoint, {method, data, ...settings});
});

api.create = (settings = {}) => ({
  settings,

  setToken(token) {
    this.settings.headers = {
      ...this.settings.headers,
      Authorization: `Token ${token}`,
    };
  },

  unsetToken() {
    this.settings.headers = {
      ...this.settings.headers,
      Authorization: undefined,
    };
  },

  request(endpoint, settings) {
    return api.request(endpoint, merge({}, this.settings, settings));
  },

  post(endpoint, data, settings) {
    return this.request(endpoint, {method: 'post', data, ...settings});
  },

  get(endpoint, settings) {
    return this.request(endpoint, {method: 'get', ...settings});
  },

  put(endpoint, data, settings) {
    return this.request(endpoint, {method: 'put', data, ...settings});
  },

  patch(endpoint, data, settings) {
    return this.request(endpoint, {method: 'patch', data, ...settings});
  },

  delete(endpoint, settings) {
    return this.request(endpoint, {method: 'delete', ...settings});
  },
});

export default api;
