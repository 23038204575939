import PropTypes from 'prop-types';
import {AccountMultiplePlus, CheckDecagram} from 'mdi-material-ui';

import {formatNumber} from 'utils/numberFormats';
import IconButton from 'components/components/IconButton';
import AdvTooltip from 'components/components/AdvTooltip';
import DeleteButton from 'components/components/DeleteButton';
import ComparisonIndicator from 'components/components/ComparisonIndicator';
import ChannelPreviewTopBar from 'components/molecules/ChannelPreviewTopBar';
import AdvModal from 'containers/AdvModal';
import RemoveLabelModal from 'components/components/RemoveLabelModal';
import useChannelPreview from './hooks/useChannelPreview';
import AttachLabelForm from '../../../../forms/AttachLabelForm';
import FeaturedCampaignsList from '../FeaturedCampaignsList';

const ChannelPreview = ({
  broadcaster,
  stats,
  showButtons,
  hasConversions,
  comparisonStats,
  isActiveOnCampaign,
  campaignSlug,
}) => {
  const {modalShow, labels, featuredCampaigns, isFeatured} = useChannelPreview(broadcaster);
  const getTotalVisits = (stats) =>
    stats && stats.clicks_by_type
      ? stats.clicks_by_type.banner_graphic_clicks +
        stats.clicks_by_type.twitch_chatbot_clicks +
        stats.clicks_by_type.twitch_command_clicks +
        stats.clicks_by_type.twitter_clicks +
        stats.clicks_by_type.live_graphic_clicks +
        stats.clicks_by_type.custom_link_clicks
      : 0;

  const totalVisits = getTotalVisits(stats);
  const totalVisitsComparison = getTotalVisits(comparisonStats);

  const filteredLabels = labels.filter((label) =>
    label.broadcasters.includes(broadcaster.username),
  );

  const mainPlatform = broadcaster && broadcaster.platforms[0];

  const {[`${mainPlatform}_viewership`]: viewership = {}} = stats || {};
  const {[`${mainPlatform}_viewership`]: comparisonViewership = {}} = comparisonStats || {};

  const clicksPerVisits = viewership ? totalVisits / (viewership.viewable_minutes / 1000000) : 0;

  return (
    <div className="channelWrapper">
      <div className={`channelPreview ${mainPlatform}`}>
        <div className="backgroundImage" />
        <ChannelPreviewTopBar broadcaster={broadcaster} mainPlatform={mainPlatform}>
          {showButtons && featuredCampaigns.length > 0 && (
            <IconButton
              label="featured"
              tooltip="Featured Campaigns"
              handleClick={() => modalShow(`featured-campaigns-${broadcaster.username}`)}
            >
              <CheckDecagram />
            </IconButton>
          )}
          {showButtons && (
            <IconButton
              label="viewing"
              tooltip="Select groups"
              handleClick={() => modalShow(`new-label-${broadcaster.username}`)}
            >
              <AccountMultiplePlus />
            </IconButton>
          )}
        </ChannelPreviewTopBar>
        <div className="bannerSection">
          {!isActiveOnCampaign && <h4 className="liveText inactive">Inactive</h4>}
          {broadcaster.is_live && isActiveOnCampaign && (
            <h4 className="liveText greenPulse">LIVE</h4>
          )}
          {isFeatured && isActiveOnCampaign && <h4 className="featuredText pinkPulse">FEATURED</h4>}
        </div>
        <div className="contentSection">
          <div className="stats">
            <span>
              <p>Total Visits: </p>
              <b>{formatNumber(totalVisits)}</b>
              {comparisonStats ? (
                <ComparisonIndicator
                  newValue={totalVisits}
                  oldValue={totalVisitsComparison - totalVisits}
                  firstThreshold={0.2}
                  secondThreshold={0.4}
                />
              ) : (
                <span className="blankSpacing" />
              )}
            </span>
            {hasConversions && (
              <span>
                <p>Total Conversions: </p>
                <b>{stats && stats.conversions ? formatNumber(stats.conversions) : 0}</b>
                {comparisonStats ? (
                  <ComparisonIndicator
                    newValue={stats ? stats.conversions : 0}
                    oldValue={
                      stats && comparisonStats ? comparisonStats.conversions - stats.conversions : 0
                    }
                    firstThreshold={0.2}
                    secondThreshold={0.5}
                  />
                ) : (
                  <span className="blankSpacing" />
                )}
              </span>
            )}
            <span>
              <p>Twitch Viewable Minutes: </p>
              <b>{formatNumber(viewership.viewable_minutes || 0)}</b>
              {comparisonStats ? (
                <ComparisonIndicator
                  newValue={viewership.viewable_minutes}
                  oldValue={
                    comparisonStats
                      ? comparisonViewership.viewable_minutes - viewership.viewable_minutes
                      : 0
                  }
                  firstThreshold={0.2}
                  secondThreshold={0.5}
                />
              ) : (
                <span className="blankSpacing" />
              )}
            </span>
            <span>
              <AdvTooltip tooltip="Clicks per Million Viewed Minutes" />
              <p>CVM: </p>
              <b>{formatNumber(clicksPerVisits)}</b>
              <span className="blankSpacing" />
            </span>
          </div>
        </div>
      </div>
      {showButtons && (
        <div className="labelSection">
          {filteredLabels.map((label) => (
            <span className="labelItem" key={`label-${label.name}`}>
              <h4>
                {label.name}
                {showButtons && (
                  <DeleteButton
                    handleClick={() =>
                      modalShow(`broadcaster-${broadcaster.username}-${label.name}-remove`)
                    }
                  />
                )}
              </h4>
            </span>
          ))}
        </div>
      )}

      {/* Create Label Modal */}
      {showButtons && (
        <AdvModal
          name={`new-label-${broadcaster.username}`}
          contentLabel="Add this broadcaster to a group"
        >
          <AttachLabelForm
            broadcaster={broadcaster.username}
            title={broadcaster.username}
            labels={filteredLabels}
            campaignSlug={campaignSlug}
          />
        </AdvModal>
      )}

      {/* Label Delete Modals */}
      {showButtons &&
        filteredLabels.map((label) => (
          <RemoveLabelModal
            key={`remove-label-modal-${label.id}`}
            modalName={`broadcaster-${broadcaster.username}-${label.name}-remove`}
            label={label}
            removeFromField="broadcasters"
            removeVal={broadcaster.username}
            valueName={broadcaster.username}
          />
        ))}
      {/* Featured Campaigns Modal */}
      {showButtons && featuredCampaigns.length > 0 && (
        <AdvModal
          name={`featured-campaigns-${broadcaster.username}`}
          conentLabel="View broadcaster's featured campaigns"
        >
          <FeaturedCampaignsList broadcaster={broadcaster} featuredCampaigns={featuredCampaigns} />
        </AdvModal>
      )}
    </div>
  );
};

ChannelPreview.propTypes = {
  avatarUrl: PropTypes.string,
  broadcaster: PropTypes.object.isRequired,
  showButtons: PropTypes.bool,
  hasConversions: PropTypes.bool,
  isActiveOnCampaign: PropTypes.bool,
  isAdmin: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.object),
  countryStats: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  modalShow: PropTypes.func.isRequired,
  stats: PropTypes.object,
  comparisonStats: PropTypes.object,
  linkSource: PropTypes.shape({
    broadcaster_link_source: PropTypes.string,
    source_url: PropTypes.string,
  }),
  modalHide: PropTypes.func,
  campaignSlug: PropTypes.string,
  updateBroadcasterLinkSource: PropTypes.func,
};

ChannelPreview.defaultProps = {
  avatarUrl: '/images/NewSquareIconSmall.png',
};

export default ChannelPreview;
