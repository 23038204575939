import {useMemo, useEffect, useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {fromMetrics} from 'store/selectors';
import {metricsRawDataRequest} from 'store/actions';
import {sortByKey} from 'utils/general';
import camelCase from 'lodash/camelCase';

const useClickMetrics = () => {
  const dispatch = useDispatch();
  const {campaignSlug} = useParams();
  const [filteredClicks, setFilteredClicks] = useState([]);

  useEffect(() => {
    dispatch(metricsRawDataRequest('clicks', {campaign: campaignSlug}));
  }, [campaignSlug, dispatch]);

  const rawClicks = useSelector((state) => fromMetrics.getRaw(state, 'clicks'));

  useEffect(() => {
    setFilteredClicks(rawClicks);
  }, [rawClicks]);

  const applyClickFilters = useCallback(
    (filters) => {
      const {startDate, endDate, uniqueClicksOnly, selectedComponentTypes, selectedBroadcasters} =
        filters;
      let filteredClicks = rawClicks;

      if (uniqueClicksOnly) {
        const uniqueClicks = {};
        filteredClicks.reverse().forEach((click) => {
          uniqueClicks[`${click.ip_addr}-${click.component_id}-${click.broadcaster}`] = click;
        });
        filteredClicks = Object.values(uniqueClicks);
      }

      const visibleBroadcasters = selectedBroadcasters.map((b) => b.username);
      const visibleComponentTypes = selectedComponentTypes.map((c) => c.slug);

      // Only filter clicks by filters if it has been set (i.e. `visibleLabels` exists)
      const clickComparator = (dataPoint, filterSet, dataField) => {
        return filterSet ? filterSet.includes(dataPoint[dataField]) : true;
      };

      setFilteredClicks(
        filteredClicks.filter((click) => {
          return (
            click.time >= startDate &&
            click.time <= endDate &&
            clickComparator(click, visibleBroadcasters, 'broadcaster') &&
            clickComparator(click, visibleComponentTypes, 'component_type')
          );
        }),
      );
    },
    [rawClicks],
  );

  const clickComponentTotals = useMemo(() => {
    const clickDataAccumulator = (totals, click) => {
      const type = camelCase(click.component_type);
      if (totals[type]) {
        totals[type].value += 1;
      }
      return totals;
    };

    const clickDataInitialValue = {
      liveGraphics: {label: 'Live Graphic Visit', value: 0},
      bannerGraphics: {label: 'Banner Graphic Clicks', value: 0},
      twitchChatBot: {label: 'Chat Bot Clicks', value: 0},
      twitchChatBotCommand: {label: 'Chat Bot Command Clicks', value: 0},
      suggestedTweet: {label: 'Twitter Clicks', value: 0},
      customLink: {label: 'Custom Link Clicks', value: 0},
    };

    const clickDataByType = filteredClicks.reduce(clickDataAccumulator, clickDataInitialValue);
    const {liveGraphics, ...clickDataOptional} = clickDataByType;
    const clickTypesAsArray = Object.values(
      clickDataByType.liveGraphics.value > 0 ? clickDataByType : clickDataOptional,
    ).sort(sortByKey('label'));

    return [{label: 'Total Visits', value: filteredClicks.length}, ...clickTypesAsArray];
  }, [filteredClicks]);

  return {
    rawClicks,
    filteredClicks,
    clickComponentTotals,
    applyClickFilters,
  };
};

export default useClickMetrics;
