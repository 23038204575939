import {denormalize} from 'normalizr';
import {createSelector} from 'reselect';
import * as schemas from './schemas';

export const initialState = {};

export const getResource = (state = initialState, resource) =>
  state && state[resource] ? state[resource] : initialState;

export const getResourceDetail = (state = initialState, resource, id) =>
  getResource(state, resource)[id] || initialState;

export const getResourceList = (state = initialState, resource) =>
  Object.keys(getResource(state, resource))
    .map((id) => getResourceDetail(state, resource, id))
    .filter((item) => item);

export const getResourceSelector = (resourceName) =>
  createSelector(
    (state) => state.resources[resourceName],
    (resources = initialState) => Object.values(resources),
  );

export const getDenormalizedDetail = (state = initialState, resource, id) =>
  denormalize(getResourceDetail(state, resource, id), schemas[resource], state);

export const getDenormalizedList = (state = initialState, resource, ids) =>
  denormalize(ids || Object.keys(getResource(state, resource)), [schemas[resource]], state);

// should be unused
export const getResourceListByFilter = (state = initialState, resource, campaign) =>
  Object.values(getResource(state, resource)).filter((value) => value.campaign === campaign);

export const getResourceListByCampaign = (state = initialState, resource, campaign) =>
  Object.values(getResource(state, resource)).filter((value) => value.campaign === campaign);

export const getResourceListByDCTAId = (state = initialState, resource, campaign) =>
  Object.values(getResource(state, resource)).filter((value) => value.campaign === campaign);

export const getResourceListByTeam = (state = initialState, resource, team) =>
  Object.values(getResource(state, resource)).filter((value) => value.team === team);

export const getResourceListByBroadcaster = (state = initialState, resource, broadcaster) =>
  Object.values(getResource(state, resource)).filter((value) => value.broadcaster === broadcaster);

export const getBroadcastersByCampaign = (state = initialState, campaign) => {
  return Object.values(getResource(state, 'broadcasters')).filter((broadcaster) => {
    return broadcaster.campaign_statuses[campaign];
  });
};

// Check if the resource exists in store for loading purposes
export const resourceExists =
  (resource) =>
  (state = initialState) =>
    !!getResource(state, resource);
