import {Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxMarked,
  MinusBox,
  Twitter,
  PlaylistCheck,
  AccountCheck,
  AccountRemove,
  FormatListBulletedSquare,
  HelpBox,
} from 'mdi-material-ui';

import IconButton from 'components/components/IconButton';
import BroadcasterComplianceOverrideModal from '../BroadcasterComplianceOverrideModal';
import BroadcasterActionModal from './components/BroadcasterActionModal';
import ComplianceHistoryModal from './components/ComplianceHistoryModal';
import useBroadcasterCompliance from './hooks/useBroadcasterCompliance';
import {formatDate} from 'utils/numberFormats';

const getChatbotModText = (status) => {
  switch (status) {
    case 'Modded':
      return 'chatbot modded';
    case 'Not Modded':
      return 'chatbot not modded';
    case "Can't Connect":
    default:
      return 'cannot verify mod status';
  }
};

const getChatbotModIcon = (status) => {
  switch (status) {
    case 'Modded':
      return <CheckboxMarked />;
    case 'Not Modded':
      return <MinusBox />;
    case "Can't Connect":
    default:
      return <HelpBox />;
  }
};

const getChatbotModClass = (status) => {
  switch (status) {
    case 'Modded':
      return 'compliant';
    case 'Not Modded':
      return 'noncompliant';
    case "Can't Connect":
    default:
      return 'cannotConnect';
  }
};

const BroadcasterCompliance = ({
  broadcasterCompliance,
  hasChatbotModCompliance,
  getIconOrStatus,
  getHref,
  showButtons,
  modalShow,
  broadcasterActions,
  expandedDetails,
  components,
}) => {
  const {overallCompliance, profile_image, name, mod_status, componentsCompliance, actions} =
    broadcasterCompliance;

  const {sortedComplianceEntries, filteredBroadcasterActions} = useBroadcasterCompliance({
    componentsCompliance,
    broadcasterActions,
    complianceActions: actions,
    broadcaster: name,
  });

  return (
    <div className="broadcasterCompliance">
      <div className={`topBar ${overallCompliance}`}>
        <a href={`https://www.twitch.tv/${name}`} target="_blank" rel="noopener noreferrer">
          <img className="componentImage" src={profile_image} alt="" />
        </a>
        <h3>{name}</h3>
        <span className="overallCompliance">
          {overallCompliance === 'noncompliant' ? <MinusBox /> : <CheckboxMarked />}
          <h4>{overallCompliance === 'noncompliant' ? 'non-compliant' : overallCompliance}</h4>
        </span>
      </div>
      {expandedDetails && (
        <div className="complianceSection nonCompliantSection fullWidth">
          {hasChatbotModCompliance && mod_status && (
            <div className={`broadcasterItem ${getChatbotModClass(mod_status.status)} chatbotMod`}>
              <span className="inline">
                <h3>
                  <b>{mod_status.mod_channel}</b> {getChatbotModText(mod_status.status)}
                </h3>
              </span>
              {getChatbotModIcon(mod_status.status)}
            </div>
          )}
          {sortedComplianceEntries.map((action) => {
            const {
              type,
              compliant,
              component,
              name: componentName,
              last_updated,
              activeVersion,
              status,
            } = action;
            let overallStatus = compliant ? 'compliant' : 'incompliant';
            let icon = compliant ? <CheckboxMarked /> : <MinusBox />;
            if (type === 'banner-graphics') {
              overallStatus = getIconOrStatus(status);
              icon = getIconOrStatus(status, true);
            }
            return (
              <Fragment key={component}>
                <div className={`broadcasterItem ${overallStatus}`}>
                  <a
                    href={getHref(broadcasterCompliance, action)}
                    target="_blank"
                    className="twitter"
                    rel="noopener noreferrer"
                  >
                    {['suggested-tweet', 'suggested-retweet'].includes(type) ? (
                      <Twitter />
                    ) : (
                      <img src={activeVersion.image} alt="" />
                    )}
                  </a>
                  <span className="inline">
                    <h3>{componentName}</h3>
                    {last_updated && <h4>{formatDate(last_updated)}</h4>}
                  </span>
                  {showButtons && type === 'banner-graphics' && (
                    <IconButton
                      handleClick={() => modalShow(`override-compliance-${component}-${name}`)}
                      tooltip={`Mark As ${compliant ? 'Non-Compliant' : 'Compliant'}`}
                      label="twitch"
                    >
                      {compliant ? <AccountRemove /> : <AccountCheck />}
                    </IconButton>
                  )}
                  {icon}
                </div>
              </Fragment>
            );
          })}
        </div>
      )}

      {expandedDetails && (
        <div className="footerSection">
          {showButtons && filteredBroadcasterActions.length > 0 && (
            <IconButton
              handleClick={() => modalShow(`broadcaster-actions-${name}`)}
              text="Broadcaster Actions"
              label="twitch"
            >
              <PlaylistCheck />
            </IconButton>
          )}
          {Object.values(componentsCompliance).length > 0 && (
            <IconButton
              handleClick={() => modalShow(`compliance-history-${name}`)}
              text="Compliance History"
              label="compliance"
            >
              <FormatListBulletedSquare />
            </IconButton>
          )}
        </div>
      )}

      {/* Compliance History Modals */}
      {Object.values(componentsCompliance).length > 0 && (
        <ComplianceHistoryModal componentsCompliance={componentsCompliance} broadcaster={name} />
      )}

      {/* Broadcaster Action Modals */}
      {showButtons && filteredBroadcasterActions.length > 0 && (
        <BroadcasterActionModal
          actions={filteredBroadcasterActions}
          components={components}
          broadcaster={name}
        />
      )}

      {/* Override compliance score modal */}
      {showButtons &&
        sortedComplianceEntries.map((action) => (
          <BroadcasterComplianceOverrideModal
            complianceAction={action}
            broadcaster={name}
            key={action.component}
          />
        ))}
    </div>
  );
};

BroadcasterCompliance.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
  broadcasterActions: PropTypes.arrayOf(PropTypes.object),
  broadcasterCompliance: PropTypes.object,
  getHref: PropTypes.func,
  getIconOrStatus: PropTypes.func,
  showButtons: PropTypes.bool,
  modalShow: PropTypes.func.isRequired,
  expandedDetails: PropTypes.bool,
  hasChatbotModCompliance: PropTypes.bool,
};

export default BroadcasterCompliance;
