import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {CSSTransitionGroup} from 'react-transition-group';
import Select from 'react-select';

import AdvWarning from 'components/components/AdvWarning';

const SelectWithIcons = ({label, input, meta, items, disabled, ...rest}) => {
  const options = useMemo(() => {
    return items.map((item) => ({
      value: item.value,
      label: (
        <div>
          {item.icon} {item.label}
        </div>
      ),
    }));
  }, [items]);

  return (
    <div className="selectWithIconWrapper">
      <CSSTransitionGroup
        transitionName="slowFadeWarning"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {meta && meta.touched && meta.error && meta.submitFailed && (
          <AdvWarning>{meta.error}</AdvWarning>
        )}
      </CSSTransitionGroup>
      <div className="selectWithIcon">
        {label && <label className="selectWithIconLabel">{label}:</label>}
        <Select
          {...input}
          {...rest}
          options={options}
          disabled={disabled}
          classNamePrefix="selectWithIcon"
          className="selectWithIcon__container"
          onChange={(option) => input.onChange(option.value)}
          value={options ? options.find((option) => option.value === input.value) : ''}
        />
      </div>
    </div>
  );
};

SelectWithIcons.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default SelectWithIcons;
