import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  PencilOutline,
  EyeOutline,
  Close,
  PlusCircle,
  CircleEditOutline,
  UndoVariant,
} from 'mdi-material-ui';

import usePermissions from 'hooks/usePermissions';
import useUserAdmin from './hooks/useUserAdmin';
import useModal from 'hooks/useModal';

import BaseTemplate from 'components/components/BaseTemplate';
import DeleteButton from 'components/components/DeleteButton';
import Button from 'components/molecules/Button';
import ManagerInvitationForm from '../../forms/ManagerInvitationForm';
import ManagerForm from '../../forms/ManagerForm';
import DeleteModal from 'components/organisms/DeleteModal';
import ReactivateModal from './components/ReactivateUserModal';
import {AdvModal} from 'containers';

const getPermissionIcon = (permissionLevel) => {
  switch (permissionLevel) {
    case 0:
      return (
        <span className="readWrite">
          <PencilOutline />
        </span>
      );
    case 1:
      return (
        <span className="readOnly">
          <EyeOutline />
        </span>
      );
    case 2:
      return (
        <span className="noAccess">
          <Close />
        </span>
      );
    default:
      return '';
  }
};

const UserRow = ({
  email,
  editModalName,
  extraInfo,
  deleteModalName,
  permissionsCampaigns,
  permissionsTeams,
  permissionsUsers,
  teamIds,
  teamNames,
  canEditUsers,
}) => {
  const [modalShow] = useModal();

  return (
    <tr className="usersRow statRow">
      <td className="sideSpacer" />
      <td className="usersColumn textColumn">
        {email} {extraInfo && <span className="extraInfo">{extraInfo}</span>}
      </td>
      <td className="usersColumn permissionsColumn">{getPermissionIcon(permissionsCampaigns)}</td>
      <td className="usersColumn permissionsColumn">{getPermissionIcon(permissionsTeams)}</td>
      <td className="usersColumn permissionsColumn">{getPermissionIcon(permissionsUsers)}</td>
      <td className="usersColumn textColumn">
        {teamIds
          .filter((teamId) => teamNames[teamId])
          .map((teamId) => teamNames[teamId])
          .join(', ')}
      </td>
      {canEditUsers && (
        <td className="usersColumn">
          <div className="userButtons">
            <div
              className="userEditButton"
              tabIndex={-1}
              role="button"
              onClick={() => modalShow(editModalName)}
            >
              <CircleEditOutline />
            </div>
            <DeleteButton
              tabIndex={-1}
              extraClass="userRemoveButton"
              handleClick={() => modalShow(deleteModalName)}
            />
          </div>
        </td>
      )}
      <td className="sideSpacer" />
    </tr>
  );
};

UserRow.propTypes = {
  canEditUsers: PropTypes.bool.isRequired,
  deleteModalName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  editModalName: PropTypes.string.isRequired,
  extraInfo: PropTypes.string,
  permissionsCampaigns: PropTypes.number.isRequired,
  permissionsTeams: PropTypes.number.isRequired,
  permissionsUsers: PropTypes.number.isRequired,
  teamIds: PropTypes.arrayOf(PropTypes.number),
  teamNames: PropTypes.object.isRequired,
};

const UserAdmin = () => {
  const {canEditUsers} = usePermissions();
  const {currentManagers, inactiveManagers, managerInvitations, teams, loading, deleteManager} =
    useUserAdmin();
  const [modalShow, modalHide] = useModal();

  const teamNames = useMemo(
    () =>
      teams.reduce(
        (obj, team) => ({
          ...obj,
          [team.id]: team.name,
        }),
        {},
      ),
    [teams],
  );

  return (
    <BaseTemplate>
      <div className="navbar-bg" />
      <div className="preset_campaign-page-options">
        {canEditUsers && (
          <div>
            <Button type="hasIcon" handleClick={() => modalShow('manager-invite')}>
              <PlusCircle />
              Add New Manager
            </Button>
          </div>
        )}
      </div>

      <div className="userAdminContent">
        <div className="userTableTitle">
          <h1>Current Managers</h1>
        </div>

        <table className={loading ? 'usersTable is-loading' : 'usersTable'}>
          <thead className="usersHeaders">
            <tr>
              <th className="sideSpacer" />
              <th className="usersHeader textColumn">
                <h2>Email</h2>
              </th>
              <th className="usersHeader">
                <h2>Campaigns</h2>
              </th>
              <th className="usersHeader">
                <h2>Teams</h2>
              </th>
              <th className="usersHeader">
                <h2>Users</h2>
              </th>
              <th className="usersHeader textColumn">
                <h2>Teams</h2>
              </th>
              {canEditUsers && (
                <th className="usersHeader">
                  <h2> </h2>
                </th>
              )}
              <th className="sideSpacer" />
            </tr>
          </thead>
          <tbody className="userTableBody">
            <tr className="rowSpacer" />
            {managerInvitations &&
              managerInvitations
                .filter((invitation) => !invitation.user)
                .map((invitation) => (
                  <>
                    <UserRow
                      email={invitation.email}
                      extraInfo="Invitation Pending"
                      permissionsCampaigns={invitation.permissions_campaigns}
                      permissionsTeams={invitation.permissions_teams}
                      permissionsUsers={invitation.permissions_users}
                      teamIds={invitation.teams}
                      editModalName={`invitation-${invitation.id}-edit`}
                      deleteModalName={`invitation-${invitation.id}-delete`}
                      teamNames={teamNames}
                      key={`row-${invitation.id}`}
                      canEditUsers={canEditUsers}
                    />
                    <AdvModal
                      name={`invitation-${invitation.id}-edit`}
                      contentLabel="Edit Invitation"
                      key={`edit-${invitation.id}`}
                    >
                      <label>Edit {invitation.email}</label>
                      <ManagerInvitationForm
                        invitation={invitation}
                        onSubmitSuccess={() => modalHide(`invitation-${invitation.id}-edit`)}
                      />
                    </AdvModal>
                    <DeleteModal
                      modalName={`invitation-${invitation.id}-delete`}
                      resource="managerInvitations"
                      objectType="invited user"
                      objectName={invitation.email}
                      objectNeedle={invitation.id}
                      key={`delete-${invitation.id}`}
                    />
                  </>
                ))}
            {currentManagers &&
              currentManagers.map((manager) => (
                <>
                  <UserRow
                    email={manager.user.email}
                    permissionsCampaigns={manager.user.permissions_campaigns}
                    permissionsTeams={manager.user.permissions_teams}
                    permissionsUsers={manager.user.permissions_users}
                    teamIds={manager.teams}
                    editModalName={`manager-${manager.id}-edit`}
                    deleteModalName={`manager-${manager.id}-delete`}
                    teamNames={teamNames}
                    key={`row-${manager.id}`}
                    canEditUsers={canEditUsers}
                  />
                  <AdvModal
                    name={`manager-${manager.id}-edit`}
                    contentLabel="Edit manager"
                    key={`edit-${manager.id}`}
                  >
                    <label>Edit {manager.user.email}</label>
                    <ManagerForm
                      manager={manager}
                      onSubmitSuccess={() => modalHide(`manager-${manager.id}-edit`)}
                    />
                  </AdvModal>
                  <DeleteModal
                    modalName={`manager-${manager.id}-delete`}
                    resource="managers"
                    objectType="user"
                    objectName={manager.user.email}
                    objectNeedle={manager.id}
                    key={`delete-${manager.id}`}
                    onDelete={() => deleteManager(manager)}
                  />
                </>
              ))}
            <tr className="rowSpacer" />
          </tbody>
        </table>

        {inactiveManagers.length > 0 && (
          <>
            <div className="userTableTitle">
              <h1>Removed Managers</h1>
            </div>

            <table className={loading ? 'usersRemoveTable is-loading' : 'usersRemoveTable'}>
              <thead className="usersHeaders">
                <tr>
                  <th className="sideSpacer" />
                  <th className="usersHeader textColumn">
                    <h2>Email</h2>
                  </th>
                  {canEditUsers && (
                    <th className="usersHeader">
                      <h2> </h2>
                    </th>
                  )}
                  <th className="sideSpacer" />
                </tr>
              </thead>
              <tbody>
                <tr className="rowSpacer" />
                {inactiveManagers.map((manager) => (
                  <>
                    <tr key={manager.id}>
                      <td className="sideSpacer" />
                      <td className="usersColumn textColumn">{manager.user.email}</td>
                      {canEditUsers && (
                        <td className="usersColumn userRemoveButtons">
                          <div className="userButtons">
                            <div
                              className="userRemoveButton"
                              tabIndex={-1}
                              role="button"
                              onClick={() => modalShow(`reactivate-${manager.id}`)}
                            >
                              <UndoVariant />
                            </div>
                          </div>
                        </td>
                      )}
                      <td className="sideSpacer" />
                    </tr>
                    <ReactivateModal manager={manager} />
                  </>
                ))}
                <tr className="rowSpacer" />
              </tbody>
            </table>
          </>
        )}
      </div>

      <AdvModal name="manager-invite" contentLabel="Invite New User">
        <label>Invite New Manager</label>
        <ManagerInvitationForm onSubmitSuccess={() => modalHide('manager-invite')} />
      </AdvModal>
    </BaseTemplate>
  );
};

export default UserAdmin;
