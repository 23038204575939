import {useCallback} from 'react';
import {Form, Field} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {CSSTransitionGroup} from 'react-transition-group';
import {LockOutline, EmailOutline} from 'mdi-material-ui';

import LoginTemplate from 'components/components/LoginTemplate';
import LoginInput from 'components/views/Login/components/LoginInput';
import AdvocateLoginLogo from 'components/views/Login/components/AdvocateLoginLogo';
import Button from 'components/components/Button';
import {email, required, useValidator} from 'services/validation';
import {authSignUpRequest} from 'store/actions';

const SignUpPage = () => {
  const dispatch = useDispatch();

  const validate = useValidator({
    email: [required, email],
    password1: [required],
    password2: [required],
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        return await dispatch(authSignUpRequest({...data, username: data.email}));
      } catch (error) {
        return error;
      }
    },
    [dispatch],
  );

  return (
    <LoginTemplate>
      <div className="logoContainer">
        <AdvocateLoginLogo />
      </div>
      <h3 className="permissionDetails">Create an account to start managing your broadcasters!</h3>
      <Form onSubmit={onSubmit} validate={validate}>
        {({handleSubmit, submitting, submitError}) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="email"
              label="email"
              placeholder="Email"
              icon={<EmailOutline />}
              component={LoginInput}
            />
            <Field
              name="password1"
              label="password1"
              placeholder="Password"
              type="password"
              icon={<LockOutline />}
              component={LoginInput}
            />
            <Field
              name="password2"
              label="password2"
              placeholder="Confirm Password"
              type="password"
              icon={<LockOutline />}
              component={LoginInput}
            />
            <Button loading={!!submitting}>Sign Up</Button>
            <CSSTransitionGroup
              transitionName="slowFade"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}
            >
              {submitError && <div className="help is-danger centered">{submitError}</div>}
            </CSSTransitionGroup>
          </form>
        )}
      </Form>
    </LoginTemplate>
  );
};

export default SignUpPage;
