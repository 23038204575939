import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form} from 'react-final-form';

import AdvInput from 'components/molecules/AdvInput';
import Button from 'components/components/Button';
import Checkbox from 'components/components/Checkbox';
import {Email, AccountOutline} from 'mdi-material-ui';
import {CSSTransitionGroup} from 'react-transition-group';
import {profileUpdateRequest} from 'store/actions';
import {required, email, useValidator} from 'services/validation';
import {fromProfile} from 'store/selectors';

const ManagerProfileForm = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => fromProfile.getFullProfile(state));

  const validate = useValidator({
    username: [required],
    email: [required, email],
  });

  const onSubmit = useCallback((data) => dispatch(profileUpdateRequest(data)), [dispatch]);

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={profile}>
      {({handleSubmit, submitting, dirty, error}) => (
        <form onSubmit={handleSubmit} className="profileForm">
          <p>Username:</p>
          <Field
            name="username"
            placeholder="Enter Username"
            icon={<AccountOutline />}
            component={AdvInput}
          />
          <p>Email:</p>
          <Field name="email" placeholder="Enter Email" icon={<Email />} component={AdvInput} />
          <div className="labelCheckbox">
            <p>Send me updates about the Advocate platform</p>
            <Field name="email_receive_advocate_updates" component={Checkbox} />
          </div>
          <CSSTransitionGroup
            transitionName="fade"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {dirty && <Button loading={!!submitting}>Update Profile</Button>}
          </CSSTransitionGroup>
          {error && <div className="help is-danger centered">{error}</div>}
        </form>
      )}
    </Form>
  );
};

export default ManagerProfileForm;
