import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from 'react-final-form';
import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';
import AdvModal from 'containers/AdvModal';
import IconButton from 'components/components/IconButton';
import Button from 'components/molecules/Button';
import {Check, ArrowRightBold} from 'mdi-material-ui';

import {resourceUpdateRequest, browsersourceTestPatternRequest} from 'store/actions';
import {fromProfile} from 'store/selectors';

import useModal from 'hooks/useModal';

const browsersourceSetupHelp = (browsersourceLink) => `
**1**. Open your broadcasting software of choice (OBS or other)\n
**2**. For each of your scenes, add a new *Browser* source above all other sources\n
**3**. Select *Create New* and click *OK* \n
**4**. Enter the URL ${browsersourceLink}\n
**5**. Enter your base canvas resolution (For OBS, this is under settings -> video) for width and height to cover your entire stream window\n
**6**. Click *OK*\n`;

const advocateDetails = `
This is your broadcaster dashboard! If you're just getting started, you'll need to set up your Advocate browsersource in your broadcasting software of choice. Your Advocate Browsersource is your dynamic live-graphic layer for all your
on-stream assets. Then, as you participate in campaigns, you'll see broadcaster actions to be completed below, like setting up clickable panels. If you need any help setting up you can reach us on discord
`;

const browsersourceDetails = `
If you're using OBS Studio V25.0 or above, you can simply drag and drop a browsersource below into your OBS scene. Otherwise, proceed to the manual steps.
`;

const browsersourceDisclaimer = `
**Important**: Your browsersource needs to cover the full size of your stream preview
in order to work properly.`;

const DraggableBrowsersource = ({width, height, link}) => (
  <a
    href={`${link}?layer-name=Advocate%20Browsersource&amp;layer-width=${width}&amp;layer-height=${height}`}
    id="dragImage"
    className="browser_drag_item obsDrag"
    draggable="true"
  >{`${width} x ${height}`}</a>
);

const BrowsersourceBroadcasterAction = ({confirmModalName, action}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [modalShow, modalHide] = useModal();

  const browsersourceLink = useSelector((state) => fromProfile.getBrowsersource(state));

  const onSubmit = useCallback(async () => {
    try {
      await dispatch(
        resourceUpdateRequest('broadcasterActions', action.id, {
          completion_action: 'mark_complete',
        }),
      );
    } catch (error) {
      return error;
    }
  }, [action.id, dispatch]);

  const showConfirmModal = useCallback(() => {
    modalShow(confirmModalName);
    return dispatch(browsersourceTestPatternRequest());
  }, [confirmModalName, dispatch, modalShow]);

  return (
    <div className="broadcasterActionWrapper">
      <div className="broadcasterAction browsersource">
        {!page ? (
          <>
            <img className="overlayLogo" src="/images/welcome_logo.svg" alt="" />
            <img src="/images/Advocate_Broadcaster_Splash_longer.jpg" alt="" />
          </>
        ) : (
          <div className="topBar">
            <h2>Set up your advocate browsersource</h2>
          </div>
        )}
        <div className="details">
          <ReactMarkdown source={!page ? advocateDetails : browsersourceDetails} />
          {page > 0 && (
            <div className="dragOptions">
              <DraggableBrowsersource link={browsersourceLink} width={1920} height={1080} />
              <DraggableBrowsersource link={browsersourceLink} width={1600} height={900} />
              <DraggableBrowsersource link={browsersourceLink} width={1280} height={720} />
            </div>
          )}
          {page > 0 && <ReactMarkdown source={browsersourceDisclaimer} />}
        </div>
        {page > 0 && (
          <div className="instructions">
            <ReactMarkdown source={browsersourceSetupHelp(browsersourceLink)} />
            <img src="images/help-browsersource-setup.png" alt="" />
          </div>
        )}
      </div>
      <div className="mask">
        <div className="bottomBar browsersource">
          <h4>{!page ? 'Set up my browsersource' : 'Verify set up'}</h4>
          <IconButton
            label={!page ? 'next page' : 'help'}
            handleClick={!page ? () => setPage(1) : showConfirmModal}
          >
            {!page ? <ArrowRightBold /> : <Check />}
          </IconButton>
        </div>
      </div>
      <AdvModal name={confirmModalName} contentLabel="Browsersource Setup">
        <label htmlFor="Browsersource Setup">Browsersource Setup</label>
        <p>
          Did you see the following pattern in your broadcasting software&lsquo;s stream preview,
          including all pink borders?
        </p>
        <img src="images/browsersource-setup-preview.png" alt="" />
        <Button type="cancel" handleClick={() => modalHide(confirmModalName)}>
          No, Take Me Back
        </Button>
        <Form onSubmit={onSubmit}>
          {({handleSubmit, submitting, form}) => (
            <form
              onSubmit={(data) => {
                handleSubmit(data)?.then(() => {
                  const {submitSucceeded} = form.getState();
                  submitSucceeded && modalHide(confirmModalName);
                });
              }}
            >
              <Button type="submit" loading={submitting}>
                Yes, I&lsquo;m All Set!
              </Button>
            </form>
          )}
        </Form>
      </AdvModal>
    </div>
  );
};

BrowsersourceBroadcasterAction.propTypes = {
  username: PropTypes.string,
  confirmModalName: PropTypes.string,
  action: PropTypes.object,
};

DraggableBrowsersource.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  link: PropTypes.string,
};

export default BrowsersourceBroadcasterAction;
