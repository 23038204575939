import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import {isObject} from 'lodash';

import BroadcasterParticipationForm from './components/BroadcasterParticipationForm';
import AutoSuggest from 'components/components/AutoSuggest/AutoSuggest';
import {managementSetStatusRequest} from 'store/actions';
import {required, useValidator} from 'services/validation';

const TeamAddBroadcasterForm = (props) => {
  const {team, extraAutoSuggestParams} = props;
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (data) => {
      try {
        await dispatch(
          managementSetStatusRequest(
            // data is a  broadcaster object if selected from the autosuggest dropdown
            // otherwise data is a broadcaster username from the text input
            'teams',
            'invite',
            isObject(data.broadcaster) ? data.broadcaster.username : data.broadcaster,
            team.id,
          ),
        );
      } catch (e) {
        return {[FORM_ERROR]: e.errors.broadcaster[0]};
      }
    },
    [dispatch, team.id],
  );

  const validate = useValidator({
    broadcaster: [required],
  });

  const componentProps = {
    searchUrl: 'broadcasters/search/',
    searchParam: 'username',
    extraParams: extraAutoSuggestParams,
  };

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, error, submitting}) => (
        <BroadcasterParticipationForm
          handleSubmit={handleSubmit}
          error={error}
          submitting={submitting}
          component={AutoSuggest}
          componentProps={componentProps}
          {...props}
        />
      )}
    </Form>
  );
};

TeamAddBroadcasterForm.propTypes = {
  team: PropTypes.object,
  extraAutoSuggestParams: PropTypes.object,
};

export default TeamAddBroadcasterForm;
