import {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ChevronRight, ChevronLeft, FileUpload} from 'mdi-material-ui';
import {CSSTransitionGroup} from 'react-transition-group';
import Draggable from 'react-draggable';
import Slider from 'rc-slider';
import unionBy from 'lodash/unionBy';
import {fromProfile, fromBrowsersource} from 'store/selectors';
import {isPending, hasFailed} from 'redux-saga-thunk';
import {browsersourcePositioningSaveRequest, profileUpdateRequest} from 'store/actions';

import EmptySplash from 'components/molecules/EmptySplash';
import Button from 'components/molecules/Button';
import HelpModal from 'components/molecules/HelpModal';
import BaseTemplate from 'components/components/BaseTemplate';
import {getImageNameFromUrl} from 'utils/campaignTools';

import useStreamPreview from './hooks/useStreamPreview';
import useRenderPreview from './hooks/useRenderPreview';
import useStreamHandlers from './hooks/useStreamHandlers';
import useStreamPreviewSidebar from './hooks/useStreamPreviewSidebar';

const BroadcasterStreamPreview = () => {
  const dispatch = useDispatch();
  const [selectedOutputResolutionWidth, setSelectedOutputResolutionWidth] = useState(null);
  const [selectedOutputResolutionHeight, setSelectedOutputResolutionHeight] = useState(null);
  const [scalingFactor, setScalingFactor] = useState(1);
  const [hasCollisions, setHasCollisions] = useState(false);
  const [selectedImageIndexes, setSelectedImageIndexes] = useState({});
  const [touchedRotations, setTouchedRotations] = useState({});
  const [originalAssetDimensions, setOriginalAssetDimensions] = useState({});
  const [activeDrag, setActiveDrag] = useState(null);

  const saving = useSelector((state) => isPending(state, 'browsersourcePositioningSave'));
  const saveFailure = useSelector((state) => hasFailed(state, 'browsersourcePositioningSave'));
  const initialRotations = useSelector((state) => fromBrowsersource.getRotations(state));
  const profile = useSelector((state) => fromProfile.getFullProfile(state));

  useStreamPreview({
    setScalingFactor,
    selectedOutputResolutionWidth,
    setSelectedImageIndexes,
    setSelectedOutputResolutionHeight,
    setSelectedOutputResolutionWidth,
  });
  const {handleDrag, handleScaleChange, handleStop} = useStreamHandlers({
    activeDrag,
    setHasCollisions,
    setActiveDrag,
    touchedRotations,
    setTouchedRotations,
    initialRotations,
  });
  const {makeImage} = useRenderPreview({
    originalAssetDimensions,
    setOriginalAssetDimensions,
    scalingFactor,
  });
  const {resetPositioning, handleImageUpdate, decrementImageIndex, incrementImageIndex} =
    useStreamPreviewSidebar({
      setHasCollisions,
      setTouchedRotations,
    });

  const activeRotations = unionBy(
    Object.values(touchedRotations),
    initialRotations,
    (rotation) => rotation.id,
  );

  // Check if any corners of live-graphics on display are rendered over any other image
  const checkCollision = useCallback(() => {
    const streamPreviewWidth = scalingFactor * selectedOutputResolutionWidth;
    const streamPreviewHeight = -1 * scalingFactor * selectedOutputResolutionHeight;
    let collisions = false;

    const imageCorners = activeRotations.map((rotation) => {
      const currentIndex = selectedImageIndexes[rotation.id] || 0;
      const image = rotation.images[currentIndex];
      const imageId = `${rotation.id}-${image.version_id}`;
      return {
        id: rotation.id,
        startHeight: rotation.yPos * scalingFactor,
        endHeight:
          rotation.yPos * scalingFactor -
          originalAssetDimensions[imageId].height * scalingFactor * (rotation.scale || 1),
        startWidth: rotation.xPos * scalingFactor,
        endWidth:
          rotation.xPos * scalingFactor +
          originalAssetDimensions[imageId].width * scalingFactor * (rotation.scale || 1),
      };
    });

    imageCorners.forEach((baseImage) => {
      imageCorners.forEach((comparisonImage) => {
        const {
          startWidth: startWidth1,
          endWidth: endWidth1,
          startHeight: startHeight1,
          endHeight: endHeight1,
        } = baseImage;

        const {
          startWidth: startWidth2,
          endWidth: endWidth2,
          startHeight: startHeight2,
          endHeight: endHeight2,
        } = comparisonImage;

        if (baseImage.id !== comparisonImage.id) {
          if (endWidth1 > streamPreviewWidth + 20 || endHeight1 < streamPreviewHeight - 20) {
            collisions = [streamPreviewWidth, streamPreviewHeight];
          } else {
            if (startWidth1 <= endWidth2 && startWidth1 >= startWidth2) {
              if (startHeight1 >= endHeight2 && startHeight1 <= startHeight2) {
                collisions = [startWidth1, startHeight1, 'corner1'];
              }
            }
            if (endWidth1 <= endWidth2 && endWidth1 >= startWidth2) {
              if (startHeight1 >= endHeight2 && startHeight1 <= startHeight2) {
                collisions = [endWidth1, startHeight1, 'corner2'];
              }
            }
            if (startWidth1 <= endWidth2 && startWidth1 >= startWidth2) {
              if (endHeight1 >= endHeight2 && endHeight1 <= startHeight2) {
                collisions = [startWidth1, endHeight1, 'corner3'];
              }
            }
            if (endWidth1 <= endWidth2 && endWidth1 >= startWidth2) {
              if (endHeight1 >= endHeight2 && endHeight1 <= startHeight2) {
                collisions = [endWidth1, endHeight1, 'corner4'];
              }
            }
          }
        }
        return false;
      });
    });
    return collisions;
  }, [
    activeRotations,
    originalAssetDimensions,
    scalingFactor,
    selectedImageIndexes,
    selectedOutputResolutionHeight,
    selectedOutputResolutionWidth,
  ]);

  const saveAllPositioning = useCallback(
    (touchedRotations) => {
      const check = checkCollision();
      const dataToSave = Object.values(touchedRotations).map((rotation) => ({
        xPos: rotation.xPos,
        yPos: rotation.yPos,
        scale: rotation.scale,
        id: rotation.id,
      }));

      !check &&
        !touchedRotations.length &&
        dispatch(browsersourcePositioningSaveRequest(dataToSave)).then(() =>
          setTouchedRotations({}),
        );
      setHasCollisions(check);
    },
    [checkCollision, dispatch],
  );

  const bannerUpdateHelp =
    'Move your graphics around as needed, and when you&apos;re all set, save your positioning to update your browsersource.\n\n You can upload a screenshot of your stream to better position your graphics. This will not affect your browsersource.\n\n Select your appropriate resolution. It should match your base canvas resolution. (In OBS, this is under settings/video)\n\n';

  return (
    <BaseTemplate>
      <div className="streamPreviewPage">
        <div className="warningDesktop">
          <EmptySplash>
            <h4>You need to be on a desktop to edit your stream</h4>
          </EmptySplash>
        </div>
        <div className="rotationSidebar">
          <h4>Select your base stream resolution</h4>
          <select
            value={`${selectedOutputResolutionWidth}x${selectedOutputResolutionHeight}`}
            onChange={(e) => {
              const newScalingFactor =
                document.getElementById('browsersource-image').offsetWidth /
                e.target.value.split('x')[0];
              setSelectedOutputResolutionWidth(e.target.value.split('x')[0]);
              setSelectedOutputResolutionHeight(e.target.value.split('x')[1]);
              setScalingFactor(newScalingFactor);
              // Update real positions held in to what they would be in the new scaling factor.
              setTouchedRotations(
                activeRotations
                  .map((rotation) => ({
                    ...rotation,
                    xPos: rotation.xPos / (newScalingFactor / scalingFactor),
                    yPos: rotation.yPos / (newScalingFactor / scalingFactor),
                  }))
                  .reduce((acc, val) => {
                    return {
                      ...acc,
                      [val.id]: val,
                    };
                  }, {}),
              );
              dispatch(
                profileUpdateRequest({
                  ...profile,
                  profile: {
                    ...profile.profile,
                    base_canvas_resolution: e.target.value,
                  },
                }),
              );
            }}
          >
            <option value="1920x1080">1920x1080</option>
            <option value="1600x900">1600x900</option>
            <option value="1440x810">1440x810</option>
            <option value="1366x768">1360x768</option>
            <option value="1280x720">1280x720</option>
          </select>
          <h4>(optional) Upload a screenshot preview</h4>
          <div className="backgroundUpload">
            <input
              onChange={handleImageUpdate}
              accept="image/gif,image/jpeg,image/jpg,image/png"
              value=""
              type="file"
              name="file"
              id="file"
            />
            <Button>
              <label htmlFor="file">
                <FileUpload />
              </label>
            </Button>
            <h4>
              {(profile.profile &&
                profile.profile.stream_preview_screenshot &&
                getImageNameFromUrl(profile.profile.stream_preview_screenshot)) ||
                'No file chosen'}
            </h4>
          </div>
          {activeRotations
            .sort((a, b) => (b.id > a.id ? -1 : 0))
            .map((rotation) => {
              const rotationIndex = selectedImageIndexes[rotation.id] || 0;
              return (
                <div className="rotationOptions" key={rotation.id}>
                  <div className="rotationOptionsName">
                    <h4>{`${rotation.campaigns[0] || 'Default'} rotation`}</h4>
                  </div>
                  {rotation.images.length > 1 && (
                    <div>
                      <Button
                        handleClick={() =>
                          setSelectedImageIndexes(
                            decrementImageIndex(selectedImageIndexes, rotation),
                          )
                        }
                      >
                        <ChevronLeft />
                      </Button>
                      <Button
                        handleClick={() =>
                          setSelectedImageIndexes(
                            incrementImageIndex(selectedImageIndexes, rotation),
                          )
                        }
                      >
                        <ChevronRight />
                      </Button>
                    </div>
                  )}
                  <Slider
                    min={25}
                    max={100}
                    value={rotation.scale * 100}
                    onChange={(val) =>
                      handleScaleChange(val, rotation, selectedImageIndexes, scalingFactor)
                    }
                  />
                  <div className="carouselDots">
                    <div className="rotationComponentName">
                      <h4>Image {rotationIndex + 1}</h4>
                    </div>
                    <span />
                    {rotation.images.length > 1 &&
                      rotation.images.map((image, imageIndex) => (
                        <div
                          key={image.version_id}
                          className={rotationIndex === imageIndex ? 'dot selected' : 'dot'}
                        />
                      ))}
                  </div>
                </div>
              );
            })}
          <div className="saveDetails">
            <CSSTransitionGroup
              transitionName="slowFadeWarning"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}
            >
              {Object.values(touchedRotations).map((rotation) => (
                <p key={rotation.id}>{rotation.campaigns[0] || 'Default Rotation'}</p>
              ))}
            </CSSTransitionGroup>
            {Object.values(touchedRotations).length > 0 && <h4>Unsaved Changes:</h4>}
          </div>
          <div className="saveButtonSection">
            <Button handleClick={resetPositioning}>Reset Positioning</Button>
            <Button loading={saving} handleClick={() => saveAllPositioning(touchedRotations)}>
              Save Positioning
            </Button>
          </div>
        </div>
        <div className="streamPreviewWrapper">
          <span>
            <p>How to use</p>
            <HelpModal
              modalName="stream-preview-help"
              contentLabel="How to use the stream preview"
              content={bannerUpdateHelp}
              videosrc="https://storage.cloud.google.com/platform-assets-prod/videos/stream-preview-instructions.webm?authuser=1&folder=true"
            />
          </span>
          <div
            className="streamPreview"
            style={{
              backgroundImage: `url(${profile.profile.stream_preview_screenshot})`,
            }}
          >
            <div id="browsersource-image">
              {activeRotations.map((rotation) => {
                const rotationIndex = selectedImageIndexes[rotation.id] || 0;
                const image = rotation.images[rotationIndex];
                return (
                  <Draggable
                    position={{x: rotation.xPos * scalingFactor, y: rotation.yPos * scalingFactor}}
                    onDrag={(e, _ui) => handleDrag(e, rotation, rotationIndex)}
                    onStop={(e, _ui) => handleStop(e, rotation, rotationIndex, scalingFactor)}
                    key={image ? image.version_id : rotation.name}
                    bounds="parent"
                  >
                    <div>{image && makeImage(image, rotation)}</div>
                  </Draggable>
                );
              })}
              <div
                className={`collisionWarningIndicator ${hasCollisions[2]}`}
                style={{
                  opacity: hasCollisions.length,
                  position: 'absolute',
                  transform: `translate(${hasCollisions[0]}px, ${hasCollisions[1]}px)`,
                }}
              />
            </div>
            <CSSTransitionGroup
              transitionName="slowFadeWarning"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}
            >
              {hasCollisions && (
                <h4 className="errorMessage">
                  Woops! Looks like some of your graphics are overlapping. You must not have
                  overlapping graphics to save your positioning.
                </h4>
              )}
              {!hasCollisions && saveFailure && (
                <h4 className="errorMessage">Unable to save this stream layout.</h4>
              )}
            </CSSTransitionGroup>
          </div>
        </div>
      </div>
    </BaseTemplate>
  );
};

export default BroadcasterStreamPreview;
