import {useEffect, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {isPending} from 'redux-saga-thunk';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {fromMetrics, fromResources} from 'store/selectors';
import {metricsRawDataRequest, resourceListReadRequest} from 'store/actions';
import {useCampaign, useTeams, useBroadcasters} from 'hooks';
import usePermissions from 'hooks/usePermissions';

const useCampaignOverview = () => {
  const dispatch = useDispatch();
  const {campaignSlug} = useParams();
  const [selectedChartView, setSelectedChartView] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState('all');
  const {canEditCampaigns} = usePermissions();

  const broadcasters = useBroadcasters();
  const campaign = useCampaign();
  const teams = useTeams();
  const globalChatbotStatus = useSelector((state) =>
    fromResources.getResource(state, 'chatbotStatus'),
  );
  const chatbotStatus =
    campaign.twitch_chatbot_connection !== 'up'
      ? campaign.twitch_chatbot_connection
      : globalChatbotStatus.status;

  useEffect(() => {
    dispatch(resourceListReadRequest('chatbotStatus'));
    dispatch(metricsRawDataRequest('clicks', {campaign: campaignSlug}));
    dispatch(
      metricsRawDataRequest('rollup-twitch-viewership', {
        campaign: campaignSlug,
        time_period: 'day',
      }),
    );
  }, [campaignSlug, dispatch]);

  const userLoading = useSelector(
    (state) =>
      isPending(state, 'profileRequest') ||
      isPending(state, 'componentsListRead') ||
      isPending(state, 'componentTypesListRead') ||
      isPending(state, 'campaignsDetailRead') ||
      isPending(state, 'broadcastersListRead'),
  );

  const activeBroadcasters = useMemo(() => {
    return broadcasters?.filter((broadcaster) =>
      broadcaster?.campaign_statuses[campaignSlug]
        ? ['Active'].includes(broadcaster.campaign_statuses[campaignSlug].status)
        : false,
    );
  }, [broadcasters, campaignSlug]);

  const activeOrEndedBroadcasters = useMemo(() => {
    return broadcasters?.filter((broadcaster) =>
      broadcaster?.campaign_statuses[campaignSlug]
        ? ['Active', 'Campaign Ended'].includes(broadcaster.campaign_statuses[campaignSlug].status)
        : false,
    );
  }, [broadcasters, campaignSlug]);

  const rawClicks = useSelector((state) => fromMetrics.getRaw(state, 'clicks'));
  const rawViewership = useSelector((state) =>
    fromMetrics.getRaw(state, 'rollup-twitch-viewership'),
  );

  useEffect(() => {
    campaign?.team && setSelectedDateRange('30');
  }, [campaign?.team]);

  const overviewMetrics = useMemo(
    () =>
      (campaign?.overview_metrics_set && campaign.overview_metrics_set[selectedDateRange]) || {},
    [campaign, selectedDateRange],
  );
  const overviewComparisonMetrics = useMemo(
    () => (campaign?.overview_metrics_set && campaign.overview_metrics_set['60']) || {},
    [campaign],
  );

  const dataStartDate = useMemo(
    () => moment().subtract(selectedDateRange, 'days'),
    [selectedDateRange],
  );

  const availableCharts = useMemo(
    () => ({
      clicks:
        campaign && campaign?.team && selectedDateRange.toString() !== 'all'
          ? rawClicks.filter((click) => Date.parse(click.time) > dataStartDate).length > 1
          : rawClicks.length > 1,
      viewership:
        campaign &&
        Array.isArray(rawViewership) &&
        campaign.team &&
        selectedDateRange.toString() !== 'all'
          ? rawViewership.filter((slice) => slice.day * 1000 > Date.parse(dataStartDate)).length > 1
          : rawViewership.length > 1,
    }),
    [campaign, dataStartDate, rawClicks, rawViewership, selectedDateRange],
  );

  const filteredClicks = useMemo(
    () =>
      campaign?.team && selectedDateRange !== 'all'
        ? rawClicks.filter((click) => Date.parse(click.time) > dataStartDate)
        : rawClicks,
    [campaign?.team, dataStartDate, rawClicks, selectedDateRange],
  );

  const filteredViewership = useMemo(
    () =>
      campaign?.team && selectedDateRange !== 'all'
        ? rawViewership.filter((slice) => slice.day > dataStartDate.unix())
        : rawViewership,
    [campaign?.team, dataStartDate, rawViewership, selectedDateRange],
  );

  return {
    campaign,
    activeBroadcasters,
    teams,
    filteredClicks,
    filteredViewership,
    readOnly: !canEditCampaigns,
    userLoading,
    availableCharts,
    selectedDateRange,
    setSelectedChartView,
    selectedChartView,
    setSelectedDateRange,
    overviewMetrics,
    overviewComparisonMetrics,
    activeOrEndedBroadcasters,
    chatbotStatus,
  };
};

export default useCampaignOverview;
