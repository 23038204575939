import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Field, Form} from 'react-final-form';

import Button from 'components/molecules/Button';
import AdvInput from 'components/molecules/AdvInput';
import BoxSelect from './components/BoxSelect';
import {
  LaptopChromebook,
  CellphoneIphone,
  GamepadVariant,
  Fire,
  TelevisionClassic,
  Teach,
  AccountBox,
  Domain,
  CalendarMonth,
} from 'mdi-material-ui';
import {profileUpdateRequest} from 'store/actions';
import {required, useValidator, minSelection, isValidDateFormat} from 'services/validation';

const platformsAvailable = [
  {text: 'PC', value: 'PC', icon: <LaptopChromebook />},
  {text: 'Mobile', value: 'MO', icon: <CellphoneIphone />},
  {text: 'Console', value: 'CO', icon: <GamepadVariant />},
];

const streamTypes = [
  {text: 'Competitive', value: 'CO', icon: <Fire />},
  {text: 'Entertaining', value: 'EN', icon: <TelevisionClassic />},
  {text: 'Educational', value: 'ED', icon: <Teach />},
];

const BroadcasterApplicationForm = ({profile}) => {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (data) => {
      dispatch(
        profileUpdateRequest({
          profile: {
            ...data,
            application_date_of_birth: new Date(data.application_date_of_birth).toISOString(),
          },
          username: profile.username,
          email: profile.email,
        }),
      );
    },
    [dispatch, profile.email, profile.username],
  );

  const validate = useValidator({
    application_full_name: [required],
    application_date_of_birth: [required, isValidDateFormat],
    application_streams_on_which_platforms: [required, minSelection(1)],
    application_stream_content_type: [required, minSelection(1)],
  });

  return (
    <Form validate={validate} onSubmit={onSubmit}>
      {({handleSubmit, submitting, submitSucceeded}) => (
        <form onSubmit={handleSubmit} className="profileForm">
          <p>Full Name:</p>
          <Field
            name="application_full_name"
            placeholder="First and last name"
            icon={<AccountBox />}
            component={AdvInput}
          />
          <p>Date of Birth:</p>
          <Field
            name="application_date_of_birth"
            placeholder="YYYY/MM/DD"
            icon={<CalendarMonth />}
            component={AdvInput}
          />
          <p>Organization / Team:</p>
          <Field
            name="application_organization"
            placeholder="Any affiliate team, group, clan, or company"
            icon={<Domain />}
            component={AdvInput}
          />
          <p>What platforms are you willing to stream on?</p>
          <Field
            name="application_streams_on_which_platforms"
            items={platformsAvailable}
            component={BoxSelect}
          />
          <p>What do you identify your stream as?</p>
          <Field name="application_stream_content_type" items={streamTypes} component={BoxSelect} />
          <Button type="submit" loading={!!submitting}>
            {submitSucceeded ? 'Application Submitted!' : 'Submit Application'}
          </Button>
        </form>
      )}
    </Form>
  );
};

BroadcasterApplicationForm.propTypes = {
  profile: PropTypes.object,
};

export default BroadcasterApplicationForm;
