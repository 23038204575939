import {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import Button from 'components/molecules/Button';
import {useSelector} from 'react-redux';
import {Close} from 'mdi-material-ui';
import Modal from 'react-modal';
import {times} from 'lodash';
import {fromModal} from 'store/selectors';
import useModal from 'hooks/useModal';

const MultiPageModal = ({
  onSubmit,
  initialValues,
  contentLabel,
  isOpen,
  isUpdating,
  fullscreen,
  name,
  validate,
  pages,
}) => {
  const [page, setPage] = useState(1);
  const [, modalHide] = useModal();
  const modalOpen = useSelector((state) => isOpen || fromModal.isOpen(state, name));
  const pageCount = pages.length;
  const isLastPage = page === pageCount;
  Modal.setAppElement(document.getElementById('root'));

  useEffect(() => {
    return () => modalOpen && (() => modalHide(name));
  }, [modalOpen, modalHide, name]);

  const formValidate = (values) => {
    return validate ? validate(values) : {};
  };

  const handleSubmit = useCallback(
    (values) => {
      if (isLastPage) {
        return onSubmit(values).then(() => modalHide(name));
      } else {
        setPage(Math.min(page + 1, pageCount));
      }
    },
    [isLastPage, onSubmit, modalHide, name, page, pageCount],
  );

  return (
    <Modal
      className={
        fullscreen
          ? {
              base: 'AdvModal multiPageModal form fullscreen buttonRight',
              beforeClose: 'remove',
              afterOpen: '',
            }
          : {
              base: 'AdvModal multiPageModal form buttonRight',
              beforeClose: 'remove',
              afterOpen: '',
            }
      }
      isOpen={modalOpen}
      onRequestClose={() => modalHide(name)}
      onAfterOpen={() => setPage(1)}
      overlayClassName={{
        base: 'modalBackdrop',
        beforeClose: '',
        afterOpen: '',
      }}
      shouldCloseOnOverlayClick
      contentLabel={contentLabel}
      parentSelector={() => document.querySelector('#modalParent')}
    >
      <Close
        className="remove"
        onClick={() => modalHide(name)}
        onKeyPress={() => modalHide(name)}
        tabIndex={0}
        role="button"
        aria-label="close"
      />
      <Form initialValues={initialValues} validate={formValidate} onSubmit={handleSubmit}>
        {({handleSubmit, submitting, form}) => (
          <div className="multiPageModalContent">
            {pages.length > 1 && (
              <div className="modalPageIndicators">
                {times(pageCount, (index) => (
                  <div
                    key={index}
                    className={`pageDotContainer has${pageCount} ${
                      index <= page - 2 ? 'passed' : ''
                    }`}
                  >
                    <div
                      className={`pageDot ${index <= page - 1 ? 'passed' : ''}`}
                      role="button"
                      tabIndex={-1}
                    >
                      {index + 1}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <form
              onSubmit={(data) =>
                handleSubmit(data)?.then(() => {
                  const {submitSucceeded} = form.getState();
                  submitSucceeded && modalHide(name);
                })
              }
            >
              {pages[page - 1](form)}

              <div className="pageDirections">
                {page > 1 && (
                  <span className="direction left">
                    <Button
                      type="direction left"
                      handleClick={() => setPage(Math.max(page - 1, 0))}
                    >
                      <div className="arrow" />
                      <p>Back</p>
                    </Button>
                  </span>
                )}
                <span className="direction right">
                  <Button type="submit" disabled={submitting}>
                    <p>{isLastPage ? (isUpdating ? 'Update' : 'Submit') : 'Next'}</p>
                    {!isLastPage && <div className="arrow right" />}
                  </Button>
                </span>
              </div>
            </form>
          </div>
        )}
      </Form>
    </Modal>
  );
};

MultiPageModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.node),
  isOpen: PropTypes.bool,
  contentLabel: PropTypes.string.isRequired,
  fullscreen: PropTypes.bool,
  isUpdating: PropTypes.bool,
  name: PropTypes.string,
  validate: PropTypes.func,
  pages: PropTypes.arrayOf(PropTypes.func),
};

export default MultiPageModal;
