import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import {times} from 'lodash';
import moment from 'moment';

import SingleDatePicker from 'components/molecules/SingleDatePicker';
import Select from 'components/molecules/Select';

const SelectHours = ({defaultLabel}) => (
  <>
    <option value="">{defaultLabel}</option>
    {times(12, (index) => (
      <option key={`${index}AM`} value={index}>{`${index}:00 AM`}</option>
    ))}
    <option key="12PM" value={12}>
      12:00 PM
    </option>
    {times(11, (index) => (
      <option key={`${index + 1}PM`} value={index + 13}>{`${index + 1}:00 PM`}</option>
    ))}
  </>
);

const setHourValue = (value, date) => {
  let baseDatetime = moment();
  if (date) {
    baseDatetime = moment(date);
  }
  baseDatetime.minutes(0);
  baseDatetime.seconds(0);
  return baseDatetime.hours(value || 0).toISOString();
};

const setDayValue = (value, date) => {
  const baseDatetime = moment(date);
  baseDatetime.minutes(0);
  baseDatetime.seconds(0);
  return value.hours(baseDatetime.hour() || 0).toISOString();
};

const DateTimeField = ({
  specifyTime,
  initialValue,
  fieldName,
  absoluteStart,
  absoluteEnd,
  extendsBeforeCurrentDay,
  disabled,
  placeholder,
  appendToBody,
  title,
}) => {
  return (
    <div className="datePickersWithHour">
      <div className="date">
        <p>{title}</p>
        <Field
          name={fieldName}
          placeholder={placeholder || 'Start Date'}
          component={SingleDatePicker}
          initialTime={initialValue}
          absoluteStart={absoluteStart}
          absoluteEnd={absoluteEnd}
          extendsBeforeCurrentDay={extendsBeforeCurrentDay}
          disabled={disabled}
          parse={(value) => setDayValue(value, initialValue)}
          appendToBody={appendToBody}
        />
        {specifyTime && (
          <Field
            component={Select}
            name={fieldName}
            disabled={disabled}
            format={(value) => (value ? new Date(value).getHours() : undefined)}
            parse={(value) => setHourValue(value, initialValue)}
            noError
          >
            <SelectHours defaultLabel="Start Time" />
          </Field>
        )}
      </div>
    </div>
  );
};

SelectHours.propTypes = {
  defaultLabel: PropTypes.string,
};

DateTimeField.propTypes = {
  specifyTime: PropTypes.bool,
  initialValue: PropTypes.string,
  fieldName: PropTypes.string,
  absoluteStart: PropTypes.string,
  absoluteEnd: PropTypes.string,
  extendsBeforeCurrentDay: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  appendToBody: PropTypes.bool,
  title: PropTypes.string,
};

export default DateTimeField;
