import {useSelector} from 'react-redux';
import {fromResources} from 'store/selectors';
import {isPending} from 'redux-saga-thunk';

import EmptySplash from 'components/molecules/EmptySplash';
import BroadcasterComponentTemplate from '../../components/BroadcasterComponentTemplate';
import CustomLinkComponentPreview from '../../components/CustomLinkComponentPreview';
import {AdvModal} from 'containers';
import ActionCompleteForm from '../../forms/ActionCompleteForm';
import {useComponents, useCampaigns, useBroadcasterActions} from 'hooks';

const BroadcasterLinks = () => {
  const components = useComponents();
  const campaigns = useCampaigns();
  const actions = useBroadcasterActions();
  const loading = useSelector(
    (state) =>
      (isPending(state, 'componentsListRead') &&
        !fromResources.resourceExists('components')(state)) ||
      (isPending(state, 'broadcasterActionsListRead') &&
        !fromResources.resourceExists('broadcasterActions')(state)),
  );

  const linkComponents = components
    .filter(
      (component) =>
        ['custom-link'].includes(component.type) && !component.is_deleted && component.is_ready,
    )
    .map((component) => ({
      ...component,
      campaign: campaigns.find((c) => c.slug === component.campaign),
    }));

  const filteredActions = actions
    .filter((action) => !action.completion_time && ['custom-link-add'].includes(action.type.slug))
    .map((action) => ({
      ...action,
      component: linkComponents.find((c) => c.id === action.version.component_id),
    }));

  return (
    <BroadcasterComponentTemplate loading={loading} loadComponents loadBroadcasterActions>
      <h2 className="typeSectionName">Custom Links</h2>
      {linkComponents.length > 0 && (
        <p className="topText">
          These custom links are available for any purpose, and are suitable for either recurring or
          one-time use. Please use only the links provided by the Advocate platform, and note that
          links are unique per broadcaster.
        </p>
      )}
      <div className="broadcasterComponents flex">
        {linkComponents
          .filter((c) => !c.activeVersion.config.is_fulfillable)
          .sort((a, b) => (a > b.type ? -1 : 0))
          .map((component) => (
            <CustomLinkComponentPreview
              component={component}
              version={component.activeVersion}
              key={`component-${component.id}`}
            />
          ))}
        {filteredActions
          .sort((a, b) => (b.version.created > a.version.created ? 1 : -1))
          .map((action, index) => (
            <CustomLinkComponentPreview
              action={action}
              component={action.component}
              version={action.version}
              key={`action-${action.id}`}
            />
          ))}
        {linkComponents.length === 0 && filteredActions.length === 0 && (
          <EmptySplash loading={loading}>
            <h3>You don&apos;t have any custom links assigned</h3>
          </EmptySplash>
        )}
      </div>

      {/* Confirm Modals */}
      {filteredActions.map((action) => (
        <AdvModal
          name={`action-${action.id}-complete`}
          key={`action-${action.id}-complete`}
          contentLabel="Component Confirm"
        >
          <ActionCompleteForm
            action={action}
            initialData={action}
            modalName={`action-${action.id}-complete`}
          />
        </AdvModal>
      ))}
    </BroadcasterComponentTemplate>
  );
};

export default BroadcasterLinks;
