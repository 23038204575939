import {Field, Form} from 'react-final-form';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {required, useValidator, maxLength} from 'services/validation';
import {resourceCreateRequest, modalHide} from 'store/actions';

import AdvInput from 'components/molecules/AdvInput';
import Button from 'components/molecules/Button';

const CampaignLabelForm = ({setSelectedLabel, campaign}) => {
  const dispatch = useDispatch();

  const validate = useValidator({
    label: [required, maxLength(32)],
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        await dispatch(
          resourceCreateRequest('labels', {
            name: data.label,
            broadcasters: [],
            campaign,
            components: [],
          }),
        );
      } catch (error) {
        return error;
      }
    },
    [campaign, dispatch],
  );

  const onSubmitSuccess = useCallback(
    (result) => {
      dispatch(modalHide('campaign-label-add'));
      dispatch(modalHide('campaign-channels-label-add'));
      setSelectedLabel && setSelectedLabel(result);
    },
    [dispatch, setSelectedLabel],
  );

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({handleSubmit, error, submitting, form, submitError}) => (
        <form
          onSubmit={(data) =>
            handleSubmit(data)?.then(() => {
              const {submitSucceeded, values} = form.getState();
              submitSucceeded && onSubmitSuccess(values['label']);
            })
          }
        >
          <label htmlFor="Campaign Link Form">Add New Campaign Group</label>
          <p className="help">Enter a unique group name</p>

          <Field name="label" placeholder="Group name" type="text" component={AdvInput} />

          <Button loading={!!submitting}>Create Group</Button>
          {(error || submitError) && (
            <div className="help is-danger centered">{error || submitError}</div>
          )}
        </form>
      )}
    </Form>
  );
};

CampaignLabelForm.propTypes = {
  setSelectedLabel: PropTypes.func,
  campaign: PropTypes.string,
};

export default CampaignLabelForm;
